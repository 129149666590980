import { ReactNode, useEffect, useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { InitialWireFormType } from "@gemini-ui/constants/giact";
import { usePageData } from "@gemini-ui/contexts";
import { Feedback, Modal, Text } from "@gemini-ui/design-system";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import AddWireFundingSourceFlow from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { BankSettingsPageDataType, XfersPagePropsType } from "@gemini-ui/transformers/PaymentMethods";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currency: CurrencyShortNameFiat;
  isInstitutional: boolean;
  bankAccountUuid?: string;
  setAjaxData: (data: unknown) => void;
  initialWireFormValues?: InitialWireFormType;
  onBack?: () => {};
  isSettingsOrOnboarding?: boolean;
  subaccountHashid: string;
  scope?: PaymentTypeScope;
};
/* istanbul ignore next */
const { BankSettingsController } = jsRoutes.com.gemini.web.server.funding.controllers;
const docUploadUrl =
  jsRoutes.com.gemini.web.server.onboarding.controllers.services.FullVerificationController.get().url;

const MODAL_STATE = {
  loading: "loading",
  verificationRequired: "verificationRequired",
  addWireFundingSourceFlow: "addWireFundingSourceFlow",
} as const;

const WireSettings = ({
  isOpen,
  onClose,
  currency,
  isInstitutional,
  bankAccountUuid,
  setAjaxData,
  initialWireFormValues,
  onBack,
  isSettingsOrOnboarding,
  subaccountHashid,
  scope,
}: Props) => {
  const { intl } = useIntl();
  const [modalState, setModalState] = useState<keyof typeof MODAL_STATE>(MODAL_STATE.loading);
  const [xfers, setXfers] = useState<XfersPagePropsType>(null);

  const {
    templateProps: {
      account: { geminiEntity },
      user: { isFullyVerified, isBasicPlusTier },
    },
  } = usePageData<BankSettingsPageDataType>();
  const isUserVerified = isFullyVerified || isBasicPlusTier;
  useEffect(() => {
    axios
      .get(BankSettingsController.get().url, {
        headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
      })
      .then(({ data }: { data: { pageProps: BankSettingsPageDataType } }) => {
        // TODO remove usage of page props
        const { xfers } = data.pageProps;
        setXfers(xfers);
        setModalState(isUserVerified ? MODAL_STATE.addWireFundingSourceFlow : MODAL_STATE.verificationRequired);
      });
  }, [isUserVerified, subaccountHashid]);

  if (modalState === MODAL_STATE.loading) return <Modal onBack={onBack} isOpen={isOpen} onClose={onClose} loading />;

  if (modalState === MODAL_STATE.verificationRequired) {
    return (
      <Modal
        onBack={onBack}
        isOpen={isOpen}
        onClose={onClose}
        title={intl.formatMessage({
          defaultMessage: "Identity verification required",
        })}
      >
        <Feedback status="warning">
          <Feedback.Title>
            {intl.formatMessage({
              defaultMessage: "Identity verification required for wire deposits",
            })}
          </Feedback.Title>
          <Feedback.Body>
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "Your identity must be verified before you manually deposit funds into your Gemini account. <TextLink>Verify identity</TextLink>",
              }),
              {
                TextLink: (v: ReactNode) => <Text.Link href={docUploadUrl}>{v}</Text.Link>,
              }
            )}
          </Feedback.Body>
        </Feedback>
      </Modal>
    );
  }

  if (modalState === MODAL_STATE.addWireFundingSourceFlow) {
    return (
      <AddWireFundingSourceFlow
        geminiEntity={geminiEntity}
        onBack={onBack}
        bankAccountUuid={bankAccountUuid}
        isModalOpen={isOpen}
        defaultFiat={currency}
        onModalClose={onClose}
        isInstitutional={isInstitutional}
        setAjaxData={setAjaxData}
        initialWireFormValues={initialWireFormValues}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        xfers={xfers}
        subaccountHashid={subaccountHashid}
        scope={scope}
      />
    );
  }
};

export const RenderComponent = WireSettings;
export default WireSettings;
