import { memo } from "react";
import { AnimatedHeader } from "@gemini-ui/components/AnimatedHeader";
import TwoFactorAuthLottie from "@gemini-ui/images/animations/2FA_graphic.json";

type Props = {
  width?: string;
};

const TwoFactorAuth = ({ width }: Props) => {
  const file = TwoFactorAuthLottie;
  const { ip: firstFrame, op: lastFrame } = file;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <AnimatedHeader
      file={file}
      delay={50}
      height={256}
      width={isSafari && !width ? "75%" : width}
      // Play once all the way through, then repeat the "HandleSparkle" animation.
      segmentsToPlay={[
        [firstFrame, lastFrame],
        [120, lastFrame],
      ]}
      loop
    />
  );
};

export const TwoFactorAuthAnimation = memo(TwoFactorAuth);
