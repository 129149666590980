import { useTheme } from "@emotion/react";
import { IconCheck, IconInfoOutlined } from "@hubble/icons";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Button, Card, Flex, IconBadge, List, ListItem, Modal, Text } from "@gemini-ui/design-system";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks/useGrowFeatureFlags";
import {
  AddPaymentMethodType,
  getEligibilityMapping,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { useIntl } from "@gemini-ui/utils/intl";
type AddPaymentMethodSuccessModalProps = {
  onClose: () => void;
  paymentMethodType: string;
  isOpen?: boolean;
  showDepositInstructions?: () => void;
  onSuccessCallback?: () => void;
  isSettingsOrOnboarding?: boolean;
  scope?: PaymentTypeScope;
  openSelectPaymentMethodModal?: () => void;
};

export const AddPaymentMethodSuccessModal = ({
  isOpen,
  onClose,
  paymentMethodType,
  showDepositInstructions,
  isSettingsOrOnboarding,
  onSuccessCallback,
  openSelectPaymentMethodModal,
  scope,
}: AddPaymentMethodSuccessModalProps) => {
  const { intl } = useIntl();
  const theme = useTheme();
  const colorScheme = theme.colorScheme;
  const isWire = paymentMethodType === AddPaymentMethodType.WIRE;
  const isXfers = paymentMethodType === AddPaymentMethodType.XFERS;
  const isRtp = paymentMethodType === AddPaymentMethodType.RTP;
  const { toggleModal } = useGlobalModal();
  const { eligibleForStaking, eligibleForPrivateStaking } = useGrowFeatureFlags();
  const showStaking = eligibleForStaking || eligibleForPrivateStaking;
  const onDoneCTA = scope => {
    if (scope === PaymentTypeScope.INSTANT_TRADE || scope === PaymentTypeScope.TRADE) {
      openSelectPaymentMethodModal();
      return;
    }
    // Reopens the cash deposit/withdraw modal
    if ((scope === PaymentTypeScope.DEPOSIT || scope === PaymentTypeScope.WITHDRAW) && onSuccessCallback) {
      onSuccessCallback();
      return;
    }
    onClose();
  };
  const isPayPalOrDebit =
    paymentMethodType === AddPaymentMethodType.PAYPAL || paymentMethodType === AddPaymentMethodType.DEBIT;
  const isPayPal = paymentMethodType === AddPaymentMethodType.PAYPAL;
  const isDebit = paymentMethodType === AddPaymentMethodType.DEBIT;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex.Column textAlign="center" justifyContent="center" align="center">
        <IconBadge
          size="xl"
          mb={2}
          backgroundColor={theme.colorScheme.status.default.background.positive}
          icon={<IconCheck color={theme.colorScheme.status.default.content.positive} />}
        />
        {!isPayPalOrDebit && (
          <Text.Body size="xl" bold>
            {intl.formatMessage({
              defaultMessage: "Your bank account is added",
            })}
          </Text.Body>
        )}
        {isPayPal && (
          <Text.Body size="xl" bold>
            {intl.formatMessage({
              defaultMessage: "Your PayPal account is added",
            })}
          </Text.Body>
        )}
        {isDebit && (
          <Text.Body size="xl" bold>
            {intl.formatMessage({
              defaultMessage: "Your debit card is added",
            })}
          </Text.Body>
        )}
        <Text.Body size="sm" color={colorScheme.content.secondary} mb={2}>
          {intl.formatMessage({
            defaultMessage: "It can be used for:",
          })}
        </Text.Body>
      </Flex.Column>

      <Card variant="filled" mt={1} padding="none">
        <List>
          {getEligibilityMapping(intl)[paymentMethodType].map((eligibilityObject, index) => {
            if (!showStaking && eligibilityObject.id === "stake") {
              return null;
            } else
              return (
                <ListItem key={eligibilityObject.label}>
                  <Flex alignItems="center" justifyContent="center">
                    {!eligibilityObject.instructions ? (
                      <IconBadge size="sm" backgroundColor="" icon={<IconCheck />} />
                    ) : (
                      <IconBadge size="sm" backgroundColor="" icon={<IconInfoOutlined />} />
                    )}
                    {!eligibilityObject.instructions ? (
                      <Text.Body size="sm" ml={1}>
                        {eligibilityObject.label}
                      </Text.Body>
                    ) : (
                      <Flex.Column ml={1} width="350px">
                        <Text.Body size="sm">{eligibilityObject.label}</Text.Body>
                        <Text.Body size="xs" color={colorScheme.content.secondary}>
                          {eligibilityObject.instructions}
                        </Text.Body>
                      </Flex.Column>
                    )}
                  </Flex>
                </ListItem>
              );
          })}
        </List>
      </Card>

      {(isWire || isXfers || isRtp) && (
        <Button.Group>
          <Button.Primary onClick={() => showDepositInstructions()}>
            {intl.formatMessage({
              defaultMessage: "See deposit instructions",
            })}
          </Button.Primary>
        </Button.Group>
      )}

      {isSettingsOrOnboarding && !isWire && !isXfers && !isRtp && (
        <Button.Group>
          {!(paymentMethodType === AddPaymentMethodType.DEBIT) && (
            <Button.Secondary
              onClick={() => {
                onClose();
                toggleModal(GlobalModalType.CashDepositModal);
              }}
            >
              {intl.formatMessage({
                defaultMessage: "Make deposit",
              })}
            </Button.Secondary>
          )}
          <Button.Primary data-testid="go-to-market-btn" href="/market" onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: "See Market",
            })}
          </Button.Primary>
        </Button.Group>
      )}

      {!isSettingsOrOnboarding && !isWire && !isXfers && (
        <Button.Group>
          <Button.Primary onClick={() => onDoneCTA(scope)}>
            {intl.formatMessage({
              defaultMessage: "Done",
            })}
          </Button.Primary>
        </Button.Group>
      )}
    </Modal>
  );
};
