import { Fragment } from "react";
import { IconArrowsHorizontal, IconProfileOutlined } from "@hubble/icons";
import { optimizelyClient } from "@gemini-ui/analytics";
import { AccountMenu } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu";
import { FiatMenu } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/FiatMenu";
import { TransferMenu } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/TransferMenu";
import NavMenu from "@gemini-ui/components/Header/navigation/IconContainer/NavMenu";
import { VerticalDivider } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import { NotificationCenter } from "@gemini-ui/components/Header/navigation/NotificationCenter";
import TransferButton from "@gemini-ui/components/Header/navigation/TransferButton";
import { PopoverId } from "@gemini-ui/components/Header/navigation/utils";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { usePageData } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Badge, Spacer, Tooltip } from "@gemini-ui/design-system";
import FiatIcons from "@gemini-ui/images/icons/nav/fiat";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  moreThanOneAccount: boolean;
  isDerivativesAccount: boolean;
  lockout?: LOCKOUT_TYPES;
}

const HeaderIconContainer = ({ moreThanOneAccount, isDerivativesAccount, lockout }: Props) => {
  const { intl } = useIntl();

  const {
    pageName,
    templateProps: {
      account: { defaultFiat, supportedFiat },
      user: { fullName, groupsInfo, institutionName },
    },
  } = usePageData();

  const { toggleModal } = useGlobalModal();
  const shouldHideCurrencySelector = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_HIDE_CURRENCIES_FOR_MOONBASE_LAUNCH
  );

  const DefaultFiatIcon = FiatIcons[defaultFiat] || FiatIcons["default"];
  const multiFiat = supportedFiat.length > 1 && !shouldHideCurrencySelector;
  const isDerivativeDebitCardFundingEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING
  );

  return (
    <Fragment>
      {pageName !== PageName.Trade && (
        <Tooltip
          textAlign="center"
          data-testid="change-single-default-currency-tooltip"
          overlay={intl.formatMessage(
            defineMessage({
              defaultMessage: "{defaultFiat} is the only available fiat currency in your country at this time.",
            }),
            { defaultFiat }
          )}
          disabled={multiFiat}
          trigger={["hover", "click", "focus"]}
        >
          {!shouldHideCurrencySelector && (
            <NavMenu
              id={PopoverId.FIAT}
              placement="end"
              width={256}
              renderComponent={multiFiat && <FiatMenu defaultFiat={defaultFiat} supportedFiat={supportedFiat} />}
              icon={<DefaultFiatIcon />}
              iconLabel={defaultFiat}
            />
          )}
        </Tooltip>
      )}
      <div id="active-trader-layout-button"></div>
      <Spacer ml={0.5} />
      <NotificationCenter />
      <Spacer ml={0.5}>
        {isDerivativesAccount && !isDerivativeDebitCardFundingEnabled ? (
          <TransferButton onClick={() => toggleModal(GlobalModalType.PerpsOnboardingTransferFormModal)} />
        ) : (
          <NavMenu
            id={PopoverId.TRANSFER}
            placement="end"
            width={isDerivativeDebitCardFundingEnabled && isDerivativesAccount ? 384 : 256}
            renderComponent={
              <TransferMenu
                moreThanOneAccount={moreThanOneAccount}
                lockout={lockout}
                isDerivativesAccount={isDerivativesAccount}
              />
            }
            icon={<IconArrowsHorizontal />}
            iconLabel={intl.formatMessage({ defaultMessage: "Transfer" })}
          />
        )}
      </Spacer>
      <VerticalDivider ml={1.5} mr={2} />

      <NavMenu
        id={PopoverId.ACCOUNT}
        placement="end"
        width={280}
        renderComponent={<AccountMenu userName={fullName} institutionName={institutionName} groupsInfo={groupsInfo} />}
        icon={<IconProfileOutlined />}
        iconLabel={intl.formatMessage({ defaultMessage: "Account" })}
        badge={
          lockout === LOCKOUT_TYPES.PENDING && (
            <Badge size="xs" status="info" ml={1}>
              {intl.formatMessage({ defaultMessage: "Verifying" })}
            </Badge>
          )
        }
        isIconOnly
      />
    </Fragment>
  );
};

export default HeaderIconContainer;
