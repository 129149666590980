import * as Sentry from "@sentry/browser";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import axios, { CACHE_ID } from "@gemini-ui/services/axios/cache";
import { stateList } from "@gemini-ui/US_States";

export const isAnalyticsSuppressed = (state?: string) => {
  const stateUpper = state ? state.toUpperCase() : "";
  return stateList[stateUpper] === stateList.CA
    ? optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CA_CCPA_ANALYTICS_SUPPRESSED)
    : false;
};

/*

 isUserLogged in method considers window object user variable to find whether user has logged in or not.
 The get ccpa settings should ideally be called on logged in state but in case it gets invoked in un authenticated
 state it sends back default value as false

*/

export function isUserLoggedIn(windowData) {
  const isLoggedIn = Boolean(windowData?.initialData?.templateProps?.user);
  return isLoggedIn;
}

export function getCcpaEnabledFromResponse(responseCcpa) {
  const { data } = responseCcpa || {};
  const { result } = data || {};

  return Boolean(result);
}

export async function getIsLoggedInCcpaEnabled(windowData, jsRoutes) {
  let isLoggedInCcpaEnabled = false;
  const isLoggedIn = isUserLoggedIn(windowData);

  if (isLoggedIn) {
    const ccpaRoute =
      jsRoutes.com.gemini.web.server.experience.controllers.ProfileSettingsController.getCcpaSettings().url;
    try {
      const responseCcpa = await axios.get(ccpaRoute, { id: CACHE_ID.ccpa, cache: { ttl: 15000 } });
      isLoggedInCcpaEnabled = getCcpaEnabledFromResponse(responseCcpa);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  return isLoggedInCcpaEnabled;
}
