import {
  BANK_REGISTRATION_ROUTE,
  RegisterBankResponse,
  RtpBankRegistrationForm,
  SUPPORTED_BANKS_ROUTE,
  SupportedBanks,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import axios from "@gemini-ui/services/axios";

export const getSupportedBanks = () => axios.get<{ supportedBanks: SupportedBanks }>(SUPPORTED_BANKS_ROUTE);
export const registerBank = ({ accountNumber, nameOnAccount, bankCode, bic }: RtpBankRegistrationForm) => {
  const data = {
    bankAccountNumber: accountNumber,
    nameOnAccount,
    bankName: bankCode,
    bic,
    fastEnabled: true,
  };
  return axios.post<{ data: RegisterBankResponse }>(BANK_REGISTRATION_ROUTE, data);
};
