import { Fragment } from "react";
import styled from "@emotion/styled";
import { IconChevronLeftLarge, IconClose } from "@hubble/icons";
import { Button, Flex, Spacing } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

export interface Props {
  onBack?: () => void;
  onClose: () => void;
  title: React.ReactNode;
  isEdit?: boolean;
}

const StyledFlex = styled(Flex)`
  margin-top: -${Spacing.scale[3]};
`;

export const ModalTitle = ({ onBack, onClose, title, isEdit }: Props) => {
  const { intl } = useIntl();
  return (
    <Fragment>
      <StyledFlex align="center" justify="space-between" mb={3}>
        {isEdit ? (
          <Button.Secondary size="sm" cta={intl.formatMessage({ defaultMessage: "Edit" })} onClick={onBack} />
        ) : (
          onBack && (
            <Button.Secondary
              size="sm"
              aria-label={intl.formatMessage({ defaultMessage: "Back button" })}
              icon={<IconChevronLeftLarge size="sm" />}
              onClick={onBack}
            />
          )
        )}
        <Flex flex={1} justify="flex-end">
          <Button.Secondary
            size="sm"
            aria-label={intl.formatMessage({ defaultMessage: "Close button" })}
            icon={<IconClose size="sm" />}
            onClick={onClose}
          />
        </Flex>
      </StyledFlex>
      {title}
    </Fragment>
  );
};
