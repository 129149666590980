import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import axios, { CACHE_ID } from "@gemini-ui/services/axios/cache";
import { HEADERS } from "@gemini-ui/services/constants";

export const getIsUserInOnboarding = (lockout: LOCKOUT_TYPES) => {
  return lockout === LOCKOUT_TYPES.UNVERIFIED || lockout === LOCKOUT_TYPES.PENDING;
};

export const getLockout = async (subaccountHashid?: string) => {
  const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : {};
  try {
    const response = await axios.get<{ lockout: LOCKOUT_TYPES | null }>(
      jsRoutes.com.gemini.web.server.auth.controllers.LockoutController.getLockout().url,
      {
        ...config,
        id: CACHE_ID.lockout,
        cache: {
          ttl: 1000 * 30, // 30 seconds
        },
      }
    );

    return response.data.lockout;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export type UseLockoutState = {
  isLoadingLockout: boolean;
  lockoutError: boolean;
  lockout: LOCKOUT_TYPES | null;
  fetchLockout: (loading?: boolean) => Promise<void>;
};

export const useLockout = (isSignedIn = true): UseLockoutState => {
  const [{ isLoadingLockout, lockoutError, lockout }, setLockoutState] = useState({
    isLoadingLockout: isSignedIn ? true : false,
    lockoutError: false,
    lockout: null,
  });

  const fetchLockout = useCallback(async (loading = false) => {
    if (loading) {
      setLockoutState(prev => ({ ...prev, isLoadingLockout: true }));
    }

    const lockoutResponse = await getLockout();

    if (typeof lockoutResponse === "undefined") {
      setLockoutState({ lockout: null, lockoutError: true, isLoadingLockout: false });
      return;
    }

    setLockoutState({ lockout: lockoutResponse, lockoutError: false, isLoadingLockout: false });
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      fetchLockout();
    }
  }, [fetchLockout, isSignedIn]);

  return {
    lockout,
    fetchLockout,
    isLoadingLockout,
    lockoutError,
  };
};
