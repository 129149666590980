import { IntlShape } from "react-intl";
import { getAdvancedAccountSelectorCopy } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import { SubaccountWithBalances } from "@gemini-ui/services/subaccounts";

export const getAccountTypeCopy = (intl: IntlShape, subaccount: SubaccountWithBalances) => {
  const copy = getAdvancedAccountSelectorCopy(intl);
  return subaccount.trading
    ? subaccount.derivatives
      ? copy.LABELS.DERIVATIVES
      : copy.LABELS.EXCHANGE
    : copy.LABELS.CUSTODY;
};
