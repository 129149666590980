import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { CurrencyOrderQuote, OrderResult } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import axios, { AxiosRequestConfig } from "@gemini-ui/services/axios";

export const instantOrderUrl = jsRoutes.com.gemini.web.server.trading.controllers.InstantOrderController.post().url;

export type InstantOrderRouteQueryParams = CurrencyOrderQuote["formData"];

export const createInstantOrder = async (
  args: InstantOrderRouteQueryParams,
  config?: AxiosRequestConfig
): Promise<OrderResult> => {
  return await axios
    .post(instantOrderUrl, args, config)
    .then(res => res.data)
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const useInstantOrder = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState<OrderResult | null>(null);

  const submitOrder = useCallback(async (args: InstantOrderRouteQueryParams, config?: AxiosRequestConfig) => {
    try {
      setLoading(true);
      const data = await createInstantOrder(args, config);
      setData(data);
      setError(null);

      // Returning the data and setting the data to local state is redundant but may be helpful depending on how this function is called.
      return data;
    } catch (e) {
      setError(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    submitOrder,
    loading,
    error,
  };
};
