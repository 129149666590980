import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { applicationApi } from "@gemini-ui/client/api";
import { CardColorEnum, Currency } from "@gemini-ui/client/credit";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { CC_PATH } from "@gemini-ui/pages/Credit/utils/constants";
import { AxiosError } from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";

interface UpdateCardAndRewardRequest {
  appId: string;
  cardDesign: CardColorEnum;
  rewardCurrency: Currency;
}

export const useUpdateCardAndReward = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();
  const { intl } = useIntl();

  const updateCardAndReward = async ({ appId, cardDesign, rewardCurrency }: UpdateCardAndRewardRequest) => {
    setIsLoading(true);
    try {
      await applicationApi.updateApplication(appId, {
        id: appId,
        cardDesign,
        rewardCurrency,
      });
      window.location.assign(CC_PATH.DASHBOARD);
    } catch (error) {
      showAlert({
        type: AlertTypes.ERROR,
        message: getError(
          error as AxiosError,
          intl.formatMessage({
            defaultMessage: "Something went wrong. Please try again or contact customer support at 1 (877) 725-1116.",
          })
        ),
      });
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { updateCardAndReward, isLoading };
};
