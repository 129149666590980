import { format, isValid, parse } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";

export const MonthDayYearMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

export const formatDate = (date: Date | string, dateFormat: DateFormats): Date | string => {
  // Return the date if date is not a Date and is less than 10 characters like 01/01/202
  if (!(date instanceof Date) && date.length < 10) {
    return date;
  }
  const parsedDate = date instanceof Date ? date : parse(date, dateFormat, new Date());

  const _date = toZonedTime(parsedDate, "UTC");

  if (isValid(_date)) {
    return format(_date, dateFormat);
  } else {
    return _date;
  }
};
