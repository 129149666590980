import Cookies from "js-cookie";
import { optimizelyClient } from "@gemini-ui/analytics";
import { useFeatureFlag } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { FeatureFlag } from "@gemini-ui/constants/templateProps/featureFlags";
import { getIsGeminiSG } from "@gemini-ui/pages/transfers/utils";

/**
 * @typedef {Object} DbsFastFeatureFlags
 * @property {boolean} isEnabled - Whether DBS Fast is enabled for this entity
 * @property {boolean} flags.isDbsFastMigrationEnabled - Status of the DBS Fast migration feature flag
 * @property {boolean} flags.isDbsFastConfigUiEnabled - Status of the DBS SG integration feature in Optimizely
 * @property {boolean} flags.isGeminiSingapore - Whether the entity is located in Singapore
 * @property {boolean} flags.isDbsFastBannerEnabled - Show the banner for deposit instructions change
 */
type DbsFastFeatureFlags = {
  isEnabled: boolean;
  flags: {
    isDbsFastMigrationEnabled: boolean; //
    isDbsFastConfigUiEnabled: boolean;
    isDbsFastBannerEnabled: boolean;
    isGeminiSingapore: boolean;
  };
};

/**
 * Hook that determines DBS Fast feature availability based on feature flags and entity location.
 *
 * @param entity - The Gemini entity to check for DBS Fast feature eligibility
 * @returns {DbsFastFeatureFlags} Object containing feature flag statuses
 */
export const useDbsFastFeatureFlags = (entity: GeminiEntity): DbsFastFeatureFlags => {
  let isEnabled = false;

  const isDbsFastMigrationEnabled = useFeatureFlag(FeatureFlag.DbsFastMigrationEnabled);
  const isDbsFastConfigUiEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.DBS_SG_INTEGRATION_ENABLED
  );

  const isGeminiSingapore = getIsGeminiSG(entity);

  if ((isDbsFastMigrationEnabled || isDbsFastConfigUiEnabled) && isGeminiSingapore) isEnabled = true;

  const isDbsFastBannerEnabled =
    isEnabled && optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DBS_FAST_BANNER_ENABLED);

  if (Cookies.get("BuildOverride")?.split("=")?.[1]) {
    console.table({
      isDbsFastMigrationEnabled,
      isDbsFastConfigUiEnabled,
      isGeminiSingapore,
      isDbsFastBannerEnabled,
      isEnabled,
      entity,
    });
  }

  return {
    isEnabled,
    flags: {
      isDbsFastBannerEnabled,
      isDbsFastMigrationEnabled,
      isDbsFastConfigUiEnabled,
      isGeminiSingapore,
    },
  };
};
