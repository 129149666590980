import { ReactNode, useCallback, useState } from "react";
import { useTheme } from "@emotion/react";
// eslint-disable-next-line no-restricted-imports
import { Formik } from "formik";
import * as Yup from "yup";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Checkbox, Modal, Spacer, Text } from "@gemini-ui/design-system";
import ProgressBar from "@gemini-ui/pages/register/OnboardingLayout/ProgressBar";
import { INPUT_SIZES } from "@gemini-ui/pages/register/Verify/constants";
import FormikAddressForm, { schema } from "@gemini-ui/pages/register/Verify/FormikAddressForm";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface FormValues {
  streetAddress?: string;
  apt?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  countryFullName?: string;
  setCountryDetails?: (country: string, countryFullName: string) => void;
}
interface AddBillingAddressManualModalProps extends FormValues {
  onToggle: ToggleDebitCardModal;
  updateBillingAddress: (BillingAddress) => void;
  useDefaultAddress?: boolean;
  setUseDefaultAddress?: (useDefaultAddress: boolean) => void;
  isManualAddressMode?: boolean;
  onBack?: () => void;
  openAddPaymentMethodsModal?: () => void;
}

const AddBillingAddressManualModal = (props: AddBillingAddressManualModalProps) => {
  const { intl } = useIntl();
  const {
    onToggle,
    streetAddress,
    apt,
    locality,
    region,
    postalCode,
    country,
    useDefaultAddress,
    setUseDefaultAddress,
    isManualAddressMode,
    countryFullName,
    setCountryDetails,
    onBack,
    openAddPaymentMethodsModal,
  } = props;
  const [selectedState, setSelectedState] = useState(region);
  const formattedCountry = country?.toLowerCase();
  const theme = useTheme();
  const colorScheme = theme.colorScheme;

  const toggleUseDefaultAddress = useCallback(() => {
    const { name, properties } = EVENTS.ADD_DEBIT_CARD_ADDRESS;
    !useDefaultAddress
      ? track(name, {
          [properties.IS_DEFAULT_ADDRESS_CHECKED]: "Checked",
          [properties.MODE]: "Default",
          [properties.STATUS]: "Open",
        })
      : track(name, {
          [properties.IS_DEFAULT_ADDRESS_CHECKED]: "Unchecked",
          [properties.MODE]: "Manual",
          [properties.STATUS]: "Open",
        });
    setUseDefaultAddress(!useDefaultAddress);
  }, [setUseDefaultAddress, useDefaultAddress]);

  const USE_DEFAULT_ADDRESS_INITIAL_VALUES = {
    streetAddress,
    apt,
    locality,
    region,
    postalCode,
    countryFullName,
  };

  const countryFullNameSchemaValidation = Yup.object().shape({
    countryFullName: isManualAddressMode
      ? Yup.string().required(
          intl.formatMessage({
            defaultMessage: "This field is required.",
          })
        )
      : Yup.string(),
  });

  return (
    <Modal.MultiStep
      isOpen
      onClose={() => {
        onToggle("addBillingAddressManualVisible", true, isManualAddressMode)();
        openAddPaymentMethodsModal();
      }}
      onBack={onBack}
    >
      <ProgressBar progressBarCompletion={33.33} />
      <Text.Heading size="md" mt={2} mb={3}>
        {intl.formatMessage({
          defaultMessage: "What's your billing address?",
        })}
      </Text.Heading>

      <Checkbox
        checked={useDefaultAddress}
        inputSize="md"
        onChange={toggleUseDefaultAddress}
        data-testid="default-address-checkbox"
      >
        <Text.Body size="md">
          {intl.formatMessage({
            defaultMessage: "Use the same address as on my Gemini account",
          })}
        </Text.Body>
      </Checkbox>

      <Spacer mt={3}>
        <Formik
          initialValues={USE_DEFAULT_ADDRESS_INITIAL_VALUES}
          validationSchema={Yup.object().shape({
            ...schema(formattedCountry, intl).fields,
            ...countryFullNameSchemaValidation.fields,
          })}
          onSubmit={(values: FormValues) => {
            const { name, properties } = EVENTS.ADD_DEBIT_CARD_ADDRESS;
            track(name, { [properties.SUBMITTED]: true });
            props.updateBillingAddress({
              address1: values.streetAddress,
              address2: values.apt,
              city: values.locality,
              state: values.region,
              zip: values.postalCode.trim(),
              countryFullName: values.countryFullName,
            });
            onToggle("addDebitCardVisible", false, isManualAddressMode)();
          }}
          enableReinitialize
        >
          {FormikAddressForm({
            selectedState,
            setSelectedState,
            formattedCountry,
            buttonText: intl.formatMessage({ defaultMessage: "Next" }),
            rightAlignedButton: true,
            isReadOnly: !isManualAddressMode,
            inputSize: INPUT_SIZES.medium,
            countryFieldProps: { isDisplayed: isManualAddressMode },
            setCountryDetails: setCountryDetails,
            streetAddressMessage:
              isManualAddressMode &&
              intl.formatMessage(
                defineMessage({
                  defaultMessage: "<StreetDescription>Address cannot be a P.O. Box.</StreetDescription>",
                }),
                {
                  StreetDescription: (v: ReactNode) => (
                    <Text.Body size="xs" color={colorScheme.content.secondary}>
                      {v}
                    </Text.Body>
                  ),
                }
              ),
          })}
        </Formik>
      </Spacer>
    </Modal.MultiStep>
  );
};

export default AddBillingAddressManualModal;
