import { Fragment, memo, ReactNode } from "react";
import _ from "lodash";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { useFeatureFlags } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { Money } from "@gemini-ui/components/Money";
import { GeminiPaymentsDisclaimerContainer } from "@gemini-ui/components/RegulatoryFooter/styles";
import { InterestProvider } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Text } from "@gemini-ui/design-system";
import { buyAndEarn } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/Disclaimer/BuyAndEarn";
import ReadMore from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/Disclaimer/ReadMore";
import { DisclaimerText } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/Disclaimer/styles";
import {
  Action,
  BuyingFrequency,
  OrderQuote,
  TradePaymentMethodType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { getPaymentMethodType } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { RetailTradePaymentMethodType } from "@gemini-ui/transformers/PaymentMethods/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface DisclaimerProps {
  frequency?: string;
  tradingPair: string;
  defaultFiat: CurrencyShortNameFiat;
  orderQuote: OrderQuote | null;
  paymentMethod: TradePaymentMethodType;
  isEarn?: boolean;
  earnProvider?: InterestProvider;
}

interface DisclaimerRenderProps extends DisclaimerProps {
  action: Action;
}

interface BuyDisclaimerProps extends DisclaimerProps {
  geminiEntity: string;
  geminiEntityLegalName: string;
}

interface SellDisclaimerProps {
  tradingPair: string;
  defaultFiat: CurrencyShortNameFiat;
  orderQuote: OrderQuote | null;
  geminiEntity: string;
  geminiEntityLegalName: string;
}

interface ConvertDisclaimerProps {
  orderQuote: OrderQuote | null;
  geminiEntity: string;
  geminiEntityLegalName: string;
}

function isRetailPaymentMethod(paymentMethod: TradePaymentMethodType): paymentMethod is RetailTradePaymentMethodType {
  return (paymentMethod as RetailTradePaymentMethodType)?.paymentMethodType !== undefined;
}

const accountTypeFromPaymentMethod = (paymentMethod: TradePaymentMethodType): AccountType => {
  // If the payment method is already of AccountType, return it
  // Otherwise, handle translating external payment method types
  if (!isRetailPaymentMethod(paymentMethod)) return paymentMethod as AccountType;

  return getPaymentMethodType(paymentMethod);
};

const PriceMovementDisclaimer = () => {
  const { intl } = useIntl();

  return (
    <DisclaimerText size="xs">
      {intl.formatMessage({
        defaultMessage:
          "Orders placed on Gemini are filled immediately against resting orders on the Gemini Exchange at the current best available price. By confirming this order, you accept the risk of price movement.",
      })}
    </DisclaimerText>
  );
};

const EuropeDisclaimer = () => {
  const { intl, buildGeminiPublicLink } = useIntl();

  return (
    <DisclaimerText size="xs">
      {intl.formatMessage(
        defineMessage({
          defaultMessage:
            "Digital asset exchange services are provided by Gemini Intergalactic UK, Ltd. Please consult our <TextLink>User Agreement</TextLink>.",
        }),
        {
          TextLink: (v: ReactNode) => (
            <Text.Link
              target="_blank"
              rel="noopener noreferrer"
              href={buildGeminiPublicLink("legal/gemini-europe-services")}
            >
              {v}
            </Text.Link>
          ),
        }
      )}
    </DisclaimerText>
  );
};

const GeminiPaymentsDisclaimer = () => {
  const { intl, buildGeminiPublicLink } = useIntl();

  return (
    <GeminiPaymentsDisclaimerContainer>
      <Text.Body bold size="sm">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Virtual asset exchange services are provided by Gemini Intergalactic Europe Limited. Please consult our <TextLink>User Agreement</TextLink>.",
          }),
          {
            TextLink: (v: ReactNode) => (
              <Text.Link
                bold
                target="_blank"
                rel="noopener noreferrer"
                href={buildGeminiPublicLink("legal/gemini-digital-assets")}
              >
                {v}
              </Text.Link>
            ),
          }
        )}
      </Text.Body>
    </GeminiPaymentsDisclaimerContainer>
  );
};

const BuyDisclaimer = ({
  orderQuote,
  defaultFiat,
  tradingPair,
  frequency,
  paymentMethod,
  isEarn,
  earnProvider,
  geminiEntity,
  geminiEntityLegalName,
}: BuyDisclaimerProps) => {
  const { intl, buildGeminiPublicLink } = useIntl();
  const quote = _.get(orderQuote, "quote", "");
  const currency = _.get(quote, "proceeds.currency", "");
  const quotePrice = _.get(quote, "formData.price");
  const total = _.get(quote, "totalSpend");
  const { EuropeAgreementsEnabled, IrelandAgreementsEnabled } = useFeatureFlags();
  const isGeminiPaymentsEntity = geminiEntity === "GeminiPayments";

  const paymentAccountType = accountTypeFromPaymentMethod(paymentMethod);

  const isEuropeEnabled = EuropeAgreementsEnabled || IrelandAgreementsEnabled;
  const europeDisclaimer =
    isEuropeEnabled && paymentMethod === AccountType.BALANCE && !isEarn && !isGeminiPaymentsEntity;

  if (europeDisclaimer) {
    return <EuropeDisclaimer />;
  }

  const totalMoney = quote && <Money currency={defaultFiat} value={total.value} />;
  const quoteMoney = quote && <Money.Price value={quotePrice} tradingPair={tradingPair} />;
  const isOneTimeBuy = frequency === BuyingFrequency.Once;

  const earn =
    isEarn &&
    buyAndEarn({
      currency,
      totalMoney,
      quoteMoney,
      isRecurring: !isOneTimeBuy,
      provider: earnProvider,
      geminiEntityLegalName,
      intl,
    });

  const getBalanceText = () => {
    if (isEarn) {
      return <ReadMore short={earn.balance.short} long={earn.balance.long} />;
    }

    if (isGeminiPaymentsEntity) {
      return <GeminiPaymentsDisclaimer />;
    }

    if (isOneTimeBuy) {
      return (
        <ReadMore
          short={
            <DisclaimerText size="xs">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "By placing this order, you authorize {geminiEntityLegalName} to debit {totalMoney} from your account balance for a {currency} purchase at a quoted price of {quoteMoney}.",
                }),
                {
                  totalMoney,
                  currency,
                  quoteMoney,
                  geminiEntityLegalName,
                }
              )}
            </DisclaimerText>
          }
          long={<PriceMovementDisclaimer />}
        />
      );
    } else {
      return (
        <ReadMore
          short={<PriceMovementDisclaimer />}
          long={
            <DisclaimerText size="xs">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "By placing this order, you authorize {geminiEntityLegalName} to debit your account balance for up to <MoneyTotal></MoneyTotal> (the recurring purchase amount).",
                }),
                {
                  MoneyTotal: () => <Money {...total} />,
                  geminiEntityLegalName,
                }
              )}
            </DisclaimerText>
          }
        />
      );
    }
  };

  const getBankText = () => {
    if (isEarn) {
      return <ReadMore short={earn.bank.short} long={earn.bank.long} />;
    }

    if (isOneTimeBuy) {
      return (
        <ReadMore
          short={
            <DisclaimerText size="xs">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "By placing this order, you authorize {geminiEntityLegalName} to debit {totalMoney} from your bank account for a {currency} purchase at a quoted price of {quoteMoney}.",
                }),
                {
                  totalMoney,
                  currency,
                  quoteMoney,
                  geminiEntityLegalName,
                }
              )}
            </DisclaimerText>
          }
          long={
            <Fragment>
              <PriceMovementDisclaimer />
              <DisclaimerText size="xs">
                {intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "You authorize {geminiEntityLegalName} to debit your bank account for {totalMoney} via Bank Transfer (ACH) and, if necessary, to initiate credit entries/adjustments for any debits made in error to your account at the above Financial Institution. You acknowledge that the origination of ACH transactions to your account comply with the provisions of U.S. law. You agree that this authorization cannot be revoked.",
                  }),
                  {
                    totalMoney,
                    geminiEntityLegalName,
                  }
                )}
              </DisclaimerText>
              <DisclaimerText size="xs">
                {intl.formatMessage({
                  defaultMessage:
                    "Your deposit will be credited to your Gemini account and will be available for trading immediately. It should fully clear into your Gemini account within 4-5 business days.",
                })}
              </DisclaimerText>
            </Fragment>
          }
        />
      );
    } else {
      return (
        <ReadMore
          short={<PriceMovementDisclaimer />}
          long={
            <Fragment>
              <DisclaimerText size="xs">
                {intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "You authorize {geminiEntityLegalName} to debit your bank account for up to <MoneyTotal></MoneyTotal> (the recurring purchase amount) via Bank Transfer (ACH) and, if necessary, to initiate credit entries/adjustments for any debits made in error to your account at the above Financial Institution. You acknowledge that the origination of ACH transactions to your account comply with the provisions of U.S. law. You agree that this authorization cannot be revoked.",
                  }),
                  {
                    MoneyTotal: () => <Money {...total} />,
                    geminiEntityLegalName,
                  }
                )}
              </DisclaimerText>
              <DisclaimerText size="xs">
                {intl.formatMessage({
                  defaultMessage:
                    "Your deposit will be credited to your Gemini account and will be available for trading immediately. It should fully clear into your Gemini account within 4-5 business days.",
                })}
              </DisclaimerText>
            </Fragment>
          }
        />
      );
    }
  };

  const getCardText = () => {
    return (
      <DisclaimerText size="xs" data-testid="debit-card-disclaimer">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Processed by {geminiEntityLegalName}. You agree to the <TextLink>terms of this transaction.</TextLink> Debit card transactions typically complete within 15 minutes.",
          }),
          {
            geminiEntityLegalName,
            TextLink: (v: ReactNode) => (
              <Text.Link
                target="_blank"
                rel="noopener noreferrer"
                href={
                  EuropeAgreementsEnabled || IrelandAgreementsEnabled
                    ? buildGeminiPublicLink("legal/gemini-europe")
                    : buildGeminiPublicLink("legal/user-agreement")
                }
              >
                {v}
              </Text.Link>
            ),
          }
        )}
      </DisclaimerText>
    );
  };

  const getPayPalText = () => {
    if (isEarn) {
      return <ReadMore short={earn.paypal.short} long={earn.paypal.long} />;
    }

    if (isOneTimeBuy) {
      return (
        <ReadMore
          short={
            <DisclaimerText size="xs">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "You agree to the terms of this transaction.",
                })
              )}
            </DisclaimerText>
          }
          long={<PriceMovementDisclaimer />}
        />
      );
    } else {
      return (
        <ReadMore
          short={
            <DisclaimerText size="xs">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "By placing this order you authorize {geminiEntityLegalName} to charge your PayPal account for up to {totalMoney} (the recurring purchase amount) on the frequency described above. Your authorization will remain in effect until canceled, and you may cancel it at any time in your portfolio.",
                }),
                {
                  totalMoney,
                  geminiEntityLegalName,
                }
              )}
            </DisclaimerText>
          }
          long={<PriceMovementDisclaimer />}
        />
      );
    }
  };

  return (
    <Fragment>
      {paymentAccountType === AccountType.BALANCE && getBalanceText()}
      {paymentAccountType === AccountType.BANK && getBankText()}
      {paymentAccountType === AccountType.DEBIT_CARD && getCardText()}
      {paymentAccountType === AccountType.PAYPAL && getPayPalText()}
    </Fragment>
  );
};

const SellDisclaimer = ({
  orderQuote,
  defaultFiat,
  tradingPair,
  geminiEntity,
  geminiEntityLegalName,
}: SellDisclaimerProps) => {
  const { intl } = useIntl();
  const { quote } = orderQuote;
  const quotePrice = _.get(quote, "formData.price");
  const total = _.get(quote, "totalSpend");
  const { EuropeAgreementsEnabled, IrelandAgreementsEnabled } = useFeatureFlags();
  const isGeminiPaymentsEntity = geminiEntity === "GeminiPayments";

  if (isGeminiPaymentsEntity) {
    return <GeminiPaymentsDisclaimer />;
  }

  if (EuropeAgreementsEnabled || IrelandAgreementsEnabled) {
    return <EuropeDisclaimer />;
  } else {
    return (
      <ReadMore
        short={
          <DisclaimerText size="xs">
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "By placing this order you agree to allow {geminiEntityLegalName} to debit <MoneyTotal></MoneyTotal> from your account for a [{totalCurrency}/{defaultFiat}] sale at a Quoted Price of <MoneyPriceTradingPairQuotePrice></MoneyPriceTradingPairQuotePrice>.",
              }),
              {
                MoneyTotal: () => <Money {...total} />,
                totalCurrency: total.currency,
                defaultFiat,
                MoneyPriceTradingPairQuotePrice: () => <Money.Price tradingPair={tradingPair} value={quotePrice} />,
                geminiEntityLegalName,
              }
            )}
          </DisclaimerText>
        }
        long={
          <Fragment>
            <PriceMovementDisclaimer />
            <DisclaimerText size="xs">
              {intl.formatMessage({
                defaultMessage:
                  "The proceeds from your sale will be credited to your Gemini account and will be available for withdrawal immediately. Any withdrawal should fully clear from your Gemini account within 4-5 business days.",
              })}
            </DisclaimerText>
          </Fragment>
        }
      />
    );
  }
};

const ConvertDisclaimer = ({ orderQuote, geminiEntity, geminiEntityLegalName }: ConvertDisclaimerProps) => {
  const { intl } = useIntl();
  const { EuropeAgreementsEnabled, IrelandAgreementsEnabled } = useFeatureFlags();
  const isGeminiPaymentsEntity = geminiEntity === "GeminiPayments";

  if (isGeminiPaymentsEntity) {
    return <GeminiPaymentsDisclaimer />;
  } else if (EuropeAgreementsEnabled || IrelandAgreementsEnabled) {
    return <EuropeDisclaimer />;
  } else {
    const { quote } = orderQuote;

    const toAsset = quote?.formData?.to;
    const exchangeRate = quote?.formData?.price;
    const fromAsset = quote?.formData?.from;
    const fromValue = quote?.totalSpend?.value;

    return (
      <ReadMore
        short={
          <DisclaimerText size="xs">
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "By placing this order you agree to the terms of this transaction for a {toAsset} purchase at a Quoted Price of {exchangeRate}. This order will be funded from your {fromAsset} balance.",
              }),
              {
                toAsset,
                exchangeRate,
                fromAsset,
              }
            )}
          </DisclaimerText>
        }
        long={
          <DisclaimerText size="xs">
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "Orders placed on Gemini are filled immediately against resting orders on the Gemini Exchange at the current best available price. By confirming this order, you accept the risk of price movement. You authorize {geminiEntityLegalName} to debit your {fromAsset} balance {fromValue}. For more information, please consult our User Agreement.",
              }),
              {
                fromAsset,
                fromValue,
                geminiEntityLegalName,
              }
            )}
          </DisclaimerText>
        }
      ></ReadMore>
    );
  }
};

const Disclaimer = (props: DisclaimerRenderProps) => {
  const { action } = props;
  const {
    templateProps: {
      account: { geminiEntity, geminiEntityName },
    },
  } = usePageData();

  if (!props.paymentMethod) return null;

  return (
    <Fragment>
      {action === Action.BUY && (
        <BuyDisclaimer {...props} geminiEntity={geminiEntity} geminiEntityLegalName={geminiEntityName} />
      )}
      {action === Action.SELL && (
        <SellDisclaimer {...props} geminiEntity={geminiEntity} geminiEntityLegalName={geminiEntityName} />
      )}
      {action === Action.CONVERT && (
        <ConvertDisclaimer {...props} geminiEntity={geminiEntity} geminiEntityLegalName={geminiEntityName} />
      )}
    </Fragment>
  );
};

export default memo(Disclaimer);
