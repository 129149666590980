import { useTheme } from "@emotion/react";
import { IconCheck, IconClockFilled, IconInfoOutlined } from "@hubble/icons";
import { Button, Card, EmptyState, Flex, IconBadge, List, ListItem, Modal, Text } from "@gemini-ui/design-system";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks/useGrowFeatureFlags";
import { getEligibilityMapping } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import { useIntl } from "@gemini-ui/utils/intl";
export type AddPaymentMethodProcessingModalProps = {
  onClose: () => void;
  paymentMethodType: string;
  isOpen?: boolean;
};

export const AddPaymentMethodProcessingModal = ({
  isOpen,
  onClose,
  paymentMethodType,
}: AddPaymentMethodProcessingModalProps) => {
  const { intl } = useIntl();
  const theme = useTheme();
  const colorScheme = theme.colorScheme;
  const { eligibleForStaking, eligibleForPrivateStaking } = useGrowFeatureFlags();
  const showStaking = eligibleForStaking || eligibleForPrivateStaking;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <EmptyState
        icon={<IconClockFilled />}
        status="informational"
        label={intl.formatMessage({ defaultMessage: "Your bank account is added" })}
        description={intl.formatMessage({
          defaultMessage:
            "Your deposit instruction is being prepared. It usually takes up to 24 hours. We will notify you as soon as it's ready.",
        })}
      />

      <Card variant="filled" mt={1} padding="none">
        <List>
          {getEligibilityMapping(intl)[paymentMethodType].map((eligibilityObject, index) => {
            if (!showStaking && eligibilityObject.id === "stake") {
              return null;
            } else
              return (
                <ListItem key={eligibilityObject.label}>
                  <Flex alignItems="center" justifyContent="center">
                    {!eligibilityObject.instructions ? (
                      <IconBadge size="sm" backgroundColor="" icon={<IconCheck />} />
                    ) : (
                      <IconBadge size="sm" backgroundColor="" icon={<IconInfoOutlined />} />
                    )}
                    {!eligibilityObject.instructions ? (
                      <Text.Body size="sm" ml={1}>
                        {eligibilityObject.label}
                      </Text.Body>
                    ) : (
                      <Flex.Column ml={1} width="350px">
                        <Text.Body size="sm">{eligibilityObject.label}</Text.Body>
                        <Text.Body size="xs" color={colorScheme.content.secondary}>
                          {eligibilityObject.instructions}
                        </Text.Body>
                      </Flex.Column>
                    )}
                  </Flex>
                </ListItem>
              );
          })}
        </List>
      </Card>

      <Button.Group>
        <Button.Primary onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: "Got it",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};
