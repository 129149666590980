import { SectionMessage, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

export const InvalidReferral = () => {
  const { intl } = useIntl();

  return (
    <SectionMessage statusType="warning" mb={2}>
      <Text.Body bold>{intl.formatMessage({ defaultMessage: "This referral link is invalid" })}</Text.Body>
      <Text.Body size="sm">
        {intl.formatMessage({
          defaultMessage:
            "Continue with this link to apply without referral bonus offer or enter correct referral link.",
        })}
      </Text.Body>
    </SectionMessage>
  );
};
