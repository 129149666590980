import { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { IconChevronLeftSmall } from "@hubble/icons";
import _ from "lodash";
import { ACCOUNT_MENU_VIEWS } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/constants";
import { AccountMenuTitle } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/DefaultView/AccountMenuTitle";
import {
  Form,
  fullScreenStyles,
  Loader,
  Logo,
  SearchBar,
  SubTitle,
  Title,
} from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/styles";
import { AccountMenuViewProps } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/types";
import { Flex, HubbleMenu, Modal, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const selectionUrl = (hashid: string) =>
  jsRoutes.com.gemini.web.server.account.controllers.AccountGroupSelectionController.post(hashid).url;

// Organizes list with current group at the beginning
const sortGroups = (groups, hashid) => _.sortBy(groups, g => g.hashid !== hashid);

export const AccountMenuGroupView = ({ userName, institutionName, groupsInfo, setView }: AccountMenuViewProps) => {
  const { intl } = useIntl();

  const form = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [options, setOptions] = useState(sortGroups(groupsInfo.groups, groupsInfo.currentGroupHashId));
  const [searchValue, setSearchValue] = useState("");
  const currentGroup = groupsInfo.groups.find(g => g.hashid === groupsInfo.currentGroupHashId);
  const [selectedOption, setSelectedOption] = useState({
    hashid: currentGroup.hashid,
    name: currentGroup.name,
  });

  const title = institutionName ? institutionName : userName;

  useEffect(() => {
    const filteredOptions = sortGroups(groupsInfo.groups, groupsInfo.currentGroupHashId).filter(({ name }) => {
      return !Boolean(searchValue) || name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setOptions(filteredOptions);
  }, [searchValue, groupsInfo]);

  useEffect(() => {
    if (submitting) form.current.submit();
  }, [submitting]);

  const handleSearch = e => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleBack = e => {
    e.preventDefault();
    setSearchValue("");
    setView(ACCOUNT_MENU_VIEWS.default);
  };

  const renderSubAccounts = useMemo(() => {
    const onOptionClicked = value => e => {
      e.preventDefault();
      setSelectedOption(value);
      const isDifferentAccount = selectedOption.hashid !== value.hashid;
      setSubmitting(isDifferentAccount);
    };

    return options.map(o => {
      return (
        <HubbleMenu.RadioItem
          key={o.hashid}
          value={o.hashid}
          leftElement={<Text.Body size="sm">{o.name}</Text.Body>}
          label=""
          onSelect={onOptionClicked(o)}
        />
      );
    });
  }, [options, selectedOption]);

  return (
    <Fragment>
      <HubbleMenu.ItemLink
        label=""
        leftElement={<IconChevronLeftSmall width="20px" height="20px" />}
        rightElement={<AccountMenuTitle groupName={currentGroup.name} title={title} userName={userName} isLink />}
        onSelect={handleBack}
      />
      <Flex width="100%" p={1}>
        <SearchBar
          placeholder={intl.formatMessage({
            defaultMessage: "Search linked accounts...",
          })}
          onChange={handleSearch}
        />
      </Flex>
      <HubbleMenu.Separator />
      <Form action={selectionUrl(selectedOption.hashid)} method="POST" ref={form}>
        <HubbleMenu.RadioGroup value={selectedOption.hashid}>{renderSubAccounts}</HubbleMenu.RadioGroup>
        <input type="hidden" name="csrfToken" value={groupsInfo.csrfToken?.value} />
      </Form>
      <Modal isOpen={submitting} css={fullScreenStyles}>
        <Loader>
          <Logo />
          <Title size="lg">
            {intl.formatMessage(
              defineMessage({
                defaultMessage: "Switching to <strong>{selectedOptionName}</strong>",
              }),
              {
                strong: (v: ReactNode) => <strong>{v}</strong>,
                selectedOptionName: selectedOption.name,
              }
            )}
          </Title>
          <SubTitle size="md">
            {intl.formatMessage({
              defaultMessage: "Please wait a few seconds while we switch your Group.",
            })}
          </SubTitle>
        </Loader>
      </Modal>
    </Fragment>
  );
};
