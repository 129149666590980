import { IconCheckCircleFilled, IconStepperNotStarted } from "@hubble/icons";
import { IntlShape } from "@gemini-ui/utils/intl";

export enum SegmentType {
  DEFAULT = "default",
  PLAID_IDV_STEPS = "plaidIdv",
}

export enum OnboardingNavigationStates {
  CreateAccount,
  ActivateAccount,
  VerifyIdentity,
  FinancialProfile,
  UploadId,
  CreatePasskey,
  Completed,
}

const getStepLabel = (stepKey: OnboardingNavigationStates, intl: IntlShape): string => {
  switch (stepKey) {
    case OnboardingNavigationStates.CreateAccount:
      return intl.formatMessage({ defaultMessage: `Create account` });
    case OnboardingNavigationStates.ActivateAccount:
      return intl.formatMessage({ defaultMessage: `Activate account` });
    case OnboardingNavigationStates.VerifyIdentity:
      return intl.formatMessage({ defaultMessage: `Verify Identity` });
    case OnboardingNavigationStates.FinancialProfile:
      return intl.formatMessage({ defaultMessage: `Financial profile` });
    case OnboardingNavigationStates.UploadId:
      return intl.formatMessage({ defaultMessage: `Upload ID` });
    case OnboardingNavigationStates.CreatePasskey:
      return intl.formatMessage({ defaultMessage: `Set up passkey` });
    default:
      return intl.formatMessage({ defaultMessage: `Completed` });
  }
};

export const getOnboardingVerticalNavigationSteps = (
  intl: IntlShape,
  activeStep: OnboardingNavigationStates,
  segmentType: SegmentType = SegmentType.PLAID_IDV_STEPS,
  didUploadDocument = false
) => {
  const segmentOrderMap: Record<string, OnboardingNavigationStates[]> = {
    [SegmentType.DEFAULT]: [
      OnboardingNavigationStates.CreateAccount,
      OnboardingNavigationStates.ActivateAccount,
      OnboardingNavigationStates.FinancialProfile,
      OnboardingNavigationStates.VerifyIdentity,
      OnboardingNavigationStates.CreatePasskey,
    ],
    [SegmentType.PLAID_IDV_STEPS]: [
      OnboardingNavigationStates.CreateAccount,
      OnboardingNavigationStates.ActivateAccount,
      OnboardingNavigationStates.VerifyIdentity,
      OnboardingNavigationStates.FinancialProfile,
      ...(activeStep === OnboardingNavigationStates.UploadId || didUploadDocument
        ? [OnboardingNavigationStates.UploadId]
        : []),
      OnboardingNavigationStates.CreatePasskey,
    ],
  };

  const activeIndex = segmentOrderMap[segmentType].indexOf(activeStep);
  const steps = segmentOrderMap[segmentType].map((stepKey, index) => ({
    stepKey,
    dataTestId: "",
    label: getStepLabel(stepKey, intl),
    isActive: index === activeIndex,
    leftElement: activeIndex === -1 || index < activeIndex ? <IconCheckCircleFilled /> : <IconStepperNotStarted />,
  }));

  return steps;
};
