import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { InitialWireFormType } from "@gemini-ui/constants/giact";
import { AddPaymentMethodsDebitCardProps } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow";
import { PaymentMethodDataType, UserAddressType } from "@gemini-ui/transformers/PaymentMethods";
import { IntlShape } from "@gemini-ui/utils/intl";

export const enum PaymentTypeScope {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  INSTANT_TRADE = "INSTANT_TRADE",
  TRADE = "TRADE",
  ALL = "ALL",
  STAKING = "STAKING",
  DERIVATIVES = "DERIVATIVES",
}

export enum LinkPaymentType {
  MANUAL = "manual",
  PLAID = "plaid",
  DEBIT = "debit",
  BANCOLOMBIA = "bancolombia",
  PAYPAL = "paypal",
  GIACT = "giact",
  PLAID_OR_GIACT = "plaidOrGiact",
  WIRE = "wire",
  BANKFRICK = "bankfrick",
  XFERS = "xfers",
  RTP = "rtp",
}

export type LinkPaymentAnalyticsType = LinkPaymentType;

export enum AddPaymentMethodModalState {
  SELECT_PAYMENT_METHOD,
  ADD_BANK_PLAID,
  ADD_PAYPAL,
  ADD_GIACT,
  ADD_WIRE,
  NONE,
  TRANSFER_LIMIT_AND_TIMINGS_MODAL,
  BANK_TRANSFER,
  CHANGE_CURRENCY_MODAL,
  ADD_DEBIT_CARD,
}

export const DepositPaymentType = [
  LinkPaymentType.MANUAL,
  LinkPaymentType.PLAID,
  LinkPaymentType.GIACT,
  LinkPaymentType.BANCOLOMBIA,
  LinkPaymentType.PLAID_OR_GIACT,
  LinkPaymentType.WIRE,
  LinkPaymentType.PAYPAL,
  LinkPaymentType.BANKFRICK,
];

export const WithdrawablePaymentType = [
  LinkPaymentType.MANUAL,
  LinkPaymentType.PLAID,
  LinkPaymentType.BANCOLOMBIA,
  LinkPaymentType.PLAID_OR_GIACT,
  LinkPaymentType.WIRE,
  LinkPaymentType.BANKFRICK,
];

// DEBIT and PAYPAL are not a valid payment type for limit orders.
// Source: https://docs.google.com/document/d/1iyYhStkwSastAwnawiuCSGgnd3OsCm1Q3Ww4sGriepQ/edit#bookmark=id.amw5lb1cbjr9
export const TradePaymentType = [LinkPaymentType.PLAID, LinkPaymentType.GIACT, LinkPaymentType.PLAID_OR_GIACT];

export const InstantTradePaymentType = [
  LinkPaymentType.PLAID,
  LinkPaymentType.DEBIT,
  LinkPaymentType.PAYPAL,
  LinkPaymentType.GIACT,
  LinkPaymentType.PLAID_OR_GIACT,
  LinkPaymentType.BANKFRICK,
];

export type AddPaymentMethodOptionsProps = {
  handleOpenWireModal: (values?: InitialWireFormType) => void;
  handleOpenBancolombiaModal: () => void;
  userDetails?: UserAddressType;
  updateSelectedCurrency?: (currency: CurrencyShortNameFiat) => void;
  type?: "onboarding" | "default" | "settings";
  addDebitCardFlowProps?: AddPaymentMethodsDebitCardProps;
  combinedPaymentMethods?: PaymentMethodDataType[];
};

type SuccessOrRefreshProps =
  | {
      onSuccess?: never;
      refreshPaymentMethodsData: () => void;
    }
  | {
      onSuccess: (paymentType?: LinkPaymentType) => void;
      refreshPaymentMethodsData?: never;
    };

export type AddPaymentModalProps = {
  scope: PaymentTypeScope;
  type?: "onboarding" | "default" | "settings";
  isOpen: boolean;
  onBack?: () => void;
  onClose: () => void;
  initialModalState?: AddPaymentMethodModalState;
  pageName?: string;
  paymentMethods: PaymentMethodDataType[];
  isRedirectedFromWithdrawals?: boolean;
  ineligibleCardSubTitle?: string;
  transferFlowCurrency?: CurrencyShortNameFiat;
  addDebitCardFlowProps?: AddPaymentMethodsDebitCardProps;
  onCurrencyChange?: (currency: CurrencyShortNameFiat) => void;
  openSelectPaymentMethodModal?: () => void;
  subaccountHashid: string;
  noPaymentMethodInDeposit?: boolean;
} & SuccessOrRefreshProps &
  Omit<AddPaymentMethodOptionsProps, "handleOpenWireModal">;

export const getIntlDescriptionCopy = (intl: IntlShape) => ({
  DEPOSIT_AND_WITHDRAW: intl.formatMessage({ defaultMessage: "Deposit and withdraw" }),
  DEPOSIT_AND_WITHDRAW_WITH_MANUAL_TRANSFER: intl.formatMessage({
    defaultMessage: "Deposit and Withdraw with manual bank transfer post account verification with Plaid",
  }),
  BUY_INSTANTLY_US_ONLY: intl.formatMessage({ defaultMessage: "Buy crypto instantly (US banks only)" }),
  MAYBE_ELIGIBLE_FOR_DIRECT_TRANSFERS: intl.formatMessage({
    defaultMessage: "May be eligible for direct bank transfers",
  }),
  VERIFY_BANK_WITH_DEPOSIT: intl.formatMessage({
    defaultMessage: "Verify bank account with deposit. May take up to 5 business days",
  }),
  ADD_VIA_DEPOSIT: intl.formatMessage({ defaultMessage: "Add via deposit. May take 1-2 business days" }),
  RECOMMENDED_FOR_LARGE_TRANSFERS: intl.formatMessage({ defaultMessage: "Recommended for large transfers" }),
  BUY_INSTANTLY: intl.formatMessage({ defaultMessage: "Buy crypto instantly" }),
  VERIFY_CARD_WITH_PHOTO: intl.formatMessage({
    defaultMessage: "Verify card via photo instantly or via microtransactions in up to 2 business days",
  }),
  VERIFY_CARD_WITH_MICROTRANSACTION: intl.formatMessage({
    defaultMessage: "Verify card via deposit microtransactions in up to 2 business days",
  }),
  PAY_CREDIT_CARD_BILLS: intl.formatMessage({ defaultMessage: "Pay credit card bills (US banks only) " }),
  LOGIN_WITH_BCOLO: intl.formatMessage({ defaultMessage: "Log into Bancolombia to verify account" }),
  DEPOSIT: intl.formatMessage({ defaultMessage: "Deposit" }),
  WITHDRAW: intl.formatMessage({ defaultMessage: "Withdrawal" }),
  TRADE: intl.formatMessage({ defaultMessage: "Trade" }),
  STAKE: intl.formatMessage({ defaultMessage: "Stake" }),
});

export const getIntlTitleCopy = (intl: IntlShape) => ({
  LINK_BANK_MANUALLY_TITLE: intl.formatMessage({ defaultMessage: "Bank account manually" }),
  LINK_BANK_VIA_PLAID_TITLE: intl.formatMessage({ defaultMessage: "Bank account instantly via Plaid" }),
  LINK_DEBIT_CARD_TITLE: intl.formatMessage({ defaultMessage: "Debit card" }),
  LINK_BANCOLOMBIA_TITLE: intl.formatMessage({ defaultMessage: "Bancolombia" }),
  LINK_PAYPAL_TITLE: intl.formatMessage({ defaultMessage: "PayPal" }),
  LINK_GIACT_TITLE: intl.formatMessage({ defaultMessage: "Bank account manually" }),
  LINK_PLAID_OR_GIACT: intl.formatMessage({ defaultMessage: "Bank account" }),
  LINK_WIRE_TRANSFER: intl.formatMessage({ defaultMessage: "Wire transfer" }),
  LINK_BANKFRICK: intl.formatMessage({ defaultMessage: "Bank account" }),
  FAST_TRANSFER: intl.formatMessage({ defaultMessage: "FAST transfer" }),
});

export const TEST_IDS = {
  ADD_PLAID_BUTTON: "add-plaid-button",
  ADD_BANK_MANUAL_BUTTON: "add-bank-manual-button",
  ADD_DEBIT_CARD_BUTTON: "add-debit-card-button",
  ADD_PAYPAL_BUTTON: "add-paypal-button",
  ADD_GIACT_BUTTON: "add-giact-button",
  ADD_BCOLO_BUTTON: "add-bcolo-button",
  ADD_PLAID_OR_GIACT_BUTTON: "add-plaid-or-giact-button",
  ADD_WIRE_BUTTON: "add-Wire-button",
  ADD_BANKFRICK: "add-bankfrick",
};
