import * as Sentry from "@sentry/browser";
import _ from "lodash";
import { CURRENCIES_DETAIL, CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { Money } from "@gemini-ui/components/Money";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { Colors, Flex } from "@gemini-ui/design-system";
import { DerivativeFundingPreCheckError } from "@gemini-ui/pages/Settlement/TransferModal/Views/constants";
import { TransferAccount } from "@gemini-ui/pages/transfers/constants";
import { getAvailableForWithdrawal } from "@gemini-ui/pages/transfers/utils";
import { searchSubaccounts } from "@gemini-ui/services/subaccounts";

export function generateSourceOptions(
  accounts: Subaccount[],
  currency: CurrencyShortName = CURRENCIES_DETAIL.GUSD.symbol
) {
  return accounts.map(account => ({
    label: (
      <Flex>
        <div css={{ flex: 1 }}>{account.name}</div>
        <Money
          css={{ color: Colors.gray[400] }}
          currency={currency}
          value={getAvailableForWithdrawal(account, currency)}
        />
      </Flex>
    ),
    value: account.hashid,
  }));
}

export function generateDestinationOptions({ subaccounts, omit }: { subaccounts: Subaccount[]; omit: string }) {
  return subaccounts
    .filter(account => account.hashid !== omit)
    .map(account => ({
      label: account.name,
      value: account.hashid,
    }));
}

export function getSubaccount(subaccounts: Subaccount[], hashid: string) {
  return subaccounts.find(account => account.hashid === hashid);
}

export function canWithdrawCurrency(currency, eligibleCurrencies) {
  return Boolean(currency && _.get(eligibleCurrencies, currency, true));
}

export const fetchSubaccounts = (name?: string) => {
  return searchSubaccounts<TransferAccount>({
    limit: 500,
    offset: 0,
    withBalance: true,
    sortBy: "friendlyName",
    name,
  })
    .then(({ results }) => results)
    .catch(e => {
      Sentry.captureException(e);
      return [];
    });
};

export const isDerivativesAccount = (account: Subaccount) => {
  return Boolean(account?.derivatives);
};

const isExchangeAccount = (account: Subaccount) => {
  return account?.trading && !account?.derivatives && !account?.coldStorage;
};

export const assertDerivativeFundingPreCheck = (
  account: Subaccount,
  from: CurrencyShortName,
  amount: number | string
) => {
  if (Number(account.balances[from.toLocaleLowerCase()].availableForWithdrawal.value) < Number(amount))
    throw new DerivativeFundingPreCheckError();
};

export const isDerivativeWithdrawal = (
  sourceAccount: Subaccount,
  destinationAccount: Subaccount,
  from?: CurrencyShortName,
  to?: CurrencyShortName
) =>
  sourceAccount &&
  isDerivativesAccount(sourceAccount) &&
  destinationAccount &&
  isExchangeAccount(destinationAccount) &&
  (from ? from === CURRENCIES_DETAIL.GUSD.symbol : true) &&
  (to ? [CURRENCIES_DETAIL.USD.symbol, CURRENCIES_DETAIL.USDC.symbol].includes(to) : true);

export const isDerivativeFunding = (
  sourceAccount: Subaccount | void,
  destinationAccount: Subaccount | void,
  from?: CurrencyShortName,
  to?: CurrencyShortName
) =>
  sourceAccount &&
  isExchangeAccount(sourceAccount) &&
  destinationAccount &&
  isDerivativesAccount(destinationAccount) &&
  (from ? [CURRENCIES_DETAIL.USD.symbol, CURRENCIES_DETAIL.USDC.symbol].includes(from) : true) &&
  (to ? to === CURRENCIES_DETAIL.GUSD.symbol : true);

export const isDerivativeFundingTransferOnly = (destinationAccount: Subaccount, selectedAsset: CurrencyShortName) =>
  destinationAccount &&
  isDerivativesAccount(destinationAccount) &&
  selectedAsset &&
  ![
    CURRENCIES_DETAIL.GUSD.symbol,
    CURRENCIES_DETAIL.USD.symbol,
    CURRENCIES_DETAIL.USDC.symbol,
    CURRENCIES_DETAIL.BTC.symbol,
  ].includes(selectedAsset);
