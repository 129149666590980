import { Fragment, ReactNode } from "react";
import { format } from "date-fns";
import { isPerpetualPair, SupportedCurrencyPairs } from "@gemini-common/scripts/constants/currencies";
import { formatDate } from "@gemini-ui/components/DatePicker/utils";
import { Money } from "@gemini-ui/components/Money";
import StatusColumn from "@gemini-ui/components/StatusColumn";
import { HistoryEntry, TransferInfo, TransferType } from "@gemini-ui/constants/custody";
import { INSTRUMENT_TYPES, Order, Side } from "@gemini-ui/constants/orders";
// TODO: These should be consolidated as shared utils
import { getDate, getOrderTypeDisplay, getPrice } from "@gemini-ui/pages/ActiveTrader/Spot/MyOrders/utils";
import { ConvertEligible } from "@gemini-ui/pages/RetailTrade/constants";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

const { CustomerAccountStatementController } = jsRoutes.com.gemini.web.server.trading.controllers;
const { HistoryController } = jsRoutes.com.gemini.web.server.trading.controllers;

interface TTradeHistoryItem {
  id: string;
  timestamp: string;
  pair: SupportedCurrencyPairs;
  side: string;
  orderType: string;
  price: ReactNode;
  quantity: ReactNode;
  total: ReactNode;
  status: ReactNode;
}

const getFormattedSide = (isConvert: boolean, side: Side, intl: IntlShape) => {
  const CONVERT = intl.formatMessage({
    defaultMessage: "Convert",
    description: "Trade type of converting one crypto for another. Eg, Buy, Sell, Convert",
  });
  if (isConvert) {
    return CONVERT;
  }

  switch (side) {
    case "buy": {
      return intl.formatMessage({ defaultMessage: "Buy", description: "'Buy' trade type. Eg, Buy, Sell, Convert" });
    }
    case "sell": {
      return intl.formatMessage({ defaultMessage: "Sell", description: "'Sell' trade type. Eg, Buy, Sell, Convert" });
    }
  }
};

export const getTradeHistoryTableItem = (
  entry: HistoryEntry,
  convertEligible: ConvertEligible,
  intl: IntlShape
): TTradeHistoryItem => {
  const { hashid, pair, price } = entry;
  const priceCurrency = price?.currency;
  const quantityCurrency = entry.quantity?.currency;
  const { instrumentType } = entry;

  const isConvert =
    Boolean(convertEligible) &&
    Object.keys(convertEligible).includes(priceCurrency) &&
    convertEligible[priceCurrency].includes(quantityCurrency) &&
    instrumentType !== INSTRUMENT_TYPES.PERP;

  const side = getFormattedSide(isConvert, entry.side, intl);
  const order = entry as Order;
  const date = getDate(order);

  const isPerpOrder = isPerpetualPair(order.pair);

  let total;
  let quantity;
  if (order.type === "order") {
    total = order.totalPrice;
    quantity = order.quantity;
  } else if (isPerpOrder) {
    total = order.type === "marketBuy" || order.type === "marketSell" ? order.grossFillPrice : order.totalPrice;
    quantity = order.side === "buy" ? order.quantityReceived : order.totalSpend;
  } else {
    total = order.side === "buy" ? order.totalSpend : order.quantityReceived;
    quantity = order.side === "buy" ? order.quantityReceived : order.totalSpend;
  }

  return {
    timestamp: format(date, DateFormats.MonthDayYearTime),
    pair,
    side,
    orderType: getOrderTypeDisplay(order),
    price: <Money.Price {...getPrice(order)} tradingPair={pair} />,
    quantity: <Money {...quantity} />,
    total: order.stopPrice ? <Fragment>&mdash;</Fragment> : <Money {...total} />,
    status: <StatusColumn order={order} noColor />,
    id: hashid,
  };
};

const adminTransferInfoTranslator = (transferInfo: TransferInfo, intl: IntlShape) => {
  switch (transferInfo) {
    case "Debit": {
      return intl.formatMessage({ defaultMessage: "Administrative Debit" });
    }
    case "Credit": {
      return intl.formatMessage({ defaultMessage: "Administrative Credit" });
    }
    case "WireDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative wire deposit" });
    }
    case "SenDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative Sen deposit" });
    }
    case "CardDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative card deposit" });
    }
    case "WireAdvanceDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative wire advance deposit" });
    }
    case "WireWithdrawal": {
      return intl.formatMessage({ defaultMessage: "Administrative wire withdraw" });
    }
    case "SenWithdrawal": {
      return intl.formatMessage({ defaultMessage: "Administrative Sen withdraw" });
    }
    case "InstantAchDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative instant ACH deposit" });
    }
    case "InstantCardDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative instant card deposit" });
    }
    case "InstantOrderFeeCredit": {
      return intl.formatMessage({ defaultMessage: "Instant order reimbursement fee" });
    }
    case "AchDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative ACH deposit" });
    }
    case "AchWithdrawal": {
      return intl.formatMessage({ defaultMessage: "Administrative ACH withdraw" });
    }
    case "AchReturn": {
      return intl.formatMessage({ defaultMessage: "Administrative ACH return" });
    }
    case "WireReturn": {
      return intl.formatMessage({ defaultMessage: "Administrative wire return" });
    }
    case "SenReturn": {
      return intl.formatMessage({ defaultMessage: "Administrative Sen return" });
    }
    case "CardReturn": {
      return intl.formatMessage({ defaultMessage: "Administrative card return" });
    }
    case "CanceledWireAdvance": {
      return intl.formatMessage({ defaultMessage: "Administrative canceled wire advance" });
    }
    case "CanceledCardAdvance": {
      return intl.formatMessage({ defaultMessage: "Administrative canceled card advance" });
    }
    case "XfersDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative Xfers deposit" });
    }
    case "XfersWithdrawal": {
      return intl.formatMessage({ defaultMessage: "Administrative Xfers withdrawal" });
    }
    case "BlincDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative Blinc deposit" });
    }
    case "BlincReturn": {
      return intl.formatMessage({ defaultMessage: "Administrative Blinc return" });
    }
    case "BlincWithdrawal": {
      return intl.formatMessage({ defaultMessage: "Administrative Blinc withdrawal" });
    }
    case "BcoloDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative Bcolo deposit" });
    }
    case "BcoloReturn": {
      return intl.formatMessage({ defaultMessage: "Administrative Bcolo return" });
    }
    case "BcoloWithdrawal": {
      return intl.formatMessage({ defaultMessage: "Administrative Bcolo withdrawal" });
    }
    case "PreCredit": {
      return intl.formatMessage({ defaultMessage: "Administrative pre-credit" });
    }
    case "PendingExecution": {
      return intl.formatMessage({ defaultMessage: "Administrative pending execution" });
    }
    case "PayPalDeposit": {
      return intl.formatMessage({ defaultMessage: "Administrative PayPal deposit" });
    }
    case "CanceledPayPalAdvance": {
      return intl.formatMessage({ defaultMessage: "Administrative canceled PayPal advance" });
    }
    case "PayPalReturn": {
      return intl.formatMessage({ defaultMessage: "Administrative PayPal return" });
    }
  }
};

// @MultiCurrencyAware
// @MultiCurrencyEliminate - these are easily overlooked
export const transferTypeFormatter = (transfer: TransferType, intl: IntlShape) => {
  const type = transfer.transferType;
  const info = transfer.transferInfo;

  if (type === "fiat" || type === "dollar") {
    if (
      info === "WireReturn" ||
      info === "CardReturn" ||
      info === "AchReturn" ||
      info === "BlincReturn" ||
      info === "PayPalReturn"
    ) {
      return intl.formatMessage({ defaultMessage: "Return (Deposit)" });
    } else if (transfer.currentStatus === "Returned") {
      if (info === "AchWithdrawal" || info === "WireWithdrawal") {
        return intl.formatMessage({ defaultMessage: "Return (Withdrawal)" });
      }
      return intl.formatMessage({ defaultMessage: "Return", description: "ACH return" });
    } else if (info && info.match(/Withdrawal/)) {
      return intl.formatMessage({ defaultMessage: "Withdrawal" });
    } else if (info && info.match(/Deposit/)) {
      return intl.formatMessage({ defaultMessage: "Deposit" });
    } else if (info === "CanceledWireAdvance" || info === "CanceledCardAdvance" || info === "CanceledPayPalAdvance") {
      return intl.formatMessage({ defaultMessage: "Expired Pre-Credited Deposit" });
    }
  } else {
    if (type === "rewardsDeposit") {
      return intl.formatMessage({ defaultMessage: "Credit Card Reward" });
    } else if (type.match(/Deposit/) && !type.match(/earn/)) {
      return intl.formatMessage({ defaultMessage: "Deposit" });
    } else if (info === "PreCredit" || info === "PendingExecution" || type.match(/Withdrawal/)) {
      if (info === "PreCredit" && type === "custodyPreCredit") {
        return intl.formatMessage({ defaultMessage: "Deposit" });
      } else return intl.formatMessage({ defaultMessage: "Withdrawal" });
    } else {
      switch (type) {
        case "earnDeposit":
          return intl.formatMessage(
            defineMessage({
              defaultMessage: "{providerType, select, Earn {Transfer} other {Stake}}",
            }),
            { providerType: transfer.providerType }
          );
        case "earnRedeem":
          return intl.formatMessage(
            defineMessage({
              defaultMessage: "{providerType, select, Earn {Redemption} other {Unstake}}",
            }),
            { providerType: transfer.providerType }
          );
        case "earnAdminRedeem":
          return intl.formatMessage(
            defineMessage({ defaultMessage: "{providerType, select, Earn {Redemption} other {Unstake}}" }),
            { providerType: transfer.providerType }
          );
        case "earnInterest":
          return intl.formatMessage(
            defineMessage({ defaultMessage: "{providerType, select, Earn {Interest} other {Reward}}" }),
            { providerType: transfer.providerType }
          );
        case "canceledBtcAdvance":
          return intl.formatMessage({ defaultMessage: "Expired Pre-Credited Deposit" });
        case "canceledNakamotoAdvance":
          return intl.formatMessage({ defaultMessage: "Expired Pre-Credited Deposit" });
        case "adminTransfer":
          return adminTransferInfoTranslator(info, intl);
        case "withdrawalFee":
          return intl.formatMessage({ defaultMessage: "Withdrawal Fee" });
        case "internalexchangetransferout":
          return intl.formatMessage({ defaultMessage: "Withdrawal" });
        case "internalexchangetransferin":
          return intl.formatMessage({ defaultMessage: "Deposit" });
        case "flexaSpend":
          return intl.formatMessage({ defaultMessage: "Gemini Pay" });
        case "giftingCryptoDebit":
          return intl.formatMessage({ defaultMessage: "Gifting Crypto Sent" });
        case "giftingCryptoCredit":
          return intl.formatMessage({ defaultMessage: "Gifting Crypto Redemption" });
        case "custodyFeeDebit":
          return intl.formatMessage({ defaultMessage: "Custody Fee" });
        case "riaFeeCredit":
          return intl.formatMessage({ defaultMessage: "RIA Fee Credit" });
        case "riaFeeDebit":
          return intl.formatMessage({ defaultMessage: "RIA Fee" });
        default:
          return null;
      }
    }
  }
};

// @MultiCurrencyAware
// @MultiCurrencyEliminate - these are easily overlooked
export const transferMethodFormatter = (transfer: TransferType, intl: IntlShape) => {
  const info = transfer.transferInfo;
  const type = transfer.transferType;
  if (info != null) {
    if (info.match(/Wire/)) {
      return intl.formatMessage({ defaultMessage: "Wire Transfer" });
    } else if (info.match(/Ach/)) {
      return intl.formatMessage({ defaultMessage: "Bank Transfer" });
    } else if (info.match(/Sen/)) {
      return intl.formatMessage({ defaultMessage: "SEN Transfer" });
    } else if (info.match(/PayPal/)) {
      return intl.formatMessage({ defaultMessage: "PayPal" });
    } else if (info.match(/Cbit/)) {
      return intl.formatMessage({ defaultMessage: "Cubix" });
    } else if (info.match(/Xfers/)) {
      return intl.formatMessage({ defaultMessage: "FAST" });
    }
  } else {
    switch (type) {
      case "adminTransfer":
        return null;
      case "withdrawalFee":
        return null;
      case "oberonWithdrawal":
        return intl.formatMessage({ defaultMessage: "Gemini dollar" });
      case "oberonDeposit":
        return intl.formatMessage({ defaultMessage: "Gemini dollar" });
      default:
        return null;
    }
  }
};

// Defaults to Canceled - we don't expect a transfer with a defined error to progress further
export const transferErrorFormatter = (intl: IntlShape) => {
  return intl.formatMessage({ defaultMessage: "Canceled" });
};

export const getTransactionHistoryUrl = (
  subaccountHashid: string,
  from: string | Date,
  to: string | Date,
  stakingHistory = false
) =>
  HistoryController.transactionHistory(
    subaccountHashid,
    formatDate(from, DateFormats.YearMonthDay),
    formatDate(to, DateFormats.YearMonthDay),
    stakingHistory
  ).url;

export const getAccountStatementUrl = (subaccountHashid: string, accountStatementDate: string | Date) =>
  CustomerAccountStatementController.downloadStatementPdf(
    subaccountHashid,
    formatDate(accountStatementDate, DateFormats.YearMonthDay)
  ).url;

export const getTradeVolumeUrl = (subaccountHashid: string) => HistoryController.tradeVolume(subaccountHashid).url;
