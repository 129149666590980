import {
  IconArrowDownLine,
  IconArrowsHorizontal,
  IconArrowUpLine,
  IconProps,
  IconQrCode,
  IconSendFilled,
} from "@hubble/icons";
import { EVENTS } from "@gemini-ui/analytics/constants/events";

const transferRoutes = jsRoutes.com.gemini.web.server.funding.controllers.TransferPageController;

export type CommonTransferItem = {
  url: string;
  id: TransferIds;
  icon: React.NamedExoticComponent<IconProps>;
  transferType?: "fiat" | "crypto";
  analyticsEventName: string;
  disabled?: boolean;
  badge?: React.ReactNode;
};

export type TransferIds =
  | "deposit-cash"
  | "withdraw-cash"
  | "withdraw-crypto"
  | "deposit-crypto"
  | "transfer-between"
  | "derivative-debit-card-funding";

export const COMMON_TRANSFER_ITEMS: { [k in TransferIds]: CommonTransferItem } = {
  "deposit-cash": {
    url: transferRoutes.getDepositPage().url,
    id: "deposit-cash",
    transferType: "fiat",
    icon: IconArrowDownLine,
    analyticsEventName: EVENTS.DEPOSIT_START.name,
  },
  "withdraw-cash": {
    url: transferRoutes.getWithdrawPage().url,
    id: "withdraw-cash",
    icon: IconArrowUpLine,
    transferType: "fiat",
    analyticsEventName: EVENTS.WITHDRAW_START.name,
  },
  "withdraw-crypto": {
    url: transferRoutes.getWithdrawPage().url,
    id: "withdraw-crypto",
    icon: IconSendFilled,
    transferType: "crypto",
    analyticsEventName: EVENTS.WITHDRAW_CRYPTO_START.name,
  },
  "deposit-crypto": {
    url: transferRoutes.getDepositPage().url,
    id: "deposit-crypto",
    icon: IconQrCode,
    transferType: "crypto",
    analyticsEventName: EVENTS.DEPOSIT_CRYPTO_START.name,
  },
  "transfer-between": {
    url: "/transfer/between",
    id: "transfer-between",
    icon: IconArrowsHorizontal,
    analyticsEventName: EVENTS.TRANSFER_BETWEEN_START.name,
  },
  "derivative-debit-card-funding": {
    url: "#",
    id: "derivative-debit-card-funding",
    icon: IconArrowDownLine,
    analyticsEventName: EVENTS.DERIVATIVE_DEBIT_CARD_FUNDING_START.name,
  },
};
