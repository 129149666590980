import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { get } from "lodash";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { usePageRefresh } from "@gemini-ui/contexts";
import { MarketFilter } from "@gemini-ui/pages/RetailTrade/constants";
import axios from "@gemini-ui/services/axios";

export type MarketFilterResponse = {
  filters: MarketFilter[];
};

export const getMarketFilters = async (defaultFiat?: CurrencyShortNameFiat) => {
  const filtersUrl =
    jsRoutes.com.gemini.web.server.trading.controllers.MarketFiltersController.getCurrencyMarketFilters(
      defaultFiat,
      false,
      false
    ).url;
  return await axios
    .get<MarketFilterResponse>(filtersUrl, { retry: 3 })
    .then(res => get(res, "data.filters", []))
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const useRetailMarketFilters = (defaultFiat: CurrencyShortNameFiat) => {
  const { refreshCount } = usePageRefresh();
  const [isLoadingMarketFilters, setIsLoadingMarketFilters] = useState(true);
  const [marketFilters, setMarketFilters] = useState<MarketFilter[]>([]);
  const fetchMarketFilters = useCallback(() => {
    getMarketFilters(defaultFiat)
      .then(data => {
        setMarketFilters(data);
        setIsLoadingMarketFilters(false);
      })
      .catch(() => setIsLoadingMarketFilters(false));
  }, [defaultFiat]);

  useEffect(() => {
    fetchMarketFilters();
  }, [fetchMarketFilters, refreshCount]);

  return {
    marketFilters,
    fetchMarketFilters,
    isLoadingMarketFilters,
  };
};
