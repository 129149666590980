// eslint-disable-next-line no-restricted-imports
import { AxiosError } from "axios";

const UNKNOWN = "unknown";

// Better error messages for error tracking in Sentry
export function enhanceErrorMessageInterceptor(error: AxiosError) {
  if (error.config && error.response) {
    const { url, method } = error.config;
    const { status } = error.response;

    // Strip query parameters from the URL
    const strippedUrl = url?.split("?")[0] || UNKNOWN;

    // Modify the error message
    error.message = `${strippedUrl} ${method?.toUpperCase()} ${status}`;
  }

  return Promise.reject(error);
}
