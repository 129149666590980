import * as Sentry from "@sentry/browser";
import { useAsyncFn } from "react-use";
import { applicationApi } from "@gemini-ui/client/api";
import { ApplicationStatusStateStateEnum } from "@gemini-ui/client/credit";
import { handleGetPrefill } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/handlers/handleGetPrefill";
import { UserEvent } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/machines/creditAppMachine";

export const useCheckSms = () => {
  return useAsyncFn(async (send: (event: UserEvent) => void) => {
    try {
      const { data } = await applicationApi.listApplications();
      if (data) {
        const currentApp = Array.isArray(data) ? data[data.length - 1] : data;
        const currentAppStatus = currentApp.statusHistory[currentApp.statusHistory.length - 1]?.state;

        const smsStates = [
          ApplicationStatusStateStateEnum.PhoneVerificationFailed,
          ApplicationStatusStateStateEnum.PhoneVerificationIndeterminate,
          ApplicationStatusStateStateEnum.PhoneTrustScoreFailed,
          ApplicationStatusStateStateEnum.PhoneTrustScoreIndeterminate,
        ];

        if (currentAppStatus === ApplicationStatusStateStateEnum.PhoneTrustScoreVerified) {
          handleGetPrefill(send);
        } else if (smsStates.includes(currentAppStatus)) {
          send({ type: currentAppStatus });
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  });
};
