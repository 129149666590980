import { useCallback, useEffect, useState } from "react";

// countdown from @param countdown to 0
export const useStaticCountdown = (countdown: number, isEnabled: boolean) => {
  const [seconds, setSeconds] = useState(countdown);

  const startCountDown = useCallback(() => {
    const timer = setTimeout(() => {
      if (seconds <= 0) {
        clearTimeout(timer);
        return;
      }
      setSeconds(prev => --prev);
    }, 1000);
    return timer;
  }, [seconds]);

  const restartCountdown = useCallback(() => {
    setSeconds(countdown);
    startCountDown();
  }, [countdown, startCountDown]);

  useEffect(() => {
    let timer = null;
    if (isEnabled) {
      timer = startCountDown();
    }

    return () => {
      if (timer) {
        // remove timeout if unmount
        clearTimeout(timer);
      }
    };
  }, [isEnabled, startCountDown]);

  return { seconds, restartCountdown };
};
