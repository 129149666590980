import { Fragment, useState } from "react";
import { useEffectOnce } from "react-use";
import { Articles, generateHelpCenterUrl } from "@gemini-ui/components/HelpCenterLink";
import { usePageData } from "@gemini-ui/contexts";
import { Button, SectionMessage } from "@gemini-ui/design-system";
import { ButtonProps } from "@gemini-ui/design-system/Button";
import { cssShorthandPropsFilter, ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { getIsEuInboundEnabled, getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import { useIntl } from "@gemini-ui/utils/intl";

export enum TransferControlVariant {
  Alert = "alert",
  Warning = "warning",
}

export interface TransferControlAlertProps extends ShorthandSpacingCssProps {
  variant: TransferControlVariant;
  variantOverride?: TransferControlVariant; // still want the variant prop logic but not apply to the message
  isFrozen?: boolean;
  hasMultiple?: boolean;
  attestationRequired?: boolean;
  disabled?: boolean;
  onConfirmDeposit?: () => void;
  children?: string;
}

export default function TransferControlAlert({
  variant,
  variantOverride,
  hasMultiple,
  onConfirmDeposit,
  isFrozen,
  attestationRequired,
  disabled,
  children,
  ...spacingProps
}: TransferControlAlertProps) {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { geminiEntity },
    },
  } = usePageData();

  const isUkTravelRuleEnabled = getIsUkInboundEnabled(geminiEntity);
  const isEuTravelRuleEnabled = getIsEuInboundEnabled(geminiEntity);

  const customerSupportHref = generateHelpCenterUrl({ article: Articles.ATTEST_ADDRESS, intl });
  const [ctaDisabled, setCtaDisabled] = useState(
    (variant === "alert" && attestationRequired ? true : false) || disabled
  );

  const { transferControl } = testIds;

  let primaryCTA;
  let primaryCtaProps: ButtonProps = {};
  let descriptionText;

  const viewTransfersText = intl.formatMessage({
    defaultMessage: "View transfers",
  });

  let transferDepositPage = "/transfer/deposit#transfer-history-table";

  if (window.location.pathname.includes("/transfer")) {
    transferDepositPage = `${window.location.pathname}#transfer-history-table`;
  }

  useEffectOnce(() => {
    // Hack, we'll need to wait ~500 ms for the data to load in the transfer history table before users can scroll to section
    setTimeout(() => {
      setCtaDisabled(false);
    }, 500);
  });

  const withdrawalCopy = isUkTravelRuleEnabled
    ? intl.formatMessage({
        defaultMessage:
          "For the UK Travel Rule, you must provide additional information for your crypto deposits before making any withdrawals.",
      })
    : isEuTravelRuleEnabled
    ? intl.formatMessage({
        defaultMessage:
          "For the EU Travel Rule, please provide additional information for your recent crypto deposits.",
      })
    : intl.formatMessage({
        defaultMessage:
          "You must provide additional information for your crypto deposits before making any withdrawals.",
      });

  switch (variant) {
    case "warning":
      if (!hasMultiple) {
        primaryCTA = intl.formatMessage({
          defaultMessage: "Confirm deposit",
        });
        primaryCtaProps = {
          onClick: onConfirmDeposit,
          disabled: disabled,
        };
      } else {
        primaryCTA = viewTransfersText;
        primaryCtaProps = {
          href: transferDepositPage,
          disabled: disabled,
        };
      }
      descriptionText = isUkTravelRuleEnabled
        ? intl.formatMessage({
            defaultMessage:
              "For the UK Travel Rule, please provide additional information for your recent crypto deposits.",
          })
        : isEuTravelRuleEnabled
        ? intl.formatMessage({
            defaultMessage:
              "For the EU Travel Rule, please provide additional information for your recent crypto deposits.",
          })
        : intl.formatMessage({
            defaultMessage: "Please provide additional information for your crypto deposits.",
          });
      break;
    case "alert":
      primaryCTA = !attestationRequired
        ? intl.formatMessage({
            defaultMessage: "Contact customer support",
          })
        : intl.formatMessage({
            defaultMessage: "View transfers",
          });

      descriptionText = isFrozen
        ? intl.formatMessage({
            defaultMessage:
              "Unfortunately, this account is frozen and unable to transfer funds or trade on Gemini. Please contact support for further assistance.",
          })
        : !attestationRequired
        ? intl.formatMessage({
            defaultMessage:
              "Your account has been frozen and customer support will reach out to you. Additional information is required about your crypto deposits.",
          })
        : withdrawalCopy;
      primaryCtaProps = {
        href: !attestationRequired ? customerSupportHref : transferDepositPage,
        target: !attestationRequired ? "_blank" : "_self",
        disabled: ctaDisabled,
      };
      break;
  }

  return (
    <SectionMessage
      mt={3}
      {...cssShorthandPropsFilter(spacingProps)}
      data-testid={variant === "warning" ? transferControl.attestationWarning : transferControl.attestationAlert}
      heading={
        !isFrozen
          ? intl.formatMessage({
              defaultMessage: "Attestation required",
            })
          : intl.formatMessage({
              defaultMessage: "Your account has been frozen",
            })
      }
      statusType={variantOverride || variant}
      renderButton={
        <Button.Primary data-testid={transferControl.primaryCTA} {...primaryCtaProps}>
          {primaryCTA}
        </Button.Primary>
      }
      renderSecondButton={
        <Fragment>
          {isUkTravelRuleEnabled ? (
            <Fragment>
              <Button.Secondary
                data-testid={transferControl.secondaryCTA}
                href="https://support.gemini.com/hc/en-us/articles/20068649848091-United-Kingdom-Travel-Rule-requirements"
              >
                {intl.formatMessage({
                  defaultMessage: "Learn more",
                })}
              </Button.Secondary>
            </Fragment>
          ) : isEuTravelRuleEnabled ? (
            <Fragment>
              <Button.Secondary
                data-testid={transferControl.secondaryCTA}
                href="https://support.gemini.com/hc/en-us/sections/31695874441883-EU-European-Economic-Area-Travel-Rule-Requirements"
              >
                {intl.formatMessage({
                  defaultMessage: "Learn more",
                })}
              </Button.Secondary>
            </Fragment>
          ) : (
            <Fragment>
              {variant === "warning" && !hasMultiple ? (
                <Button.Secondary data-testid={transferControl.secondaryCTA} href={transferDepositPage}>
                  {viewTransfersText}
                </Button.Secondary>
              ) : null}
            </Fragment>
          )}
        </Fragment>
      }
    >
      {children || descriptionText}
    </SectionMessage>
  );
}
