import { Fragment, ReactNode, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import * as yup from "yup";
import {
  CountryAbbreviation,
  noRegionAllowedCountries,
  noZipcodeRequiredCountries,
  stateRequiredCountries,
} from "@gemini-common/scripts/constants/Countries";
import * as Countries from "@gemini-common/scripts/constants/Countries";
import * as CaProvinces from "@gemini-ui/CA_Provinces";
import { Button, Form, Input, Select } from "@gemini-ui/design-system";
import { SelectProps } from "@gemini-ui/design-system/forms/Select/constants";
import { FormElementSize } from "@gemini-ui/design-system/forms/shared/constants";
import { findObject, objectToDropdownArray } from "@gemini-ui/pages/register/Register/utils";
import { testIds } from "@gemini-ui/pages/register/testIds";
import { INPUT_SIZES } from "@gemini-ui/pages/register/Verify/constants";
import { ButtonWrapper } from "@gemini-ui/pages/register/Verify/styles";
import { irelandEircodeSchema } from "@gemini-ui/pages/register/Verify/utils/irelandEircodeSchema";
import { usePlacesApi } from "@gemini-ui/pages/register/Verify/utils/usePlacesApi";
import UsStates from "@gemini-ui/US_States";
import { isCountryIreland } from "@gemini-ui/utils/countries";
import { IntlShape, useIntl } from "@gemini-ui/utils/intl";

export const schema = (country: string, intl: IntlShape) => {
  const ERROR_MSG = intl.formatMessage({ defaultMessage: "This field is required." });
  const zipNotRequired = noZipcodeRequiredCountries.includes(country);
  const stateRequired = stateRequiredCountries.includes(country);

  return yup.object().shape({
    streetAddress: yup.string().required(ERROR_MSG),
    apt: yup.string(),
    locality: yup.string().required(ERROR_MSG),
    region: stateRequired ? yup.string().required(ERROR_MSG) : yup.string(),
    postalCode: isCountryIreland(country as CountryAbbreviation)
      ? irelandEircodeSchema(intl)
      : zipNotRequired
      ? yup.string()
      : yup.string().trim().required(ERROR_MSG),
  });
};

const ADDRESS_INPUT_NAME = "streetAddress";
const MIN_SEARCH_LENGTH = 3;

interface FormikAddressForm {
  setSelectedState: Function;
  selectedState: string;
  formattedCountry: string;
  buttonText: string;
  rightAlignedButton?: boolean;
  isReadOnly?: boolean;
  inputSize?: FormElementSize;
  countryFieldProps?: { isDisplayed: boolean; fieldProps?: Partial<SelectProps> };
  setCountryDetails?: Function;
  streetAddressMessage?: ReactNode;
  dobField?: (values, handleChange, touched, errors) => JSX.Element;
  nationalIdField?: (values, handleChange, touched, errors) => JSX.Element;
  addressLabel?: () => JSX.Element;
}
const isSubmitDisabled = (values, country) => {
  const postalCodeCheck = noZipcodeRequiredCountries.includes(country) ? false : !values?.postalCode?.trim();
  const stateCheck = stateRequiredCountries.includes(country) ? !values.region : false;
  const isSubmitDisabled = !values?.streetAddress || !values?.locality || postalCodeCheck || stateCheck;
  return isSubmitDisabled;
};
const FormikAddressForm = ({
  setSelectedState,
  selectedState,
  formattedCountry,
  buttonText,
  rightAlignedButton,
  isReadOnly = false,
  inputSize = INPUT_SIZES.large,
  setCountryDetails,
  countryFieldProps,
  streetAddressMessage,
  dobField,
  nationalIdField,
  addressLabel,
}: FormikAddressForm) => {
  const isJapan = formattedCountry === "jp";
  const isCanadaOrUsa = formattedCountry === "us" || formattedCountry === "ca";
  const countryItems = objectToDropdownArray(Countries.menuItems);

  const { intl } = useIntl();
  const { isEnabled, isLoading, setIsEnabled, sessionToken, placesService, autoCompleteService, getAddressComponents } =
    usePlacesApi();
  const addressInputName = isEnabled
    ? intl.formatMessage({ defaultMessage: "Home address" })
    : intl.formatMessage({ defaultMessage: "Street address" });

  // The following state items help control the Dropdown's appearance/content
  const [searchTerm, setSearchTerm] = useState("");
  const autofocusDropdown = isJapan ? false : true;
  const [numDropdownItems, setNumDropdownItems] = useState(0);

  const stateItems = useMemo(() => {
    if (!isCanadaOrUsa) return [];
    const { abbrevMenu } = formattedCountry === "us" ? UsStates : CaProvinces;
    return Object.keys(abbrevMenu).map(stateKey => ({
      value: stateKey,
      label: abbrevMenu[stateKey],
    }));
  }, [formattedCountry, isCanadaOrUsa]);

  return ({ values, handleChange, handleSubmit, isSubmitting, touched, errors, setFieldValue }) => {
    const hasValuesDefined = Boolean(values.streetAddress);

    const onStateDropdownChange = (item: string) => {
      setSelectedState(item); // Set to null for empty item (helps us with clearing auto-filled values)
      setFieldValue("region", item);
    };
    const onCountryDropdownChange = item => {
      const countryObj = findObject(countryItems, item);
      setCountryDetails(item, countryObj.label);
    };

    /**
     * Correctly populate US/CA State dropdowns
     */
    const handleState = (state = "") => {
      if (isCanadaOrUsa) {
        onStateDropdownChange(state);
      } else if (!noRegionAllowedCountries.includes(formattedCountry)) {
        setFieldValue("region", state);
      }
    };

    // Note: returns a placeId when used with google api and an address value when used as a text input
    const handleAddressChange = (addressValue: string) => {
      setSearchTerm("");
      // User cleared the address box manually, reset all fields
      if (!addressValue) {
        setFieldValue("streetAddress", "");
        setFieldValue("apt", "");
        setFieldValue("locality", "");
        handleState("");
        setFieldValue("postalCode", "");
        setNumDropdownItems(0);
      } else {
        // Act like a text input if the placesApi is not enabled
        if (!isEnabled) return setFieldValue("streetAddress", addressValue);

        // Get full address details for the user-selected address
        placesService?.getDetails({ placeId: addressValue }, (_res, _status) => {
          setIsEnabled(false);
          if (_status !== "OK" || !_res?.address_components) {
            Sentry.captureMessage(`Onboarding: Verify - address input getDetails error: ${_status}`, "warning");
            // persist search term when flipping to manual
            setFieldValue("streetAddress", searchTerm);
          } else {
            const { address1, address2, city, stateAbbrev, zip } = getAddressComponents(_res.address_components);
            // Auto-complete fields for the user
            setFieldValue("streetAddress", address1 || "");
            setFieldValue("apt", address2 || "");
            setFieldValue("locality", city || "");
            handleState(stateAbbrev);
            setFieldValue("postalCode", zip || "");
          }
        });
      }
    };

    const handleLoadOptions = (input: string, callback) => {
      setSearchTerm(input);

      if (input.length <= MIN_SEARCH_LENGTH) {
        setNumDropdownItems(0);
        return callback([]);
      }

      // Ensure that we are searching only for addresses in the user's country
      const request = { input, sessionToken, componentRestrictions: { country: formattedCountry } };

      autoCompleteService?.getPlacePredictions(request, (results, status) => {
        if (status !== "OK") {
          // allows the typed input to be selectable in case there are no results
          // this will auto fallback to manual input if they select
          setNumDropdownItems(1);
          callback([{ value: request.input, label: request.input }]);
        } else {
          callback(results.map(x => ({ label: x.description, value: x.place_id })));
          setNumDropdownItems(results.length);
        }
      });
    };

    const getInputSize = (inputSize: FormElementSize) => inputSize;

    const dropdownProps = (label: string, placeholder: string): SelectProps<string> => ({
      name: ADDRESS_INPUT_NAME,
      "data-testid": testIds.dropdown.addressStreet,
      label,
      value: values.streetAddress,

      // Only use async select if we actually have the placesApi available
      asyncLoadOptions: isEnabled ? handleLoadOptions : undefined,
      onChange: handleAddressChange,
      onInputChange: (newValue, action) => {
        if (action.action === "input-change") {
          setFieldValue("streetAddress", newValue);
        }
      },
      isClearable: Boolean(values.streetAddress),
      escapeClearsValue: true,
      menuIsOpen: autofocusDropdown && searchTerm.length > MIN_SEARCH_LENGTH && numDropdownItems > 0,
      error: touched.streetAddress && errors.streetAddress,
      autoComplete: isJapan ? "address-line2" : "address-line1",
      placeholder,
      autoFocus: autofocusDropdown,
      options: [{ value: values.streetAddress || placeholder, label: values.streetAddress || placeholder }],
    });

    const address = () => {
      switch (formattedCountry) {
        case "jp":
          return (
            <Fragment>
              {/* When the Google autocomplete service is available, use this Dropdown,
               otherwise show a plain input (previous default behavior) */}
              {isEnabled && !isReadOnly ? (
                <Select
                  {...dropdownProps(
                    intl.formatMessage({ defaultMessage: "Address, District" }),
                    intl.formatMessage({ defaultMessage: "Address, District" })
                  )}
                  size={getInputSize(inputSize)}
                />
              ) : (
                <Input
                  data-testid={testIds.input.addressStreet}
                  type="text"
                  name={ADDRESS_INPUT_NAME}
                  value={values.streetAddress}
                  label={intl.formatMessage({ defaultMessage: "Address, District" })}
                  onChange={handleChange}
                  error={touched.streetAddress && errors.streetAddress}
                  autoComplete="address-line2"
                  inputSize={getInputSize(inputSize)}
                  readOnly={isReadOnly}
                />
              )}
            </Fragment>
          );
        default:
          return (
            <Fragment>
              {isEnabled && !isReadOnly ? (
                <Select
                  {...dropdownProps(addressInputName, intl.formatMessage({ defaultMessage: "600 Third Avenue" }))}
                  size={getInputSize(inputSize)}
                  hideDropdownIndicator
                  message={streetAddressMessage}
                />
              ) : (
                <Input
                  data-testid={testIds.input.addressStreet}
                  type="text"
                  name="streetAddress"
                  value={values.streetAddress}
                  label={addressInputName}
                  onChange={handleChange}
                  error={touched.streetAddress && errors.streetAddress}
                  autoComplete="address-line1"
                  placeholder={intl.formatMessage({ defaultMessage: "600 Third Avenue" })}
                  inputSize={getInputSize(inputSize)}
                  readOnly={isReadOnly}
                  message={streetAddressMessage}
                />
              )}
            </Fragment>
          );
      }
    };

    const apt = () => {
      switch (formattedCountry) {
        case "jp":
          return (
            <Fragment>
              <Input
                data-testid={testIds.input.addressApartment}
                type="text"
                name="apt"
                value={values.apt}
                label={intl.formatMessage({ defaultMessage: "Apartment (Optional)" })}
                onChange={handleChange}
                error={touched.apt && errors.apt}
                autoComplete="address-line1"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                inputSize={getInputSize(inputSize)}
              />
            </Fragment>
          );
        default:
          return (
            <Fragment>
              <Input
                data-testid={testIds.input.addressApartment}
                type="text"
                name="apt"
                value={values.apt}
                label={intl.formatMessage({ defaultMessage: "Unit or apartment number (optional)" })}
                onChange={handleChange}
                error={touched.apt && errors.apt}
                autoComplete="address-line2"
                placeholder={intl.formatMessage({ defaultMessage: "Unit or apartment number" })}
                inputSize={getInputSize(inputSize)}
                readOnly={isReadOnly}
              />
            </Fragment>
          );
      }
    };

    const stateAndZip = () => {
      const zipCode = (label: string, placeholder?: string) => (
        <Input
          data-testid={testIds.input.addressPostalCode}
          type="text"
          name="postalCode"
          value={values.postalCode}
          label={label}
          autoComplete="postal-code"
          onChange={handleChange}
          error={touched.postalCode && errors.postalCode}
          placeholder={placeholder ? placeholder : label}
          inputSize={getInputSize(inputSize)}
          readOnly={isReadOnly}
        />
      );
      switch (formattedCountry) {
        case "us":
          return (
            <Fragment>
              {!isReadOnly ? (
                <Select
                  data-testid={testIds.input.addressRegion}
                  label={intl.formatMessage({ defaultMessage: "State" })}
                  name="region"
                  onChange={onStateDropdownChange}
                  options={stateItems}
                  value={selectedState}
                  placeholder={intl.formatMessage({ defaultMessage: "Select state" })}
                  size={getInputSize(inputSize)}
                />
              ) : (
                <Input
                  data-testid={testIds.input.addressRegion}
                  type="text"
                  name="region"
                  value={values.region}
                  label={intl.formatMessage({ defaultMessage: "State" })}
                  inputSize={getInputSize(inputSize)}
                  readOnly={isReadOnly}
                />
              )}

              {zipCode(
                intl.formatMessage({ defaultMessage: "ZIP" }),
                intl.formatMessage({ defaultMessage: "ZIP Code" })
              )}
            </Fragment>
          );
        case "ca":
          return (
            <Fragment>
              {!isReadOnly ? (
                <Select
                  data-testid={testIds.input.addressRegion}
                  label={intl.formatMessage({ defaultMessage: "Province" })}
                  name="region"
                  onChange={onStateDropdownChange}
                  options={stateItems}
                  value={selectedState}
                  error={touched.region && errors.region}
                  size={getInputSize(inputSize)}
                />
              ) : (
                <Input
                  data-testid={testIds.input.addressRegion}
                  type="text"
                  name="region"
                  value={values.region}
                  label={intl.formatMessage({ defaultMessage: "Province" })}
                  inputSize={getInputSize(inputSize)}
                  readOnly={isReadOnly}
                />
              )}
              {zipCode(intl.formatMessage({ defaultMessage: "Postal Code" }))}
            </Fragment>
          );
        case "ie":
          return zipCode(
            intl.formatMessage({ defaultMessage: "Eircode (optional)" }),
            intl.formatMessage({ defaultMessage: "Eircode (optional)" })
          );
        case "gb":
        case "sg":
          return zipCode(intl.formatMessage({ defaultMessage: "Postal code" }));
        case "hk":
          return;
        case "jp":
          return (
            <Fragment>
              <Input
                data-testid={testIds.input.addressRegion}
                type="text"
                name="region"
                value={values.region}
                label={intl.formatMessage({ defaultMessage: "Prefecture" })}
                onChange={handleChange}
                error={touched.region && errors.region}
                inputSize={getInputSize(inputSize)}
                readOnly={isReadOnly}
              />
              {zipCode(intl.formatMessage({ defaultMessage: "Postal Code" }))}
            </Fragment>
          );
        case "co":
          return zipCode(
            intl.formatMessage({ defaultMessage: "Postal code (optional)" }),
            intl.formatMessage({ defaultMessage: "Postal code" })
          );
        default:
          return (
            <Fragment>
              <Input
                data-testid={testIds.input.addressRegion}
                type="text"
                name="region"
                value={values.region}
                label={intl.formatMessage({ defaultMessage: "State / Province" })}
                onChange={handleChange}
                error={touched.region && errors.region}
                placeholder={intl.formatMessage({ defaultMessage: "State/Province" })}
                inputSize={getInputSize(inputSize)}
                readOnly={isReadOnly}
              />
              {zipCode(intl.formatMessage({ defaultMessage: "Postal Code" }))}
            </Fragment>
          );
      }
    };

    const city = () => (
      <Input
        data-testid={testIds.input.addressLocality}
        type="text"
        name="locality"
        value={values.locality}
        label={intl.formatMessage({ defaultMessage: "City" })}
        onChange={handleChange}
        error={touched.locality && errors.locality}
        placeholder={intl.formatMessage({ defaultMessage: "City, town, or subdivision" })}
        inputSize={getInputSize(inputSize)}
        readOnly={isReadOnly}
      />
    );

    const countryField = () => {
      return (
        <Select
          data-testid={testIds.input.addressCountry}
          label={intl.formatMessage({ defaultMessage: "Country" })}
          name="countryFullName"
          onChange={onCountryDropdownChange}
          options={countryItems}
          value={formattedCountry}
          placeholder={intl.formatMessage({ defaultMessage: "Country" })}
          size={getInputSize(inputSize)}
          {...countryFieldProps?.fieldProps}
        />
      );
    };

    return (
      <Form onSubmit={handleSubmit}>
        {dobField ? dobField(values, handleChange, touched, errors) : null}
        {nationalIdField ? nationalIdField(values, handleChange, touched, errors) : null}
        {countryFieldProps?.isDisplayed ? countryField() : null}
        {addressLabel ? addressLabel() : null}
        {isLoading ? null : (
          <Fragment>
            {address()}
            {hasValuesDefined && (
              <Fragment>
                {apt()}
                {city()}
                {stateAndZip()}
              </Fragment>
            )}
            {!hasValuesDefined && isEnabled && (
              <Fragment>
                <Button onClick={() => setIsEnabled(false)} pl={0}>
                  {intl.formatMessage({ defaultMessage: "Enter Address Manually" })}
                </Button>
              </Fragment>
            )}
            {!hasValuesDefined && !isEnabled && (
              <Fragment>
                {apt()}
                {city()}
                {stateAndZip()}
              </Fragment>
            )}
          </Fragment>
        )}
        <ButtonWrapper rightAlignedButton={rightAlignedButton}>
          <Button.Primary
            data-testid={testIds.button.submitAddressForm}
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitDisabled(values, formattedCountry) || isSubmitting}
            size={getInputSize(inputSize)}
            cta={buttonText}
          />
        </ButtonWrapper>
      </Form>
    );
  };
};

export default FormikAddressForm;
