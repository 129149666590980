import { EVENTS, trackBrazeEvent } from "@gemini-ui/analytics";
import { applicationApi } from "@gemini-ui/client/api";
import { ApplicationPrefill, ApplicationStatus } from "@gemini-ui/client/credit";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { VerifyPhoneOrRegister } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/components";
import { HandleSubmit } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/constants";
import { handleCreditExceptions } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/handlers/handleCreditExceptions";
import { mapFormValuesToApplication } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/handlers/mapFormValuesToApplication";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/machines/creditAppMachine";
import {
  initPixelTracking,
  trackAnalyticsEvent,
} from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/tracking/analytics";
import { isEmailVerified } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/utils";
import { CreditError } from "@gemini-ui/pages/Credit/utils/errorCodes";
import { AxiosError } from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";

const VERIFY_PHONE_OR_REGISTER = "VerifyPhoneOrRegister";
export const getNewAppError = (e: AxiosError<{ message: string; code: CreditError }>) =>
  e.response?.data.message === VERIFY_PHONE_OR_REGISTER ||
  e.response?.data.code === CreditError.VerifyPhoneOrRegister ? (
    <VerifyPhoneOrRegister />
  ) : (
    getError(e)
  );

interface HandleNewApp extends HandleSubmit {
  isLoggedIn?: boolean;
  handleSendVerificationEmail?: () => void;
  onError?: () => void;
}

export const handleNewAppSubmit = async ({
  send,
  showAlert,
  values,
  isLoggedIn,
  handleSendVerificationEmail,
  onError = () => {},
}: HandleNewApp) => {
  try {
    const applicationData: ApplicationPrefill = mapFormValuesToApplication(values);
    initPixelTracking(applicationData?.email);
    const handleNextState = (type: ClientState, data: ApplicationStatus) => {
      send({
        type,
        appData: applicationData,
        statusHistory: data.statusHistory,
      });
    };

    const { data } = await applicationApi.createApplicationPrefill(applicationData);

    if (window.nid) window.nid("identify", data?.userId);

    trackBrazeEvent(EVENTS.CC_BEGIN_CREDIT_PREQUAL.name);
    trackAnalyticsEvent({
      trackingEvent: EVENTS.CC_START_APPLICATION.name,
      properties: { applicationData },
    });
    if (!isLoggedIn) {
      trackAnalyticsEvent({
        trackingEvent: EVENTS.CC_BEGIN_CREDIT_PREQUAL.name,
        properties: { applicationData },
      });
      handleSendVerificationEmail();
      handleNextState(ClientState.EMAIL_VERIFY, data);
    } else {
      if (!isEmailVerified) {
        handleSendVerificationEmail();
        handleNextState(ClientState.EMAIL_VERIFY, data);
      } else {
        handleNextState(ClientState.SMS_VERIFY, data);
      }
    }
  } catch (e) {
    if (!handleCreditExceptions({ e, send })) {
      showAlert({ type: AlertTypes.ERROR, message: getNewAppError(e) });
    }
    onError();
  }
};
