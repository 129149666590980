import { useCallback, useEffect, useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { useToaster } from "@gemini-ui/design-system";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { RtpFundingInstructions } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import { getFiatDepositRtpInstructions } from "@gemini-ui/services/transfer/deposit";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";

export const useGetRtpInstructions = (subaccountId: string, currency: CurrencyShortNameFiat) => {
  const { intl } = useIntl();
  const { showToast } = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [instructions, setInstructions] = useState<RtpFundingInstructions>(null);

  const fetchInstructions = useCallback(() => {
    setIsLoading(true);
    setError(null);
    getFiatDepositRtpInstructions(subaccountId, { currency, sendEmail: false })
      .then(({ data: { data } }) => {
        setInstructions(data);
      })
      .catch(err => {
        const errorMessage = getError(
          err,
          intl.formatMessage({
            defaultMessage: "Unable to fetch Instructions data. Please contact support for assistance.",
          })
        );
        setError(errorMessage);
        showToast({
          message: errorMessage,
        });
        trackPaymentRegistrationFailure(LinkPaymentType.RTP, errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [subaccountId, intl, showToast, currency]);

  useEffect(() => {
    fetchInstructions();
  }, [setInstructions, subaccountId, showToast, intl, fetchInstructions]);

  return { instructions, isLoading, error, refetch: fetchInstructions };
};
