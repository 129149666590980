// eslint-disable-next-line no-restricted-imports
import { InternalAxiosRequestConfig } from "axios";

export function credentialsRequestInterceptor(config: InternalAxiosRequestConfig) {
  const host = window.location.host;

  // creditcard -> exchange requests should be made with credentials
  if (host.includes("creditcard.") && !config.baseURL?.includes("creditcard.")) {
    // Remove the creditcard subdomain from the request
    const cleanHost = host.split("creditcard.")[1];
    // Set the baseURL to https://exchange.gemini.com
    config.baseURL = `https://${cleanHost}`;
    config.withCredentials = true;
  }

  // exchange -> creditcard requests should be made with credentials
  if (host.includes("exchange.") && !host.includes("creditcard.") && config.url?.includes("credit-card")) {
    config.withCredentials = true;
  }

  return config;
}
