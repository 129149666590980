import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { WireDepositDetails } from "@gemini-ui/components/WireDepositDetails";
import { WireTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { usePageRefresh } from "@gemini-ui/contexts";
import { Button, Modal } from "@gemini-ui/design-system";
import { IntlShape, useIntl } from "@gemini-ui/utils/intl";

type CompletDepositModalProps = {
  isOpen: boolean;
  onClose: () => void;
  wireDetails: WireTransferInfoAPIPayload;
  customerBankName: string;
  currency: CurrencyShortNameFiat;
  geminiEntity: GeminiEntity;
  subaccountHashid: string;
};

const getComponentCopy = (intl: IntlShape) => ({
  MODAL_TITLE: intl.formatMessage({
    defaultMessage: "Complete your deposit",
  }),
  ACTION_PRIMARY: intl.formatMessage({
    defaultMessage: "Got it",
  }),
});

const CompleteDepositModal = ({
  isOpen,
  onClose,
  currency,
  wireDetails,
  subaccountHashid,
}: CompletDepositModalProps) => {
  const { intl } = useIntl();
  const copy = getComponentCopy(intl);

  const { requestRefresh } = usePageRefresh();

  return (
    <Modal.MultiStep isOpen={isOpen} title={copy.MODAL_TITLE} onClose={onClose}>
      <WireDepositDetails currency={currency} {...wireDetails} subaccountHashid={subaccountHashid} />
      <Button.Group>
        <Button.Primary
          data-testid="wire-instruction-got-it-btn"
          onClick={() => {
            onClose();
            requestRefresh();
          }}
        >
          {copy.ACTION_PRIMARY}
        </Button.Primary>
      </Button.Group>
    </Modal.MultiStep>
  );
};

export default CompleteDepositModal;
