import * as Sentry from "@sentry/browser";
import { EVENTS, track, trackBingEvent, trackGTMEvent, trackRedditEvent, trackTikTokEvent } from "@gemini-ui/analytics";
import {
  BingEvents,
  GoogleAnalyticsEvents,
  GoogleTagManagerEvents,
  RedditEvents,
  TikTokEvents,
} from "@gemini-ui/analytics/constants/events";
import { trackGoogleAnalyticsEvent } from "@gemini-ui/analytics/googleTracking";
import { snapchatTrack, snapEvents } from "@gemini-ui/analytics/snapchatTracking";
import { LegalIdType } from "@gemini-ui/pages/register/Verify/types";
import { AxiosError } from "@gemini-ui/services/axios";
import { EVENTS_ERROR_TRACKING_LABEL_PREFIX } from "@gemini-ui/utils/constants";
import { getCookieSettings } from "@gemini-ui/utils/cookie";
import { getError } from "@gemini-ui/utils/error";

export const trackRegistration = () => {
  try {
    if (!getCookieSettings().isSuppressed) {
      trackGoogleAnalyticsEvent(GoogleAnalyticsEvents.CreateAccount);
      trackBingEvent(BingEvents.Registration);
      trackRedditEvent(RedditEvents.CreateAccount);
      trackTikTokEvent(TikTokEvents.Registration);
      snapchatTrack(snapEvents.signUp);
      trackGTMEvent(GoogleTagManagerEvents.REGISTER);
    }
  } catch (error) {
    error.message = EVENTS_ERROR_TRACKING_LABEL_PREFIX + error.message;
    Sentry.captureMessage(error.message, "error");
  }
};

export const trackVerification = (isUsaOrCanada: boolean, idType?: LegalIdType) => {
  track(EVENTS.ENTER_ADDRESS.name, {}, { braze: true, optimizely: true });
  if (isUsaOrCanada) {
    track(EVENTS.ENTER_TAX_ID_NUMBER.name, {
      [EVENTS.ENTER_TAX_ID_NUMBER.properties.ID_TYPE]: idType,
    });
  }
  track(EVENTS.ENTER_DATE_OF_BIRTH.name);
};

export const trackOnboardingKYCComplete = () => {
  if (!getCookieSettings().isSuppressed) {
    trackGoogleAnalyticsEvent(GoogleAnalyticsEvents.KYCComplete);
    trackGTMEvent(GoogleTagManagerEvents.KYC_COMPLETE);
  }
};

export const trackOnboardingError = ({ message, error }: { message: string; error: AxiosError }) => {
  const err = getError(error);
  if (error && error.message) {
    error.message = `Onboarding error: ${message} - ${err}`;
  }
  Sentry.captureException(error);
};
