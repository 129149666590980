import styled from "@emotion/styled";
import { Spacer, Text } from "@gemini-ui/design-system";

export const PlaidDisclaimerBulletText = styled(Text.Body)`
  flex: 1;
`;

export const SpacerFlex = styled(Spacer)`
  display: flex;
`;
