import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { SiteLockout } from "@gemini-ui/components/Lockout/utils";
import { MoneyProps } from "@gemini-ui/components/Money";
import {
  BankAccountInitialDataType,
  BcoloInitialDataType,
  DebitCardInitialDataType,
  PayPalInitialDataType,
  XfersInitialDataType,
} from "@gemini-ui/constants/initialData/pageProps";
import { CardApprovalState, PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { RtpWithdrawalLimitsType } from "@gemini-ui/pages/transfers/constants";

export enum BankAccountType {
  "Checking" = "Checking",
  "Savings" = "Savings",
  "Brokerage" = "Brokerage",
  Other = "Other",
}

export enum BankAccountTransferLevel {
  "Approved" = "Approved",
  "Pending" = "Pending",
  "NoTransfers" = "NoTransfers",
  "ForeignEntity" = "ForeignEntity",
}

type SharedPaymentType = {
  id: string;
  displayName: string;
  currencies?: CurrencyShortNameFiat[];
  institutionName: string;
  lastFour?: string;
  createdAt?: number;
  lastUsedAt?: number;
  isVerified?: boolean;
  eligibility?: {
    buy: boolean;
    deposit: boolean;
    withdraw: boolean;
  };
};

type SgAccountsPagePropsType = {
  accountHashId: string;
  name: string;
  uuid: string;
  currency: string;
};

export type XfersAccountsPagePropsType = {
  xfersApproved: boolean;
  createdAt: number;
} & SgAccountsPagePropsType;

export type RtpAccountsPagePropsType = {
  isInternational: Boolean;
  achApproved?: boolean;
  wireApproved?: boolean;
  senApproved?: boolean;
  blincApproved?: boolean;
  rtpApproved?: boolean;
  bankName?: string;
  hasIntermediary: boolean;
  supportsPlaidPaymentInitiation: boolean;
} & SgAccountsPagePropsType;

type SingaporePaymentsPagePropsType = {
  hasVirtualAccountDeposit: boolean;
  primaryUuid: string;
};

export type XfersPagePropsType = {
  hasAuthToken: boolean;
  hasCustomerProfile: boolean;
  accounts: XfersAccountsPagePropsType[];
} & SingaporePaymentsPagePropsType;

export type XfersAccountType = {
  paymentMethodType: PaymentMethodType.XFERS;
  enabled?: boolean;
  accountType?: BankAccountType;
} & SharedPaymentType;

export type BcoloAccountType = {
  paymentMethodType: PaymentMethodType.BCOLO;
  available: boolean;
  accountType: BankAccountType;
} & SharedPaymentType;

export type PayPalAccountType = {
  paymentMethodType: PaymentMethodType.PAYPAL;
} & SharedPaymentType;

export type DebitCardType = {
  paymentMethodType: PaymentMethodType.DEBIT_CARD;
  // accountPreview: string; -> lastFour
  approvalState: keyof typeof CardApprovalState | string;
  cardIssuer: string;
  lastUsedAt: number;
  canGenerateAuth: boolean;
  canVerifyAuth: boolean;
  feeRate?: number;
  maxMicroDeposit?: MoneyProps<CurrencyShortNameFiat>;
  minMicroDeposit?: MoneyProps<CurrencyShortNameFiat>;
} & SharedPaymentType;

export type SharedBankType = {
  achLevel: BankAccountTransferLevel;
  wireLevel: BankAccountTransferLevel;
  accountType: BankAccountType;
  senLevel?: BankAccountTransferLevel;
  blincLevel?: BankAccountTransferLevel;
  supportsPlaidPaymentInitiation?: boolean;
  rtpLevel?: BankAccountTransferLevel;
  rtpApproved?: boolean;
} & SharedPaymentType;

export type RtpType = {
  paymentMethodType: PaymentMethodType.RTP;
} & SharedBankType;

export type BankType = {
  paymentMethodType: PaymentMethodType.BANK;
} & SharedBankType;

export type BcoloDataType = {
  primaryUuid: string;
  hasSessionToken: boolean;
  loginUrl: string;
};

export type XfersDataType = {
  primaryUuid: string;
  hasAuthToken: boolean;
  hasCustomerProfile: boolean;
};

export type RtpDataType = {
  primaryUuid: string;
  hasAuthToken: boolean;
  hasCustomerProfile: boolean;
};

export type PayPalDataType = {
  primaryUuid: string;
};

export type CbitAccountType = {
  paymentMethodType: PaymentMethodType.CBIT;
  accountType?: BankAccountType;
  walletName?: string;
  walletAddress?: string;
  accountName?: string;
  accountNumberLastFour?: string;
} & SharedPaymentType;

export type PaymentMethodDataType =
  | DebitCardType
  | BcoloAccountType
  | BankType
  | XfersAccountType
  | RtpType
  | PayPalAccountType
  | CbitAccountType;

export type BankSettingsPageDataType = {
  isPlaidRuxEnabled: boolean;
  isPlaidSupported: boolean;
  paymentMethods: Array<PaymentMethodDataType>;
  bcoloData: BcoloDataType;
  xfersData: XfersDataType;
  payPalData: PayPalDataType;
  canManagePaymentMethods: boolean;
  loadAddViaWireModal: boolean;
  lockout?: SiteLockout;
  isVerifiedUser: boolean;
  userDetails: UserAddressType;
  xfers?: XfersPagePropsType;
};

export type UserAddressType = {
  address: {
    apt: string;
    city: string;
    country: string;
    state: string;
    street: string;
    zip: string;
  };
};

export type BankSettingsInitialData = {
  banks: BankAccountInitialDataType[];
  bcolo: BcoloInitialDataType;
  cards: DebitCardInitialDataType[];
  lockout?: SiteLockout;
  canManageBankAccounts: boolean;
  loadAddViaWireModal: boolean;
  isInternational: boolean;
  isVerifiedUser?: boolean;
  payPal: PayPalInitialDataType;
  xfers: XfersInitialDataType;
  isPlaidRuxEnabled: boolean;
  isPlaidSupported: boolean;
  userDetails: UserAddressType;
};

/* Transfers */
export interface LimitType<T = MoneyProps<CurrencyShortNameFiat>> {
  portion: T;
  total: T;
}

export type BankLimitsType<T = MoneyProps<CurrencyShortNameFiat>> = {
  dailyDeposit: LimitType<T>;
  thirtyDayDeposit: LimitType<T>;
  withdrawalDaily: LimitType<T>;
};

export type PaymentMethodLimitsType<T> = {
  limits: T;
};

export type TransfersFiatDetailsType = {
  paymentMethods: Array<PaymentMethodDataType>;
  achData: PaymentMethodLimitsType<BankLimitsType>;
  ppiData: PaymentMethodLimitsType<{
    minimum: Array<MoneyProps<CurrencyShortNameFiat>>;
    maximum: Array<MoneyProps<CurrencyShortNameFiat>>;
  }>;
  bcoloData: BcoloDataType & PaymentMethodLimitsType<{ thirtyDayDeposit: LimitType; thirtyDayWithdrawal: LimitType }>;
  xfersData: XfersDataType & PaymentMethodLimitsType<{ deposit: MoneyProps; withdrawal: MoneyProps }>;
  payPalData: PayPalDataType &
    PaymentMethodLimitsType<{
      deposit: LimitType & {
        minimum: MoneyProps;
      };
    }>;
  rtpData: PaymentMethodLimitsType<RtpWithdrawalLimitsType>;
};

/* Retail Trade */
export type RetailTradePaymentMethodType = BankType | DebitCardType | PayPalAccountType;
