import { isProdUrl } from "@gemini-ui/analytics";

export enum ENVIRONMENTS {
  DEV = "dev",
  QA = "qa",
  PROD = "production",
}

const getSentryEnvironment = () => {
  // uses hostname check to differentiate prod and qa100, sandbox
  if (isProdUrl()) {
    return ENVIRONMENTS.PROD;
  } else if (process.env.SENTRY_ENVIRONMENT === ENVIRONMENTS.PROD) {
    return ENVIRONMENTS.QA;
  }
  return process.env.SENTRY_ENVIRONMENT || ENVIRONMENTS.DEV;
};

export default getSentryEnvironment;
