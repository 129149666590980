import { useEffect, useState } from "react";
import { useToaster } from "@gemini-ui/design-system";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { SupportedBanks } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { getSupportedBanks } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/services";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";

export const useGetSupportedBanks = () => {
  const { intl } = useIntl();
  const { showToast } = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [supportedBanks, setSupportedBanks] = useState<SupportedBanks>([]);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    getSupportedBanks()
      .then(({ data }) => {
        setSupportedBanks(data.supportedBanks);
      })
      .catch(err => {
        const errorMessage = getError(
          err,
          intl.formatMessage({
            defaultMessage: "Unable to fetch supported banks. Please contact support for assistance.",
          })
        );
        setError(errorMessage);
        showToast({
          message: errorMessage,
        });
        trackPaymentRegistrationFailure(LinkPaymentType.RTP, errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setSupportedBanks, showToast, intl]);

  return { supportedBanks, isLoading, error };
};
