import { Colors, Flex, Text, text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const DepositInstructionChangeHeader = () => {
  const { intl } = useIntl();

  return (
    <Flex pl={2} pr={2} justifyContent="center" data-testid="SG_BANNER" backgroundColor={Colors.brandCyan}>
      <Text color={Colors.black} pb={1} pt={1} {...text.base.scale[14]}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "IMPORTANT: Your FAST deposit instructions may have changed. Please check before transferring to avoid delays and fees. <link>Learn more</link>",
          }),
          {
            link: (str: React.ReactNode) => (
              <Text.Link
                href="https://support.gemini.com/hc/en-us/articles/360053638291-How-do-I-deposit-Singapore-Dollars"
                target="_blank"
                referrerPolicy="no-referrer"
                color={Colors.black}
                fontWeight={text.base.weight.bold}
                css={{ ":hover": { color: Colors.white, opacity: 1 } }}
              >
                {str}
              </Text.Link>
            ),
          }
        )}
      </Text>
    </Flex>
  );
};

export default DepositInstructionChangeHeader;
