import * as Sentry from "@sentry/browser";
import { formatDistanceToNow } from "date-fns";
import { IntlShape } from "react-intl";
import {
  CurrencyShortName,
  CurrencyShortNameCrypto,
  CurrencyShortNameForCustody,
  isCurrency,
} from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient, track } from "@gemini-ui/analytics";
import { getCryptoDepositDetails } from "@gemini-ui/components/Transfer/CryptoDepositFlow/apiLayer";
import {
  CRYPTO_DEPOSIT_EVENTS,
  CRYPTO_DEPOSIT_MIXPANEL_EVENTS,
} from "@gemini-ui/components/Transfer/CryptoDepositFlow/constants";
import { BUSINESS_METHODS } from "@gemini-ui/components/Transfer/CryptoDepositFlow/ruleMethods";
import { CryptoDepositEvents } from "@gemini-ui/components/Transfer/CryptoDepositFlow/types";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GeminiAccount } from "@gemini-ui/constants/templateProps/account";
import { User } from "@gemini-ui/constants/templateProps/users";
import { ToastProps } from "@gemini-ui/design-system/Toaster/constants";
import { CryptoAddress, CurrencyWithBoolean, TransferAccount } from "@gemini-ui/pages/transfers/constants";
import { CryptoDepositRequest, DepositTransferRequestStatus } from "@gemini-ui/services/transfer/types";

interface HandleCurrencySelectionProps {
  accounts: TransferAccount[];
  user: User;
  currency: CurrencyShortNameCrypto;
  supportedExchangeCrypto: Partial<CurrencyShortNameForCustody>[];
  transactionsEnabled: CurrencyWithBoolean;
  account?: GeminiAccount;
}
interface MarketFilter {
  type: string;
  assets: CurrencyShortNameCrypto[];
}
interface MarketData {
  value?: MarketFilter[];
}
export const getEligibleCurrencies = (orderedAssets: CurrencyShortName[], transactionsEnabled: CurrencyWithBoolean) => {
  if (!orderedAssets || !transactionsEnabled) return [];

  const sortedAssets = orderedAssets.sort((a, b) => {
    const aEnabled = transactionsEnabled[a] || false;
    const bEnabled = transactionsEnabled[b] || false;

    if (aEnabled && bEnabled) return 0;
    else if (aEnabled) return -1;
    else return 1;
  });

  return sortedAssets;
};
export const filterRows = (
  searchedRows: { name: string; symbol: CurrencyShortNameCrypto }[],
  isCustody: boolean,
  exchangeOnlyAssets: CurrencyShortName[]
): { name: string; symbol: CurrencyShortNameCrypto }[] => {
  if (isCustody) {
    return BUSINESS_METHODS.getFilteredAssetsForCustody(searchedRows, exchangeOnlyAssets);
  } else {
    return searchedRows;
  }
};

export const getIdentifierAttribute = (currency: CurrencyShortNameCrypto): string => {
  const MEMO = "memo";
  const ADDRESS = "address";
  if (isCurrency.ATOM(currency) || isCurrency.XRP(currency)) {
    return MEMO;
  }
  return ADDRESS;
};

export const handleCurrencySelection = async (
  { accounts, user, currency, supportedExchangeCrypto, transactionsEnabled, account }: HandleCurrencySelectionProps,
  send: (event: CryptoDepositEvents) => void,
  intl: IntlShape,
  showToast: (toast: ToastProps) => ToastProps
) => {
  try {
    const destination = accounts.find(account => account.hashid === user.subaccountHashid);
    const isAssetError = BUSINESS_METHODS.getAssetsError(currency, transactionsEnabled, account?.geminiEntity);

    if (isAssetError?.value) {
      send({
        type: CRYPTO_DEPOSIT_EVENTS.SET_CURRENCY,
        currency,
        isError: true,
        destination,
      });
    } else {
      const response = await getCryptoDepositDetails(currency, user?.subaccountHashid);
      if (response?.status === 200 && response?.data) {
        const { addresses, multiAddressFormat } = response.data;
        send({
          type: CRYPTO_DEPOSIT_EVENTS.SET_CURRENCY,
          currency,
          isError: false,
          destination,
          address: addresses?.[0],
          addresses,
          multiAddressFormat,
        });
        track(CRYPTO_DEPOSIT_MIXPANEL_EVENTS.SELECT_ASSET.name, {
          [CRYPTO_DEPOSIT_MIXPANEL_EVENTS.SELECT_ASSET.properties.CURRENCY]: currency,
        });
      } else {
        showToast({
          message: intl.formatMessage({ defaultMessage: "Something went wrong. Please try again." }),
        });
      }
    }
  } catch (e) {
    showToast({ message: intl.formatMessage({ defaultMessage: "Something went wrong. Please try again." }) });
    Sentry.captureException(e);
  }
};

export const isSameAddressEntity = (
  selectedAddressValue: CryptoAddress,
  addressEntity: CryptoAddress,
  identifierAttribute: string
): boolean => {
  if (
    selectedAddressValue?.address === addressEntity.address &&
    selectedAddressValue?.[identifierAttribute] === addressEntity?.[identifierAttribute]
  ) {
    return true;
  } else return false;
};
const getNotionalValue = (asset: string, balanceData: CurrencyBalances): number => {
  const assetLower = asset.toLowerCase();
  return parseFloat(balanceData?.[assetLower]?.notionalValue?.value || 0);
};
export const getSortMetaDataArray = (
  balanceData: CurrencyBalances,
  marketData: MarketData
): CurrencyShortNameCrypto[] => {
  const marketArray = marketData?.value?.find(data => data.type === "AllMarketFilter")?.assets || [];

  const balanceSortedAssets = balanceData
    ? Object.keys(balanceData)
        .sort((a, b) => getNotionalValue(b, balanceData) - getNotionalValue(a, balanceData))
        .map(asset => balanceData[asset.toLowerCase()]?.availableForWithdrawal?.currency)
        .filter(Boolean)
    : [];

  const combinedAssets = [...balanceSortedAssets];

  marketArray.forEach(asset => {
    if (!combinedAssets.includes(asset)) {
      combinedAssets.push(asset);
    }
  });

  return combinedAssets;
};

export const sortSupportedAssets = (
  supported: CurrencyShortNameForCustody[],
  combined: CurrencyShortNameForCustody[]
): CurrencyShortNameForCustody[] => {
  const sortedAssets = supported.sort((a, b) => {
    const aIndex = combined.indexOf(a);
    const bIndex = combined.indexOf(b);
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    if (aIndex !== -1) return -1;
    if (bIndex !== -1) return 1;

    return 0;
  });

  return sortedAssets;
};

export const eligibleForCryptoDeposits = (user: User) => {
  const isBasicPlusEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BASIC_PLUS_DEPOSIT_CRYPTO);
  return user.isFullyVerified || (isBasicPlusEnabled && user.isBasicPlusTier);
};

export const mergeDepositStatuses = (seenDeposits: CryptoDepositRequest[], newData: CryptoDepositRequest[]) => {
  const [newIds, seenIds] = [new Set(newData.map(i => i.id)), new Set(seenDeposits.map(i => i.id))];

  // Update existing deposits: If they still exist in newData, keep them as is; otherwise, mark them as finalized
  const updatedDeposits = seenDeposits.map(deposit =>
    // If an item has been removed from the response, it's completed
    newIds.has(deposit.id) ? deposit : { ...deposit, status: DepositTransferRequestStatus.COMPLETE }
  );

  // Add new deposits that weren’t previously tracked
  const newDeposits = newData.filter(d => !seenIds.has(d.id));

  return [...updatedDeposits, ...newDeposits];
};

export const formatTimeAgoLabel = (timestamp: number, intl: IntlShape) => {
  const timeAgoDiff = Math.floor((Date.now() - timestamp) / 1000);
  return timeAgoDiff < 60
    ? intl.formatMessage({ defaultMessage: "Just now " })
    : formatDistanceToNow(new Date(timestamp), { addSuffix: true });
};
