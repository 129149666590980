import { usePageData } from "@gemini-ui/contexts";

/**
 * Whether the user is waiting for verification from basic plus
 */
export const useIsVerificationPendingFromBasicPlus = () => {
  const {
    templateProps: {
      user: { userLevel },
    },
  } = usePageData();

  return userLevel === "VerificationPendingFromBasicPlus";
};
