import * as Sentry from "@sentry/browser";
import _ from "lodash";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { DebitCardInitialDataType } from "@gemini-ui/constants/initialData/pageProps";
import { GENERIC_3DS_ERROR } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { DebitCardModalState } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/types";
import {
  AuthenticateRespone,
  DeviceDataInitialisation,
  HandleDebitCardSubmitType,
  WorldpayTokenResponse,
} from "@gemini-ui/pages/settings/BankSettings/debit/types";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { DebitCardType } from "@gemini-ui/transformers/PaymentMethods";
import { transformDebitCard } from "@gemini-ui/transformers/PaymentMethods/transformPaymentMethods";
import { getError, getFormErrors, getGlobalError } from "@gemini-ui/utils/error";

export const handleDeviceDataInitialization = async (
  unVerifiedTokenID: string,
  currency: CurrencyShortNameFiat,
  country: string
): Promise<DeviceDataInitialisation> => {
  const { data } = await axios.post(
    jsRoutes.com.gemini.web.server.funding.controllers.VerificationsController.card3dsDeviceDataInitialization(
      unVerifiedTokenID
    ).url,
    {
      amount: {
        amount: 0,
        currency: currency,
      },
      countryCode: country,
    }
  );
  const { jwt, url, bin } = data.form;
  const { internalReference } = data;
  return {
    deviceDataJWT: jwt,
    deviceDataURL: url,
    bin: bin,
    internalReference: internalReference,
    showDeviceDataIframe: true,
  };
};

export const handleAuthenticateTransaction = async (
  SessionId: string,
  unVerifiedTokenID: string,
  internalReference: string,
  country: string,
  currency: CurrencyShortNameFiat
): Promise<{
  displayName: string;
  authenticateResponse: AuthenticateRespone;
}> => {
  const { data } = await axios.post(
    jsRoutes.com.gemini.web.server.funding.controllers.VerificationsController.card3dsAuthenticateTransaction(
      unVerifiedTokenID
    ).url,
    {
      internalReference: internalReference,
      countryCode: country,
      amount: {
        amount: 0,
        currency: currency,
      },
      collectionReference: SessionId,
    }
  );
  return {
    displayName: data?.challengeType?.displayName,
    authenticateResponse: data,
  };
};

export const handleVerifyChallenge = (unVerifiedTokenID, internalReference) =>
  axios.post(
    jsRoutes.com.gemini.web.server.funding.controllers.VerificationsController.card3dsVerifyChallenge(unVerifiedTokenID)
      .url,
    {
      internalReference: internalReference,
    }
  );

// To be phased out in favor of handleInitialDebitCardSubmit and handleFinalDebitCardSubmit
export const handleDebitCardSubmit = async (
  payload: HandleDebitCardSubmitType,
  selectedCurrency: CurrencyShortNameFiat,
  country: string,
  subaccountHashid: string,
  onToggle: ToggleDebitCardModal,
  handleVerifyCard: (cardProps: DebitCardType, isExistingCard?: boolean) => void,
  openDebitCardErrorModal: (debitCardErrorHeading?: string, debitCardErrorDescription?: string) => void,
  isWebDebitCard3DSEnabled: boolean,
  setHandleDeviceDataResponse?: (
    handleDeviceDataResponse: DeviceDataInitialisation & { unVerifiedTokenID: string }
  ) => void,
  setShowDeviceDataIframe?: (showDeviceDataIframe: boolean) => void,
  showChallengeFormIframe?: boolean
) => {
  // Once feature flag is removed, move this into handleInitialDebitCardSubmit
  const { name, properties } = EVENTS.ADD_DEBIT_CARD_INFO;
  track(name, { [properties.SUBMITTED]: true });

  const isWorldPayFlowRevamped = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_WORLDPAY_FLOW_REVAMP);
  if (isWorldPayFlowRevamped) {
    await handleInitialDebitCardSubmit(
      payload,
      selectedCurrency,
      country,
      subaccountHashid,
      onToggle,
      handleVerifyCard,
      openDebitCardErrorModal,
      isWebDebitCard3DSEnabled,
      setHandleDeviceDataResponse,
      setShowDeviceDataIframe
    );
  } else {
    axios
      .post(jsRoutes.com.gemini.web.server.funding.controllers.CardsController.post().url, payload, {
        headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
      })
      .then(res => {
        const { approvalState } = res.data;
        switch (approvalState) {
          case "3DSApproved":
            const { name } = EVENTS.DEBIT_CARD_LINKED;
            track(name);
            onToggle(DebitCardModalState.showDebitCardSuccessModal)();
            break;
          case "Pending":
            //show microdeposit modal
            handleVerifyCard(transformDebitCard(res.data), false);
            break;
        }
      })
      .catch(async err => {
        const unVerifiedTokenID = _.first(_.values(getFormErrors(err)?.unVerifiedTokenID));
        if (isWebDebitCard3DSEnabled && unVerifiedTokenID) {
          try {
            const { deviceDataJWT, deviceDataURL, bin, internalReference, showDeviceDataIframe } =
              await handleDeviceDataInitialization(unVerifiedTokenID, selectedCurrency, country);
            setHandleDeviceDataResponse({
              deviceDataJWT,
              deviceDataURL,
              bin,
              internalReference,
              showDeviceDataIframe,
              unVerifiedTokenID,
            });
          } catch (error) {
            const errorMessage = getError(error, GENERIC_3DS_ERROR);
            openDebitCardErrorModal(errorMessage);
          }
        } else {
          const errorMessage = _.first(_.values(getFormErrors(err)?.error)) || getGlobalError(err.response);
          if (setShowDeviceDataIframe) setShowDeviceDataIframe(false);
          if (showChallengeFormIframe) onToggle(DebitCardModalState.addDebitCardVisible)();
          openDebitCardErrorModal(errorMessage);
          Sentry.captureException(err);
        }
      });
  }
};

// Rename once microdeposit feature flag is accepted, removed
export const handleInitialDebitCardSubmit = async (
  payload: HandleDebitCardSubmitType,
  selectedCurrency: CurrencyShortNameFiat,
  country: string,
  subaccountHashid: string,
  onToggle: ToggleDebitCardModal,
  handleVerifyCard: (cardProps: DebitCardType, isExistingCard?: boolean) => void,
  openDebitCardErrorModal: (debitCardErrorHeading?: string, debitCardErrorDescription?: string) => void,
  isWebDebitCard3DSEnabled: boolean,
  setHandleDeviceDataResponse?: (
    handleDeviceDataResponse: DeviceDataInitialisation & { unVerifiedTokenID: string }
  ) => void,
  setShowDeviceDataIframe?: (showDeviceDataIframe: boolean) => void
): Promise<void> => {
  await axios
    .post<WorldpayTokenResponse>(
      jsRoutes.com.gemini.web.server.funding.controllers.CardsController.registerWorldpayToken().url,
      payload,
      {
        headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
      }
    )
    .then(async res => {
      const { tokenUUID } = res.data;
      if (isWebDebitCard3DSEnabled) {
        try {
          const { deviceDataJWT, deviceDataURL, bin, internalReference, showDeviceDataIframe } =
            await handleDeviceDataInitialization(tokenUUID, selectedCurrency, country);
          setHandleDeviceDataResponse({
            deviceDataJWT,
            deviceDataURL,
            bin,
            internalReference,
            showDeviceDataIframe,
            unVerifiedTokenID: tokenUUID,
          });
        } catch (error) {
          if (setShowDeviceDataIframe) setShowDeviceDataIframe(false);
          const errorMessage = getError(error, GENERIC_3DS_ERROR);
          openDebitCardErrorModal(errorMessage);
        }
      } else {
        const newPayload = { ...payload, tokenUUID };
        handleFinalDebitCardSubmit(newPayload, subaccountHashid, onToggle, handleVerifyCard, openDebitCardErrorModal);
      }
    })
    .catch(err => {
      if (setShowDeviceDataIframe) setShowDeviceDataIframe(false);
      const errorMessage = _.first(_.values(getFormErrors(err)?.error)) || getGlobalError(err.response);
      openDebitCardErrorModal(errorMessage);
      Sentry.captureException(err);
    });
};

export const handleFinalDebitCardSubmit = async (
  payload: HandleDebitCardSubmitType,
  subaccountHashid: string,
  onToggle: ToggleDebitCardModal,
  handleVerifyCard: (cardProps: DebitCardType, isExistingCard?: boolean) => void,
  openDebitCardErrorModal: (debitCardErrorHeading?: string, debitCardErrorDescription?: string) => void,
  setShowDeviceDataIframe?: (showDeviceDataIframe: boolean) => void,
  showChallengeFormIframe?: boolean
) => {
  await axios
    .post<DebitCardInitialDataType>(
      jsRoutes.com.gemini.web.server.funding.controllers.CardsController.post().url,
      payload,
      {
        headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
      }
    )
    .then(res => {
      const { approvalState } = res.data;
      switch (approvalState) {
        case "3DSApproved":
          // card is added and approved in our backend, customer is done
          track(EVENTS.DEBIT_CARD_LINKED.name);
          onToggle(DebitCardModalState.showDebitCardSuccessModal)();
          break;
        case "Pending":
          //show microdeposit modal
          handleVerifyCard(transformDebitCard(res.data), false);
          break;
      }
    })
    .catch(err => {
      const errorMessage = _.first(_.values(getFormErrors(err)?.error)) || getGlobalError(err.response);
      openDebitCardErrorModal(errorMessage);
      if (setShowDeviceDataIframe) setShowDeviceDataIframe(false);
      if (showChallengeFormIframe) onToggle(DebitCardModalState.addDebitCardVisible)();
      Sentry.captureException(err);
    });
};
