import * as Sentry from "@sentry/browser";
import BigNumber from "bignumber.js";
import { flatMap, orderBy } from "lodash";

// @MultiCurrencyAware - basically this whole file
export const CRYPTO_CURRENCIES = window.currencyData.CRYPTO_CURRENCIES;
export const FIAT_CURRENCIES = window.currencyData.FIAT_CURRENCIES;
export const SPL_CURRENCIES = window.currencyData.SPL_CURRENCIES;
export const ERC20_CURRENCIES = window.currencyData.ERC20_CURRENCIES;
export const PERPETUAL_PAIRS = window.currencyData.PERPETUAL_PAIRS;

/** Stablecoins (pegged 1:1 to USD) */
export const STABLECOIN_CURRENCIES = ["DAI", "GUSD", "USDC", "UST"] as const;
export const PERP = "PERP";
export const PERPETUAL_CONTRACTS = [PERP] as const;
/** Currencies which should no longer have options to deposit or buy**/
const DEPOSIT_DISABLED_CURRENCIES = ["MIR", "FXC", "UST"] as const;
/** Currencies enabled for cross collateral in perps trading **/
const XC_ENABLED_CURRENCIES = ["BTC", "ETH", "USDT"] as const;
/**
 * Currencies designated for funding in perps transfer flow
 * These are converted to GUSD when transferring to derivative accounts
 * */
const FUNDING_ENABLED_CURRENCIES = ["SGD", "USD", "GUSD", "USDC"] as const;

export const SUPPORTED_CURRENCIES = [
  ...CRYPTO_CURRENCIES,
  ...FIAT_CURRENCIES,
  ...ERC20_CURRENCIES,
  ...SPL_CURRENCIES,
  ...PERPETUAL_CONTRACTS,
] as const;
export const SUPPORTED_CRYPTOS = [
  ...CRYPTO_CURRENCIES,
  ...ERC20_CURRENCIES,
  ...SPL_CURRENCIES,
  ...PERPETUAL_CONTRACTS,
] as const;

/**
 * IMPORTANT: This is for display purposes only. Do not attempt to pass the new symbol to an API - it will fail!
 *
 * Some tokens have changed names since we first listed them.
 * As of 05/24, we do not have a way to update the symbols without affecting functionality.
 * This map can be used to lookup the new token name based on the old symbol.
 */
export const LEGACY_TOKEN_SYMBOLS = {
  UST: "USTC",
  LUNA: "LUNC",
  QRDO: "OPEN",
  FET: "ASI",
  ZBC: "ZBCN",
  PLA: "PDA",
  MC: "BEAM",
  GAL: "G",
} as const;

/**
 * Some tokens have been delisted from the exchange since Earn was shut down.
 * As of 05/24, this is the master hardcoded list of delisted assets that will be distributed as part of the Earn distributions.
 */
export const EARN_DELISTED_ASSETS = {
  "1INCH": "1INCH",
  ALCX: "ALCX",
  BAL: "BAL",
  BNT: "BNT",
  KNC: "KNC",
  RBN: "RBN",
  RLY: "RLY",
  TOKE: "TOKE",
  UST: "UST",
  LUNA: "LUNA",
} as const;

export const unsupportedERC20Pairs = [
  "MIMUSD",
  "WCFGUSD",
  "REVVUSD",
  "BICOUSD",
  "DPIUSD",
  "ASHUSD",
  "TRUUSD",
  "USTUSD",
  "MIRUSD",
  "FRAXUSD",
  "BUSDUSD",
  "FRAXUSD",
  "FXSUSD",
  "INDEXUSD",
  "RADUSD",
  `KP3RUSD`,
  "BNTUSD",
  "EULUSD",
  "PLAUSD",
  "NMRUSD",
  "ALCXUSD",
  "BONDUSD",
  "AUDIOUSD",
  "CVCUSD",
  `MCO2USD`,
  "SLPUSD",
  "BALUSD",
  "SPELLUSD",
  "KNCUSD",
  "1INCHUSD",
  "ENJUSD",
  "GFIUSD",
  "LQTYUSD",
  "LUSDUSD",
  "MCUSD",
  "METISUSD",
  "MPLUSD",
  "RBNUSD",
  "LUNAUSD",
  "SNXUSD",
  "QRDOUSD",
  "RLYUSD",
  "TOKEUSD",
];

export const unsupportedSPLPairs = ["FIDAUSD", "SBRUSD", "ORCAUSD", "RAYUSD", "ZBCUSD"];

export const suspendedPairs = [
  "OXTBTC",
  "OXTETH",
  "BATBTC",
  "BATETH",
  "ZECBTC",
  "ZECETH",
  "ZECLTC",
  "ZECBCH",
  "BTCDAI",
  "ETHDAI",
];

export const suspendedPerpPairs = ["MATICGUSDPERP"];

export type CurrencyShortNameCrypto = (typeof CRYPTO_CURRENCIES)[number];
export type CurrencyShortNameSupportedCryptos = (typeof SUPPORTED_CRYPTOS)[number];
export type CurrencyShortNameSupportedCryptosLower = Lowercase<CurrencyShortNameSupportedCryptos>;
export type CurrencyShortNameFiat = (typeof FIAT_CURRENCIES)[number];
export type CurrencyShortNameERC20 = (typeof ERC20_CURRENCIES)[number];
export type CurrencyShortNameSolana = (typeof SPL_CURRENCIES)[number];
export type CurrencyShortNameStableCoin = (typeof STABLECOIN_CURRENCIES)[number];
export type CurrencyShortName = (typeof SUPPORTED_CURRENCIES)[number];
export type CurrencyShortNameDepositDisabled = (typeof DEPOSIT_DISABLED_CURRENCIES)[number];
export type CurrencyShortNameLower = Lowercase<CurrencyShortName>;
export type CurrencyShortNameCrossCollateral = (typeof XC_ENABLED_CURRENCIES)[number];
export type CurrencyShortNameFunding = (typeof FUNDING_ENABLED_CURRENCIES)[number];
export type PerpetualPair = (typeof PERPETUAL_PAIRS)[number];

/**
 * Use this as a type-narrowing helper. It's better and more type-aware than just calling .toUpperCase()
 *
 * @example
 * typeof "doge".toUpperCase(); // string
 * typeof currencyToUpper("doge"); // "DOGE"
 */
export const currencyToUpper = <T extends CurrencyShortName | CurrencyShortNameLower>(currency: T) => {
  return currency?.toLocaleUpperCase() as Uppercase<T>;
};

/**
 * Use this as a type-narrowing helper. It's better and more type-aware than just calling .toLowercase()
 *
 * @example
 * typeof "DOGE".toLowerCase(); // string
 * typeof currencyToLower("DOGE"); // "doge"
 */
export const currencyToLower = <T extends CurrencyShortName | CurrencyShortNameLower>(currency: T) => {
  return currency?.toLocaleLowerCase() as Lowercase<T>;
};

export type SupportedCurrencyPairs = string; /* managed by templateProps.account.supportedPairs */

export interface CurrencyPairDetail {
  priceCurrency: CurrencyShortName;
  quantityCurrency: CurrencyShortName;
  symbol: SupportedCurrencyPairs;
  retailTrader: boolean;
  activeTrader: boolean;
  isWrappedPair: boolean;
}

export const getCurrencyPairDetail = (symbol: string, supportedPairs: CurrencyPairDetail[]) =>
  supportedPairs.find(currencyPair => currencyPair.symbol === symbol);

export interface CurrencyDetail {
  symbol: any; // used as both CurrencyShortName and string
  name: string;
  rankOrder: number;
  decimals: number;
  minDecimals: number;
  maxDecimals: number;
  receiptDecimals: number;
  isNotional: boolean;
  leadingSymbol: string;
  network: string;
  showCodeAfter?: boolean;
  isStablecoin?: boolean;
}

const CURRENCIES_DATA = window.currencyData.currencies;
export type CurrenciesDetail = typeof CURRENCIES_DATA;

const currencyProxyHandler = {
  get: (target, name) => {
    if (target.hasOwnProperty(name)) {
      return target[name];
    }
    // $$typeof is a react internal property that is used to check if a component is a react element
    const invalidName = name === "$$typeof";
    // ignore logging exceptions for delisted asset
    const isDelistedAsset = Boolean(EARN_DELISTED_ASSETS[name]);
    // currency is not found in CURRENCIES_DATA object key lookup.
    // log to sentry once
    if (!window.currencyData.missingData[name] && !invalidName && !isDelistedAsset) {
      Sentry.captureMessage(`Currency ${name} not found in window.currencyData.currencies`, "warning");
      window.currencyData.missingData[name] = `${name} not found in CURRENCIES_DATA lookup`;
    }

    // provide default fallback to avoid crash
    return {
      symbol: name,
      name: name,
      decimals: 8,
      minDecimals: 0,
      maxDecimals: 8,
      receiptDecimals: 8,
      rankOrder: 9999, // last in list
      isNotional: false,
      leadingSymbol: null,
      network: null,
    };
  },
};

export const CURRENCIES_DETAIL = new Proxy<CurrenciesDetail>(CURRENCIES_DATA, currencyProxyHandler);
/*----

CURRENCY HELPER FUNCTIONS

----*/

export const isOrderedCurrency = (currency: CurrencyShortName): currency is CurrencyShortName => {
  return typeof CURRENCIES_DETAIL[currency]?.rankOrder === "number";
};

export const isFiatCurrency = (currency: string): currency is CurrencyShortNameFiat => {
  return FIAT_CURRENCIES.includes(currency as CurrencyShortNameFiat);
};

export const isErc20Token = (currency: string): currency is CurrencyShortNameERC20 => {
  return ERC20_CURRENCIES.includes(currency as CurrencyShortNameERC20);
};

export const isSplToken = (currency: string): currency is CurrencyShortNameSolana => {
  return SPL_CURRENCIES.includes(currency as CurrencyShortNameSolana);
};

export const isSupportedCrypto = (currency: string): currency is CurrencyShortNameSupportedCryptos => {
  return SUPPORTED_CRYPTOS.includes(currency as CurrencyShortNameSupportedCryptos);
};

export const isStablecoin = (currency: string): currency is CurrencyShortNameStableCoin => {
  return STABLECOIN_CURRENCIES.includes(currency as CurrencyShortNameStableCoin);
};

export const isDepositDisabled = (currency: string): currency is CurrencyShortNameDepositDisabled => {
  return DEPOSIT_DISABLED_CURRENCIES.includes(currency as CurrencyShortNameDepositDisabled);
};

export const isPerpetualPair = (pair: string): pair is PerpetualPair => {
  return PERPETUAL_PAIRS.includes(pair as PerpetualPair);
};

export const isCrossCollateralSupportedCurrency = (currency: string): currency is CurrencyShortNameCrossCollateral => {
  return XC_ENABLED_CURRENCIES.includes(currency as CurrencyShortNameCrossCollateral);
};

export const isFundingSupportedCurrency = (currency: string): currency is CurrencyShortNameFunding => {
  return FUNDING_ENABLED_CURRENCIES.includes(currency as CurrencyShortNameFunding);
};

type CurrencyHelperMap = Record<CurrencyShortName, (currency: string) => boolean>;

const isCurrencyProxyHandler = {
  get: function (_target, name: CurrencyShortName) {
    return (x: string) => x === name;
  },
};

/**
 * This is an object that contains generic helper functions for all supported currencies.
 *
 * @example
 * isCurrency.BTC("BTC") // true
 * isCurrency.ETH("ETH") // true
 * isCurrency.MATIC("GUSD") // false
 */
export const isCurrency = new Proxy<CurrencyHelperMap>({} as CurrencyHelperMap, isCurrencyProxyHandler);

/*----


TRANSFER


----*/

// Peer to Peer
const CURRENCIES_FOR_PEER_TO_PEER = ["GBP", "EUR"] as const;
export type CurrenciesForPeerToPeer = (typeof CURRENCIES_FOR_PEER_TO_PEER)[number];

/*----


CUSTODY


----*/

export type CurrencyShortNameForCustody = CurrencyShortNameCrypto | CurrencyShortNameERC20 | CurrencyShortNameSolana;

/*
  Adding list to FE as compound assets are not available through service driven props
  see: supportedCustodyCrypto which drives initialData.account.supportedCrypto
*/
export const COMPOUND_CRYPTO_CURRENCIES_FOR_CUSTODY = ["CBAT", "CDAI", "CZRX", "CETH", "CWBTC"] as const;

/*----


CHAIN NETWORK


----*/

/**
 * Specifically needed for our new ChainNetwork model where Currency by itself is not enough to indicate what chain
 * a money belongs to. For example, USD is in both Ethereum, Wire, ACH, SEN, etc, so we need extra information.
 */
export const CHAIN_NETWORKS = window.currencyData.networks;

export type ChainNetworkShortName = keyof typeof CHAIN_NETWORKS;

// Arbitrary pairs that we want to ignore (e.g. PAG-GUSD vs PAXG-USD)
const NON_SUPPORTED_PAIRS: Record<
  SupportedCurrencyPairs,
  Pick<CurrencyPairDetail, "priceCurrency" | "quantityCurrency">
> = {
  PAXGUSD: { quantityCurrency: "PAX", priceCurrency: "GUSD" },
};

const isNonSupported = (
  symbol: SupportedCurrencyPairs,
  quantityCurrency: CurrencyShortName,
  priceCurrency: CurrencyShortName
) =>
  NON_SUPPORTED_PAIRS[symbol]?.quantityCurrency === quantityCurrency &&
  NON_SUPPORTED_PAIRS[symbol]?.priceCurrency === priceCurrency;

const priceCurrencyToAllMap = (priceCurrency: string) =>
  // Use PERPETUAL_CONTRACTS to override behavior for PERP pairs e.g. BTCGUSDPERP { priceCurrency: "GUSD", quantityCurrency: "BTC" }
  priceCurrency === PERP
    ? SUPPORTED_CURRENCIES.map(quantityCurrency => [
        quantityCurrency + "GUSD" + priceCurrency,
        "GUSD",
        quantityCurrency,
      ])
    : SUPPORTED_CURRENCIES.map(quantityCurrency => [quantityCurrency + priceCurrency, priceCurrency, quantityCurrency]);

// Returns a map of all pair combinations
const allPairs: Record<SupportedCurrencyPairs, Omit<CurrencyPairDetail, "symbol">> = flatMap(
  SUPPORTED_CURRENCIES,
  priceCurrencyToAllMap
).reduce((acc, [symbol, priceCurrency, quantityCurrency]) => {
  if (
    priceCurrency !== quantityCurrency &&
    // Skip currencies with fiat quantity currencies, which'll also remove some conflicts (e.g. USDT-USD vs USD-TUSD)
    !isFiatCurrency(quantityCurrency) &&
    !isNonSupported(symbol, quantityCurrency as CurrencyShortName, priceCurrency as CurrencyShortName)
  ) {
    acc[symbol] = { priceCurrency, quantityCurrency };
  }
  return acc;
}, {});

export const allCryptoPairs: Record<SupportedCurrencyPairs, Omit<CurrencyPairDetail, "symbol">> = flatMap(
  SUPPORTED_CRYPTOS,
  priceCurrency => {
    return SUPPORTED_CRYPTOS.map(quantityCurrency => [
      quantityCurrency + priceCurrency,
      quantityCurrency,
      priceCurrency,
    ]);
  }
).reduce((acc, [symbol, priceCurrency, quantityCurrency]) => {
  if (priceCurrency !== quantityCurrency) {
    acc[symbol] = { priceCurrency, quantityCurrency };
  }
  return acc;
}, {});

export const quantityCurrency = (tradingPair: SupportedCurrencyPairs): CurrencyShortName =>
  allPairs[tradingPair]?.quantityCurrency;

export const priceCurrency = (tradingPair: SupportedCurrencyPairs): CurrencyShortName =>
  allPairs[tradingPair]?.priceCurrency;

export const getPriceCurrencyDetail = (tradingPair: SupportedCurrencyPairs): CurrencyDetail =>
  CURRENCIES_DETAIL[priceCurrency(tradingPair)];

export const getQuantityCurrencyDetail = (tradingPair: SupportedCurrencyPairs): CurrencyDetail =>
  CURRENCIES_DETAIL[quantityCurrency(tradingPair)];

/**
 * Used to avoid logging exceptions when doing market minimum lookups on these delisted pairs.
 */
const unavailablePairs = [...unsupportedERC20Pairs, ...unsupportedSPLPairs, ...suspendedPairs] as const;

const logMissingPairToSentryOnce = (tradingPair: SupportedCurrencyPairs) => {
  // logging to sentry if MarketMinimums is missing a pair
  // only do it once by checking for presence of key. Prevents duplicate POST when currency list refreshes.
  if (!window.currencyData.missingData[tradingPair] && !unavailablePairs.includes(tradingPair)) {
    // see QueueMarketMinimumsKeeper.scala
    Sentry.captureMessage(
      `MarketMinimums not defined for pair=${tradingPair}. Pair not found in currencyData.tradingPairs`,
      "warning"
    );
    window.currencyData.missingData[tradingPair] = `${tradingPair} not found in lookup`;
  }
};

// Returns tradingPair price precision if available, otherwise failing back to currency detail decimals.
export const getDecimalsForPrice = (tradingPair: SupportedCurrencyPairs) => {
  if (!window.currencyData.tradingPairs[tradingPair]) {
    logMissingPairToSentryOnce(tradingPair);
    return getPriceCurrencyDetail(tradingPair).decimals;
  }
  return window.currencyData.tradingPairs[tradingPair]?.priceTickDecimalPlaces;
};

export const getDecimalsForQuantity = (tradingPair: SupportedCurrencyPairs) => {
  if (!window.currencyData.tradingPairs[tradingPair]) {
    logMissingPairToSentryOnce(tradingPair);
    return getQuantityCurrencyDetail(tradingPair).decimals;
  }
  return window.currencyData.tradingPairs[tradingPair].quantityTickDecimalPlaces;
};

export const getDecimalsForCurrency = (currency: CurrencyShortName) => CURRENCIES_DETAIL[currency].decimals;

export const getMaxDecimalsForCurrency = (currency: CurrencyShortName) =>
  CURRENCIES_DETAIL[currency] ? CURRENCIES_DETAIL[currency].maxDecimals : 8;

export const shouldOrderByRankOrAlpha = (asset: CurrencyShortName) => {
  return CURRENCIES_DETAIL[asset].isNotional
    ? asset // sort fiats alphabetically
    : CURRENCIES_DETAIL[asset].rankOrder; // sort cryptos by rankOrder
};

export const orderCurrencies = <T extends CurrencyShortName | CurrencyShortNameLower>(
  assets: T[],
  defaultFiat?: CurrencyShortNameFiat
): T[] => {
  return orderBy(
    assets,
    [
      asset => Boolean(defaultFiat) && currencyToUpper(asset) === currencyToUpper(defaultFiat), // defaultFiat first
      asset => CURRENCIES_DETAIL[currencyToUpper(asset)].isNotional, // other fiats next
      asset => shouldOrderByRankOrAlpha(currencyToUpper(asset)),
    ],
    ["desc", "desc", "asc"]
  );
};

export function bigNumberToDecimalString(
  value: BigNumber,
  currency: CurrencyShortName,
  showExponential = false,
  roundingMode: BigNumber.RoundingMode | "none" = BigNumber.ROUND_DOWN
): string {
  if (value.isNaN()) return "";
  const maxDecimals = getMaxDecimalsForCurrency(currency);
  return value
    .decimalPlaces(maxDecimals, roundingMode === "none" ? undefined : roundingMode)
    .toString(showExponential ? undefined : 10);
}

/**
 * Given a currency short name, returns the associated network.
 *
 * Works with lower and upper-cased currency strings.
 *
 * @example
 *
 * getNetworkFromCurrency('BCH') // returns "Bitcoin Cash"
 * getNetworkFromCurrency('ltc') // returns "Litecoin"
 * getNetworkFromCurrency('MATIC') // returns "ERC-20"
 * getNetworkFromCurrency('GUSD') // returns "Ethereum"
 */
export const getNetworkFromCurrency = (currency: CurrencyShortName | CurrencyShortNameLower | undefined) => {
  if (!currency) return;
  const currencyUpper = currencyToUpper(currency);
  if (isErc20Token(currencyUpper)) return "ERC-20";

  return CHAIN_NETWORKS[CURRENCIES_DETAIL[currencyUpper].network]?.displayName;
};

export const getNetworkNameFromCurrency = (currency: CurrencyShortName | CurrencyShortNameLower | undefined) => {
  if (!currency) return;
  const currencyUpper = currencyToUpper(currency);
  return CHAIN_NETWORKS[CURRENCIES_DETAIL[currencyUpper].network]?.name;
};

export const getNetworkTokenSymbolFromCurrency = (currency: CurrencyShortName | CurrencyShortNameLower | undefined) => {
  if (!currency) return;
  const currencyUpper = currencyToUpper(currency);
  return CHAIN_NETWORKS[CURRENCIES_DETAIL[currencyUpper].network]?.nativeCurrency;
};

const WRAPPED_CURRENCY_TO_GILP_CURRENCY_MAP = {
  EFIL: "FIL",
};

/*
  Helper function to get symbol from currencyPair.
  Handles GilpAndWrapPair e.g. EFILUSD which doesn't have pair precision data
*/
export const getCurrencyPairSymbol = (currencyPair: {
  quantityCurrency: string;
  priceCurrency: string;
  symbol: string;
}) => {
  const { priceCurrency, quantityCurrency, symbol } = currencyPair;
  if (WRAPPED_CURRENCY_TO_GILP_CURRENCY_MAP[quantityCurrency]) {
    return `${WRAPPED_CURRENCY_TO_GILP_CURRENCY_MAP[quantityCurrency]}${priceCurrency}`;
  }
  return symbol;
};
