import { useEffect, useState } from "react";
import { rewardApi } from "@gemini-ui/client/api";
import { Currency } from "@gemini-ui/client/credit";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { MarketFilterTypes, PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import { useRetailMarketFilters } from "@gemini-ui/services/retail/marketFilters";
import { useAllPairDetails } from "@gemini-ui/services/trading/getAllPairDetails";
import { getError } from "@gemini-ui/utils/error";

interface RewardDetails {
  rewards: PairDetail[];
  isLoading: boolean;
}

export const useRewards = (): RewardDetails => {
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [rewards, setRewards] = useState<PairDetail[] | null>();
  const { allPairDetails, isLoadingPairDetails } = useAllPairDetails("1m", Currency.Usd);
  const { marketFilters, isLoadingMarketFilters } = useRetailMarketFilters(Currency.Usd);

  useEffect(() => {
    const getRewardsData = async () => {
      try {
        const { data: rewardCurrencyData } = await rewardApi.getSupportedRewardCurrencies();
        const eligibleRewards = allPairDetails.filter(pair => {
          const currency = pair?.to as Currency;
          return (rewardCurrencyData?.currencies || []).includes(pair.symbol === "GUSDUSD" ? Currency.Gusd : currency);
        });

        const allFilterAssets = marketFilters.find(
          filter => filter?.type === MarketFilterTypes.AllMarketFilter
        )?.assets;
        const sortedEligibleRewards = allFilterAssets
          ?.map(asset => eligibleRewards.find(reward => (reward?.to ?? Currency.Gusd) === asset))
          .filter(Boolean);
        setRewards(sortedEligibleRewards);
      } catch (error) {
        showAlert({ type: AlertTypes.ERROR, message: getError(error) });
      } finally {
        setIsLoading(false);
      }
    };

    if (!rewards && !isLoadingPairDetails && !isLoadingMarketFilters) {
      getRewardsData();
    }
  }, [allPairDetails, marketFilters, showAlert, rewards, isLoadingPairDetails, isLoadingMarketFilters]);

  return { rewards, isLoading };
};
