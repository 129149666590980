export type BankAccountTransferLevel = "Approved" | "Pending" | "NoTransfers";

export enum BankAccountType {
  "Checking" = "Checking",
  "Savings" = "Savings",
  "Brokerage" = "Brokerage",
}

export const CardApprovalState = {
  "3DSRequired": "3DSRequired",
  "3DSApproved": "3DSApproved",
  Approved: "Approved",
  MicroDepositApproved: "MicroDepositApproved",
  Pending: "Pending",
  Rejected: "Rejected",
  BouncerApproved: "BouncerApproved",
  StripeApproved: "StripeApproved",
};

export enum PaymentMethodType {
  BANK = "Bank",
  DEBIT_CARD = "DebitCard",
  XFERS = "Xfers",
  BCOLO = "Bcolo",
  PAYPAL = "PayPal",
  CBIT = "Cbit",
  RTP = "Rtp",
}

export const NonWithdrawablePaymentMethods = [PaymentMethodType.DEBIT_CARD, PaymentMethodType.PAYPAL];
export const NonLimitOrderPaymentMethods = [PaymentMethodType.DEBIT_CARD, PaymentMethodType.PAYPAL];
