import { Flex, PageLayout, Text, VerticalNavigation } from "@gemini-ui/design-system";
import SimpleFooter from "@gemini-ui/pages/register/OnboardingLayout/SimpleFooter";
import { OnboardingProps } from "@gemini-ui/pages/register/OnboardingLayout/types";
import { getOnboardingVerticalNavigationSteps } from "@gemini-ui/pages/register/OnboardingLayout/utils";
import { useIntl } from "@gemini-ui/utils/intl";

export const OnboardingPageLayout = ({
  title,
  customTitle,
  subTitle,
  children,
  ["data-testid"]: testID,
  activeOnboardingState,
  segmentType,
  didUploadDocument,
}: OnboardingProps) => {
  const { intl } = useIntl();

  const navigationItems = getOnboardingVerticalNavigationSteps(
    intl,
    activeOnboardingState,
    segmentType,
    didUploadDocument
  );

  const activeLabel = navigationItems.find(item => item.isActive)?.label;
  return (
    <PageLayout>
      <PageLayout.SecondaryNav label={activeLabel}>
        <VerticalNavigation items={navigationItems} />
      </PageLayout.SecondaryNav>
      <PageLayout.Form>
        <Flex.Column data-testid={testID}>
          {customTitle || (
            <Text.Heading size="lg" data-testid="title" mb={Boolean(subTitle) ? 1.5 : 3}>
              {title}
            </Text.Heading>
          )}
          {subTitle && (
            <Text.Body size="sm" mb={3} mt={1} data-testid="subtitle">
              {subTitle}
            </Text.Body>
          )}
          {children}
          <SimpleFooter />
        </Flex.Column>
      </PageLayout.Form>
    </PageLayout>
  );
};

export default OnboardingPageLayout;
