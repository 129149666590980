import { Fragment, SyntheticEvent } from "react";
import { optimizelyClient, track } from "@gemini-ui/analytics";
import { AnalyticsEventName, AnalyticsProperty, EVENTS } from "@gemini-ui/analytics/constants/events";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import {
  getOtherTransferMenuData,
  getTransferMenuData,
  HEADER_MENU,
} from "@gemini-ui/components/Header/navigation/data/geminiMenuData";
import { getTransferActionType } from "@gemini-ui/components/Header/VerificationPendingModal/utils";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import { TransferIds } from "@gemini-ui/components/Transfer/navigation/transferItems";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal, VerificationPendingProps } from "@gemini-ui/contexts/GlobalModal";
import { Badge, Flex, HubbleMenu } from "@gemini-ui/design-system";
import { getIsUserInOnboarding } from "@gemini-ui/services/user/lockout";
import { handleCashDepositClick } from "@gemini-ui/utils/handleCashDepositClick";
import { handleCashWithdrawClick } from "@gemini-ui/utils/handleCashWithdrawClick";
import { handleCryptoDepositInitiation } from "@gemini-ui/utils/handleCryptoDepositInitiation";
import { handleCryptoWithdrawInitiation } from "@gemini-ui/utils/handleCryptoWithdrawInitiation";
import { useIsVerificationPendingFromBasicPlus } from "@gemini-ui/utils/hooks/useIsVerificationPendingFromBasicPlus";
import { useShouldPromptBasicPlusUpgrade } from "@gemini-ui/utils/hooks/useShouldPromptBasicPlusUpgrade";
import { useIntl } from "@gemini-ui/utils/intl";

interface TransferMenuProps {
  moreThanOneAccount: boolean;
  lockout?: LOCKOUT_TYPES;
  isDerivativesAccount: boolean;
}

export const TransferMenu = ({ moreThanOneAccount, lockout, isDerivativesAccount }: TransferMenuProps) => {
  const { intl } = useIntl();
  const {
    pageName,
    templateProps: {
      user: { subaccounts },
    },
  } = usePageData();

  const isVerificationPendingFromBasicPlus = useIsVerificationPendingFromBasicPlus();
  const shouldPromptBasicPlusUpgrade = useShouldPromptBasicPlusUpgrade();

  const { toggleModal } = useGlobalModal();
  const transferMenuData = getTransferMenuData(intl);
  let otherTransferMenuData = getOtherTransferMenuData(moreThanOneAccount, intl);
  const isPerpsTransferEligible = subaccounts.some(account => account.derivatives);
  const isDerivativeDebitCardFundingEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING
  );

  otherTransferMenuData = otherTransferMenuData.filter(item => {
    if (item.id === "derivative-debit-card-funding") {
      return isDerivativeDebitCardFundingEnabled && isDerivativesAccount;
    }
    return true;
  });

  const handleClick = (
    e: SyntheticEvent<HTMLDivElement>,
    id: TransferIds,
    href: string,
    analyticsEventName: AnalyticsEventName,
    analyticsProperties: AnalyticsProperty
  ) => {
    const isFullyVerifiedOtherwiseShowModal = () => {
      if (isVerificationPendingFromBasicPlus) {
        toggleModal(GlobalModalType.VerificationPendingFromBasicPlusModal, {
          actionType: getTransferActionType(id),
        });
        return false;
      } else if (shouldPromptBasicPlusUpgrade) {
        toggleModal(GlobalModalType.UpgradeFromBasicPlusModal, {
          actionType: getTransferActionType(id),
        });
        return false;
      }
      return true;
    };

    if (getIsUserInOnboarding(lockout)) {
      toggleModal<VerificationPendingProps>(GlobalModalType.VerificationPending, {
        actionType: getTransferActionType(id),
      });
    } else {
      if (id === "deposit-cash") {
        handleCashDepositClick(e, () => toggleModal(GlobalModalType.CashDepositModal), HEADER_MENU);
      } else if (id === "transfer-between" && isPerpsTransferEligible) {
        track(EVENTS.PERPS_TRANSFER_START.name, {
          [EVENTS.PERPS_TRANSFER_START.properties.INITIATED_FROM]: pageName,
        });
        toggleModal(GlobalModalType.PerpsOnboardingTransferFormModal);
      } else if (
        id === "derivative-debit-card-funding" &&
        isDerivativeDebitCardFundingEnabled &&
        isDerivativesAccount
      ) {
        track(EVENTS.DERIVATIVE_DEBIT_CARD_FUNDING_START.name, {
          [EVENTS.DERIVATIVE_DEBIT_CARD_FUNDING_START.properties.INITIATED_FROM]: pageName,
        });
        toggleModal(GlobalModalType.DerivativeDebitCardFundingModal);
      } else if (id === "withdraw-cash") {
        handleCashWithdrawClick(e, () => toggleModal(GlobalModalType.CashWithdrawModal), HEADER_MENU, href);
      } else if (id === "deposit-crypto") {
        handleCryptoDepositInitiation(
          e,
          () => toggleModal(GlobalModalType.CryptoDepositModal, { preSelectedCrypto: undefined }),
          analyticsEventName,
          analyticsProperties
        );
      } else if (id === "withdraw-crypto") {
        if (isFullyVerifiedOtherwiseShowModal()) {
          handleCryptoWithdrawInitiation(
            e,
            () => toggleModal(GlobalModalType.WithdrawCryptoModal),
            analyticsEventName,
            analyticsProperties
          );
        }
      } else {
        mixpanelClient.trackLinkClickAndRedirectManually(href, analyticsEventName, analyticsProperties);
      }
    }
  };

  const modifiedTransferMenuData = transferMenuData.map(item => {
    if (isDerivativesAccount && isDerivativeDebitCardFundingEnabled) {
      if (
        item.id === "deposit-cash" ||
        item.id === "withdraw-cash" ||
        item.id === "deposit-crypto" ||
        item.id === "withdraw-crypto"
      ) {
        return { ...item, disabled: true, badge: intl.formatMessage({ defaultMessage: "Unavailable in Perps" }) };
      }
    }
    return item;
  });

  return (
    <Fragment>
      <HubbleMenu.Title>
        {intl.formatMessage({
          defaultMessage: "Transfer",
        })}
      </HubbleMenu.Title>
      <HubbleMenu.Separator />
      <HubbleMenu.Group>
        {modifiedTransferMenuData.map(
          ({ url, name, id, analyticsProperties, analyticsEventName, icon: Icon, disabled, badge }) => (
            <Flex width="100%" alignItems="center" justifyContent="space-between" key={id}>
              <HubbleMenu.Item
                id={id}
                leftElement={<Icon />}
                label={name}
                onSelect={e => handleClick(e, id, url, analyticsEventName, analyticsProperties)}
                disabled={disabled}
                style={{ flexGrow: 1 }}
              />
              {badge && (
                <Badge style={{ height: "fit-content" }} mr={1} size="xs" status="neutral">
                  {badge}
                </Badge>
              )}
            </Flex>
          )
        )}
        {otherTransferMenuData.length > 0 &&
          otherTransferMenuData.map(({ url, id, analyticsProperties, analyticsEventName, name, icon: Icon }) => (
            <HubbleMenu.Item
              key={id}
              id={id}
              leftElement={<Icon />}
              label={name}
              onSelect={e => handleClick(e, id, url, analyticsEventName, analyticsProperties)}
            />
          ))}
      </HubbleMenu.Group>
    </Fragment>
  );
};
