import { Fragment } from "react";
import { format } from "date-fns";
import { Text } from "@gemini-ui/design-system";
import { GENERIC_CARDHOLDER_AGREEMENT } from "@gemini-ui/pages/Credit/utils";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export const getNewUserFormCopy = (intl: IntlShape) => ({
  FIRST_NAME: intl.formatMessage({ defaultMessage: "Legal first name" }),
  LAST_NAME: intl.formatMessage({ defaultMessage: "Legal last name" }),
  MIDDLE_NAME: intl.formatMessage({ defaultMessage: "Legal middle name (optional)" }),
  PHONE_NUMBER: intl.formatMessage({ defaultMessage: "Mobile phone number" }),
  SOCIAL_SECURITY_NUMBER: intl.formatMessage({ defaultMessage: "Social security number" }),
  ENTER_SSN_LAST_FOUR: intl.formatMessage({ defaultMessage: "Enter the last 4 digits of your SSN" }),
  EMAIL_ADDRESS: intl.formatMessage({ defaultMessage: "Email address" }),
  PASSWORD: intl.formatMessage({ defaultMessage: "Password" }),
  REVIEW_TERMS: intl.formatMessage({ defaultMessage: "Please review the following terms." }),
  AGREE_TO_TERMS: intl.formatMessage({
    defaultMessage: "By checking the box: You agree to these Gemini Constellation and WebBank terms and conditions:",
  }),
  ELECTRONIC_DELIVERY_CONSENT_STATEMENT: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "{consentStatementLink} to receive documents from us electronically. You also confirm that you have access to a computer or other device that satisfies all of the hardware and software system requirements stated within, and that you can access documents in a PDF format. If you are unsure and would like to test your ability to access PDF documents, click {testPdfLink}.",
    }),
    {
      consentStatementLink: (
        <Text.Link href="https://www.gemini.com/legal/esign-agreement" target="_blank">
          Electronic Delivery Consent Authorization
        </Text.Link>
      ),
      testPdfLink: (
        <Text.Link href="https://www.gemini.com/documents/credit/Test_PDF.pdf" target="_blank">
          here
        </Text.Link>
      ),
    }
  ),
  AUTOMATED_AUTHORIZATION: intl.formatMessage(defineMessage({ defaultMessage: "{link}" }), {
    link: (
      <Text.Link href="https://www.gemini.com/legal/autodialer-authorization" target="_blank">
        Automated Call/Text Authorization
      </Text.Link>
    ),
  }),
  AGREE_TO_GEMINI_ACCOUNT: intl.formatMessage({
    defaultMessage:
      "By checking the above box you also agree to create a Gemini exchange account and agree to the following terms and conditions:",
  }),
  USER_AGREEMENT: intl.formatMessage(defineMessage({ defaultMessage: "{link}" }), {
    link: (
      <Text.Link href="https://www.gemini.com/legal/user-agreement" target="_blank">
        User Agreement
      </Text.Link>
    ),
  }),
  PRIVACY_POLICY: intl.formatMessage(defineMessage({ defaultMessage: "{link}" }), {
    link: (
      <Text.Link href="https://www.gemini.com/legal/privacy-policy" target="_blank">
        Gemini Privacy Policy
      </Text.Link>
    ),
  }),
  PRIVACY_NOTICE: intl.formatMessage(defineMessage({ defaultMessage: "{link}" }), {
    link: (
      <Text.Link href="https://www.gemini.com/legal/consumer-privacy-notice" target="_blank">
        Notice
      </Text.Link>
    ),
  }),
  WEB_BANK_PRIVACY_NOTICE: intl.formatMessage(defineMessage({ defaultMessage: "{link}" }), {
    link: (
      <Text.Link href="https://www.gemini.com/legal/webbank-consumer-privacy-notice" target="_blank">
        WebBank Privacy Notice
      </Text.Link>
    ),
  }),
  CONTINUE: intl.formatMessage({ defaultMessage: "Continue" }),
  PLACEHOLDERS: {
    FIRST_NAME: intl.formatMessage({ defaultMessage: "William" }),
    LAST_NAME: intl.formatMessage({ defaultMessage: "Smith" }),
    EMAIL: intl.formatMessage({ defaultMessage: "satoshi@gemini.com" }),
  },
});

export const getConfirmCardCopy = (intl: IntlShape) => ({
  TITLE: intl.formatMessage({ defaultMessage: "Confirm your card." }),
  SUB_TITLE: intl.formatMessage({
    defaultMessage: "Choose from black, silver, or rose gold metal cards and an option to add a preferred first name.",
  }),
  ALT_SUB_TITLE: intl.formatMessage({ defaultMessage: "Choose from black, silver, or rose gold metal cards." }),
  LEGAL_FIRST_NAME: intl.formatMessage({ defaultMessage: "Legal first name" }),
  LEGAL_LAST_NAME: intl.formatMessage({ defaultMessage: "Legal last name" }),
  CREATE_PREFERRED_NAME: intl.formatMessage({ defaultMessage: "Create a preferred first name" }),
  PREFERRED_FIRST_NAME: intl.formatMessage({ defaultMessage: "Preferred first name" }),
  ORDER_CARD: intl.formatMessage({ defaultMessage: "Order card" }),
  TOOLTIP: {
    INFO: intl.formatMessage({
      defaultMessage:
        "Your Preferred Name will be printed on your physical card while your legal First Name is collected and used for security and compliance purposes and for your Gemini Exchange account. Gemini reserves the right to deny a Preferred Name request to safeguard against the use of inappropriate names (e.g. obscenities and copyright infringements).",
    }),
    LEARN_MORE: intl.formatMessage({
      defaultMessage: "To learn more about Mastercard’s True Name initiative, please refer to our FAQs",
    }),
  },
});

export const getAnnualIncomeCopy = (intl: IntlShape) => ({
  TITLE: intl.formatMessage({ defaultMessage: "What’s your annual income?" }),
  SUB_TITLE: intl.formatMessage({
    defaultMessage: "Include all income available to you. If under age 21, include only your own salary or wages.",
  }),
  TOOLTIP: intl.formatMessage({
    defaultMessage:
      "Income includes wages, retirement income, investments, rental properties, etc. Alimony, child support, or separate maintenance need not be revealed if you do not wish to rely upon it.",
  }),
  INPUT_LABEL: intl.formatMessage({ defaultMessage: "Annual income" }),
  INPUT_PLACEHOLDER: intl.formatMessage({ defaultMessage: "100,000" }),
  INPUT_ERROR: intl.formatMessage({ defaultMessage: "Max: $99,999,999" }),
  CONTINUE: intl.formatMessage({ defaultMessage: "Continue" }),
});

export const getPricingTermsCopy = (intl: IntlShape) => ({
  TITLE: intl.formatMessage({ defaultMessage: "Pricing and terms" }),
  SUB_TITLE: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "Important Disclosures: Rates, fees, and other important information about the Gemini Mastercard Credit Card (“Card”) and associated credit card account (“Account”) are set forth in these Important Disclosures. The customized version of these Important Disclosures (the “Customized Disclosures”) that we provide you prior to your first use of the Card or the Account are part of the Gemini Mastercard Credit Card Cardholder Agreement (“Agreement”). {effectiveDate}",
    }),
    {
      effectiveDate: (
        <Fragment>
          <br />
          Effective as of {format(new Date(), DateFormats.MonthDayYear)}
        </Fragment>
      ),
    }
  ),
  YOU_UNDERSTAND: intl.formatMessage({
    defaultMessage:
      " You understand that by pressing the “Agree and apply” button immediately following this notice, you certify and agree that:",
  }),
  YOUR_USE: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "Your use of the account is subject to the {link}. You also agree to pay all charges incurred under such terms.",
    }),
    {
      link: (
        <Text.Link href={GENERIC_CARDHOLDER_AGREEMENT} target="_blank">
          Cardholder Agreement
        </Text.Link>
      ),
    }
  ),
  YOUR_PARTICIPATION: intl.formatMessage(
    defineMessage({
      defaultMessage: "Your participation in the Gemini Credit Card Crypto Rewards Program is subject to its {link}.",
    }),
    {
      link: (
        <Text.Link href="https://www.gemini.com/legal/credit-card-rewards-agreement" target="_blank">
          Rewards Program Terms
        </Text.Link>
      ),
    }
  ),
  REWARDS_PROGRAM_TERMINATION: intl.formatMessage({
    defaultMessage:
      "The Rewards Program may be terminated at any time for any reason without advance notice, and will terminate automatically without notice in the event the Gemini exchange permanently ceases to operate.",
  }),
  RESULT_IN_CREDIT_CHECK: intl.formatMessage({
    defaultMessage:
      "This also will result in a formal credit check which can impact your credit score. Upon approval, please continue the application to finalize your credit card account creation.",
  }),
  BUTTON_REVIEW: intl.formatMessage({ defaultMessage: "Review" }),
  BUTTON_APPLY: intl.formatMessage({ defaultMessage: "Agree and apply" }),
});

export const getHardPullApprovedCopy = (intl: IntlShape, email: string) => ({
  TITLE: intl.formatMessage({ defaultMessage: "You’re approved!" }),
  SUB_TITLE: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "Congrats on qualifying for the Gemini Credit Card. Your full terms and conditions and Cardholder Agreement will be sent to {email}.",
    }),
    {
      email,
    }
  ),
  CREDIT_LIMIT: intl.formatMessage({ defaultMessage: "Credit limit" }),
  TEMP_CREDIT_LIMIT: intl.formatMessage({ defaultMessage: "Temporary credit limit" }),
  APR: intl.formatMessage({ defaultMessage: "APR" }),
  CONTINUE: intl.formatMessage({ defaultMessage: "Continue" }),
  TOOLTIP: intl.formatMessage({
    defaultMessage:
      "While you are able to make purchases with your digital card before receiving your physical card, a temporary 30% credit limit is in place until you make your first in-person physical card transaction, at which time you’ll have access to your full credit line. Your available credit reflects the temporary limit.",
  }),
});
