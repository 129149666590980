import * as Sentry from "@sentry/browser";
import { GoogleTagManagerEvents, SUPER_PROPERTIES, UserEvent } from "@gemini-ui/analytics/constants/events";
import { PEOPLE_PROPERTIES } from "@gemini-ui/analytics/constants/trade";
import {
  incrementPeopleProperty,
  incrementSuperProperty,
  setPeopleProperty,
  setPeoplePropertyOnce,
  trackGTMEvent,
} from "@gemini-ui/analytics/index";
import axios from "@gemini-ui/services/axios";

export function calculateNotionalTotal(quantity: number, lastTradePrice: string) {
  if (!quantity || !lastTradePrice) {
    return 0;
  }

  const total = Number(quantity) * Number(lastTradePrice);
  return total.toFixed(2);
}

export function setTradePeopleProperties() {
  const now = new Date().toISOString();
  sendFirstTradeEvent();
  setPeoplePropertyOnce({ [PEOPLE_PROPERTIES.FIRST_TRADE_DATE]: now });
  setPeopleProperty({ [PEOPLE_PROPERTIES.LAST_TRADE_DATE]: now });
  incrementPeopleProperty(PEOPLE_PROPERTIES.NUMBER_OF_TRADES_PLACED);
  incrementSuperProperty(SUPER_PROPERTIES.NUMBER_OF_TRADES_PREVIOUSLY_PLACED);
}

export const sendFirstTradeEvent = async () => {
  // the user-event api returns a 200 if a matching eventName is present, otherwise it returns a 404
  // if there's a 404, we go into the catch block, track a first time trade, then update the first trade cache event
  try {
    await axios.get(`user-event?eventName=${UserEvent.FIRST_TRADE}`);
  } catch (error) {
    trackGTMEvent(GoogleTagManagerEvents.FIRST_TRADE);
    try {
      await axios.post("user-event", { eventName: UserEvent.FIRST_TRADE });
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};
