// eslint-disable-next-line no-restricted-imports
import { AxiosError, AxiosInstance } from "axios";
import { ErrorStatus, getErrorCode } from "@gemini-ui/services/axios/errorCodes";

export function loginRedirectInterceptor(
  this: AxiosInstance,
  error: AxiosError<{ error?: string; redirect?: string }>
) {
  const response = error.response;
  error.code = getErrorCode(error);

  if (response?.data?.error === "needsLogin") {
    // Redirect users who have been logged out
    // Note: return so the code doesn't fall through to the reject call
    // Otherwise it will throw an unhandled error before directing
    window.location.assign(response.data.redirect);
    return Promise.reject(error);
  }

  const status = response?.status;
  if (status === ErrorStatus.TEMP_REDIRECT || status === ErrorStatus.PERM_REDIRECT) {
    // retry originalRequest at newLocation
    const newLocation = response?.headers?.location;
    const originalRequest = error.config;
    if (location) {
      return this[originalRequest.method](newLocation, originalRequest);
    }
  }

  return Promise.reject(error);
}
