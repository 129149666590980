import styled from "@emotion/styled";
import { Spacing } from "@gemini-ui/design-system";
import { ReactComponent as LoadingCircle } from "@gemini-ui/images/icons/load_circle.svg";

export const TableNav = styled("div")`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;

export const ButtonContainer = styled("div")`
  display: flex;

  > [type="button"] {
    margin-left: auto;
  }

  > [type="button"] + [type="button"] {
    margin-left: 0;
  }
`;

export const EmptyState = styled("div")`
  padding: 13px 15px;
`;

export const LoadingContainer = styled("div")`
  height: 100%;
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingIcon = styled(LoadingCircle)`
  margin-right: ${Spacing.scale["1"]};
  margin-left: ${Spacing.scale[0.25]};
  animation: spin 600ms linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
