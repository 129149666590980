import { Fragment } from "react";
import { useMedia } from "react-use";
import { SIGNED_OUT_EVENTS } from "@gemini-ui/analytics/constants/events";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { GeminiLogoHeader, HeaderFlexContainer } from "@gemini-ui/components/Header/SignedOutHeader/styles";
import Mark from "@gemini-ui/components/Mark";
import { Button, Flex, mediaQuery } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

function GeminiLogo() {
  const { buildGeminiPublicLink, intl } = useIntl();

  return (
    <GeminiLogoHeader
      href={buildGeminiPublicLink()}
      onClick={e => {
        e.preventDefault();
        mixpanelClient.trackLinkClickAndRedirectManually(buildGeminiPublicLink(), SIGNED_OUT_EVENTS.HEADER_CLICK.name, {
          [SIGNED_OUT_EVENTS.HEADER_CLICK.properties.TRIGGER]: SIGNED_OUT_EVENTS.HEADER_CLICK.callsites.GEMINI_LOGO,
        });
      }}
      title={intl.formatMessage({ defaultMessage: "Gemini Home" })}
    >
      <Mark size={24} />
    </GeminiLogoHeader>
  );
}

export function SignedOutHeader({
  centerLogo,
  showAccountButtons,
  showBackToGeminiLink,
}: {
  centerLogo: boolean;
  showAccountButtons: boolean;
  showBackToGeminiLink: boolean;
}) {
  const { buildGeminiPublicLink, intl } = useIntl();
  const isTabletAndUp = useMedia(mediaQuery.tabletSmUp);

  return (
    <HeaderFlexContainer
      width="100%"
      justify={centerLogo ? "center" : "space-between"}
      data-testid="signed-out-header-wrapper"
    >
      {centerLogo ? (
        <GeminiLogo />
      ) : (
        <Fragment>
          <Flex align="center">
            <GeminiLogo />
            {showBackToGeminiLink && isTabletAndUp && (
              <Button.Tertiary
                ml={1}
                size="sm"
                href={
                  document.referrer.includes("https://www.gemini.com") ? document.referrer : buildGeminiPublicLink()
                }
                onClick={e => {
                  e.preventDefault();
                  const url = document.referrer.includes("https://www.gemini.com")
                    ? document.referrer
                    : buildGeminiPublicLink();
                  mixpanelClient.trackLinkClickAndRedirectManually(url, SIGNED_OUT_EVENTS.HEADER_CLICK.name, {
                    [SIGNED_OUT_EVENTS.HEADER_CLICK.properties.TRIGGER]:
                      SIGNED_OUT_EVENTS.HEADER_CLICK.callsites.BACK_LINK,
                  });
                }}
              >
                {intl.formatMessage({ defaultMessage: "Back to Gemini.com" })}
              </Button.Tertiary>
            )}
          </Flex>
          {showAccountButtons && (
            <Flex ml={2} align="center">
              <div id="active-trader-layout-button"></div>
              <Button.Primary
                display="inline-flex"
                ml={2}
                size="sm"
                href="/register"
                onClick={e => {
                  e.preventDefault();
                  const HREF = "/register";
                  mixpanelClient.trackLinkClickAndRedirectManually(HREF, SIGNED_OUT_EVENTS.HEADER_CLICK.name, {
                    [SIGNED_OUT_EVENTS.HEADER_CLICK.properties.TRIGGER]:
                      SIGNED_OUT_EVENTS.HEADER_CLICK.callsites.REGISTER_BUTTON,
                  });
                }}
              >
                {intl.formatMessage({ defaultMessage: "Create account" })}
              </Button.Primary>
              <Button.Secondary
                display="inline-flex"
                ml={2}
                size="sm"
                href="/signin"
                onClick={e => {
                  e.preventDefault();
                  const HREF = "/signin";
                  mixpanelClient.trackLinkClickAndRedirectManually(HREF, SIGNED_OUT_EVENTS.HEADER_CLICK.name, {
                    [SIGNED_OUT_EVENTS.HEADER_CLICK.properties.TRIGGER]:
                      SIGNED_OUT_EVENTS.HEADER_CLICK.callsites.SIGN_IN_BUTTON,
                  });
                }}
              >
                {intl.formatMessage({ defaultMessage: "Sign in" })}
              </Button.Secondary>
            </Flex>
          )}
        </Fragment>
      )}
    </HeaderFlexContainer>
  );
}
