import { Mixpanel } from "mixpanel-browser";
import uuidv4 from "uuid/v4";
import { mockLog } from "@gemini-ui/analytics/mockLog";

const mockTrack = eventType => (eventName, eventAttrs) => {
  mockLog(eventType, eventName, eventAttrs);
};
const mockProperty = propertyType => eventAttrs => {
  mockLog(propertyType, "", eventAttrs);
};

export const mixpanelMock: Mixpanel = {
  alias: id => null,
  clear_opt_in_out_tracking: () => null,
  disable: () => null,
  get_config: () => null,
  get_distinct_id: () => uuidv4(),
  get_property: mockProperty("Super Property"),
  has_opted_in_tracking: () => null,
  has_opted_out_tracking: () => null,
  identify: id => null,
  init: (token, config) => null,
  opt_in_tracking: () => null,
  opt_out_tracking: () => null,
  people: {
    append: mockProperty("People Property (Append)"),
    clear_charges: mockProperty("People Property (Clear Charges)"),
    delete_user: () => {},
    increment: mockProperty("People Property (Increment)"),
    set_once: mockProperty("People Property (Set Once)"),
    set: mockProperty("People Property"),
    track_charge: mockProperty("People Property (Track Charge)"),
    union: mockProperty("People Property (Union)"),
    unset: mockProperty("People Property (Unset)"),
  },
  push: () => null,
  register_once: () => null,
  register: mockProperty("Super Property"),
  reset: () => null,
  set_config: () => null,
  time_event: () => null,
  track_forms: () => null,
  track_links: mockTrack("Event"),
  track: mockTrack("Event"),
  unregister: () => null,
};
