import { CurrencyDetail, CurrencyShortName } from "@gemini-common/scripts/constants/currencies";

import { CurrenciesFixtures } from "../__fixtures__/currencyData";
import { TradingPair } from "../constants/tradingPair";

type ChainNetwork = {
  name: string;
  nativeCurrency: CurrencyShortName;
  displayName: string;
};

export type ServerCurrencyData = {
  currencies: Array<
    [
      CurrencyDetail["symbol"], // 0
      CurrencyDetail["name"], // 1
      CurrencyDetail["rankOrder"], // 2
      CurrencyDetail["decimals"], // 3
      CurrencyDetail["minDecimals"], // 4
      CurrencyDetail["maxDecimals"], // 5
      CurrencyDetail["receiptDecimals"], // 6
      CurrencyDetail["isNotional"], // 7
      CurrencyDetail["leadingSymbol"] | null, // 8
      CurrencyDetail["network"] | null // 9
    ]
  >;
  tradingPairs: Array<
    [
      TradingPair["pair"], // 0
      TradingPair["priceTickDecimalPlaces"], // 1
      TradingPair["quantityTickDecimalPlaces"], // 2
      TradingPair["quantityMinimum"], // 3
      TradingPair["quantityRoundDecimalPlaces"], // 4
      TradingPair["minimumsAreInclusive"] // 5
    ]
  >;
  networks: Array<
    [
      ChainNetwork["name"], // 0
      ChainNetwork["nativeCurrency"], // 1
      ChainNetwork["displayName"] // 2
    ]
  >;
};

export type TransformedCurrencyData = {
  currencies: Record<CurrencyShortName, CurrencyDetail>;
  tradingPairs: Record<TradingPair["pair"], TradingPair>;
  networks: Record<ChainNetwork["name"], ChainNetwork>;
  missingData: any; // object to hold currency and tradingPair keys to avoid duplicate outgoing Sentry logs
  FIAT_CURRENCIES: CurrenciesFixtures["CurrencyShortNameFiat"][];
  ERC20_CURRENCIES: CurrenciesFixtures["CurrencyShortNameERC20"][];
  SPL_CURRENCIES: CurrenciesFixtures["CurrencyShortNameSolana"][];
  CRYPTO_CURRENCIES: CurrenciesFixtures["CurrencyShortNameCrypto"][];
  PERPETUAL_PAIRS: CurrenciesFixtures["CurrencyShortNamePerpetual"][];
};

const transformCurrencyData = (currencyData: ServerCurrencyData): TransformedCurrencyData => {
  const currencies = {} as TransformedCurrencyData["currencies"];
  const tradingPairs = {} as TransformedCurrencyData["tradingPairs"];
  const networks = {} as TransformedCurrencyData["networks"];
  const FIAT_CURRENCIES = [];
  const ERC20_CURRENCIES = [];
  const SPL_CURRENCIES = [];
  const CRYPTO_CURRENCIES = [];
  const PERPETUAL_PAIRS = [];

  if (!currencyData) {
    try {
      // @ts-ignore - responseStatus is not defined in PerformanceEntry
      if (window.performance?.getEntries?.().find(e => e.entryType === "navigation")?.responseStatus === 500)
        console.error("500 Error: Ensure the services required to render this page controller are healthy.");
    } catch (e) {
      console.error(e);
    }

    return {
      currencies,
      tradingPairs,
      networks,
      missingData: {},
      ERC20_CURRENCIES,
      SPL_CURRENCIES,
      FIAT_CURRENCIES,
      CRYPTO_CURRENCIES,
      PERPETUAL_PAIRS,
    };
  }

  for (const currency of currencyData.currencies) {
    // NOTE: Picking from array index. This order must match the JsCurrencyData.scala Tuple.
    const [
      symbol,
      name,
      rankOrder,
      decimals,
      minDecimals,
      maxDecimals,
      receiptDecimals,
      isNotional,
      leadingSymbol,
      network,
    ] = currency;

    currencies[symbol] = {
      symbol,
      name,
      rankOrder,
      decimals,
      minDecimals,
      maxDecimals,
      receiptDecimals,
      isNotional,
      leadingSymbol,
      network,
    };

    // @MultiCurrencyAware
    if (isNotional) {
      FIAT_CURRENCIES.push(symbol);
    } else if (network === "ethereum" && symbol !== "ETH" && symbol !== "GUSD") {
      ERC20_CURRENCIES.push(symbol);
    } else if (network === "solana" && symbol !== "SOL") {
      SPL_CURRENCIES.push(symbol);
    } else {
      CRYPTO_CURRENCIES.push(symbol);
    }
  }

  for (const tradingPair of currencyData.tradingPairs) {
    // NOTE: Picking from array index. This order must match the JsCurrencyData.scala Tuple.
    const [
      pair,
      priceTickDecimalPlaces,
      quantityTickDecimalPlaces,
      quantityMinimum,
      quantityRoundDecimalPlaces,
      minimumsAreInclusive,
    ] = tradingPair;

    tradingPairs[pair] = {
      pair,
      priceTickDecimalPlaces,
      quantityTickDecimalPlaces,
      quantityMinimum,
      quantityRoundDecimalPlaces,
      minimumsAreInclusive,
    };

    // identify and add wrapped assets into the map
    if (pair.startsWith("FIL")) {
      const wrappedPair = `E${pair}`;
      tradingPairs[wrappedPair] = {
        pair: wrappedPair,
        priceTickDecimalPlaces,
        quantityTickDecimalPlaces,
        quantityMinimum,
        quantityRoundDecimalPlaces,
        minimumsAreInclusive,
      };
    }

    if (pair.slice(-4) === "PERP") {
      PERPETUAL_PAIRS.push(pair);
    }
  }

  for (const network of currencyData.networks) {
    // NOTE: Picking from array index. This order must match the JsCurrencyData.scala Tuple.
    const [name, nativeCurrency, displayName] = network;

    networks[name] = {
      name,
      nativeCurrency,
      displayName,
    };
  }

  return {
    currencies,
    tradingPairs,
    networks,
    missingData: {},
    ERC20_CURRENCIES,
    SPL_CURRENCIES,
    FIAT_CURRENCIES,
    CRYPTO_CURRENCIES,
    PERPETUAL_PAIRS,
  };
};

export const setupTransformedCurrencyData = () => {
  const currencyData = JSON.parse(document.getElementById("currencyData").textContent);
  // read in currencies.ts
  window.currencyData = transformCurrencyData(currencyData);
};
