import Clarity from "@microsoft/clarity";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import ReactModal from "react-modal";
import {
  initializeAnalytics,
  initializeGoogleAnalytics,
  initializeGTM,
  initializeSnapchat,
  optimizelyClient,
} from "@gemini-ui/analytics";
import ActivityTimeoutHandler from "@gemini-ui/components/ActivityTimeoutHandler";
import App from "@gemini-ui/components/App";
import AutoRefresh from "@gemini-ui/components/AutoRefresh";
import { ExperimentListener } from "@gemini-ui/components/ExperimentListener";
import axios from "@gemini-ui/services/axios";
import { getIsLoggedInCcpaEnabled, isAnalyticsSuppressed } from "@gemini-ui/utils/ccpa";
import {
  getCcpaConfigForLoggedOut,
  getCookieSettings,
  saveCookieSettings,
  saveGTMConsentKey,
} from "@gemini-ui/utils/cookie";
import { LANG_COOKIE_NAME } from "@gemini-ui/utils/intl";
import storage from "@gemini-ui/utils/storage";

const doBrowserSetup = function () {
  require("focus-visible");
  require("vanilla-autofill-event");
};

function domReady(fn) {
  // If we're early to the party
  document.addEventListener("DOMContentLoaded", fn);
  // If late; I mean on time.
  if (document.readyState === "interactive" || document.readyState === "complete") {
    fn();
  }
}

const setupAnalytics = () => {
  const { pageName, templateProps } = window.initialData;

  if (templateProps.user) {
    initializeAnalytics(pageName, templateProps.user);
  }

  if (window.Cypress) {
    window.analyticsStubs = { optimizelyClient };
  }
};

const initializeThirdPartyTracking = async () => {
  try {
    let isSuppressed = getCookieSettings().isSuppressed || false;
    if (!isSuppressed) {
      const route =
        jsRoutes.com.gemini.web.server.onboarding.controllers.services.MarketingLocationController.checkLocation().url;
      const response = await axios.get(route);
      isSuppressed = isAnalyticsSuppressed(response.data?.state);
    }
    // downstream components can leverage cookie rather than continuing to hit the api

    const isLoggedInCcpaEnabled = await getIsLoggedInCcpaEnabled(window, jsRoutes);

    const isCcpaEnabled = isLoggedInCcpaEnabled || getCcpaConfigForLoggedOut();
    saveCookieSettings({ ...getCookieSettings(), isSuppressed, isCcpaEnabled });
    saveGTMConsentKey(!isCcpaEnabled && getCookieSettings().allowAnalytics);
    initializeGTM();

    if (!isCcpaEnabled && getCookieSettings().allowAnalytics && !isSuppressed) {
      const mixpanelId = _.get(window.initialData, "templateProps.user.mixpanelId");
      initializeGoogleAnalytics();

      try {
        Clarity.init("k85y0ypapn");
        // can be called before window.clarity is ready, ignore any failures
        Clarity.identify(mixpanelId);
      } catch (e) {}

      if (!window.location.pathname.startsWith("/credit-card/")) {
        // scripts are loaded via GTM but we seem to be passing in email here, leave for now
        initializeSnapchat(initialData.templateProps.user?.email);
      }
    }
  } catch (error) {
    Sentry.captureMessage(`Error occurred while initializing third party tracking scripts -- ${error.message}`, "info");
  }
};

/* istanbul ignore next */
const openDevNotesPopup = function () {
  if (!document.referrer) {
    // only bug you if you just arrive on the site
    const left = window.screenX + window.innerWidth;
    const top = window.screenY;
    const popupParams = `left=${left},top=${top},height=500,width=500`;
    // should be blocked by popup blockers, but can be opened manually from the "blocked popup" alert in FF or Chrome if you want
    return window.open(
      jsRoutes.com.gemini.web.server.experience.controllers.DevNotesController.devNotes().url,
      "DevNotes",
      popupParams
    );
  }
};

let renderError: string | null = null;

const oops = () => {
  domReady(() => {
    const removeElements: Element[] = [];
    for (const element of document.body.children) {
      if (element.id !== "renderError") {
        removeElements.push(element);
      }
    }
    // needs to be done in a separate loop since children is "live"
    removeElements.forEach(rElement => {
      document.body.removeChild(rElement);
    });
    document.getElementById("renderError").style.display = "block";
  });
};

const initializeChartingBundle = () => {
  require("@gemini-ui/charting-bundle");
};

const doRender = function (pageModule: any) {
  if (renderError != null) {
    throw renderError;
  }
  try {
    const initialData = window.initialData;
    const subaccountHashid = _.get(initialData, "templateProps.user.subaccountHashid");

    if (window.Cypress && ["SignIn", "Register"].includes(initialData.pageName)) {
      initialData.pageProps.siteKeyV2 = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
    }
    // initialize language for the app on each page load
    const userLocale =
      _.get(initialData, "templateProps.user.lang") ?? Cookies.get(LANG_COOKIE_NAME) ?? navigator.language;

    if (subaccountHashid) {
      storage.set("subaccountHashid", subaccountHashid);
    }
    let ElementType = pageModule;
    for (const accessor of initialData.jsPath) {
      ElementType = ElementType[accessor];
    }

    const toRender = (
      <App userLocale={userLocale}>
        <AutoRefresh ElementType={ElementType} initialData={initialData} />
        <ExperimentListener />
        {initialData.templateProps.user != null && <ActivityTimeoutHandler />}
      </App>
    );

    const container = document.getElementById("container");
    ReactModal.setAppElement(container);

    const root = createRoot(container);
    root.render(toRender); // should be a no-op if we already got rendered HTML from the server

    if (ElementType.displayName) {
      document.body.classList.add(ElementType.displayName);
    }

    if (["dev", "test"].includes(initialData.mode) && !initialData.hideDevNotes && !window.Cypress) {
      openDevNotesPopup();
    }
  } catch (error) {
    renderError = error;
    oops();
    throw renderError;
  }
};

// Do all the dirty business necessary to get React up and running. This is called by an auto-added Webpack loader (renderer-loader.js)
const render = function (pageModule: any) {
  if (typeof window !== "undefined" && window !== null) {
    try {
      initializeThirdPartyTracking();
      setupAnalytics();
      doBrowserSetup();
      if (window.location.pathname.includes("/trade/") && !window.Cypress) {
        initializeChartingBundle();
      }
      domReady(() => doRender(pageModule));
    } catch (renderError) {
      oops();
    }
  }
};

export { initializeThirdPartyTracking, oops, render };
