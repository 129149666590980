// eslint-disable-next-line no-restricted-imports
import axios, { AxiosError } from "axios";

export enum ErrorCode {
  SERVER_ERROR = "SERVER_ERROR",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
  NETWORK_ERROR = "NETWORK_ERROR",
  REQUEST_ERROR = "REQUEST_ERROR",
  CANCEL_ERROR = "CANCEL_ERROR",
}

export enum ErrorStatus {
  TEMP_REDIRECT = 307,
  PERM_REDIRECT = 308,
}

export const getErrorCode = (error: AxiosError): ErrorCode => {
  if (error.response) {
    return ErrorCode.SERVER_ERROR;
  } else if (error.request) {
    if (error.code === "ECONNABORTED") {
      return ErrorCode.TIMEOUT_ERROR;
    } else {
      return ErrorCode.NETWORK_ERROR;
    }
  } else if (axios.isCancel(error)) {
    return ErrorCode.CANCEL_ERROR;
  } else {
    return ErrorCode.REQUEST_ERROR;
  }
};
