import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { Name } from "@gemini-ui/client/credit";
import { CardColorEnum, CardColors } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/constants";

export type CardContextValue = {
  cardColor: CardColorEnum;
  setCardColor: Dispatch<SetStateAction<CardColorEnum>>;
  cardName: Name;
  setCardName: Dispatch<SetStateAction<Name>>;
};

const defaultValue: CardContextValue = {
  cardColor: CardColors.Black,
  setCardColor: () => {},
  cardName: { firstName: "", lastName: "", preferredFirstName: "", middleName: "" },
  setCardName: () => {},
};

export const CardContext = createContext<CardContextValue>(defaultValue);
export const useCardContext = () => useContext(CardContext);

export const CardContextProvider = ({ name, children }: { name: Name; children: ReactNode }) => {
  const [cardColor, setCardColor] = useState<CardColorEnum>(defaultValue.cardColor);
  const [cardName, setCardName] = useState<Name>(name);

  const context: CardContextValue = {
    cardColor,
    setCardColor,
    cardName,
    setCardName,
  };

  return <CardContext.Provider value={context}>{children}</CardContext.Provider>;
};
