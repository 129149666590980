import { ComponentType, PropsWithChildren } from "react";
import LazyModalFooterButtonGroup from "@gemini-ui/components/LazyLoading/LazyModalFooterButtonGroup";
import { LazyRetry } from "@gemini-ui/components/LazyLoading/LazyRetry";
import { GlobalModalType } from "@gemini-ui/contexts/GlobalModal";
import { Dialog, Flex, Modal, Sheet, Spacing, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { useIntl } from "@gemini-ui/utils/intl";

export const MODAL_TYPES = {
  Modal: "Modal",
  Sheet: "Sheet",
  Dialog: "Dialog",
} as const;

export type ModalType = (typeof MODAL_TYPES)[keyof typeof MODAL_TYPES];
type SheetConfig = {
  side: "left" | "right";
  disableAnimation?: boolean;
};
const defaultSheetConfigs: SheetConfig = { side: "right", disableAnimation: true };

type LazyModalPropsBase<T = object> = {
  isOpen?: boolean;
  onClose?: () => void;
  multiStep?: boolean;
  importFn?: () => Promise<{ default: ComponentType<PropsWithChildren<T>> }>;
  retry?: () => void;
  modalType?: ModalType | GlobalModalType;
  sheetConfigs?: SheetConfig;
};

type LazyModalPropsSheet = LazyModalPropsBase & {
  modalType: typeof MODAL_TYPES.Sheet;
  sheetConfigs: SheetConfig;
};

type LazyModalPropsModal = LazyModalPropsBase & {
  modalType?: typeof MODAL_TYPES.Modal;
  sheetConfigs?: never;
};

type LazyModalPropsDialog = LazyModalPropsBase & {
  modalType?: typeof MODAL_TYPES.Dialog;
  sheetConfigs?: never;
};

type LazyModalPropsGlobalModal = LazyModalPropsBase & {
  modalType?: GlobalModalType;
  sheetConfigs?: never;
};

type LazyModalProps = LazyModalPropsSheet | LazyModalPropsModal | LazyModalPropsDialog | LazyModalPropsGlobalModal;

export const FailedLazyLoadModal = ({
  onClose,
  isOpen,
  multiStep,
  retry,
  modalType,
  sheetConfigs,
}: Omit<LazyModalProps, "importFn">) => {
  const { intl } = useIntl();
  const errorTitle = intl.formatMessage({
    defaultMessage: "Oops, something went wrong",
  });

  if (modalType === MODAL_TYPES.Sheet) {
    return (
      <Sheet onClose={onClose} open={isOpen} disableAnimation={sheetConfigs.disableAnimation}>
        <Sheet.Header>
          <Sheet.Title>{errorTitle}</Sheet.Title>
        </Sheet.Header>
        <Sheet.Container side={sheetConfigs.side}>
          <Sheet.Content>
            <Text.Body size="sm" mt={3} mb={3}>
              {intl.formatMessage({
                defaultMessage: "Please retry. If this error persists, try refreshing the page.",
              })}
            </Text.Body>
          </Sheet.Content>
          <Sheet.Footer>
            <LazyModalFooterButtonGroup retry={retry} />
          </Sheet.Footer>
        </Sheet.Container>
      </Sheet>
    );
  }
  return (
    <Modal multiStep={multiStep} onClose={onClose} isOpen={isOpen} title={errorTitle}>
      <Text.Body size="sm" mt={3} mb={3}>
        {intl.formatMessage({
          defaultMessage: "Please retry. If this error persists, try refreshing the page.",
        })}
      </Text.Body>
      <LazyModalFooterButtonGroup retry={retry} />
    </Modal>
  );
};

export const LazyModalWrapper = <T extends object>(importFn: LazyModalPropsBase<T>["importFn"]) =>
  LazyRetry<T & Omit<LazyModalProps, "importFn" | "retry">>({
    importFn,
    LoaderView: ({ isOpen, onClose, multiStep, modalType, sheetConfigs = defaultSheetConfigs }) => {
      if (modalType === MODAL_TYPES.Sheet)
        return (
          <Sheet open={isOpen} onClose={onClose} disableAnimation={sheetConfigs.disableAnimation}>
            <Sheet.Container side={sheetConfigs.side}>
              <Sheet.Content>
                <Flex flexDirection="column" align="center" mb={3} height="500px" justifyContent="center">
                  <SpinnerAnimation size={Spacing.scale[4]} pt={3} pb={3} />
                </Flex>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        );

      if (modalType === MODAL_TYPES.Dialog)
        return (
          <Dialog open={isOpen} onClose={onClose}>
            <Dialog.Container>
              <Dialog.Content>
                <Flex flexDirection="column" align="center" mb={3} height="400px" justifyContent="center">
                  <SpinnerAnimation size={Spacing.scale[4]} pt={3} pb={3} />
                </Flex>
              </Dialog.Content>
            </Dialog.Container>
          </Dialog>
        );

      return <Modal isOpen={isOpen} onClose={onClose} multiStep={multiStep} loading />;
    },
    ErrorRetryView: ({ retry, isOpen, onClose, multiStep, modalType, sheetConfigs = defaultSheetConfigs }) => (
      <FailedLazyLoadModal
        isOpen={isOpen}
        onClose={onClose}
        multiStep={multiStep}
        retry={retry}
        modalType={modalType}
        sheetConfigs={sheetConfigs}
      />
    ),
  });
