import { Fragment } from "react";
import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import _ from "lodash";
import { countDecimals } from "@gemini-common/scripts/Money/utils";
import { Money } from "@gemini-ui/components/Money";
import { RenderArgs, RenderArgsWithIntl } from "@gemini-ui/components/Table/Renderers/TransferHistoryRow/constants";
import { getDateRange, TransferUtils } from "@gemini-ui/components/TransactionTable/utils";
import { AnnotationMetadataTag, TransferType } from "@gemini-ui/constants/custody";
import { Button, Text } from "@gemini-ui/design-system";
import { LimitTextLength } from "@gemini-ui/design-system/utils/LimitTextLength";
import {
  CryptoDepositStatus,
  CryptoWithdrawalStatus,
  DepositTransferRequestStatus,
  WithdrawalTransferRequestStatus,
} from "@gemini-ui/services/transfer/types";
import { transferErrorFormatter, transferTypeFormatter } from "@gemini-ui/utils/balanceHistory";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

function getDisplayAmount(data: TransferType, full?: boolean) {
  const amountCandidates = [data?.amount, data?.value?.amount, data?.amountWithFee?.amount].filter(Boolean);
  const chosenAmount = amountCandidates[0];
  if (!chosenAmount) {
    Sentry.captureMessage(`Unknown amount ${data}`, "error");
    return null;
  }
  const decimalsOverride = full ? countDecimals(chosenAmount.value) : undefined;
  return <Money {...chosenAmount} decimalsOverride={decimalsOverride} />;
}

function getFee(data: TransferType) {
  const hasFee = data?.fee && Number(data.fee.value) > 0;
  const hasFeeInAmountWithFee = data?.amountWithFee?.fee && Number(data.amountWithFee.fee.value) > 0;
  if (hasFee) return <Money {...data.fee} />;
  if (hasFeeInAmountWithFee) return <Money {...data.amountWithFee.fee} />;
  return <Fragment>&ndash;</Fragment>;
}

export function toOrFrom(data: TransferType) {
  const maybeAddress = _.isObject(data.destination) ? data.destination.wrapped : data.destination;
  return data.providerType || maybeAddress || data.source || data.merchantName;
}

function getStatusText(
  data: TransferType & { isTransactionHistoryRevampEnabled?: boolean },
  intl: IntlShape,
  onConfirmDeposit?: RenderArgsWithIntl["onConfirmDeposit"],
  onlyText?: boolean
) {
  // Attestation checks
  if (data?.isAwaitingAttestation || data?.isAwaitingUkAttestation || data?.isAwaitingEuAttestation) {
    const text = intl.formatMessage({ defaultMessage: "Confirm deposit" });
    if (onlyText) return text;
    if (!onConfirmDeposit) {
      return <Text.Link href={`/transfer/deposit/${data.amount.currency.toLowerCase()}`}>{text}</Text.Link>;
    }
    return (
      <Button.Secondary size="sm" onClick={() => onConfirmDeposit(data)}>
        {text}
      </Button.Secondary>
    );
  }

  // Attestation required
  if (data.currentStatus === "AttestationRequired") {
    return intl.formatMessage({ defaultMessage: "Attestation required" });
  }

  // Earn deposit or redeem checks
  if (TransferUtils.isEarnDeposit(data) && data.accrueOn) {
    return data.accrueOn < Date.now()
      ? intl.formatMessage({ defaultMessage: "Complete" })
      : intl.formatMessage({ defaultMessage: "Pending" });
  }
  if (TransferUtils.isEarnRedeem(data) && !data.redeemedInFull) {
    return intl.formatMessage({ defaultMessage: "Pending" });
  }

  // Old transaction history
  // Custody withdrawal, wire withdrawals, ach withdrawals etc.
  // (Kept logic as is but structured in switch statements below)

  // Non-revamp logic
  if (!data.isTransactionHistoryRevampEnabled) {
    if (data.transferInfo === "PendingExecution") {
      return data.transferError?.length
        ? transferErrorFormatter(intl)
        : intl.formatMessage({ defaultMessage: "Pending Execution" });
    }

    if (data.transferInfo === "WireWithdrawal") {
      switch (data.currentStatus) {
        case "Unsubmitted":
          return intl.formatMessage({ defaultMessage: "Initiated" });
        case "Pending":
          return intl.formatMessage({ defaultMessage: "Pending" });
        case "CancelRequested":
        case "Canceled":
        case "Failed":
          return intl.formatMessage({ defaultMessage: "Canceled" });
        case "Succeeded":
          return intl.formatMessage({ defaultMessage: "Complete" });
        default:
          return data.currentStatus;
      }
    }

    if (data.transferInfo === "AchWithdrawal") {
      return data.currentStatus;
    }

    if (data.currentStatus && ["AchDeposit", "XfersWithdrawal"].includes(data.transferInfo)) {
      switch (data.currentStatus) {
        case "Unsubmitted":
          return intl.formatMessage({ defaultMessage: "Initiated" });
        case "Pending":
        case "Deposited":
        case "HeldForReview":
          return intl.formatMessage({ defaultMessage: "Pending" });
        case "CancelRequested":
        case "Canceled":
        case "Failed":
        case "Recalled":
          return intl.formatMessage({ defaultMessage: "Canceled" });
        case "Succeeded":
          return intl.formatMessage({ defaultMessage: "Complete" });
        default:
          return data.currentStatus;
      }
    }
  }

  // Revamp logic (wire, ach, rtp, etc.)
  if (data?.transferInfo === "WireWithdrawal") {
    switch (data?.currentStatus) {
      case "Unsubmitted":
      case "Pending":
        return intl.formatMessage({ defaultMessage: "Processing" });
      case "Canceled":
        return intl.formatMessage({ defaultMessage: "Canceled" });
      case "Failed":
        return intl.formatMessage({ defaultMessage: "Failed" });
      case "Succeeded":
        return intl.formatMessage({ defaultMessage: "Complete" });
      case "Returned":
        return intl.formatMessage({ defaultMessage: "Returned" });
      default:
        return data?.currentStatus;
    }
  }

  if (data?.transferInfo === "AchWithdrawal") {
    switch (data?.currentStatus) {
      case "Unsubmitted":
      case "Pending":
        return intl.formatMessage({ defaultMessage: "Processing" });
      case "Canceled":
        return intl.formatMessage({ defaultMessage: "Canceled" });
      case "Failed":
        return intl.formatMessage({ defaultMessage: "Failed" });
      case "Deposited":
        return intl.formatMessage({ defaultMessage: "Complete" });
      case "Returned":
        return intl.formatMessage({ defaultMessage: "Returned" });
      default:
        return data?.currentStatus;
    }
  }

  if (
    data?.currentStatus &&
    ["AchDeposit", "XfersWithdrawal", "RtpDeposit", "RtpWithdrawal"].includes(data?.transferInfo)
  ) {
    switch (data?.currentStatus) {
      case "Unsubmitted":
      case "Pending":
      case "Deposited":
        return intl.formatMessage({ defaultMessage: "Processing" });
      case "Failed":
        return intl.formatMessage({ defaultMessage: "Failed" });
      case "Recalled":
      case "Canceled":
        return intl.formatMessage({ defaultMessage: "Canceled" });
      case "Returned":
        return intl.formatMessage({ defaultMessage: "Returned" });
      case "Succeeded":
        return intl.formatMessage({ defaultMessage: "Complete" });
      default:
        return data?.currentStatus;
    }
  }

  if (data?.transferInfo === "CryptoDepositTransferRequest") {
    switch (data?.status as CryptoDepositStatus) {
      case DepositTransferRequestStatus.COMPLETE:
        return intl.formatMessage({ defaultMessage: "Complete" });
      case DepositTransferRequestStatus.PENDING:
      default:
        return intl.formatMessage({ defaultMessage: "Pending" });
    }
  }

  if (data?.transferInfo === "CryptoWithdrawalTransferRequest") {
    switch (data?.status as CryptoWithdrawalStatus) {
      case WithdrawalTransferRequestStatus.COMPLETE:
        return intl.formatMessage({ defaultMessage: "Complete" });
      case WithdrawalTransferRequestStatus.PENDING:
      default:
        return intl.formatMessage({ defaultMessage: "Pending execution" });
    }
  }

  // Default
  return data.complete == null
    ? intl.formatMessage({ defaultMessage: "Complete" })
    : data.precredit
    ? intl.formatMessage({ defaultMessage: "Pre-Credited" })
    : intl.formatMessage({ defaultMessage: "Complete" });
}

function getSpecificationText(data: TransferType, intl: IntlShape) {
  const stakingDeposit =
    data?.annotations?.annotationMetadataType === "staking" &&
    data?.annotations?.annotationMetadata?.tags.includes(AnnotationMetadataTag.Deposit);
  const stakingWithdrawal =
    data?.annotations?.annotationMetadataType === "staking" &&
    data?.annotations?.annotationMetadata?.tags.includes(AnnotationMetadataTag.Withdrawal);
  const stakingReward =
    data?.annotations?.annotationMetadataType === "staking" &&
    data?.annotations?.annotationMetadata?.tags.includes(AnnotationMetadataTag.Reward);

  return [
    stakingDeposit && intl.formatMessage(defineMessage({ defaultMessage: "Staking Deposit" })),
    stakingWithdrawal && intl.formatMessage(defineMessage({ defaultMessage: "Staking Withdrawal" })),
    stakingReward && intl.formatMessage(defineMessage({ defaultMessage: "Staking Reward" })),
  ]
    .filter(Boolean)
    .join(", ");
}

export const renderers = {
  date: ({ data }: RenderArgs) => {
    return TransferUtils.isEarnInterest(data)
      ? getDateRange(data.minDateAccrualForMonth, data.maxDateAccrualForMonth)
      : format(data.timestamp, DateFormats.MonthDayYearTime);
  },
  amount: ({ data }: RenderArgs) => getDisplayAmount(data),
  fullAmount: ({ data }: RenderArgs) => getDisplayAmount(data, true),
  fee: ({ data }: RenderArgs) => getFee(data),
  type: ({ data, intl, lowercase, noFormat }: RenderArgsWithIntl) => {
    const type = transferTypeFormatter(data, intl);
    if (type.length > 18 && !noFormat) {
      return (
        <LimitTextLength text={type} addParentheses={false} finalTextLength={18} hasTooltip lowercase={lowercase} />
      );
    }
    return type;
  },
  specification: ({ data, intl }: RenderArgsWithIntl) => getSpecificationText(data, intl),
  toFrom: ({ data, maxLength = 8 }: RenderArgs & { maxLength?: number }) => {
    if (data.bankName) return data.bankName;
    const destOrSource = toOrFrom(data);
    if (!destOrSource) return null;
    return <LimitTextLength text={destOrSource} addParentheses={false} finalTextLength={maxLength} hasTooltip />;
  },
  status: ({
    data,
    intl,
    onConfirmDeposit,
    onlyText,
    isTransactionHistoryRevampEnabled,
  }: RenderArgsWithIntl & { isTransactionHistoryRevampEnabled?: boolean }) =>
    getStatusText({ ...data, isTransactionHistoryRevampEnabled }, intl, onConfirmDeposit, onlyText),
};
