import { IconCheck } from "@hubble/icons";
import { Tooltip } from "@gemini-ui/design-system";
import { StyledSwatch } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/components/CardColorSwatch/styles";
import { CardColorEnum, cardColorText } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/constants";

interface CardColorSwatchProps {
  color: CardColorEnum;
  isChecked: boolean;
  onChange: () => void;
}

export const CardColorSwatch = ({ color, isChecked, onChange }: CardColorSwatchProps) => {
  return (
    <Tooltip data-testid={`card-color-tooltip-${color}`} overlay={cardColorText[color]} key={color}>
      <label>
        <input
          checked={isChecked}
          value={color}
          type="radio"
          name="card-select"
          data-testid={`card-color-selection-${color}`}
          onChange={onChange}
        />
        <StyledSwatch color={color}>{isChecked && <IconCheck size="sm" color="white" />}</StyledSwatch>
      </label>
    </Tooltip>
  );
};
