import { Fragment } from "react";
import { FormatDistanceToken, formatDistanceToNowStrict } from "date-fns";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";

const getFormatDistanceLocale = (intl: IntlShape, { count }) => ({
  lessThanXSeconds: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}s",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received less than X seconds ago, e.g. 3s",
    }),
    { count }
  ),
  xSeconds: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}s",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received X seconds ago, e.g. 25s",
    }),
    { count }
  ),
  halfAMinute: intl.formatMessage(
    defineMessage({
      defaultMessage: "30s",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received half a minute ago, i.e. 30s",
    }),
    { count }
  ),
  lessThanXMinutes: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}m",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received less than X minutes ago, e.g. 3m",
    }),
    { count }
  ),
  xMinutes: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}m",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received X minutes ago, e.g. 5m",
    }),
    { count }
  ),
  aboutXHours: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}h",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received about X hours ago, e.g. 2h",
    }),
    { count }
  ),
  xHours: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}h",
      description: "Distance between the given dates in words abbreviated, i.e. data was received X hours ago, e.g. 8h",
    }),
    { count }
  ),
  xDays: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}d",
      description: "Distance between the given dates in words abbreviated, i.e. data was received X days ago, e.g. 5d",
    }),
    { count }
  ),
  aboutXWeeks: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}w",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received about X weeks ago, e.g. 2w",
    }),
    { count }
  ),
  xWeeks: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}w",
      description: "Distance between the given dates in words abbreviated, i.e. data was received X weeks ago, e.g. 3w",
    }),
    { count }
  ),
  aboutXMonths: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}m",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received about X months ago, e.g. 4m",
    }),
    { count }
  ),
  xMonths: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}m",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received X months ago, e.g. 5m",
    }),
    { count }
  ),
  aboutXYears: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}y",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received about X years ago e.g. 2y",
    }),
    { count }
  ),
  xYears: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}y",
      description: "Distance between the given dates in words abbreviated, i.e. data was received X years ago e.g. 5y",
    }),
    { count }
  ),
  overXYears: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}y",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received over X years ago e.g. 7y",
    }),
    { count }
  ),
  almostXYears: intl.formatMessage(
    defineMessage({
      defaultMessage: "{count}y",
      description:
        "Distance between the given dates in words abbreviated, i.e. data was received almost X years ago e.g. 10y",
    }),
    { count }
  ),
});

interface TimeDistanceAbbreviatedProps {
  time: string;
}

/**
 * Custom date-fns util component, leveraged with formatDistanceToNowStrict to get the
 * abbreviated format of time elapsed since a given timestamp, e.g. 5s, 3m, 7h, 1d
 */
export function TimeDistanceAbbreviated({ time }: TimeDistanceAbbreviatedProps) {
  const { intl } = useIntl();

  function formatDistanceAbbreviated(token: FormatDistanceToken, count: number) {
    return getFormatDistanceLocale(intl, { count })[token];
  }

  return (
    <Fragment>
      {formatDistanceToNowStrict(new Date(time), {
        locale: { formatDistance: formatDistanceAbbreviated },
      })}
    </Fragment>
  );
}
