import { CurrencyShortName, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { ACCOUNT_NUMBER, BIC, NAME_ON_ACCOUNT } from "@gemini-ui/constants/wireFunding";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";

export type SupportedBank = {
  minLength: number;
  maxLength: number;
  bankName: string;
  bankAbbreviation: string;
  bic?: string;
};

export type SupportedBanks = SupportedBank[];

export type RegisteredRtpAccount = {
  lastUsedAt: string;
  name: string;
  uuid: string;
  rtpCurrency: CurrencyShortNameFiat;
};

export enum RtpStep {
  INIT = "INIT",
  SUCCESS_MODAL = "SUCCESS_MODAL",
  MAX_ACCOUNTS = "MAX_ACCOUNTS",
  SHOW_DEPOSIT_INSTRUCTIONS = "SHOW_DEPOSIT_INSTRUCTIONS",
}
export interface RtpBankRegFlowProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  currency: CurrencyShortNameFiat;
  isSettingsOrOnboarding?: boolean;
  scope?: PaymentTypeScope;
  subaccountHashid?: string;
}

export interface UnifiedBankAccount {
  id: string;
  uuid: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  lastUsedAt?: string;
  exchangeAccountId: string;
  bankAccountNumber: string;
  legacyBankAccountRefId: string;
  rtpInfo?: RtpInfo;
  bic?: string;
}

interface RtpInfo {
  isApproved: boolean;
  isDepositOnly: boolean;
}

export type RtpBankRegistrationForm = {
  [NAME_ON_ACCOUNT]: string;
  bankCode: string;
  [ACCOUNT_NUMBER]: string;
  [BIC]: string;
};

export type VirtualAccountStatus = "Pending" | "Active" | "Rejected" | "Deleted" | "Unavailable";

export type RtpBeneficiary = {
  name: string;
  bankName: string;
  accountNumber: string;
};

export type RtpFundingInstructions = {
  beneficiary: RtpBeneficiary;
  currency: CurrencyShortName;
  vaStatus: VirtualAccountStatus;
};

export interface RegisterBankResponse {
  id: string;
  uuid: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  exchangeAccountId: string;
  bankAccountNumber: string;
  legacyBankAccountRefId: string;
  rtpInfo: {
    fastInfo: {
      isEnrolled: boolean;
      lastUpdated: string;
    };
    approvalState: VirtualAccountStatus;
  };
}

export const SUPPORTED_BANKS_ROUTE = "/payments/rtp/supported-banks";
export const BANK_REGISTRATION_ROUTE = "payments/rtp/register-bank";
