import { Fragment } from "react";
import { IconActiveTrader } from "@hubble/icons";
import { ItemTitle } from "@gemini-ui/components/Header/navigation/MobileMenu/components/components";
import { HubbleMenuAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/HubbleMenuAccordion";
import { StyledItemLink } from "@gemini-ui/components/Header/navigation/MobileMenu/styles";
import { useTradeUI } from "@gemini-ui/components/Header/navigation/TradingInterfaceSwitcher/utils";
import { usePageData } from "@gemini-ui/contexts";
import { Text } from "@gemini-ui/design-system";
import { isDerivativesEnabled } from "@gemini-ui/utils/derivativeAccountUtils";
import { useIntl } from "@gemini-ui/utils/intl";

export const InterfaceAccordion = ({}) => {
  const { intl } = useIntl();

  const {
    templateProps: { user },
  } = usePageData();
  const isDerivativesAccount = isDerivativesEnabled(user);

  const { isActiveTrader, switchTradeUI } = useTradeUI();

  return (
    <HubbleMenuAccordion
      leftElement={
        <ItemTitle
          icon={<IconActiveTrader size="sm" />}
          title={intl.formatMessage({ defaultMessage: "Trading Interface" })}
        />
      }
      content={
        <Fragment>
          {!isDerivativesAccount && (
            <StyledItemLink
              indent
              label=""
              active={!isActiveTrader}
              onClick={() => switchTradeUI()}
              leftElement={
                <Text.Body fontSize="12px" lineHeight="16px" bold>
                  {intl.formatMessage({ defaultMessage: "Gemini" })}
                </Text.Body>
              }
            />
          )}
          <StyledItemLink
            indent
            label=""
            active={isActiveTrader}
            onClick={() => switchTradeUI()}
            leftElement={
              <Text.Body fontSize="12px" lineHeight="16px" bold>
                {intl.formatMessage({ defaultMessage: "Gemini ActiveTrader™" })}
              </Text.Body>
            }
          />
        </Fragment>
      }
    />
  );
};
