import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { CurrencyShortNameLower } from "@gemini-common/scripts/constants/currencies";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import axios, { CACHE_ID } from "@gemini-ui/services/axios/cache";
import { getError } from "@gemini-ui/utils/error";

const balanceUrl = jsRoutes.com.gemini.web.server.trading.controllers.AccountController.webAccountBalances().url;

export const getNonZeroBalances = (data: CurrencyBalances): CurrencyBalances =>
  Object.keys(data)
    .filter(k => Number(data[k as CurrencyShortNameLower]?.total?.value) > 0)
    .reduce(
      (res, key) => ((res[key as CurrencyShortNameLower] = data[key as CurrencyShortNameLower]), res),
      {} as CurrencyBalances
    );

export const getAccountBalances = () =>
  axios
    .get<CurrencyBalances>(balanceUrl, {
      retry: 3,
      id: CACHE_ID.accountBalances,
      cache: {
        ttl: 1000 * 15, // 15 seconds
      },
    })
    .then(res => {
      if (!res.data) throw new Error("No response when getting account balances.");
      return res.data;
    });

/**
 * useAccountBalances hook for fetching account balances without grow data.
 * If grow data is needed, use balances via the usePortfolio hook.
 * @returns
 */
export const useAccountBalances = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CurrencyBalances | null>(null);

  const fetchAccountBalances = useCallback(() => {
    getAccountBalances()
      .then(data => {
        if (data) {
          setData(data);
          setError(null);
        }
        setLoading(false);
      })
      .catch(error => {
        Sentry.captureException(error);
        setLoading(false);
        setError(getError(error));
      });
  }, []);

  useEffect(() => {
    fetchAccountBalances();
  }, [fetchAccountBalances]);

  return {
    data,
    error,
    loading,
    fetchAccountBalances,
  };
};

export type UseAccountBalances = ReturnType<typeof useAccountBalances>;
