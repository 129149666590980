import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { referralsApi } from "@gemini-ui/client/api";
import { PromoParam, ReferralApplicationDetailResponse } from "@gemini-ui/client/credit";

export const useGetReferralAppDetails = () => {
  const [referralAppDetails, setReferralAppDetails] = useState<ReferralApplicationDetailResponse>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchReferralDetails = async () => {
      setIsLoading(true);
      try {
        const { data } = await referralsApi.getReferralDetailsForApplication(PromoParam.StatementSpend);
        setReferralAppDetails(data);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReferralDetails();
  }, []);

  return { referralAppDetails, isLoading };
};
