import {
  ChainNetworkShortName,
  CurrencyShortNameCrypto,
  CurrencyShortNameFiat,
} from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { WhitelistAddress } from "@gemini-ui/pages/transfers/constants";
import { usePaymentData } from "@gemini-ui/services/transfer/paymentMethods";

export type LimitStatus = {
  available: MoneyProps<CurrencyShortNameFiat>;
  total: MoneyProps<CurrencyShortNameFiat>;
};

export type Limit = {
  daily?: LimitStatus;
  monthly?: LimitStatus;
  minimum: MoneyProps<CurrencyShortNameFiat>;
  maximum?: MoneyProps<CurrencyShortNameFiat>;
};

export type DepositLimits = {
  ach: Limit;
  ppi: Limit;
  wire: Limit;
  payPal?: Limit;
  xfers?: Limit;
  bcolo?: Limit;
  card: Limit;
  rtp?: Limit;
  cbit?: Limit;
};

export type WithdrawalLimits = {
  ach: Limit;
  wire: Limit;
  xfers: Limit;
  rtp: Limit;
};

export type TransferLimits = DepositLimits & WithdrawalLimits;

export type UsePaymentData = ReturnType<typeof usePaymentData>;

export type WithdrawLimits = {
  ach: Limit;
  ppi: Limit;
  wire: Limit;
  xfers?: Limit;
  cbit?: Limit;
  rtp?: Limit;
};

// FIXME: Translate from Scala to TypeScript
type CodedAmount = unknown;

export type CryptoWithdrawControls = {
  areWhitelistsEnabled: boolean;
  travelRuleWithdrawalApprovedAddressAttestationEnabled: boolean;
  whitelistAddresses?: WhitelistAddress[];
  withdrawalLimit: {
    withdrawalLimitUSD: CodedAmount;
    withdrawableBalanceUSD: CodedAmount;
    estimatedWithdrawableBalanceNative: CodedAmount;
  };
};

export const DepositTransferRequestStatus = {
  PENDING: "Pending",
  COMPLETE: "Complete",
} as const;

export type CryptoDepositStatus = (typeof DepositTransferRequestStatus)[keyof typeof DepositTransferRequestStatus];

export interface CryptoDepositQueryParams {
  status?: CryptoDepositStatus;
}

export type CryptoDepositRequest = {
  id: string;
  currency: CurrencyShortNameCrypto;
  chainNetwork: ChainNetworkShortName;
  amount: MoneyProps;
  toAddress: {
    wrapped: string;
    $type: string;
  };
  memo?: string;
  status: CryptoDepositStatus;
  createdAt: number;
  completedAt?: number;
};

export interface CryptoWithdrawalsQueryParams {
  status?: CryptoWithdrawalStatus;
}

export type GetCryptoDepositsUrlParams = CryptoDepositQueryParams;
export type GetCryptoWithdrawalsUrlParams = CryptoWithdrawalsQueryParams;

export const WithdrawalTransferRequestStatus = {
  PENDING: "Pending",
  COMPLETE: "Complete",
} as const;

export type CryptoWithdrawalStatus =
  (typeof WithdrawalTransferRequestStatus)[keyof typeof WithdrawalTransferRequestStatus];

export type CryptoWithdrawalRequest = {
  id: string;
  currency: CurrencyShortNameCrypto;
  chainNetwork: ChainNetworkShortName;
  amount: MoneyProps;
  destination: {
    wrapped: string;
    $type: string;
  };
  memo?: string;
  status: CryptoWithdrawalStatus;
  createdAt: number;
  completedAt?: number;
};
