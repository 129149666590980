import { useState } from "react";
import { usePageRefresh } from "@gemini-ui/contexts";
import { AddPaymentMethodProcessingModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodProcessingModal";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import {
  RtpBankRegFlowProps,
  RtpStep,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { useGetRtpInstructions } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/hooks/useGetInstructions";
import { InstructionModal } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/InstructionModal";
import { MaximumAccountsModal } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/MaximumAccountsModal";
import { RtpBankRegistration } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/RtpBankRegistration";

const RtpFlow = ({
  onClose,
  onBack,
  currency,
  isOpen,
  isSettingsOrOnboarding,
  scope,
  subaccountHashid,
}: RtpBankRegFlowProps) => {
  const { requestRefresh } = usePageRefresh();
  const [step, setStep] = useState<RtpStep>(RtpStep.INIT);
  const { instructions } = useGetRtpInstructions(subaccountHashid, currency);

  const handlesAddBankSubmit = () => {
    requestRefresh();
    setStep(RtpStep.SUCCESS_MODAL);
  };

  const showDepositInstructions = () => {
    setStep(RtpStep.SHOW_DEPOSIT_INSTRUCTIONS);
  };
  if (step === RtpStep.SUCCESS_MODAL) {
    return instructions?.vaStatus === "Active" ? (
      <AddPaymentMethodSuccessModal
        isOpen={isOpen}
        onClose={onClose}
        paymentMethodType={AddPaymentMethodType.RTP}
        showDepositInstructions={showDepositInstructions}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
      />
    ) : (
      <AddPaymentMethodProcessingModal isOpen={isOpen} onClose={onClose} paymentMethodType={AddPaymentMethodType.RTP} />
    );
  }

  if (step === RtpStep.SHOW_DEPOSIT_INSTRUCTIONS) {
    return (
      <InstructionModal
        onClose={onClose}
        isOpen={isOpen}
        onBack={() => setStep(RtpStep.SUCCESS_MODAL)}
        subaccountHashid={subaccountHashid}
        currency={currency}
      />
    );
  }

  if (step === RtpStep.MAX_ACCOUNTS) return <MaximumAccountsModal onClose={onClose} currency={currency} />;

  return (
    <RtpBankRegistration onClose={onClose} onSubmit={handlesAddBankSubmit} onBack={() => onBack()} isOpen={isOpen} />
  );
};

export default RtpFlow;
