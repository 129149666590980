import * as Sentry from "@sentry/browser";
import { IntlShape } from "react-intl";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { GoogleAnalyticsEvents } from "@gemini-ui/analytics/constants/events";
import { trackGoogleAnalyticsEvent } from "@gemini-ui/analytics/googleTracking";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GeminiAccount, GeminiEntities, GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { FeatureFlags } from "@gemini-ui/constants/templateProps/featureFlags";
import {
  DepositPaymentType,
  getIntlDescriptionCopy,
  InstantTradePaymentType,
  LinkPaymentType,
  PaymentTypeScope,
  TradePaymentType,
  WithdrawablePaymentType,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { getUKUser } from "@gemini-ui/pages/settings/BankSettings/utils/getUKUser";
import { getIsGeminiSG } from "@gemini-ui/pages/transfers/utils";

type PaymentMethodFeatureFlags = Pick<FeatureFlags, "AddDebitCards" | "BcoloEnabled"> &
  Record<"GiactManualAddBankAccount", boolean> &
  Record<"PayPalEnabled", boolean> &
  Record<"WireTransferEnabled", boolean>;

export const getManualDescription = (entity: GeminiEntity, intl: IntlShape) => {
  const { DEPOSIT_AND_WITHDRAW, VERIFY_BANK_WITH_DEPOSIT, RECOMMENDED_FOR_LARGE_TRANSFERS } =
    getIntlDescriptionCopy(intl);
  switch (entity) {
    case "GeminiPayments": {
      return [DEPOSIT_AND_WITHDRAW, VERIFY_BANK_WITH_DEPOSIT];
    }
    default: {
      return [DEPOSIT_AND_WITHDRAW, VERIFY_BANK_WITH_DEPOSIT, RECOMMENDED_FOR_LARGE_TRANSFERS];
    }
  }
};

export const getPlaidDescription = (userCountryCode: CountryAbbreviation, entity: GeminiEntity, intl: IntlShape) => {
  const { DEPOSIT_AND_WITHDRAW, DEPOSIT_AND_WITHDRAW_WITH_MANUAL_TRANSFER, MAYBE_ELIGIBLE_FOR_DIRECT_TRANSFERS } =
    getIntlDescriptionCopy(intl);
  if (userCountryCode === "gb") {
    return [DEPOSIT_AND_WITHDRAW, MAYBE_ELIGIBLE_FOR_DIRECT_TRANSFERS];
  } else if (entity === "GeminiPayments") {
    return [DEPOSIT_AND_WITHDRAW_WITH_MANUAL_TRANSFER];
  } else {
    return [DEPOSIT_AND_WITHDRAW];
  }
};

export const getDebitDescription = (intl: IntlShape, showStaking: Boolean = false) => {
  const { STAKE, TRADE } = getIntlDescriptionCopy(intl);
  return [TRADE, ...(showStaking ? [STAKE] : [])];
};

export const getBankFrickDescription = (entity: GeminiEntity, intl: IntlShape) => {
  const { DEPOSIT, WITHDRAW } = getIntlDescriptionCopy(intl);
  return [DEPOSIT, WITHDRAW];
};

export const getPayPalPaymentType = (
  entity: GeminiEntity,
  currency: CurrencyShortNameFiat,
  countryCode: CountryAbbreviation
) => {
  const { USD, EUR, GBP, SGD } = CURRENCIES_DETAIL;
  if (
    ((
      [GeminiEntities.GeminiTrust, GeminiEntities.GeminiTrustEmployees, GeminiEntities.GeminiMoonbase] as GeminiEntity[]
    ).includes(entity) &&
      countryCode === "us" &&
      currency === USD.symbol) ||
    (([GeminiEntities.GeminiEurope, GeminiEntities.GeminiEuropeEmployees] as GeminiEntity[]).includes(entity) &&
      countryCode === "gb" &&
      [EUR.symbol, GBP.symbol].includes(currency)) ||
    (([GeminiEntities.GeminiPayments, GeminiEntities.GeminiIrelandEmployees] as GeminiEntity[]).includes(entity) &&
      currency === EUR.symbol) ||
    (getIsGeminiSG(entity) && currency === SGD.symbol)
  ) {
    return [LinkPaymentType.PAYPAL];
  }

  return [];
};

export const listAvailablePaymentTypes = (
  account: GeminiAccount,
  countryCode: CountryAbbreviation,
  {
    AddDebitCards,
    BcoloEnabled,
    PayPalEnabled,
    GiactManualAddBankAccount,
    WireTransferEnabled,
  }: PaymentMethodFeatureFlags,
  isPlaidSupported: boolean,
  currency: CurrencyShortNameFiat,
  isInstitutional = false,
  issuingCountryCode?: string,
  isForeignEntityACHDisabled?: boolean
): LinkPaymentType[] => {
  const isGiactEnabled = GiactManualAddBankAccount && currency === "USD" && countryCode === "us";
  const manualPaymentType = isGiactEnabled ? LinkPaymentType.GIACT : LinkPaymentType.MANUAL;
  const newPaymentMethodsEnabledForGlobal = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP_GLOBAL
  );
  const newPaymentMethodsEnabled = newPaymentMethodsEnabledForGlobal
    ? newPaymentMethodsEnabledForGlobal
    : areNewAddPaymentMethodsEnabled(currency);
  // Determine user should see Bank Frick flow based off entity + currency
  const bankFrickEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BANK_FRICK_ENABLED);

  const isUKUser = getUKUser(account.geminiEntity);
  const currencyUSD = isCurrency.USD(currency);
  const isBankFrickFlowEnabled = bankFrickEnabled && isUKUser && currencyUSD;
  const isInstitutionalDebitCardDisabled =
    optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_INSTITUTIONAL_DEBIT_CARD_DISABLED) &&
    isInstitutional;
  const isInstitutionalPaypalDisabled =
    optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_INSTITUTIONAL_PAYPAL_DISABLED) && isInstitutional;

  if (isBankFrickFlowEnabled) {
    return [LinkPaymentType.BANKFRICK];
  }

  // just showing bancolombia for Colombian Peso
  if (isCurrency.COP(currency)) {
    return BcoloEnabled ? [LinkPaymentType.BANCOLOMBIA] : [];
  }

  const getAvailablePlaidPaymentTypes = () => {
    //If country is US
    if (countryCode === "us" && currencyUSD) {
      if (isInstitutional && Boolean(issuingCountryCode) && issuingCountryCode !== "US" && isForeignEntityACHDisabled)
        return [];
      return [LinkPaymentType.PLAID_OR_GIACT];
    }
    //If country is UK and currency is GBP or EUR
    if (
      isPlaidSupported &&
      [CURRENCIES_DETAIL.GBP.symbol, CURRENCIES_DETAIL.EUR.symbol].includes(currency) &&
      (countryCode === "gb" || account.geminiEntity === GeminiEntities.GeminiPayments)
    ) {
      return [LinkPaymentType.PLAID];
    }
    return [];
  };

  const getAvailableWirePaymentTypes = () => {
    if (!(currency === CURRENCIES_DETAIL.SGD.symbol && countryCode !== "sg")) {
      return [LinkPaymentType.WIRE];
    }
    return [];
  };

  const shufflePlaidToEndForEU = (list: LinkPaymentType[]) => {
    if (account.geminiEntity !== GeminiEntities.GeminiPayments) return list;
    const plaid = list.filter(type => type === LinkPaymentType.PLAID);
    const rest = list.filter(type => type !== LinkPaymentType.PLAID);
    return [...plaid, ...rest];
  };

  return shufflePlaidToEndForEU([
    ...(newPaymentMethodsEnabled
      ? getAvailablePlaidPaymentTypes()
      : isPlaidSupported && (currency === CURRENCIES_DETAIL.USD.symbol || countryCode !== "us")
      ? [LinkPaymentType.PLAID]
      : []),
    ...(newPaymentMethodsEnabled ? [] : [manualPaymentType]),
    ...(AddDebitCards && !isInstitutionalDebitCardDisabled ? [LinkPaymentType.DEBIT] : []),
    ...(PayPalEnabled && !isInstitutionalPaypalDisabled
      ? getPayPalPaymentType(account.geminiEntity, currency, countryCode)
      : []),
    ...(newPaymentMethodsEnabled && WireTransferEnabled ? getAvailableWirePaymentTypes() : []),
  ]);
};

const getWithdrawablePaymentTypes = ({ paymentTypes }: { paymentTypes: LinkPaymentType[] }) => {
  return [
    paymentTypes.filter(type => WithdrawablePaymentType.includes(type)),
    paymentTypes.filter(type => !WithdrawablePaymentType.includes(type)),
  ];
};

const getDepositPaymentTypes = ({ paymentTypes }: { paymentTypes: LinkPaymentType[] }) => {
  return [
    paymentTypes.filter(type => DepositPaymentType.includes(type)),
    paymentTypes.filter(type => !DepositPaymentType.includes(type)),
  ];
};

const getTradePaymentTypes = ({
  paymentTypes,
  countryCode,
  scope,
}: {
  paymentTypes: LinkPaymentType[];
  countryCode: CountryAbbreviation;
  scope: PaymentTypeScope;
}) => {
  const PaymentTypeList = scope === PaymentTypeScope.INSTANT_TRADE ? InstantTradePaymentType : TradePaymentType;

  const isEligible = type =>
    PaymentTypeList.includes(type) && !(countryCode !== "us" && type === LinkPaymentType.PLAID);

  return [
    paymentTypes.filter(paymentType => isEligible(paymentType)),
    paymentTypes.filter(paymentType => !isEligible(paymentType)),
  ];
};

const getBuyAndStakePaymentTypes = ({ paymentTypes }: { paymentTypes: LinkPaymentType[] }) => {
  return [
    paymentTypes.filter(paymentType => InstantTradePaymentType.includes(paymentType)),
    paymentTypes.filter(paymentType => !InstantTradePaymentType.includes(paymentType)),
  ];
};

export const getDerivativesPaymentTypes = ({ paymentTypes }: { paymentTypes: LinkPaymentType[] }) => {
  return [
    paymentTypes.filter(type => type === LinkPaymentType.DEBIT),
    paymentTypes.filter(type => type !== LinkPaymentType.DEBIT),
  ];
};

/**
 * @description
 * Splits payment types into two arrays for the given scope: [0] available payment types, [1] ineligible payment types
 * */
export const PaymentTypeBifurcationMap: {
  [key in PaymentTypeScope]: ({
    paymentTypes,
    countryCode,
    scope,
  }: {
    paymentTypes: LinkPaymentType[];
    countryCode: CountryAbbreviation;
    scope: PaymentTypeScope;
  }) => LinkPaymentType[][];
} = {
  [PaymentTypeScope.TRADE]: getTradePaymentTypes,
  [PaymentTypeScope.INSTANT_TRADE]: getTradePaymentTypes,
  [PaymentTypeScope.DEPOSIT]: getDepositPaymentTypes,
  [PaymentTypeScope.WITHDRAW]: getWithdrawablePaymentTypes,
  [PaymentTypeScope.ALL]: ({ paymentTypes }) => [paymentTypes, []],
  [PaymentTypeScope.STAKING]: getBuyAndStakePaymentTypes,
  // in case of derivatives, all payment types are ineligible except debit card
  [PaymentTypeScope.DERIVATIVES]: getDerivativesPaymentTypes,
};

export const IneligiblePaymentMessageMap = (
  intl: IntlShape
): {
  [key in PaymentTypeScope]: string;
} => ({
  [PaymentTypeScope.TRADE]: intl.formatMessage({
    defaultMessage: "These payment methods cannot be used for limit orders.",
  }),
  [PaymentTypeScope.INSTANT_TRADE]: intl.formatMessage({
    defaultMessage: "These payment methods cannot be used for instant purchases.",
  }),
  [PaymentTypeScope.DEPOSIT]: intl.formatMessage({
    defaultMessage: "These payment methods cannot be used for deposits.",
  }),
  [PaymentTypeScope.WITHDRAW]: intl.formatMessage({
    defaultMessage: "These payment methods cannot be used for withdrawals.",
  }),
  [PaymentTypeScope.ALL]: intl.formatMessage({
    defaultMessage: "These payment methods cannot be used.",
  }),
  [PaymentTypeScope.STAKING]: intl.formatMessage({
    defaultMessage: "These payment methods cannot be used for buy and stake.",
  }),
  [PaymentTypeScope.DERIVATIVES]: intl.formatMessage({
    defaultMessage: "These payment methods cannot be used for derivatives.",
  }),
});

export const areNewAddPaymentMethodsEnabled = (currency: CurrencyShortNameFiat) => {
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);

  return isNewAddPaymentsFlowEnabled && currency === CURRENCIES_DETAIL.USD.symbol;
};

export const shouldShowCurrencyDropdown = (
  noPaymentMethodInDeposit: boolean,
  scope: PaymentTypeScope,
  advancedTradeUIEnabled: boolean,
  newPaymentMethodsEnabled: boolean
): boolean => {
  const isDerivativeDebitCardAdditionEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING
  );

  return (
    (noPaymentMethodInDeposit && scope === PaymentTypeScope.DEPOSIT) ||
    (scope === PaymentTypeScope.DERIVATIVES && isDerivativeDebitCardAdditionEnabled) ||
    (newPaymentMethodsEnabled &&
      advancedTradeUIEnabled &&
      ![PaymentTypeScope.WITHDRAW, PaymentTypeScope.TRADE, PaymentTypeScope.INSTANT_TRADE].includes(scope))
  );
};

export const trackLinkedFundingMethodSuccess = (paymentMethod: string) => {
  try {
    trackGoogleAnalyticsEvent(GoogleAnalyticsEvents.LinkedFundingSource, { method: paymentMethod });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const trackLinkFundingStarted = () => {
  try {
    trackGoogleAnalyticsEvent(GoogleAnalyticsEvents.LinkFundingStarted);
  } catch (e) {
    Sentry.captureException(e);
  }
};
