import styled from "@emotion/styled";
import { Colors, Flex, Spacer, Spacing } from "@gemini-ui/design-system";
import MasterCard from "@gemini-ui/images/icons/debit-card/debitCardMastercard.svg";
import DebitCardPlaceholder from "@gemini-ui/images/icons/debit-card/debitCardPlaceholder.svg";
import Visa from "@gemini-ui/images/icons/debit-card/debitCardVisa.svg";

export const WorldpayInput: any = styled("section")`
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${Colors.gray[200]};
  padding: 0 10px;
  margin-top: ${Spacing.scale[1]};

  &#card-pan {
    background-image: url(${DebitCardPlaceholder});
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 30px;
    padding-left: 50px;

    &.unknown,
    &.is-invalid:not(.is-empty) {
      background-image: url(${DebitCardPlaceholder});
    }

    &.visa {
      background-image: url(${Visa});
    }

    &.mastercard {
      background-image: url(${MasterCard});
    }

    &.amex + div#card-pan-msg:before,
    &.unknown + div#card-pan-msg:before {
      content: attr(data-before);
    }
  }

  &.is-onfocus {
    border: 2px solid ${Colors.gray[600]};
    border-color: black;
    padding: 0 9px;
  }

  &.is-invalid {
    border: 2px solid ${Colors.red[600]};
    padding: 0 9px;

    & + div#card-pan-msg:before {
      content: attr(data-before);
    }

    & + div#card-expiry-msg:before {
      content: attr(data-before);
    }

    & + div#card-cvv-msg:before {
      content: attr(data-before);
    }
  }
`;

export const TwoColumnInputContainer = styled.div`
  display: flex;

  & > *:first-of-type:not(:only-child) {
    margin-right: ${Spacing.scale[0.5]};
  }

  & > *:last-of-type:not(:only-child) {
    margin-left: ${Spacing.scale[0.5]};
  }
`;

export const ModalIconContainer = styled(Flex)`
  svg {
    width: ${Spacing.scale[6]};
    height: ${Spacing.scale[6]};
  }
`;

export const CKOInputContainer = styled(Spacer)`
  height: 60px;
`;
