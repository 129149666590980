export const CC_PATH = {
  APPLICATION: "/credit-card/apply",
  DASHBOARD: "/credit-card/dashboard",
  SETTINGS: "/credit-card/dashboard/settings",
  BENEFITS: "/credit-card/dashboard/benefits",
};

export const PUB_SITE_CREDIT_CARD_LINK = "https://www.gemini.com/credit-card";
export const REWARDS_TERMS_LINK = "https://www.gemini.com/legal/credit-card-rewards-agreement";
export const APPLICATION_HELP_LINK =
  "https://support.gemini.com/hc/en-us/articles/4405098390043-Gemini-Credit-Card-Application-Process";
