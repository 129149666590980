import { Fragment, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/browser";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { FailedLazyLoadModal } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import {
  CONTAINER_ID,
  CONTENTFUL_BRAND_ASSET,
  DEFAULT_LOCALE,
  DocumentUploaderProps,
  ONFIDO_CUSTOM_UI,
  ONFIDO_EVENT_LISTENER,
  ONFIDO_SDK_STATE,
  OnfidoTheme,
  SdkError,
} from "@gemini-ui/components/OnfidoDocumentUploader/constants";
import { Uploader } from "@gemini-ui/components/OnfidoDocumentUploader/styles";
import { formatPhoneNumber, trackOnfidoEvent } from "@gemini-ui/components/OnfidoDocumentUploader/utils";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Dialog, Flex } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { onfidoLocales, useIntl } from "@gemini-ui/utils/intl";

export const OnfidoDocumentUploader = ({
  onfidoSDKToken,
  onComplete,
  onError,
  handleDialogOpen,
  handleDialogClose,
  isDialogOpen,
  isLoading,
  welcome,
  documentTypes,
  poa,
  confirmation,
  phoneNumber,
  requestedLivenessVariant,
  forceCrossDevice = false,
}: DocumentUploaderProps) => {
  const enableIssuingCountrySelection = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_ONFIDO_ENABLE_ISSUING_COUNTRY_SELECTION
  );
  const onfidoInstance = useRef(null);
  const { intl, locale } = useIntl();
  const [sdkState, setSdkState] = useState(ONFIDO_SDK_STATE.initializing);
  const isDialog = Boolean(handleDialogClose);

  const handleOnError = (error: SdkError) => {
    track(EVENTS.ON_FIDO.name, {
      [EVENTS.ON_FIDO.properties.ERROR_TYPE]: error?.type,
      [EVENTS.ON_FIDO.properties.ERROR_MESSAGE]: error?.message,
    });
    Sentry.captureMessage(`OnFido document upload error ${error?.message}`, "error");
    if (onError) {
      onError(error);
    }
  };

  const getSteps = () => {
    const steps = [];

    if (welcome) {
      const welcomeStep = {
        type: "welcome",
        options: welcome,
      };
      steps.push(welcomeStep);
    }

    if (poa) {
      const poaStep = {
        type: "poa",
        options: poa,
      };
      steps.push(poaStep);
    }
    if (documentTypes) {
      // Defaults to ID uploader unless proof of address options provided.
      const docTypes = {};
      for (const [docType, docValue] of Object.entries(documentTypes)) {
        if (docValue) {
          docTypes[docType] = {};
        }
      }
      const document = {
        type: "document",
        options: {
          documentTypes: docTypes,
          forceCrossDevice,
          hideCountrySelection: !enableIssuingCountrySelection,
        },
      };
      steps.push(document);
    }
    if (requestedLivenessVariant) {
      const face = {
        type: "face",
        options: {
          requestedVariant: requestedLivenessVariant,
          forceCrossDevice,
        },
      };

      steps.push(face);
    }

    if (confirmation) {
      const confirmationStep = {
        type: "complete",
        options: confirmation,
      };
      steps.push(confirmationStep);
    }
    return steps;
  };

  useEffect(() => {
    addEventListener(ONFIDO_EVENT_LISTENER, trackOnfidoEvent);

    // only run hook if we are initializing the SDK
    if (sdkState === ONFIDO_SDK_STATE.initializing) {
      const onfidoConfig = {
        token: onfidoSDKToken,
        containerId: CONTAINER_ID,
        steps: getSteps(),
        theme: {
          name: OnfidoTheme.Light,
          config: ONFIDO_CUSTOM_UI,
        },
        language: {
          locale: onfidoLocales[locale] ?? DEFAULT_LOCALE,
          phrases: {
            "document.selection.title": intl.formatMessage({
              defaultMessage: "Which type of photo ID would you like to provide?",
            }),
            "common.selection.driving_licence": intl.formatMessage({ defaultMessage: "Permanent driver's license" }),
            "common.selection.national_identity_card": intl.formatMessage({ defaultMessage: "National ID" }),
            "document.upload.title_national_identity_card_front": intl.formatMessage({
              defaultMessage: "Submit national ID (front)",
            }),
            "document.upload.title_national_identity_card_back": intl.formatMessage({
              defaultMessage: "Submit national ID (back)",
            }),
            "document.upload.title_driving_licence_front": intl.formatMessage({
              defaultMessage: "Submit government-issued permanent driver's license (front)",
            }),
            "document.upload.title_driving_licence_back": intl.formatMessage({
              defaultMessage: "Submit government-issued permanent driver's license (back)",
            }),
            "crossdevice.mobile_intro.subtitle": intl.formatMessage({
              defaultMessage: "Continue with the verification for Gemini",
            }),
          },
        },
        crossDeviceClientIntroProductLogoSrc: CONTENTFUL_BRAND_ASSET,
        onComplete,
        onError: handleOnError,
      };

      if (phoneNumber) {
        onfidoConfig["userDetails"] = { smsNumber: formatPhoneNumber(phoneNumber) };
      }

      import("onfido-sdk-ui")
        .then(({ Onfido }) => {
          handleDialogOpen && handleDialogOpen();
          setSdkState(ONFIDO_SDK_STATE.ready);
          onfidoInstance.current = Onfido.init(onfidoConfig);
        })
        .catch(err => {
          setSdkState(ONFIDO_SDK_STATE.failed);
          track(EVENTS.ON_FIDO.name, {
            [EVENTS.ON_FIDO.properties.ERROR_TYPE]: "Chunk loading error",
            [EVENTS.ON_FIDO.properties.ERROR_MESSAGE]: err.message,
          });
          // err.message: ChunkLoadError: Loading chunk $id failed.
          Sentry.captureMessage(`Error loading onfido sdk ui document uploader - ${err}`, "error");
        });
    }

    return () => {
      if (sdkState === ONFIDO_SDK_STATE.ready) {
        onfidoInstance?.current?.tearDown();
      }
      removeEventListener(ONFIDO_EVENT_LISTENER, trackOnfidoEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdkState]);

  const renderUploader = () => {
    return isLoading || sdkState === ONFIDO_SDK_STATE.initializing ? (
      <Flex alignItems="center" justifyContent="center" minHeight="672px">
        <SpinnerAnimation />
      </Flex>
    ) : (
      <Uploader id={CONTAINER_ID} />
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <Dialog.Container>
          {!isLoading && <Dialog.Header />}
          <Dialog.Content>{renderUploader()}</Dialog.Content>
        </Dialog.Container>
      </Dialog>
    );
  };

  return (
    <Fragment>
      <FailedLazyLoadModal
        isOpen={sdkState === ONFIDO_SDK_STATE.failed}
        retry={() => setSdkState(ONFIDO_SDK_STATE.initializing)}
      />
      {isDialog ? renderDialog() : renderUploader()}
    </Fragment>
  );
};
