import { Fragment, useState } from "react";
import { CurrentMethodState, TwoFactorViewType } from "@gemini-ui/components/TwoFactorAuth/types";
import { AuthenticationRequest } from "@gemini-ui/constants/initialData";
import { Button, Dialog } from "@gemini-ui/design-system";
import { usePasskeyAuth } from "@gemini-ui/pages/Authy/AuthyForm/usePasskeyAuth";
import { useIntl } from "@gemini-ui/utils/intl";
import { isWebAuthnSupported } from "@gemini-ui/utils/webauthn";

type WebAuthnViewProps = {
  authRequest: AuthenticationRequest;
  allowsFallback: boolean;
  onFallback: () => void;
  type: CurrentMethodState;
  redirect: string;
  email: string;
  onSuccess: (data: { redirect: string }) => void;
  parentType: TwoFactorViewType;
};
export const WebAuthnView = ({
  allowsFallback,
  onFallback,
  authRequest,
  type,
  email,
  redirect,
  onSuccess,
  parentType,
}: WebAuthnViewProps): JSX.Element => {
  const { intl } = useIntl();
  const { isLoading, initiateAuth, refetchAuthRequest } = usePasskeyAuth();
  // auth request now has a timeout of 5 minutes, instead of supporting refetching at each call site, manage everything here
  const [localAuthRequest, setLocalAuthRequest] = useState(authRequest);
  const [hasError, setHasError] = useState(!isWebAuthnSupported());
  const [isRefetching, setIsRefetching] = useState(false);

  const handleTryAgain = async () => {
    setIsRefetching(true);
    const newAuthRequest = await refetchAuthRequest();
    setIsRefetching(false);
    if (newAuthRequest) {
      setLocalAuthRequest(newAuthRequest);
      handleWebAuthn(newAuthRequest);
    } else {
      setHasError(true);
    }
  };

  const handleWebAuthn = (authRequest: AuthenticationRequest) => {
    setHasError(false);
    if (!isWebAuthnSupported()) {
      return;
    }

    if (!authRequest) return;

    const publicKeyCredentialRequestOptions = authRequest.assertion.assertion.publicKeyCredentialRequestOptions;
    const authSignedRequest = authRequest.signed;
    initiateAuth({
      redirect,
      onSuccess,
      email,
      publicKeyCredentialRequestOptions,
      authSignedRequest,
      onError: _ => {
        setHasError(true);
      },
    });
  };

  return (
    <Fragment>
      <Button.Group>
        {allowsFallback && (
          <Button.Tertiary
            cta={intl.formatMessage({
              defaultMessage: "Try another way",
            })}
            onClick={onFallback}
            size={parentType === TwoFactorViewType.Dialog ? "md" : "lg"}
          />
        )}
        <Button.Primary
          cta={intl.formatMessage({ defaultMessage: "Verify" })}
          data-testid="webauthn-verify-cta"
          size={parentType === TwoFactorViewType.Dialog ? "md" : "lg"}
          onClick={() => {
            setHasError(false);
            handleWebAuthn(localAuthRequest);
          }}
          loading={isLoading}
          disabled={isLoading}
        />
      </Button.Group>
      {hasError && (
        <Dialog open={hasError} onClose={() => setHasError(false)}>
          <Dialog.Container>
            <Dialog.Header>
              <Dialog.Title>
                {type === CurrentMethodState.Passkey
                  ? intl.formatMessage({ defaultMessage: "Passkey authentication failed" })
                  : intl.formatMessage({ defaultMessage: "Security key authentication failed" })}
              </Dialog.Title>
            </Dialog.Header>
            <Dialog.Content>
              {type === CurrentMethodState.Passkey
                ? intl.formatMessage({
                    defaultMessage:
                      "Passkey request either timed out or was not allowed. Try again or use another method.",
                  })
                : intl.formatMessage({
                    defaultMessage:
                      "Security key request either timed out or was not allowed. Try again or use another method. ",
                  })}
            </Dialog.Content>
            <Dialog.Footer>
              {allowsFallback && (
                <Button.Tertiary
                  data-testid="webauthn-try-another-way"
                  onClick={() => {
                    setHasError(false);
                    onFallback();
                  }}
                  cta={intl.formatMessage({ defaultMessage: "Try another way" })}
                />
              )}
              <Button.Primary
                loading={isRefetching}
                data-testid="webauthn-try-again"
                onClick={handleTryAgain}
                cta={intl.formatMessage({ defaultMessage: "Try again" })}
              />
            </Dialog.Footer>
          </Dialog.Container>
        </Dialog>
      )}
    </Fragment>
  );
};
