import * as Sentry from "@sentry/browser";
import {
  CurrencyShortName,
  CurrencyShortNameCrypto,
  CurrencyShortNameForCustody,
} from "@gemini-common/scripts/constants/currencies";
import { SiteLockout } from "@gemini-ui/components/Lockout/utils";
import { getSortMetaDataArray } from "@gemini-ui/components/Transfer/CryptoDepositFlow/utils";
import { GeminiAccount, Subaccounts } from "@gemini-ui/constants/templateProps/account";
import { User } from "@gemini-ui/constants/templateProps/users";
import { CryptoAddress, CurrencyWithBoolean, TransferAccount } from "@gemini-ui/pages/transfers/constants";
import { getAccountBalances } from "@gemini-ui/services/account/balances";
import axios, { AxiosResponse } from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getMarketFilters } from "@gemini-ui/services/retail/marketFilters";

const DEFAULT_RETRY_COUNT = 3;
function isFulfilled<T>(result: PromiseSettledResult<T>): result is PromiseFulfilledResult<T> {
  return result.status === "fulfilled";
}
export const fetchAvailableCrypto = async (): Promise<{
  accounts: TransferAccount[];
  canAddAddress: CurrencyWithBoolean;
  exchangeOnlyAssets: CurrencyShortName[];
  isMultiAccount: boolean;
  isMultiUserAccount: boolean;
  lockout: SiteLockout;
  sandboxWalletEnabled: boolean;
  subaccounts: Subaccounts;
  supportedAssets: Partial<CurrencyShortName>[];
  supportedExchangeCrypto: Partial<CurrencyShortNameForCustody>[];
  user: User;
  account: GeminiAccount;
  transactionsEnabled: CurrencyWithBoolean;
  sortingCriterion: CurrencyShortNameForCustody[];
}> => {
  const response = await axios.get(
    jsRoutes.com.gemini.web.server.funding.controllers.TransferPageController.getTransferDetails().url,
    {
      retry: DEFAULT_RETRY_COUNT,
    }
  );
  const { user, account } = window.initialData.templateProps;

  const [marketFilterResponse, balanceResponse] = await Promise.allSettled([
    getMarketFilters(account.defaultFiat),
    getAccountBalances(),
  ]);
  const marketData = isFulfilled(marketFilterResponse) ? marketFilterResponse : {};
  const balanceData = isFulfilled(balanceResponse) ? balanceResponse : {};
  const sortingCriterion = getSortMetaDataArray(balanceData, marketData);

  const {
    accounts,
    canAddAddress,
    exchangeOnlyAssets,
    isMultiAccount,
    isMultiUserAccount,
    lockout,
    sandboxWalletEnabled,
    subaccounts,
    supportedAssets,
    supportedExchangeCrypto,
    transactionsEnabled,
  } = response.data;

  const data = {
    accounts,
    canAddAddress,
    exchangeOnlyAssets,
    isMultiAccount,
    isMultiUserAccount,
    lockout,
    sandboxWalletEnabled,
    subaccounts,
    supportedAssets,
    supportedExchangeCrypto,
    user,
    account,
    transactionsEnabled,
    sortingCriterion,
  };
  return data;
};

export const getCryptoDepositDetails = async (
  asset: CurrencyShortNameCrypto,
  accountHash: string
): Promise<AxiosResponse<{ addresses: CryptoAddress[]; multiAddressFormat: boolean }>> => {
  try {
    const response = await axios.get(`/transfer/deposit/${asset.toLowerCase()}/details`, {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash },
      retry: DEFAULT_RETRY_COUNT,
    });
    return response;
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const getNewCryptoAddress = (
  asset: CurrencyShortName,
  accountHash: string
): Promise<AxiosResponse<{ address: string }>> => {
  return axios.post(
    `/transfer/deposit/${asset.toLowerCase()}`,
    {},
    {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash, "Content-Type": "application/json" },
    }
  );
};

export const getNewMpcCustodyAddress = (
  currency: CurrencyShortName,
  accountHash: string
): Promise<AxiosResponse<{ address: { wrapped: string } }>> => {
  return axios.post(
    jsRoutes.com.gemini.web.server.onchain.controllers.CustodyDashboardController.createMPCCustodyAddress(currency).url,
    {},
    {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash, "Content-Type": "application/json" },
    }
  );
};
interface DepositLabel {
  address: string;
  label: string;
  memo?: string;
}
const updateDepositLabel = (
  asset: CurrencyShortName,
  accountHash: string,
  depositLabel: DepositLabel
): Promise<AxiosResponse<{ label: string; success: boolean }>> => {
  return axios.post(`/transfer/deposit/${asset.toLowerCase()}/label`, depositLabel, {
    headers: { [HEADERS.ACCOUNT_ID]: accountHash },
  });
};
export const cryptoDepositApi = {
  getNewCryptoAddress,
  getNewMpcCustodyAddress,
  updateDepositLabel,
  getCryptoDepositDetails,
};
