import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/browser";
import { CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import {
  CbitTransferInfoAPIPayload,
  WireTransferInfoAPIPayload,
} from "@gemini-ui/components/WireDepositDetails/constants";
import { WireDepositDetails } from "@gemini-ui/components/WireDepositDetails/WireDepositDetails";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { Button, Flex, Modal, Spacing, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { DepositTransferMechanism, TRANSFER_MECHANISM } from "@gemini-ui/pages/transfers/constants";
import { CbitDepositDetails } from "@gemini-ui/pages/transfers/Deposit/CbitDepositDetails";
import { AxiosResponse, CancelToken, CancelTokenSource, isCancel } from "@gemini-ui/services/axios";
import {
  getFiatDepositCbitInstructions,
  getFiatDepositRtpInstructions,
  getFiatDepositWireInstructionsV2,
  getStraitsXDepositInstructions,
} from "@gemini-ui/services/transfer/deposit";
import { useIntl } from "@gemini-ui/utils/intl";

type WireInstructionsModalPropsType = {
  customerBankName?: ReactNode;
  currency: CurrencyShortNameFiat;
  onChangeCurrencyClick?: (currency: CurrencyShortNameFiat) => void;
  onClose: () => void;
  isOpen: boolean;
  onBack?: () => void;
  title?: string;
  paymentMethodType?: PaymentMethodType;
  subaccountHashid: string;
};

enum ModalState {
  ERROR,
  LOADING,
  SUCCESS,
}

enum ErrorCodes {
  BlocklistedCountry = "BlocklistedCountry",
  ErrorOnDepositInstruction = "ErrorOnDepositInstruction",
}

export const WireInstructionsModal = ({
  onClose,
  currency,
  title,
  isOpen,
  onBack,
  paymentMethodType,
  subaccountHashid,
}: WireInstructionsModalPropsType) => {
  const { intl } = useIntl();

  const [modalState, setModalState] = useState(ModalState.LOADING);
  const [wireDetails, setWireDetails] = useState<WireTransferInfoAPIPayload>(null);
  const cancelTokenSource = useRef<CancelTokenSource>();

  const [cbitInstructionData, setCbitInstructionData] = useState<CbitTransferInfoAPIPayload>(null);
  const isCbitEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CBIT);
  const isCbit = paymentMethodType === PaymentMethodType.CBIT && isCbitEnabled;
  const isRtpEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DBS_SG_INTEGRATION_ENABLED);
  const isXfersPaymentType = paymentMethodType && paymentMethodType === PaymentMethodType.XFERS;
  const isRtpPaymentType = paymentMethodType && paymentMethodType === PaymentMethodType.RTP;
  // Xfers fallback for SGD if RTP is not enabled
  const isXfers = isXfersPaymentType || (isCurrency.SGD(currency) && !isRtpEnabled);
  const isRtp = isCurrency.SGD(currency) && isRtpEnabled && isRtpPaymentType;
  const defaultErrorMessage = intl.formatMessage({
    defaultMessage: "There was an error getting wire instructions. Please try again or contact support for assistance.",
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(defaultErrorMessage);
  const [errorCode, setErrorCode] = useState<ErrorCodes | null>(ErrorCodes.ErrorOnDepositInstruction);

  const fetchWireDetails = useCallback(async () => {
    cancelTokenSource.current = CancelToken.source();
    setModalState(ModalState.LOADING);

    type Condition = boolean;
    type Instructions = () => Promise<AxiosResponse<any, any>>;
    type ResponseCallback = (res: AxiosResponse<any, any>) => void;
    const instructionsCollection: {
      name: DepositTransferMechanism;
      condition: Condition;
      instructions: Instructions;
      responseCallback: ResponseCallback;
    }[] = [
      {
        name: TRANSFER_MECHANISM.cbit,
        condition: isCbit,
        instructions: () => getFiatDepositCbitInstructions(subaccountHashid, { currency, sendEmail: false }),
        responseCallback: res => setCbitInstructionData(res.data?.data),
      },
      {
        name: TRANSFER_MECHANISM.rtp,
        condition: isRtp,
        instructions: () => getFiatDepositRtpInstructions(subaccountHashid, { currency, sendEmail: false }),
        responseCallback: res =>
          setWireDetails({ internationalBankInfo: res.data?.data, currency, vaStatus: res.data?.data?.vaStatus }),
      },
      {
        name: TRANSFER_MECHANISM.xfers,
        condition: isXfers,
        instructions: () => getStraitsXDepositInstructions(currency, subaccountHashid),
        responseCallback: res => setWireDetails({ internationalBankInfo: res.data, currency }),
      },
      {
        name: TRANSFER_MECHANISM.wire,
        condition: !isXfers && !isRtp && !isCbit,
        instructions: () => getFiatDepositWireInstructionsV2(currency, subaccountHashid),
        responseCallback: res => setWireDetails(res.data),
      },
    ];

    try {
      const collection = instructionsCollection.find(({ condition }) => condition);
      if (!collection) throw new Error("No collection instructions found based on the conditions");

      const response = await collection.instructions();
      collection.responseCallback(response);

      setModalState(ModalState.SUCCESS);
    } catch (e) {
      if (!isCancel(e)) {
        Sentry.captureException(e);
        if (!isXfers && !isRtp && !isCbit) {
          const { errorCode, errorMessage } = e.response.data;
          setErrorMessage(errorMessage || defaultErrorMessage);
          setErrorCode(errorCode || ErrorCodes.ErrorOnDepositInstruction);
        }
        setModalState(ModalState.ERROR);
      }
    } finally {
      cancelTokenSource?.current?.cancel?.();
    }
  }, [currency, isXfers, isCbit, subaccountHashid, defaultErrorMessage, isRtp]);

  useEffect(() => {
    if (isOpen) {
      fetchWireDetails();
    } else {
      cancelTokenSource?.current?.cancel?.();
    }

    return () => {
      cancelTokenSource?.current?.cancel?.();
    };
  }, [fetchWireDetails, isOpen, intl]);

  const renderModalContent = () => {
    if (modalState === ModalState.ERROR) {
      return (
        <Fragment>
          <Text.Body size="md">{errorMessage}</Text.Body>
          {errorCode === ErrorCodes.ErrorOnDepositInstruction && (
            <Button.Group>
              <Button.Primary onClick={fetchWireDetails} data-testid="retry-get-wire-instructions-btn">
                {intl.formatMessage({ defaultMessage: "Try again" })}
              </Button.Primary>
            </Button.Group>
          )}
        </Fragment>
      );
    } else if (modalState === ModalState.LOADING) {
      return (
        <Flex mt={8} mb={8} justifyContent="center" alignItems="center">
          <SpinnerAnimation size={Spacing.scale[4]} />
        </Flex>
      );
    } else {
      return (
        <Fragment>
          {isCbit && isCbitEnabled ? (
            <CbitDepositDetails {...cbitInstructionData} />
          ) : (
            <WireDepositDetails
              {...wireDetails}
              currency={currency}
              isXfers={isXfers}
              isRtp={isRtp}
              subaccountHashid={subaccountHashid}
            />
          )}

          <Button.Group>
            <Button.Primary data-testid="wire-instruction-got-it-btn" onClick={onClose}>
              {intl.formatMessage({
                defaultMessage: "Got it",
              })}
            </Button.Primary>
          </Button.Group>
        </Fragment>
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onBack={onBack}
      onClose={onClose}
      multiStep
      title={
        modalState !== ModalState.ERROR ? (
          <Flex>
            <span>
              {title ||
                intl.formatMessage({
                  defaultMessage: "Deposit instructions",
                })}
            </span>
          </Flex>
        ) : (
          intl.formatMessage({
            defaultMessage: "We're sorry",
          })
        )
      }
    >
      {renderModalContent()}
    </Modal>
  );
};
