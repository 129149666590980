import * as Sentry from "@sentry/browser";
import {
  CountryConfigState,
  CountryDocumentResponseType,
  ONFIDO_DOCUMENT_TYPE,
  VerifyContext,
} from "@gemini-ui/pages/register/Verify/types";
import axios, { AxiosError, AxiosResponse } from "@gemini-ui/services/axios";

const ONBOARDING_DATA_URL =
  jsRoutes.com.gemini.web.server.onboarding.controllers.services.DynamicInfoCaptureController.getUserData().url;

export const fetchOnboardingData = () => {
  return axios
    .get(ONBOARDING_DATA_URL, { retry: 3 })
    .then((response: AxiosResponse<VerifyContext>) => {
      const { data } = response;

      return data;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const fetchCountryDocumentConfig = async (countryCode = "") => {
  try {
    const response: AxiosResponse<CountryDocumentResponseType> = await axios.get(
      jsRoutes.com.gemini.web.server.onboarding.controllers.services.DocUploadController.getDocumentRequirements(
        countryCode
      ).url
    );
    const { data } = response;
    return convertToCountryConfigState(data);
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setExtra(
        "countryDocumentConfigFailure",
        "Country endpoint failed, defaulting to verifyFallback (passport & POA/Bill)"
      );
      Sentry.captureException(error);
    });
    // default to fallback flow (passport & POA/Bill)
    return {
      useMultiDocFlow: false,
      useSingleDocFlow: false,
      firstDocuments: {},
      secondDocuments: {},
    };
  }
};
// convert to a model easily consumable in the state machine/onfido sdk
const convertToCountryConfigState = (data: CountryDocumentResponseType): CountryConfigState => {
  // api sends Bill if the countryCode could not be found. Send this case to the verifyFallback step
  const isValidSingleDocFlow =
    (data?.documentSet?.firstDocuments?.length > 0 &&
      !data?.documentSet?.firstDocuments.includes(ONFIDO_DOCUMENT_TYPE.Bill)) ??
    false;
  return {
    useMultiDocFlow: Boolean(data?.documentSet?.secondDocuments?.length > 0),
    useSingleDocFlow: isValidSingleDocFlow,
    firstDocuments: data?.documentSet?.firstDocuments.reduce(
      (acc, doc) => (ONFIDO_DOCUMENT_TYPE[doc] && (acc[ONFIDO_DOCUMENT_TYPE[doc]] = true), acc),
      {}
    ),
    secondDocuments: data.documentSet.secondDocuments.reduce(
      (acc, doc) => (ONFIDO_DOCUMENT_TYPE[doc] && (acc[ONFIDO_DOCUMENT_TYPE[doc]] = true), acc),
      {}
    ),
  };
};
