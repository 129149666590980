import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga4";
import { CreditAnalyticsEvents } from "@gemini-ui/analytics/constants/creditCard";
import { GoogleAnalyticsEvents } from "@gemini-ui/analytics/constants/events";
import { EVENTS_ERROR_TRACKING_LABEL_PREFIX } from "@gemini-ui/utils/constants";
import { getCookieSettings } from "@gemini-ui/utils/cookie";

export function trackGoogleAnalyticsEvent(
  eventAction: GoogleAnalyticsEvents | CreditAnalyticsEvents,
  additionalParams?: Record<string, string>
) {
  if (getCookieSettings().allowAnalytics) {
    try {
      if (additionalParams) {
        ReactGA.event(eventAction, additionalParams);
      } else {
        ReactGA.event(eventAction);
      }
    } catch (e) {
      if (e instanceof Error) {
        e.message = `${EVENTS_ERROR_TRACKING_LABEL_PREFIX} -- ${eventAction} -- GA event not tracked`;
      }
      Sentry.captureException(e);
    }
  }
}
