import { CurrencyShortNameCrypto } from "@gemini-common/scripts/constants/currencies";
import { LazyModalWrapper, ModalType } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import { CryptoDepositEntrypoint } from "@gemini-ui/components/Transfer/CryptoDepositFlow/types";

const CryptoDepositFlow = LazyModalWrapper(() => import("./index"));

interface Props {
  onClose: () => void;
  preSelectedCrypto?: CurrencyShortNameCrypto;
  entrypoint?: CryptoDepositEntrypoint;
  // LazyModalWrapper props
  isOpen: boolean;
  modalType: ModalType;
}

function LazyLoadCryptoDepositFlow({ onClose, preSelectedCrypto, entrypoint, ...rest }: Props) {
  return <CryptoDepositFlow onClose={onClose} preSelectedCrypto={preSelectedCrypto} entrypoint={entrypoint} />;
}

export default LazyLoadCryptoDepositFlow;
