import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import useScript from "@gemini-ui/utils/useScript";

export const SOCURE_SCRIPT = "https://sdk.dv.socure.io/latest/device-risk-sdk.js";
export const SOCURE_SDK_KEY = "f0e1e667-d482-4adc-8848-a33362ed2169";

export const useSocureCollector = () => {
  const isSocureEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CREDIT_CARD_SOCURE);
  const { ready } = useScript(SOCURE_SCRIPT, {
    dataPublicKey: SOCURE_SDK_KEY,
    context: "signup",
    shouldLoad: isSocureEnabled,
  });
  const [socureId, setSocureId] = useState(null);

  useEffect(() => {
    if (ready && window?.SigmaDeviceManager?.getSessionToken) {
      window.SigmaDeviceManager.getSessionToken()
        .then(sessionToken => setSocureId(sessionToken))
        .catch(error => Sentry.captureException(error));
    }
  }, [ready]);

  return socureId;
};
