import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { IconChevronDownSmall } from "@hubble/icons";
import _ from "lodash";
import { UserTitle } from "@gemini-ui/components/Header/navigation/MobileMenu/components/components";
import {
  fullScreenStyles,
  Loader,
  Logo,
  Subtitle,
  Title,
} from "@gemini-ui/components/Header/navigation/MobileMenu/styles";
import { GroupSwitcherProps } from "@gemini-ui/components/Header/navigation/MobileMenu/types";
import { handleLogout } from "@gemini-ui/components/Header/navigation/utils";
import { Flex, HubbleButton, HubbleMenu, Modal } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const selectionUrl = (hashid: string) =>
  jsRoutes.com.gemini.web.server.account.controllers.AccountGroupSelectionController.post(hashid).url;
const signOutUrl = jsRoutes.com.gemini.web.server.account.controllers.Application.signOut().url;

const sortGroups = (groups, hashid) => _.sortBy(groups, g => g.hashid !== hashid);

export const GroupSwitcher = ({ groupsInfo, institutionName, userName }: GroupSwitcherProps) => {
  const { intl } = useIntl();

  const form = useRef(null);

  const currentGroup = useMemo(() => {
    return groupsInfo.groups.find(g => g.hashid === groupsInfo.currentGroupHashId);
  }, [groupsInfo]);

  const groupName = useMemo(() => {
    return institutionName ? institutionName : currentGroup.name !== userName ? currentGroup.name : "";
  }, [currentGroup, institutionName, userName]);

  const [sortedGroups, setSortedGroups] = useState(sortGroups(groupsInfo.groups, groupsInfo.currentGroupHashId));
  const [selectedGroup, setSelectedGroup] = useState(currentGroup);
  const [submitting, setSubmitting] = useState(false);

  const handleChangeGroup = newHash => {
    if (newHash !== currentGroup.hashid) {
      setSelectedGroup(sortedGroups.find(g => g.hashid === newHash));
      setSortedGroups(sortGroups(sortedGroups, newHash));
      setSubmitting(true);
    }
  };

  useEffect(() => {
    if (submitting) form.current.submit();
  }, [submitting]);

  return (
    <Flex width="100%" height="40px" justifyContent="space-between">
      {sortedGroups.length === 1 ? (
        <UserTitle title={userName} subtitle={institutionName} margin={2} />
      ) : (
        <HubbleMenu>
          <HubbleMenu.Trigger>
            <HubbleButton.Tertiary
              leftElement={<UserTitle title={userName} subtitle={groupName} margin={0} />}
              rightElement={<IconChevronDownSmall />}
            />
          </HubbleMenu.Trigger>
          <HubbleMenu.Content>
            <form action={selectionUrl(selectedGroup.hashid)} method="POST" ref={form}>
              <HubbleMenu.RadioGroup value={currentGroup.hashid} onValueChange={handleChangeGroup}>
                {sortedGroups.map(group => (
                  <HubbleMenu.RadioItem value={group.hashid} label={group.name} key={group.hashid} />
                ))}
              </HubbleMenu.RadioGroup>
              <input type="hidden" name="csrfToken" value={groupsInfo.csrfToken?.value} />
            </form>
          </HubbleMenu.Content>
        </HubbleMenu>
      )}
      <HubbleButton.Tertiary
        href={signOutUrl}
        cta={intl.formatMessage({ defaultMessage: "Log Out" })}
        onClick={handleLogout}
      />
      <Modal isOpen={submitting} css={fullScreenStyles}>
        <Loader>
          <Logo />
          <Title>
            {intl.formatMessage(
              defineMessage({
                defaultMessage: "Switching to <strong>{selectedOptionName}</strong>",
              }),
              {
                strong: (v: ReactNode) => <strong>{v}</strong>,
                selectedOptionName: selectedGroup.name,
              }
            )}
          </Title>
          <Subtitle>
            {intl.formatMessage({
              defaultMessage: "Please wait a few seconds while we switch your Group.",
            })}
          </Subtitle>
        </Loader>
      </Modal>
    </Flex>
  );
};
