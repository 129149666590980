import { datadogLogs, LogsEvent } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { isProdUrl } from "@gemini-ui/analytics";
import { optimizelyClient } from "@gemini-ui/analytics/optimizely";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { DataDogCredentials } from "@gemini-ui/constants/initialData/datadog";
import { User } from "@gemini-ui/constants/templateProps/users";

export const beforeSend = (event: LogsEvent) => {
  const IGNORE_ERRORS = [
    "top.GLOBALS", // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose", // Facebook borked
    "fb_xd_fragment", // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See https://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived", // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ];
  if (IGNORE_ERRORS.includes(event.error?.message || "") || event.view.url.includes("devNotes")) {
    return false;
  }
  return true;
};
export const initializeDataDogLogging = (credentials: DataDogCredentials, user?: User) => {
  const isDataDogLoggingEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG_LOGGING);
  if (!isDataDogLoggingEnabled) {
    return;
  }

  datadogLogs.init({
    clientToken: credentials.tradingDashBoardClientToken,
    site: "datadoghq.com",
    env: isProdUrl() ? "prod" : "qa",
    service: "web-frontend",
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["error", "debug"],
    sessionSampleRate: 100,
    beforeSend,
  });

  if (user) {
    datadogLogs.setUser({
      id: user.mixpanelId,
      email: user.email,
      countryCode: user.countryCode,
      lang: user.lang,
    });
  }
};
type VariableTypeMap = { integer: number; boolean: boolean };
function getDatadogFeatureVariable<T extends keyof VariableTypeMap>(
  variable: string,
  type: T
): VariableTypeMap[T] | undefined {
  const featureKey = OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG;
  let value: VariableTypeMap[T] | null = null;
  switch (type) {
    case "integer":
      value = optimizelyClient.getFeatureVariableInteger(featureKey, variable);
    case "boolean":
      value = optimizelyClient.getFeatureVariableBoolean(featureKey, variable);
  }
  return value || undefined;
}

export const initializeDataDogRUM = (credentials: DataDogCredentials, user?: User) => {
  const isDataDogEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG);

  if (!isDataDogEnabled) return;

  datadogRum.init({
    applicationId: credentials.tradingDashBoardApplicationId,
    clientToken: credentials.tradingDashBoardClientToken,
    site: "datadoghq.com",
    service: "web-frontend",
    env: isProdUrl() ? "prod" : "qa",
    sessionSampleRate: getDatadogFeatureVariable("sampleRate", "integer"),
    trackUserInteractions: getDatadogFeatureVariable("trackInteractions", "boolean"),
    trackLongTasks: getDatadogFeatureVariable("trackLongTasks", "boolean"),
    trackResources: getDatadogFeatureVariable("trackResources", "boolean"),
    trackSessionAcrossSubdomains: getDatadogFeatureVariable("trackSessionAcrossSubdomains", "boolean"),
  });

  if (user?.mixpanelId) {
    datadogRum.setUser({
      id: user?.mixpanelId,
      email: user.email,
      name: user.fullName,
      countryCode: user.countryCode,
      lang: user.lang,
    });
  }
};
