import * as Sentry from "@sentry/browser";
import { subYears } from "date-fns";
import { CurrencyShortName, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { HourlyFundingTransfer, PositionTransfer, RealizedPnL } from "@gemini-ui/constants/perpEvents";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { makeLiquidationData } from "@gemini-ui/utils/derivativeAccountUtils";

const { RecurringOrdersController } = jsRoutes.com.gemini.web.server.trading.controllers;
const { HistoryController } = jsRoutes.com.gemini.web.server.trading.controllers;

export const getTradeHistory = (url: string, subaccountHashid: string) => {
  const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : undefined;

  return axios.get(url, config);
};

export const getRecurringOrders = (subaccountHashid: string, defaultFiat: CurrencyShortNameFiat) => {
  const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : undefined;

  return axios.get(RecurringOrdersController.getV2(defaultFiat).url, config);
};

export const getOrderPermissions = (subaccountHashid: string) => {
  const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : undefined;

  return axios.get(jsRoutes.com.gemini.web.server.account.controllers.UserController.getPermissions().url, config);
};

export const getTransferHistory = (
  subaccountHashid: string,
  from: string | null,
  currency?: CurrencyShortName,
  includeEarn?: boolean
) => {
  const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : undefined;
  const url = HistoryController.getTransfers(from, currency, includeEarn).url;
  return axios.get(url, config);
};

export const getTradesAndTransfersHistory = (
  from: string | null = null,
  subaccountHashid,
  currency?: CurrencyShortName,
  limit?: number
) => {
  const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : undefined;
  return axios.get(HistoryController.getTradesAndTransfers(from, limit, currency).url, config);
};

export const getFundingTransfers = async (startTimeMs: number, endTimeMs: number, subaccountHashid: string) => {
  const url = HistoryController.getHourlyFundingTransferEvents(startTimeMs, endTimeMs).url;
  try {
    const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : undefined;

    const response = await axios.get(url, config);
    const transferEvents: HourlyFundingTransfer[] = response.data;
    return transferEvents;
  } catch (err) {
    Sentry.captureException(err);
    return [];
  }
};

export const getRealizedPnL = async (subaccountHashid: string) => {
  // Temporarily hardcoding startTimeMs because no data exists beyond this point, and requesting a wider timeframe
  // results in an empty array in the response
  const startTimeMs = new Date("Fri Feb 11 2023").getTime();
  const endTimeMs = new Date().getTime();
  const url = HistoryController.getRealizedPnLEvents(startTimeMs, endTimeMs).url;
  try {
    const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : null;
    const response = await axios.get(url, config);
    const pnlEvents: RealizedPnL[] = response.data;
    return pnlEvents;
  } catch (err) {
    Sentry.captureException(err);
    return [];
  }
};

export const getLiquidationTransfers = async (startTimeMs: number, endTimeMs: number, subaccountHashid?: string) => {
  const url = HistoryController.getPositionTransferEvents(startTimeMs, endTimeMs).url;
  try {
    const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : null;
    const response = await axios.get(url, config);
    const liquidationTransfers: PositionTransfer[] = response.data;
    return liquidationTransfers;
  } catch (err) {
    Sentry.captureException(err);
    return [];
  }
};

export const getLiquidationEvents = async (subaccountHashid?: string) => {
  try {
    const fromDate = subYears(new Date(), 1).getTime();
    const toDate = new Date().getTime();
    const liquidationTransfers = getLiquidationTransfers(fromDate, toDate, subaccountHashid);
    const liquidationOrders = getTradeHistory(HistoryController.getTrades().url, subaccountHashid);
    const [resolvedTransfers, resolvedOrders] = await Promise.all([liquidationTransfers, liquidationOrders]);

    return makeLiquidationData(
      resolvedOrders.data?.entries?.filter(order => order.isLiquidation),
      resolvedTransfers
    );
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};
