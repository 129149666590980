export const REGEX = {
  ALPHA: /[A-Za-z]/,
  ALPHA_CAPITALIZED: /[A-Z]/,
  ALPHA_OPTIONAL: /[A-Za-z]?/,
  ALPHA_NUMERIC: /[A-Za-z0-9]/,
  ALPHA_NUMERIC_OPTIONAL: /[A-Za-z0-9]?/,
  NUMERIC: /\d/,
  NUMERIC_OPTIONAL: /\d?/,
};

export const MAINTENANCE_STATUS_CODE = 503; // reserved for maintenance status error response

export interface HistoricalPrices {
  change: string;
  change_percent: string;
  last_price: string;
}

export type NotificationSettingKey =
  | "emailOnDigitalAssetAction"
  | "confirmCancelOrders"
  | "confirmMarketOrders"
  | "confirmLimitOrders"
  | "emailOnMarketing"
  | "emailOnCompletedMarketOrder"
  | "emailOnNewOrder"
  | "emailOnBankAction"
  | "emailOnCompletedLimitOrder"
  | "emailOnCompletedInstantOrder"
  | "emailOnRecurringOrderActivity"
  | "emailOnWrappedDigitalAssetAction";
export type NotificationSettings = {
  [setting in NotificationSettingKey]: "true" | "false";
};

export interface ThemeSchemes<T> {
  [schemeName: string]: T;
}
