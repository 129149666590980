import { Button, Modal, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  errorHeading: string;
  errorDescription: string;
};

export const DebitCardErrorModal = ({ isOpen, onClose, errorHeading, errorDescription }: Props) => {
  const { intl } = useIntl();
  const defaultErrorHeading = intl.formatMessage({
    defaultMessage: "Something went wrong. Unable to complete this action at this time. Please try again.",
  });

  return (
    <Modal isOpen={isOpen} title={errorHeading ?? defaultErrorHeading}>
      <Text.Body data-testid="generic-try-again-error-message" mt={2} mb={2} size="sm">
        {errorDescription}
      </Text.Body>
      <Button.Group>
        <Button.Primary data-testid="transfer-flow-cancel-button" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: "Got it",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};
