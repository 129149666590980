import { ReactNode, useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Button, Flex, Modal, Text } from "@gemini-ui/design-system";
import { ReactComponent as WireIcon } from "@gemini-ui/images/icons/wire.svg";
import { SuccessTitle } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/styles";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const getDepositRoute = currency =>
  jsRoutes.com.gemini.web.server.funding.controllers.TransferPageController.getAssetDepositPage(currency.toLowerCase())
    .url;

interface Props {
  onClose: () => void;
  name: ReactNode;
  currency: CurrencyShortNameFiat;
  registrationFlow?: boolean;
  hasMultipleAccountsFlow?: boolean;
}

export const SuccessModal = ({ onClose, name, currency, registrationFlow, hasMultipleAccountsFlow }: Props) => {
  const { intl } = useIntl();

  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    onClose();
    setShowModal(false);
  };

  const handleClick = () => {
    if (registrationFlow) {
      const { name, properties } = EVENTS.FINAL_STEP_DEPOSIT_PAGE;
      track(name, { [properties.CURRENCY]: currency });
    }

    return true;
  };

  const title = (
    <Flex.Column align="center">
      <WireIcon />
      <SuccessTitle>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "Send us a deposit to <br></br>verify your bank account",
          }),
          {
            br: () => <br />,
          }
        )}
      </SuccessTitle>
    </Flex.Column>
  );

  return (
    <Modal title={title} isOpen={showModal} shouldCloseOnOverlayClick onClose={closeModal} centered>
      {hasMultipleAccountsFlow ? (
        <Text.Body size="sm" mt={1} mb={1}>
          {intl.formatMessage({
            defaultMessage:
              "The final step is to fund your Gemini Account. On the next screen, you'll be able to select a linked bank account and make your deposit.",
          })}
        </Text.Body>
      ) : (
        <Text.Body size="sm" mt={1} mb={1}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "The final step is to fund your Gemini Account from your payment method <TextBody>{name}</TextBody>.",
            }),
            {
              TextBody: (v: ReactNode) => (
                <Text.Body size="sm" bold as="strong">
                  {v}
                </Text.Body>
              ),
              name,
            }
          )}
        </Text.Body>
      )}
      <Button.Group type="centered">
        <Button.Primary
          size="lg"
          data-testid="bank-registration-success-goto-transfers"
          href={getDepositRoute(currency)}
          onClick={handleClick}
          cta={intl.formatMessage({
            defaultMessage: "Make a Deposit",
          })}
        />
      </Button.Group>
    </Modal>
  );
};
