import { Dispatch, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { removeNonLatinString } from "@gemini-ui/pages/register/Verify/utils/sanitizeString";

const API_KEY = "AIzaSyDo2PZNtWoOsatNNMJfgQcpAWpZ7sBladg";

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

const getComponent = (components: AddressComponent[], type: string, short?: boolean) => {
  const item = components?.find(item => item.types?.includes(type.toLowerCase()));
  if (!item) return null;
  return short ? item.short_name : item.long_name;
};

const addressBuilder = {
  KR: (components: AddressComponent[]) => {
    // gets the locality closest to the premise, typically sub_locality_level_4 or level 3 and ends in -daero, -ro or -gil
    return { streetNumber: getComponent(components, "PREMISE"), streetName: getComponent(components, "SUBLOCALITY") };
  },
};
const buildAddress = (components: AddressComponent[], country: string) => {
  const streetNumber = getComponent(components, "STREET_NUMBER") ?? addressBuilder[country]?.(components)?.streetNumber;
  const streetName = getComponent(components, "ROUTE") ?? addressBuilder[country]?.(components)?.streetName;

  // avoid re-using the same address component type in multiple places (for now only applies to KR)
  const remainingComponents = components.filter(
    (component: AddressComponent) => ![streetNumber, streetName].includes(component.long_name)
  );
  return { address1: _.compact([streetNumber, streetName]).join(" "), remainingComponents };
};

export const getAddressComponents = (address_components: AddressComponent[]) => {
  // remove non latin characters (api call will fail)
  const cleanedComponents = address_components.map(component => {
    return {
      ...component,
      short_name: removeNonLatinString(component.short_name),
      long_name: removeNonLatinString(component.long_name),
    };
  });
  const country = getComponent(cleanedComponents, "COUNTRY", true);
  const countryFullName = getComponent(cleanedComponents, "COUNTRY", false);
  const { address1, remainingComponents } = buildAddress(cleanedComponents, country);
  const address2 = ""; // google maps doesnt return apartment numbers
  const city =
    getComponent(remainingComponents, "LOCALITY") ||
    getComponent(remainingComponents, "SUBLOCALITY") ||
    getComponent(remainingComponents, "POSTAL_TOWN") ||
    getComponent(remainingComponents, "ADMINISTRATIVE_AREA_LEVEL_2") ||
    getComponent(remainingComponents, "ADMINISTRATIVE_AREA_LEVEL_3") ||
    getComponent(remainingComponents, "NEIGHBORHOOD"); // HK address
  const state = getComponent(remainingComponents, "ADMINISTRATIVE_AREA_LEVEL_1");
  const stateAbbrev = getComponent(remainingComponents, "ADMINISTRATIVE_AREA_LEVEL_1", true);
  const zip = getComponent(remainingComponents, "POSTAL_CODE");

  return { address1, address2, city, state, stateAbbrev, zip, country, countryFullName };
};

export interface UsePlacesApi {
  getAddressComponents: typeof getAddressComponents;
  isEnabled: boolean;
  isLoading: boolean;
  setIsEnabled: Dispatch<boolean>;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
  autoCompleteService?: google.maps.places.AutocompleteService;
  placesService?: google.maps.places.PlacesService;
}

export const usePlacesApi = () => {
  const [placesApi, setPlacesApi] = useState<typeof google.maps.places>(undefined);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isFFActive = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_GOOGLE_ADDRESS_AUTOCOMPLETE);

  useEffect(() => {
    // Don't bother mounting the script tag if our feature flag is disabled
    if (!isFFActive) {
      setIsLoading(false);
      return;
    }

    // If we already have the script loaded, we don't need to do anything
    if (placesApi) {
      setIsLoading(false);
      return;
    }

    // handle async callback
    window.GooglePlacesLoaded = () => {
      setPlacesApi(window.google.maps.places);
      setIsEnabled(true);
      setIsLoading(false);
    };
    // Check if the script already exists in the window (probably from a previous mount)
    if (window.google) {
      window.GooglePlacesLoaded();
      return;
    }

    const scriptJs = document.createElement("script");
    scriptJs.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&loading=async&callback=GooglePlacesLoaded`;
    scriptJs.async = true;

    scriptJs.onerror = () => setIsLoading(false);

    // Add the script to the document
    document.body && document.body.appendChild(scriptJs);
  }, [isFFActive, placesApi]);

  const value = useMemo(() => {
    const _values: UsePlacesApi = {
      getAddressComponents,
      isEnabled,
      isLoading,
      setIsEnabled,
      sessionToken: isEnabled ? new placesApi.AutocompleteSessionToken() : undefined,
      autoCompleteService: isEnabled ? new placesApi.AutocompleteService() : undefined,
      placesService: isEnabled ? new placesApi.PlacesService(document.createElement("div")) : undefined,
    };
    return _values;
  }, [isEnabled, placesApi, isLoading]);

  return value;
};
