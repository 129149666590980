import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { TemplateProps } from "@gemini-ui/constants/templateProps";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { CurrencyOrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { TransferPanelProps } from "@gemini-ui/pages/transfers/constants";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export const getCopy = (intl: IntlShape) => {
  return {
    from: intl.formatMessage({
      defaultMessage: "From",
      description: "Label for selecting or viewing the source account for making transfers",
    }),
    to: intl.formatMessage({
      defaultMessage: "To",
      description: "Label for selecting or viewing the destination account for making transfers",
    }),
    asset: intl.formatMessage({
      defaultMessage: "Asset",
    }),
    amount: intl.formatMessage({
      defaultMessage: "Amount",
    }),
    amountToConvert: intl.formatMessage({
      defaultMessage: "Amount to convert",
    }),
    makeDeposit: intl.formatMessage({
      defaultMessage: "Make a deposit",
    }),
    continue: intl.formatMessage({
      defaultMessage: "Continue",
    }),
    transferTitle: intl.formatMessage({
      defaultMessage: "Transfer between accounts",
    }),
    confirmTitle: intl.formatMessage({
      defaultMessage: "Confirm transfer",
    }),
    receiveAs: intl.formatMessage({
      defaultMessage: "Receive as",
    }),
    hasBeenTransferred: intl.formatMessage({
      defaultMessage: "has been transferred",
    }),
    fundsAvailable: intl.formatMessage({
      defaultMessage: "Funds are now available to use in your",
    }),
    done: intl.formatMessage({
      defaultMessage: "Done",
    }),
    cancel: intl.formatMessage({
      defaultMessage: "Cancel",
    }),
    availableFundsMsg: intl.formatMessage({
      defaultMessage: "available",
    }),
    insufficientFunds: intl.formatMessage({
      defaultMessage: "Insufficient Funds",
    }),
    fee: intl.formatMessage({
      defaultMessage: "Fee",
    }),
    total: intl.formatMessage({
      defaultMessage: "Total",
    }),
    convertFundsMsg: (from: CurrencyShortName, to: CurrencyShortName) =>
      intl.formatMessage(
        defineMessage({ defaultMessage: "Your {from} transfer will be converted into {to} at 1:1 with zero cost." }),
        { from, to }
      ),
    derivativeFundingTransferOnly: intl.formatMessage({
      defaultMessage: "This asset will not contribute to your ability to trade derivatives.",
    }),
    derivativeFundingConversion: intl.formatMessage({
      defaultMessage:
        "Any USD or USDC transfers to a derivatives account will be converted into GUSD at 1:1 with zero cost.",
    }),
    derivativeWithdrawalConversion: intl.formatMessage({
      defaultMessage:
        "Any GUSD transfers from a derivatives account can be converted into USD or USDC at 1:1 with zero cost.",
    }),
    defaultError: intl.formatMessage({
      defaultMessage:
        "Something went wrong, please try again. If this issue persists please contact Gemini Customer Support.",
    }),
  };
};

export interface TransferPageProps {
  pageProps: TransferPanelProps;
  templateProps: TemplateProps;
}

export type TransferFormType = {
  to: CurrencyShortName;
  from: CurrencyShortName;
  amount: number | string;
  sourceAccount: Subaccount;
  destinationAccount: Subaccount;
  quote: CurrencyOrderQuote | void;
};

export class DerivativeFundingPreCheckError extends Error {}
