import { ReactNode } from "react";
import AlertProvider from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { HubbleProvider } from "@gemini-ui/design-system";
import { ToasterProvider } from "@gemini-ui/design-system/Toaster";
// eslint-disable-next-line no-restricted-imports
import { IntlProvider, LangCodes, useIntl } from "@gemini-ui/utils/intl";
import { getLocalizedHubbleStrings } from "@gemini-ui/utils/localizedHubbleStrings";

// Global styles that we can hopefully continue to trim down
import "@gemini-ui/styles/main.scss";
type AppProps = {
  userLocale: LangCodes;
  children: ReactNode;
};

const LocalizedApp = ({ children }) => {
  const { intl } = useIntl();

  return (
    <HubbleProvider locale={getLocalizedHubbleStrings(intl)} scheme={window.SCHEME || "light"}>
      <ToasterProvider>
        <AlertProvider>{children}</AlertProvider>
      </ToasterProvider>
    </HubbleProvider>
  );
};

const App = ({ userLocale, children }: AppProps) => (
  <IntlProvider userLocale={userLocale}>
    <LocalizedApp>{children}</LocalizedApp>
  </IntlProvider>
);

const APP = App;
App.displayName = APP;

export default App;
