import { IntlShape } from "react-intl";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import {
  formatCountryString,
  LocationProps,
  WireTransferInfoAPIPayload,
} from "@gemini-ui/components/WireDepositDetails/constants";
import { FundInstructionItem } from "@gemini-ui/components/WireDepositDetails/InstructionsCard";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";

const getTransferMechanismText = (intl: IntlShape) => {
  return {
    FAST: intl.formatMessage({
      defaultMessage: "FAST",
      description: "Acronym for a payment system used in Singapore",
    }),
    BANK_TRANSFER_FASTER: intl.formatMessage({
      defaultMessage: "Bank transfer (Faster Payments)",
      description: "Type of payments used in the UK",
    }),
    BANK_TRANSFER_FASTER_CHAPS: intl.formatMessage({ defaultMessage: "Bank transfer (Faster Payments, CHAPS)" }),
    BANK_TRANSFER_SEPA: intl.formatMessage({ defaultMessage: "Bank transfer (SEPA)" }),
    SAME_DAY_WIRE: intl.formatMessage({ defaultMessage: "Same-day wire transfer" }),
    SWIFT_TRANSFER: intl.formatMessage({ defaultMessage: "SWIFT transfer" }),
    WIRE: intl.formatMessage({ defaultMessage: "Wire transfer" }),
    BANK_TRANSFER: intl.formatMessage({ defaultMessage: "Bank transfer" }),
  };
};

export function formatTransferMechanismText(
  currency: CurrencyShortNameFiat,
  isSGD: Boolean,
  geminiEntity: GeminiEntity,
  intl: IntlShape,
  isBankFrickUSDInternational: Boolean
): string {
  if (isSGD) return getTransferMechanismText(intl).FAST;
  if (currency === CURRENCIES_DETAIL.GBP.symbol) {
    if (geminiEntity === "GeminiPayments") {
      return getTransferMechanismText(intl).BANK_TRANSFER_FASTER;
    }
    return getTransferMechanismText(intl).BANK_TRANSFER_FASTER_CHAPS;
  } else if (currency === CURRENCIES_DETAIL.EUR.symbol) {
    return getTransferMechanismText(intl).BANK_TRANSFER_SEPA;
  } else if (currency === CURRENCIES_DETAIL.USD.symbol && isBankFrickUSDInternational) {
    return getTransferMechanismText(intl).SWIFT_TRANSFER;
  } else if (currency === CURRENCIES_DETAIL.USD.symbol) {
    return getTransferMechanismText(intl).SAME_DAY_WIRE;
  } else if (
    ([CURRENCIES_DETAIL.HKD.symbol, CURRENCIES_DETAIL.AUD.symbol, CURRENCIES_DETAIL.CAD.symbol] as const).includes(
      currency
    )
  ) {
    return getTransferMechanismText(intl).WIRE;
  }

  return getTransferMechanismText(intl).BANK_TRANSFER;
}

const getCurrencyConversionText = (intl: IntlShape) => {
  return {
    UNSUPPORTED: intl.formatMessage({
      defaultMessage:
        "Deposits in other currencies will be returned at your expense, and may take up to 10 business days to process.",
    }),
    SUPPORTED: intl.formatMessage({
      defaultMessage: "Deposits in other currencies will be automatically converted at market rates.",
    }),
  };
};

const currencyConversionMap = {
  AUD: false,
  CAD: false,
  CHF: true,
  COP: false,
  EUR: true,
  GBP: false,
  HKD: false,
  JPY: true,
  SGD: false,
  USD: false,
} as const;

const canConvertCurrency = (currency: CurrencyShortNameFiat) => {
  return Boolean(currencyConversionMap[currency]);
};

export const formatCurrencyWarningText = (currency: CurrencyShortNameFiat, intl: IntlShape) => {
  return canConvertCurrency(currency)
    ? getCurrencyConversionText(intl).SUPPORTED
    : getCurrencyConversionText(intl).UNSUPPORTED;
};

export const depositInformationToFundInstructionItems = (
  currency: CurrencyShortNameFiat,
  transferMechanismSupportedText: string,
  intl: IntlShape
) => {
  return [
    {
      title: intl.formatMessage({ defaultMessage: "Currency" }),
      values: [`${CURRENCIES_DETAIL[currency].name} (${currency})`],
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Transfer type",
        description: "Types of transfers eg. 'Same day wire', 'SEPA', 'Bank transfer (Faster Payments)'",
      }),
      values: [transferMechanismSupportedText],
    },
  ];
};

export const referenceToFundInstructionItems = (reference: string, intl: IntlShape): FundInstructionItem[] => {
  return [
    {
      title: intl.formatMessage({ defaultMessage: "Reference" }),
      values: [reference],
      copyValue: reference,
    },
  ];
};

export const intermediaryToFundInstructionItems = (
  intermediary: WireTransferInfoAPIPayload["internationalBankInfo"]["intermediary"],
  intl: IntlShape
): FundInstructionItem[] => {
  return [
    {
      title: intl.formatMessage({ defaultMessage: "Name" }),
      values: [intermediary.name],
      copyValue: intermediary.name,
    },
    {
      title: intl.formatMessage({ defaultMessage: "BIC/SWIFT" }),
      values: [intermediary.swift?.id],
      copyValue: intermediary.swift?.id,
    },
  ];
};

const getLocationValueAndCopyValue = (location: LocationProps) => {
  if (!Boolean(location)) return { values: [], copyValue: "" };
  const locationArray = [
    ...(location.address ? [location.address] : []),
    ...(location.city ? [location.city] : []),
    ...(location.state ? [location.state] : []),
    ...(location.zip ? [location.zip] : []),
  ];
  const locationArrayToDisplay = [
    ...locationArray,
    ...(location.country ? [formatCountryString(location.country)] : []),
  ];
  const locationArrayToCopy = [...locationArray, ...(location.country ? [location.country] : [])];
  return {
    values: locationArrayToDisplay,
    copyValue: locationArrayToCopy.join("\n"),
  };
};

export const receivingBankToFundInstructionItems = (
  receivingBank: WireTransferInfoAPIPayload["internationalBankInfo"]["receivingBank"],
  beneficiary: WireTransferInfoAPIPayload["internationalBankInfo"]["beneficiary"],
  intl: IntlShape
): FundInstructionItem[] => {
  const { values: locationValue, copyValue: locationCopyValue } = getLocationValueAndCopyValue(receivingBank.location);
  const routingNumber = receivingBank.routingNumber
    ? typeof receivingBank.routingNumber === "string"
      ? receivingBank.routingNumber
      : receivingBank.routingNumber.id
    : null;

  return [
    {
      title: intl.formatMessage({ defaultMessage: "Name" }),
      values: [receivingBank.name],
      copyValue: receivingBank.name,
    },
    {
      title: intl.formatMessage({ defaultMessage: "Address" }),
      values: locationValue,
      copyValue: locationCopyValue,
    },
    ...(receivingBank.swift
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "BIC/SWIFT" }),
            values: [receivingBank.swift.id],
            copyValue: receivingBank.swift.id,
          },
        ]
      : []),
    ...(beneficiary.sortCode
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "Sort code" }),
            values: [beneficiary.sortCode],
            copyValue: beneficiary.sortCode,
          },
        ]
      : []),
    ...(routingNumber
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "Routing number" }),
            values: [routingNumber],
            copyValue: routingNumber,
          },
        ]
      : []),
  ];
};

export const beneficiaryToFundInstructionItems = (
  beneficiary: WireTransferInfoAPIPayload["internationalBankInfo"]["beneficiary"],
  isSGDCurrency: boolean,
  intl: IntlShape
): FundInstructionItem[] => {
  const { values: locationValue, copyValue: locationCopyValue } = getLocationValueAndCopyValue(beneficiary.location);
  return [
    {
      title: intl.formatMessage({ defaultMessage: "Name" }),
      values: [beneficiary.name],
      copyValue: beneficiary.name,
    },
    ...(beneficiary.bankName
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "Bank Name" }),
            values: [beneficiary.bankName],
            copyValue: beneficiary.bankName,
          },
        ]
      : []),
    ...(beneficiary.location
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "Address" }),
            values: locationValue,
            copyValue: locationCopyValue,
          },
        ]
      : []),
    ...(!(isSGDCurrency && beneficiary?.parsedIBAN)
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "Account Number" }),
            values: [beneficiary.accountNumber],
            copyValue: beneficiary.accountNumber,
          },
        ]
      : []),
    ...(beneficiary.parsedIBAN
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "IBAN" }),
            values: [beneficiary.parsedIBAN?.iban],
            copyValue: beneficiary.parsedIBAN?.iban,
          },
        ]
      : []),
  ];
};

export const domesticReceivingBankToFundInstructionItems = (
  receivingBank: WireTransferInfoAPIPayload["domesticBankInfo"]["receivingBank"],
  routingNumber: string,
  intl: IntlShape
): FundInstructionItem[] => {
  const { values: locationValue, copyValue: locationCopyValue } = getLocationValueAndCopyValue(receivingBank.location);

  return [
    {
      title: intl.formatMessage({ defaultMessage: "Name" }),
      values: [receivingBank.name],
      copyValue: receivingBank.name,
    },
    {
      title: intl.formatMessage({ defaultMessage: "Address" }),
      values: locationValue,
      copyValue: locationCopyValue,
    },
    {
      title: intl.formatMessage({ defaultMessage: "Routing number" }),
      values: [routingNumber],
      copyValue: routingNumber,
    },
    ...(receivingBank.swift
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "BIC/SWIFT" }),
            values: [receivingBank.swift.id],
            copyValue: receivingBank.swift.id,
          },
        ]
      : []),
  ];
};

export const domesticBeneficiaryToFundInstructionItems = (
  beneficiary: WireTransferInfoAPIPayload["internationalBankInfo"]["beneficiary"],
  intl: IntlShape
): FundInstructionItem[] => {
  const { values: locationValue, copyValue: locationCopyValue } = getLocationValueAndCopyValue(beneficiary.location);
  return [
    {
      title: intl.formatMessage({ defaultMessage: "Name" }),
      values: [beneficiary.name],
      copyValue: beneficiary.name,
    },
    ...(beneficiary.location
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "Address" }),
            values: locationValue,
            copyValue: locationCopyValue,
          },
        ]
      : []),
    ...(beneficiary.accountNumber
      ? [
          {
            title: intl.formatMessage({ defaultMessage: "Account Number" }),
            values: [beneficiary.accountNumber],
            copyValue: beneficiary.accountNumber,
          },
        ]
      : []),
  ];
};
