import { IconBankFilled, IconCardOutlined, IconSpendingTransfers } from "@hubble/icons";
import { ColumnDef } from "@tanstack/react-table";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { PayPalIcon } from "@gemini-ui/components/Icons/PaymentMethod/PaypalIcon";
import { Money } from "@gemini-ui/components/Money";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { Flex, IconBadge, Text } from "@gemini-ui/design-system";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import {
  DEPOSIT,
  getTransferLimitAndTimingModalConstants,
  TRADE,
  WITHDRAW,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/transferLimitAndTiming/constants";
import { getPayPalPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/utils";
import { getIsGeminiSG } from "@gemini-ui/pages/transfers/utils";
import { DepositLimits, TransferLimits, WithdrawalLimits } from "@gemini-ui/services/transfer/types";
import { IntlShape } from "@gemini-ui/utils/intl";

const ICON_BY_TRANSFER_TYPE = {
  [LinkPaymentType.PLAID_OR_GIACT]: <IconBadge icon={<IconBankFilled />} />,
  [LinkPaymentType.PLAID]: <IconBadge icon={<IconBankFilled />} />,
  [LinkPaymentType.PAYPAL]: <PayPalIcon size="md" />,
  [LinkPaymentType.DEBIT]: <IconBadge icon={<IconCardOutlined />} />,
  [LinkPaymentType.WIRE]: <IconBadge icon={<IconSpendingTransfers />} />,
  [LinkPaymentType.XFERS]: <IconBadge icon={<IconSpendingTransfers />} />,
};

const getTransferTypeLabelsById = (intl: IntlShape) => ({
  [LinkPaymentType.PLAID_OR_GIACT]: intl.formatMessage({ defaultMessage: "Bank account" }),
  [LinkPaymentType.PLAID]: intl.formatMessage({ defaultMessage: "Bank account" }),
  [LinkPaymentType.PAYPAL]: intl.formatMessage({ defaultMessage: "PayPal" }),
  [LinkPaymentType.DEBIT]: intl.formatMessage({ defaultMessage: "Debit card" }),
  [LinkPaymentType.WIRE]: intl.formatMessage({ defaultMessage: "Wire transfer" }),
  [LinkPaymentType.XFERS]: intl.formatMessage({ defaultMessage: "FAST transfer" }),
});

export interface TransferLimitAndTimingsTableDataTypes {
  transferType: string;
  transferTime: string;
  dailyLimit?: string;
}

export const getColumns = (intl: IntlShape): ColumnDef<TransferLimitAndTimingsTableDataTypes>[] => [
  {
    id: "transferType",
    accessorFn: row => row.transferType,
    header: "",
    cell: ({ row }) => (
      <Flex data-testid={row.original.transferType} align="center">
        {ICON_BY_TRANSFER_TYPE[row.original.transferType]}
        <Text.Body size="sm" bold ml={0.5}>
          {getTransferTypeLabelsById(intl)[row.original.transferType]}
        </Text.Body>
      </Flex>
    ),
    size: 180,
    enableSorting: false,
  },
  {
    id: "transferTime",
    accessorFn: row => row.transferTime,
    header: intl.formatMessage({ defaultMessage: "Transfer time" }),
    cell: ({ row }) => <Text.Body size="sm">{row.original.transferTime}</Text.Body>,
    enableSorting: false,
  },
  {
    id: "dailyLimit",
    header: intl.formatMessage({ defaultMessage: "Limit/Day" }),
    accessorFn: row => row.dailyLimit,
    cell: ({ row }) => (
      <Flex align="center" width="100%" justifyContent="center">
        <Text.Body size="sm">{row.original.dailyLimit}</Text.Body>
      </Flex>
    ),
    enableSorting: false,
  },
];

export const getTransferLimitAndTimingLabelsByTabId = (intl: IntlShape) => ({
  [DEPOSIT]: intl.formatMessage({ defaultMessage: "Deposit" }),
  [WITHDRAW]: intl.formatMessage({ defaultMessage: "Withdraw" }),
  [TRADE]: intl.formatMessage({ defaultMessage: "Trade" }),
});

export const getTabDataById = (
  depositLimits: DepositLimits,
  withdrawalLimits: WithdrawalLimits,
  currency: CurrencyShortNameFiat,
  countryCode: CountryAbbreviation,
  geminiEntity: GeminiEntity,
  intl: IntlShape
) => {
  const isSingaporeUser = getIsGeminiSG(geminiEntity);
  const isUKUser =
    countryCode === "gb" && [CURRENCIES_DETAIL.GBP.symbol, CURRENCIES_DETAIL.EUR.symbol].includes(currency);
  const isUSUser = countryCode === "us";
  const isPayPalEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.PAYPAL_ENABLED);

  const getAchOrPpiPaymentType = () => {
    if (isUKUser) return LinkPaymentType.PLAID;
    if (isUSUser) return LinkPaymentType.PLAID_OR_GIACT;
  };

  const getLimitsRow = (paymentType: LinkPaymentType, limits: TransferLimits, currency: CurrencyShortNameFiat) => {
    switch (paymentType) {
      case LinkPaymentType.PLAID_OR_GIACT:
        return {
          transferType: paymentType,
          transferTime: getTransferLimitAndTimingModalConstants(intl).achAndPaypalTransferTiming,
          dailyLimit: limits?.ach?.daily?.total?.value ? (
            <Money value={limits.ach.daily.total.value} currency={currency} hideTrailingSign />
          ) : (
            "-"
          ),
        };
      case LinkPaymentType.PLAID:
        return {
          transferType: paymentType,
          transferTime: getTransferLimitAndTimingModalConstants(intl).achAndPaypalTransferTiming,
          dailyLimit: limits?.ppi?.daily?.total?.value ? (
            <Money value={limits.ppi.daily.total.value} currency={currency} hideTrailingSign />
          ) : (
            "-"
          ),
        };
      case LinkPaymentType.PAYPAL:
        return {
          transferType: paymentType,
          transferTime: getTransferLimitAndTimingModalConstants(intl).achAndPaypalTransferTiming,
          dailyLimit: limits?.payPal?.daily?.total?.value ? (
            <Money value={limits.payPal.daily.total.value} currency={currency} hideTrailingSign />
          ) : (
            "-"
          ),
        };
      case LinkPaymentType.DEBIT:
        return {
          transferType: paymentType,
          transferTime: getTransferLimitAndTimingModalConstants(intl).debitTransferTiming,
          dailyLimit: limits?.card?.daily?.total?.value ? (
            <Money value={limits.card.daily.total.value} currency={currency} />
          ) : (
            "-"
          ),
        };
      case LinkPaymentType.WIRE:
        return isSingaporeUser
          ? {
              // TODO: This shouldn't be XFERS, it should be RTP
              transferType: LinkPaymentType.XFERS,
              transferTime: getTransferLimitAndTimingModalConstants(intl).xfersTransferTiming,
              dailyLimit: limits?.rtp?.maximum ? <Money value={limits.rtp.maximum.value} currency={currency} /> : "-",
            }
          : {
              transferType: paymentType,
              transferTime: getTransferLimitAndTimingModalConstants(intl).wireTransferTiming,
              dailyLimit: getTransferLimitAndTimingModalConstants(intl).noLimit,
            };
    }
  };

  const buildPaymentTypes = (
    deposit: boolean,
    payPalAllowed: boolean,
    extraTypes: LinkPaymentType[]
  ): LinkPaymentType[] => {
    const achOrPpi = getAchOrPpiPaymentType();
    const baseTypes = deposit ? [achOrPpi, LinkPaymentType.WIRE] : [achOrPpi, LinkPaymentType.WIRE];
    return payPalAllowed ? [...baseTypes, ...extraTypes] : [...baseTypes];
  };

  if (!depositLimits || !withdrawalLimits) {
    return { [DEPOSIT]: [], [WITHDRAW]: [], [TRADE]: [] };
  }

  const DEPOSIT_PAYMENT_TYPES = buildPaymentTypes(
    true,
    isPayPalEnabled,
    getPayPalPaymentType(geminiEntity, currency, countryCode)
  );
  const WITHDRAW_PAYMENT_TYPES = buildPaymentTypes(false, false, []);
  const TRADE_PAYMENT_TYPES = [
    getAchOrPpiPaymentType(),
    LinkPaymentType.DEBIT,
    ...(isPayPalEnabled ? getPayPalPaymentType(geminiEntity, currency, countryCode) : []),
  ];

  const getTableData = (limits: TransferLimits, paymentTypes: LinkPaymentType[]) =>
    paymentTypes.map(type => type && getLimitsRow(type, limits, currency)).filter(Boolean);

  return {
    [DEPOSIT]: getTableData(depositLimits as TransferLimits, DEPOSIT_PAYMENT_TYPES),
    [WITHDRAW]: getTableData(withdrawalLimits as TransferLimits, WITHDRAW_PAYMENT_TYPES),
    [TRADE]: getTableData(depositLimits as TransferLimits, TRADE_PAYMENT_TYPES),
  };
};
