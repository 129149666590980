import styled from "@emotion/styled";
import { Button } from "@gemini-ui/design-system";
import TertiaryButton from "@gemini-ui/design-system/Button/TertiaryButton";
import { mediaQuery, Spacing } from "@gemini-ui/design-system/primitives";

export const NavMenuContainer = styled("div")`
  padding: ${Spacing.scale[3]} ${Spacing.scale[1.5]} 0 ${Spacing.scale[4]};

  @media (${mediaQuery.tabletSmDown}) {
    padding: 0;
  }
`;

export const NavButton = styled(TertiaryButton)<{ active: boolean }>`
  justify-content: flex-start;
  color: ${props => props.active && props.theme.colorScheme.action.content.tertiary.neutral.active};
  background: ${props => props.active && props.theme.colorScheme.action.background.tertiary.neutral.active};
  text-align: left;
`;

export const LayoutHeader = styled("div")<{ hasMessage?: boolean }>`
  padding-bottom: ${({ hasMessage }) => (hasMessage ? Spacing.scale[2] : Spacing.scale[6])};

  @media (${mediaQuery.tabletSmDown}) {
    display: none;
  }
`;

export const StyledButtonGroup = styled(Button.Group)`
  @media (${mediaQuery.tabletSmDown}) {
    margin-top: 0;
  }

  @media (${mediaQuery.mobileXsDown}) {
    -webkit-mask-image: none;
    mask-image: none;
    padding-top: ${Spacing.scale[2]};
  }
`;
