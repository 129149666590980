import { CREDIT_APP_LOGIN, XStateSend } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/constants";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/machines/creditAppMachine";
import { CreditError } from "@gemini-ui/pages/Credit/utils/errorCodes";
import { AxiosError } from "@gemini-ui/services/axios";

export const handleCreditExceptions = ({
  e,
  send,
}: {
  e: AxiosError<{ code: CreditError }>;
  send: XStateSend;
}): any => {
  const res = e.response;

  if (res) {
    const status = res.status;
    const code = res.data.code;

    if (code === CreditError.CBNotInWaitlistQueue) {
      return send({ type: ClientState.NOT_IN_WAITLIST_QUEUE });
    }

    if (code === CreditError.CBReappliedTooSoon) {
      return send({ type: ClientState.RECENT_DENIED });
    }

    if (code === CreditError.MaxDailyApplicationLimit) {
      return send({ type: ClientState.MAX_APPLICATION_LIMIT });
    }

    if (status === 409 && code === CreditError.TUserAlreadyExists) {
      window.location.pathname = CREDIT_APP_LOGIN;
    }
  }
};
