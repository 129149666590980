// eslint-disable-next-line no-restricted-imports
import { AxiosError, AxiosInstance } from "axios";

// sourced from: https://github.com/axios/axios/issues/164#issuecomment-327837467
export function retryInterceptor(this: AxiosInstance, error: AxiosError) {
  const config = error.config;
  // If config does not exist or the retry option is not set, or we are in jest tests, reject
  if (!config || !config.retry || process.env.NODE_ENV === "test") return Promise.reject(error);

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(error);
  }

  // Increase the retry count
  config.__retryCount += 1;

  const backOffDelay = Boolean(config.retry) ? (1 / 2) * (Math.pow(2, config.__retryCount) - 1) * 500 : 1;

  // Create new promise to handle exponential backoff
  const backoff = new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, backOffDelay);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(() => {
    return this(config);
  });
}
