import { usePageData } from "@gemini-ui/contexts";
import { useAccountTotalPortfolio } from "@gemini-ui/services/account/total";
import { PaymentMethodEligibility, usePaymentData } from "@gemini-ui/services/transfer/paymentMethods";

/**
 * Whether the user should be prompted to upgrade from Basic Plus to fully verified.
 * Conditions:
 * 1. User is on Basic Plus tier.
 * 2. User is not fully verified.
 * 3. User is not in a verification pending state.
 * 4. User has at least one payment method or a balance greater than 0.
 */
export const useShouldPromptBasicPlusUpgrade = () => {
  const {
    templateProps: {
      user,
      account: { defaultFiat },
    },
  } = usePageData();
  const { data: totalPortfolio } = useAccountTotalPortfolio();

  const { isBasicPlusTier, isFullyVerified, userLevel } = user;

  const isVerificationPending = userLevel === "VerificationPendingFromBasicPlus";

  const paymentMethodData = usePaymentData(defaultFiat, PaymentMethodEligibility.ALL, false);

  const hasPaymentMethod = paymentMethodData.paymentMethods?.length > 0;

  const hasBalance = Number(totalPortfolio?.totals?.totalNotional?.balance?.value ?? 0) > 0;

  return isBasicPlusTier && !isFullyVerified && !isVerificationPending && (hasPaymentMethod || hasBalance);
};
