import { useEffect } from "react";
import { optimizelyClient } from "@gemini-ui/analytics";
import { isProdUrl } from "@gemini-ui/analytics/index";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import useScript from "@gemini-ui/utils/useScript";

export enum APP_FLOW {
  PrimaryUser,
  AuthorizedUser,
}

const APP_FLOW_ID = {
  [APP_FLOW.PrimaryUser]: "trims400",
  [APP_FLOW.AuthorizedUser]: "quill457",
};

const closeNidSession = () => {
  if (window.nid) window.nid("closeSession");
};

export const useNeuroIdCollector = (appFlow: APP_FLOW) => {
  const isNeuroIdEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_NEURO_ID_CREDIT_CARD);
  const NEURO_ID_SCRIPT = `//scripts.neuro-id.com/c/nid-${APP_FLOW_ID[appFlow]}${!isProdUrl() ? "-test" : ""}.js`;

  useScript(NEURO_ID_SCRIPT, { shouldLoad: isNeuroIdEnabled });

  useEffect(() => {
    if (isNeuroIdEnabled) {
      window.addEventListener("beforeunload", closeNidSession);
      return () => {
        window.removeEventListener("beforeunload", closeNidSession);
      };
    }
  }, [isNeuroIdEnabled]);
};
