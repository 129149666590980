import { Fragment, ReactNode, useState } from "react";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import { Controller } from "react-hook-form";
import { AuthCodeInput } from "@gemini-ui/components/AuthCodeInput";
import { CurrentMethodState } from "@gemini-ui/components/TwoFactorAuth/types";
import { Button, Checkbox, Flex, Form, SectionMessage, Text, useForm, useToaster } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/register/testIds";
import axios from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
import { useStaticCountdown } from "@gemini-ui/utils/useStaticCountdown";
const SMS_TIMEOUT = 30;
type AuthForm = {
  token: string;
  remember: boolean;
};

type FallbackAuthViewProps = {
  type: CurrentMethodState;
  rememberDuration: string;
  sudo: boolean;
  email: string;
  redirect: string;
  onSuccess: (data: { redirect: string }) => void;
};

export const FallbackAuthView = ({
  sudo,
  rememberDuration,
  type,
  email,
  onSuccess,
  redirect,
}: FallbackAuthViewProps): JSX.Element => {
  const { intl } = useIntl();
  // due to the nature of the auth input auto clearing on a failure,
  // use state outside of form to make sure its persisted
  const [serverError, setServerError] = useState("");
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AuthForm>({ defaultValues: { token: "", remember: true } });

  const isSMS = type === CurrentMethodState.TextMessage;

  const { seconds, restartCountdown } = useStaticCountdown(SMS_TIMEOUT, isSMS);
  const { colorScheme } = useTheme();
  const { showToast } = useToaster();
  const rememberField = register("remember");

  const handleFormSubmit = (values: AuthForm) => {
    const authyUrl = jsRoutes.com.gemini.web.server.auth.controllers.AuthyController.post(
      redirect,
      sudo,
      email,
      isSMS
    ).url;

    const { token, remember } = values;
    // react-hook-form requires returning a promise to manage form states
    return axios
      .post(authyUrl, { token, remember })
      .then(resp => {
        onSuccess(resp.data);
      })
      .catch(error => {
        const errorMessage = getError(error);
        setServerError(errorMessage);
        // suppress form errors
        if (error.response?.status !== 422) {
          Sentry.captureException(error);
        }
      });
  };

  const handleResendToken = () => {
    const url = jsRoutes.com.gemini.web.server.auth.controllers.AuthyController.resendToken(email).url;
    restartCountdown();
    axios
      .post(url)
      .then(_ => {
        showToast({ message: intl.formatMessage({ defaultMessage: "New code has been sent." }) });
      })
      .catch(error => {
        const errorMessage = getError(error);
        setServerError(errorMessage);
        Sentry.captureException(error);
      });
  };

  return (
    <Form>
      {serverError && (
        <SectionMessage
          heading={intl.formatMessage({ defaultMessage: "Oops! There was an error" })}
          mt={2}
          mb={0}
          statusType="alert"
          data-testid={testIds.input.authCodeError}
        >
          {serverError}
        </SectionMessage>
      )}

      <Controller
        name="token"
        control={control}
        render={({ field }) => (
          <AuthCodeInput
            ariaLabel={intl.formatMessage({ defaultMessage: "Input Token" })}
            onChange={value => {
              if (value.length > 0) {
                // clear previous error as user starts typing again
                setServerError("");
              }
              field.onChange(value);
              // Auto submit form when valid
              if (value.length === 7) {
                handleSubmit(handleFormSubmit)();
              }
            }}
            length={7}
            disabled={isSubmitting}
            loading={isSubmitting}
            clear={Boolean(serverError)}
            error={Boolean(serverError)}
            noMarginTop={Boolean(serverError)}
          />
        )}
      />
      {!sudo && (
        <div>
          <Checkbox inputSize="sm" {...rememberField}>
            {intl.formatMessage(
              defineMessage({
                defaultMessage: "Remember this device for {rememberDuration}",
              }),
              {
                rememberDuration,
              }
            )}
          </Checkbox>
        </div>
      )}

      {isSMS && (
        <Fragment>
          {seconds > 0 ? (
            <Flex.Column>
              <Text.Body bold size="sm">
                {intl.formatMessage({
                  defaultMessage: "Didn’t get it?",
                })}
              </Text.Body>

              <Text.Body size="sm" color={colorScheme.content.secondary} numeric>
                {intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "You can request a new code in <span>{seconds}</span> {seconds, plural,=0 {} one {second} other {seconds}}.",
                  }),
                  {
                    seconds,
                    span: (v: ReactNode) => (
                      <Text.Body size="sm" as="span" bold>
                        {v}
                      </Text.Body>
                    ),
                  }
                )}
              </Text.Body>
            </Flex.Column>
          ) : (
            <Flex>
              <Button.Secondary
                data-testid={testIds.button.sendSms}
                onClick={handleResendToken}
                cta={intl.formatMessage({ defaultMessage: "Request a new code" })}
              />
            </Flex>
          )}
        </Fragment>
      )}
    </Form>
  );
};
