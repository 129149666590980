import {
  CurrencyPairDetail,
  CurrencyShortNameFiat,
  CurrencyShortNameForCustody,
} from "@gemini-common/scripts/constants/currencies";
import { SupportedForWrap } from "@gemini-ui/components/WrapAssetModal/constants";
import { CurrencyBalances, CurrencyRiskBalances } from "@gemini-ui/constants/balances";

export interface Subaccount {
  name: string;
  shortName: string;
  hashid: string;
  trading: boolean;
  coldStorage: boolean;
  isMpcCustody?: boolean;
  createdAt?: number;
  derivatives?: boolean;
  balances?: CurrencyBalances;
  isMpcAccount?: boolean;
  riskBalances?: CurrencyRiskBalances;
  crossCollateralParams?: CollateralParams;
}

export type Subaccounts = Subaccount[];

export interface CollateralParams {
  enableCrossCollateral: string;
  enableNegativeBalances: string;
  maxLeverage: string;
}

// maps to the ids of src/main/scala/com/gemini/accounts/models/GeminiEntity.scala
export const GeminiEntities = {
  GeminiTrust: "GeminiTrust",
  GeminiColombia: "GeminiColombia",
  GeminiDigitalAssetsSAS: "GeminiDigitalAssetsSAS",
  GeminiEurope: "GeminiEurope",
  GeminiEuropeEmployees: "GeminiEuropeEmployees",
  GeminiEuropeInstitutional: "GeminiEuropeInstitutional",
  GeminiIreland: "GeminiIreland",
  GeminiIntergalacticCanada: "GeminiIntergalacticCanada",
  GeminiIrelandEmployees: "GeminiIrelandEmployees",
  GeminiPayments: "GeminiPayments",
  GeminiSingapore: "GeminiSingapore",
  GeminiTrustEmployees: "GeminiTrustEmployees",
  GeminiGalacticMarkets: "GeminiGalacticMarkets",
  GeminiVoyager: "GeminiVoyager",
  GeminiDefault: "GeminiDefault",
  GeminiMoonbase: "GeminiMoonbase",
  GeminiIntergalacticBermuda: "GeminiIntergalacticBermuda",
  GeminiArtemisDigitalPayments: "GeminiArtemisDigitalPayments",
  GeminiIntergalacticEU: "GeminiIntergalacticEU",
} as const;

export type GeminiEntity = keyof typeof GeminiEntities;

export interface GeminiAccount {
  defaultFiat: CurrencyShortNameFiat;
  supportedFiat: CurrencyShortNameFiat[];
  supportedCrypto: CurrencyShortNameForCustody[];
  supportedExchangeCrypto: CurrencyShortNameForCustody[];
  supportedPairs: CurrencyPairDetail[];
  supportedForWrap: SupportedForWrap;
  supportedForUnwrap: SupportedForWrap;
  geminiEntity: GeminiEntity;
  achAdvanceStatus: AccountAdvanceType;
  hasDepositsAwaitingAttestation?: boolean;
  hasUkDepositsAwaitingAttestation?: boolean;
  hasEuDepositsAwaitingAttestation?: boolean;
  geminiEntityName?: string;
}

export enum AccountAdvanceType {
  ADVANCE = "ADVANCE",
  NON_ADVANCE = "NON_ADVANCE",
}
