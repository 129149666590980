import { IconClose } from "@hubble/icons";
import Cookies from "js-cookie";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { CUSTODY_ONLY_PAGES, EXCHANGE_ONLY_PAGES } from "@gemini-ui/components/Header/constants";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { Button, Flex, HubbleProvider, Text } from "@gemini-ui/design-system";
import axios from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";
import storage from "@gemini-ui/utils/storage";

export const NAV_HEIGHT = 52;

export const isSwitcherEligiblePage = (pageName: PageName) => {
  const noSwitcherPageNames = [PageName.SendGift];

  return !noSwitcherPageNames.some(name => name === pageName);
};

export const handleChangeFiatSubmit = (currency, showAlert) => event => {
  event.preventDefault();

  /* istanbul ignore next */
  const changeDefaultFiatRoute =
    jsRoutes.com.gemini.web.server.experience.controllers.AccountCurrencySettingsController.upsertCurrency(
      currency
    ).url;

  axios
    .post(changeDefaultFiatRoute, { currency: currency })
    .then(res => {
      // @ts-expect-error
      document.activeElement.blur();
      showAlert({
        type: AlertTypes.INFO,
        message: "Success! Your default currency has been changed.",
        timeout: 5000,
      });
      window.location.reload();
    })
    .catch(err => {
      showAlert({
        type: AlertTypes.ERROR,
        message: getError(err, "Please contact support for additional assistance changing your default currency."),
      });
    });
};

export const getSubaccountRedirect = (subaccount: Subaccount, pageName: PageName): string | void => {
  const isCustodyAccount = subaccount?.coldStorage;
  const isDerivatives = subaccount?.derivatives;

  if (isCustodyAccount) {
    // Exchange account only pages
    // If on a page that is for exchange accounts only, redirect to custody.
    // Or if on the Portfolio page
    if (EXCHANGE_ONLY_PAGES.includes(pageName)) return "/custody";
  } else if (isDerivatives) {
    return jsRoutes.com.gemini.web.server.trading.controllers.OrderControllerV2.get("BTCGUSDPERP").url;
  } else {
    // If on an exchange account, and on a page that is custody only, redirect to home
    if (CUSTODY_ONLY_PAGES.includes(pageName)) return "/";
  }
};

export const navigateToSubaccount = async ({
  hashid,
  handleError,
  subaccounts,
  pageName,
  refresh = true,
  redirect = true,
}: {
  hashid: string;
  handleError?: Function;
  subaccounts: Subaccount[];
  pageName: PageName;
  refresh?: boolean;
  redirect?: boolean;
}) => {
  /* istanbul ignore next */
  const url = jsRoutes.com.gemini.web.server.account.controllers.AccountSelectionController.switchAccount(hashid).url;
  const selectedAccount = subaccounts.find(acct => acct.hashid === hashid);
  const pageRedirect = redirect && getSubaccountRedirect(selectedAccount, pageName);

  try {
    await axios.post(url, {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Csrf-Token": "nocheck",
      },
    });

    storage.set("subaccountHashid", hashid);

    if (pageRedirect) {
      return window.location.assign(pageRedirect);
    }
    if (refresh) {
      window.location.reload();
    }
  } catch (error) {
    if (handleError) {
      handleError(error);
    }
    console.error(error);
  }
};

export const maybeRenderBuildOverride = (scheme: "light" | "dark") => {
  const build = Cookies.get("BuildOverride")?.split("=")?.[1];
  if (build) {
    return (
      <HubbleProvider scheme={scheme}>
        <Flex alignItems="center">
          <Button.Secondary
            size="sm"
            onClick={() => {
              Cookies.remove("BuildOverride", { path: "/", domain: window.location.hostname });
              window.location.reload();
            }}
          >
            <Text.Body mr={1} size="sm">
              {build}
            </Text.Body>
            <IconClose size="xs" />
          </Button.Secondary>
        </Flex>
      </HubbleProvider>
    );
  }
};
