import { defineMessage } from "@formatjs/intl";
import BigNumber from "bignumber.js";
import * as Yup from "yup";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import {
  CURRENCIES_DETAIL,
  CurrencyShortName,
  CurrencyShortNameFiat,
  isCurrency,
} from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { MoneyProps } from "@gemini-ui/components/Money";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { EarnEligibilityInfo, GrowAsset, GrowProviderType } from "@gemini-ui/constants/earn";
import { DepositModalView, SourceType, UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { GrowBuyContextValues, GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { checkStakingAssetProductOptions, getUnstakeProviderTypes } from "@gemini-ui/pages/Earn/utils";
import { BuyingFrequency, TradePaymentMethodType } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import {
  getEligibleBanks,
  getGeminiBalance,
  getVerifiedDebitCards,
  isAmountInRemainingLimit,
} from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { UsePaymentData } from "@gemini-ui/services/transfer/types";
import { RetailTradePaymentMethodType } from "@gemini-ui/transformers/PaymentMethods";
import { IntlShape } from "@gemini-ui/utils/intl";

export const QUOTE_LOADING_PLACEHOLDER_TEXT = "-";

// TODO: Temporary fallback solution. Remove these values and their uses when backend is ready.
// See: https://gemini-spaceship.atlassian.net/browse/GEM-44004
export const DEFAULT_EARN_PROVIDER_NAME = "Genesis";
export const DEFAULT_EARN_PROVIDER_FULL_NAME = "Genesis Global Capital, LLC";

export const calculateValue = ({ value, lastTradePrice }: { value: string; lastTradePrice: string }) => {
  if (!value || !lastTradePrice) return "0";

  return `${Number(value) * Number(lastTradePrice)}`;
};

export const getSource = (asset: GrowAsset, defaultFiat: CurrencyShortNameFiat): SourceType | null => {
  if (!asset) return null;

  const hasTradingBalance = asset.availableForEarningInterest.value !== "0";

  if (isCurrency.GUSD(asset.currency) && !isCurrency.USD(defaultFiat)) return null;

  if (hasTradingBalance) return SourceType.TRADING_BALANCE;

  return SourceType.BANK_OR_CARD;
};

export const schema = ({
  assetInfo,
  redeemableBalance,
  isRedeem,
  unstakeSource,
  maxValidatorCount,
  intl,
}: {
  assetInfo: GrowAsset;
  redeemableBalance?: MoneyProps;
  isRedeem?: boolean;
  unstakeSource?: UnstakeSourceType;
  maxValidatorCount?: number;
  intl: IntlShape;
}) => {
  if (unstakeSource === UnstakeSourceType.validatorBalance) {
    return Yup.object().shape({
      validatorCount: Yup.number()
        .required(
          intl.formatMessage({
            defaultMessage: "Number of validators required.",
            description: "Form error: user must input a validator amount",
          })
        )
        .moreThan(
          0,
          intl.formatMessage({
            defaultMessage: "Number of validators must be more than 0.",
            description: "Form error: user must input a validator amount greater than zero",
          })
        )
        .max(
          maxValidatorCount,
          intl.formatMessage(
            defineMessage({
              defaultMessage: "Number of validators must be below {maxValidatorCount}.",
              description: "Form error: user has entered an validator count over the maximum",
            }),
            {
              maxValidatorCount,
            }
          )
        )
        .integer(
          intl.formatMessage({
            defaultMessage: "Only whole validators can be unstaked.",
            description: "Form error: user has entered a fractional validator count",
          })
        ),
    });
  }

  if (isRedeem) {
    return Yup.object().shape({
      amount: Yup.number()
        .required(
          intl.formatMessage({
            defaultMessage: "Amount required.",
            description: "Form error: user must input a currency amount",
          })
        )
        .moreThan(
          0,
          intl.formatMessage({
            defaultMessage: "Amount must be more than 0.",
            description: "Form error: user must input a currency amount greater than zero",
          })
        )
        .max(
          Number(redeemableBalance?.value ?? "0"),
          intl.formatMessage({
            defaultMessage: "Max amount reached.",
            description: "Form error: user has entered an amount over the maximum",
          })
        ),
    });
  } else {
    return Yup.object().shape({
      source: Yup.string().required(
        intl.formatMessage({
          defaultMessage: "Source required.",
          description: "Form error: user must select a funding source",
        })
      ),
      amount: Yup.number()
        .required(
          intl.formatMessage({
            defaultMessage: "Amount required.",
            description: "Form error: user must input a currency amount",
          })
        )
        .moreThan(
          0,
          intl.formatMessage({
            defaultMessage: "Amount must be more than 0.",
            description: "Form error: user must input a currency amount greater than zero",
          })
        )
        .when("source", {
          is: SourceType.TRADING_BALANCE,
          then: Yup.number().max(
            Number(assetInfo?.availableForEarningInterest?.value),
            intl.formatMessage({
              defaultMessage: "Max amount reached.",
              description: "Form error: user has entered an amount over the maximum",
            })
          ),
        }),
    });
  }
};

export const getDefaultPaymentMethod = (
  paymentMethodData: UsePaymentData,
  defaultFiat: CurrencyShortNameFiat,
  internationalDebitCardEnabled: boolean,
  balances: CurrencyBalances,
  amount: string | number,
  frequency?: BuyingFrequency,
  recurringOrderViaDebitCardEnabled?: boolean
): TradePaymentMethodType | null => {
  const hasSufficientBalance = getGeminiBalance(balances, defaultFiat) >= Number(amount);

  if (hasSufficientBalance) {
    return AccountType.BALANCE;
  }

  if (!paymentMethodData || paymentMethodData.isLoading) {
    return null;
  }

  const hasSufficientAchLimit = isAmountInRemainingLimit(amount, paymentMethodData.limits.ach.daily?.available);
  const hasSufficientDebitLimit = isAmountInRemainingLimit(amount, paymentMethodData.limits.card.daily?.available);

  let paymentMethods: RetailTradePaymentMethodType[] = [];
  if (hasSufficientAchLimit) {
    paymentMethods = getEligibleBanks(paymentMethodData.paymentMethods, defaultFiat);
  }
  if (internationalDebitCardEnabled && hasSufficientDebitLimit) {
    if (!recurringOrderViaDebitCardEnabled && frequency !== BuyingFrequency.Once) {
      // exclude debit cards
    } else {
      paymentMethods = [...paymentMethods, ...getVerifiedDebitCards(paymentMethodData.paymentMethods, defaultFiat)];
    }
  }

  if (paymentMethods.length > 0) {
    return paymentMethods.sort((el1, el2) => {
      return el2.lastUsedAt - el1.lastUsedAt;
    })[0];
  }

  return null;
};

/* -------------------------------------------------------------------------- */

type LegalSummaryItem = {
  headline: string;
  summary: string;
};

export const getLegalSummaryItems = (countryCode: CountryAbbreviation, intl: IntlShape): LegalSummaryItem[] => {
  /**
   * This object maps a country code to the region's respective legal call-outs.
   * To extend it, add a new country code key to the object.
   * NOTE: There are currently only number icons 1 through 5 in the Hubble icon set.
   * If a region has more than 2 summary items, you may need to add more icons to the icon set.
   */
  const regionalSummaryItems: Partial<Record<CountryAbbreviation, LegalSummaryItem[]>> = {
    gb: [
      {
        headline: intl.formatMessage({ defaultMessage: "Earn may impact tax liability" }),
        summary: intl.formatMessage({
          defaultMessage:
            "Assets acquired through Earn may increase your income tax liability, as you will be gaining a yield from the funds. Transferring assets to Earn is considered a disposition of those assets which may lead to recognition of a capital gain or loss.",
        }),
      },
    ],
  };

  /**
   * This is a list of legal summary items that are displayed regardless of region.
   */
  const genericSummaryItems: LegalSummaryItem[] = [
    {
      headline: intl.formatMessage({ defaultMessage: "Your crypto is lent to rigorously vetted borrowers" }),
      summary: intl.formatMessage({
        defaultMessage:
          "By transferring crypto to Earn, you are originating a loan with our disclosed, rigorously vetted institutional borrowing partner(s).",
      }),
    },
    {
      headline: intl.formatMessage({ defaultMessage: "Gemini acts as the lending agent" }),
      summary: intl.formatMessage({
        defaultMessage:
          "Gemini facilitates the transfer of funds between you and our borrowing partner(s). Funds earning interest are not held with Gemini.",
      }),
    },
    {
      headline: intl.formatMessage({ defaultMessage: "You can request your funds back at any time" }),
      summary: intl.formatMessage({
        defaultMessage:
          "You do not commit to lend your funds for any minimum period of time. You can pull your principal and earned interest back at any time.",
      }),
    },
    {
      headline: intl.formatMessage({ defaultMessage: "Interest rates may vary" }),
      summary: intl.formatMessage({ defaultMessage: "We will notify you when interest rates change." }),
    },
  ];

  return [...(regionalSummaryItems[countryCode] ?? []), ...genericSummaryItems];
};

/* -------------------------------------------------------------------------- */

export const getMaxAccrualText = (accrueDelay: number, intl: IntlShape): string | null => {
  if (!accrueDelay) return null;
  return intl.formatMessage(
    defineMessage({
      defaultMessage: `{accrueDelay, select,
          0 {now}
          1 {within one day}
          2 {within two days}
          3 {within three days}
          4 {within four days}
          5 {within five days}
          6 {within six days}
          7 {within seven days}
          8 {within eight days}
          9 {within nine days}
          other {within {accrueDelay, number} days}}`,
    }),
    {
      accrueDelay,
    }
  );
};

export const GEMINI_STAKING_AGREEMENT_URL = "https://www.gemini.com/legal/staking-agreement";

/**
 * Instantiate the Stake / Unstake modal flow.
 *
 * TODO: This could use some cleanup. Props here are hook/context specific functions - they should not be confused with generic callbacks.
 *
 */
export const instantiateGrowStakeUnstakeModal = ({
  asset,
  eligibleForStaking,
  eligibleForPrivateStaking,
  direction,
  instantiateGrowTransaction,
}: Pick<EarnEligibilityInfo, "eligibleForStaking" | "eligibleForPrivateStaking"> &
  Pick<GrowBuyContextValues, "instantiateGrowTransaction"> & {
    asset: GrowAsset;
    direction?: GrowTransactionType;
  }) => {
  // Determine if the user can stake multiple provider types
  const { canStakeAssetMultipleProviderTypes, canStakeAssetPooled, canStakeAssetPrivate } =
    checkStakingAssetProductOptions(asset, { eligibleForStaking, eligibleForPrivateStaking });

  // Determine if the user can unstake multiple provider types
  const { canUnstakeAssetPooled, canUnstakeAssetPrivate, canUnstakeAssetMultipleProviderTypes } =
    getUnstakeProviderTypes(asset.interestProviders);

  instantiateGrowTransaction({
    currency: asset.currency,
    transactionType: direction,

    ...(direction === GrowTransactionType.STAKE &&
      !canStakeAssetMultipleProviderTypes && {
        ...(canStakeAssetPooled && { providerType: GrowProviderType.POOLED_STAKING }),
        ...(canStakeAssetPrivate && { providerType: GrowProviderType.PRIVATE_STAKING }),
      }),

    ...(direction === GrowTransactionType.UNSTAKE &&
      !canUnstakeAssetMultipleProviderTypes && {
        ...(canUnstakeAssetPooled && { providerType: GrowProviderType.POOLED_STAKING }),
        ...(canUnstakeAssetPrivate && { providerType: GrowProviderType.PRIVATE_STAKING }),
      }),

    isOpen: true,
    view:
      direction === GrowTransactionType.UNSTAKE
        ? RedeemStep.PlaceRedeem
        : canStakeAssetMultipleProviderTypes
        ? DepositModalView.PICK_ETH_STAKING_TYPE
        : DepositModalView.PLACE_DEPOSIT,
  });

  track(EVENTS.INSTANTIATE_STAKE_UNSTAKE_MODAL.name, {
    [EVENTS.INSTANTIATE_STAKE_UNSTAKE_MODAL.properties.CURRENCY]: asset.currency,
    [EVENTS.INSTANTIATE_STAKE_UNSTAKE_MODAL.properties.DIRECTION]: direction,
    [EVENTS.INSTANTIATE_STAKE_UNSTAKE_MODAL.properties.ELIGIBLE_FOR_STAKING]: eligibleForStaking,
    [EVENTS.INSTANTIATE_STAKE_UNSTAKE_MODAL.properties.ELIGIBLE_FOR_STAKING_PRO]: eligibleForPrivateStaking,
  });
};

export function balanceValueToCurrencyReceiptDecimals(value: string | number, currency: CurrencyShortName) {
  return new BigNumber(Number(value))
    .decimalPlaces(CURRENCIES_DETAIL[currency].receiptDecimals, BigNumber.ROUND_DOWN)
    .toString();
}
