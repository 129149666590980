import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { USStateAbbreviation } from "@gemini-ui/US_States";
import { IntlShape } from "@gemini-ui/utils/intl";

export type UserLocation = {
  country: CountryAbbreviation;
  state: USStateAbbreviation;
};

export enum ACCOUNT_TYPES {
  individual = "individual",
  business = "business",
}

export const PasswordValidationState = {
  PASS: "PASS",
  INVALID: "INVALID",
} as const;

export const getRegisterCopy = (intl: IntlShape) => {
  return {
    CREATE_ACCOUNT: intl.formatMessage({ defaultMessage: "Let’s get you started with a Gemini account" }),
    ENTER_NAME_DOCS: intl.formatMessage({
      defaultMessage: "Please enter your name as it appears on official documents.",
    }),
    ATL_IMG_TEXT: intl.formatMessage({ defaultMessage: "Gemini Register Image" }),
    ENTER_NATIONAL_ID: intl.formatMessage({
      defaultMessage: "Please enter your information as it appears on your national ID.",
    }),
    ENTER_NATIONAL_ID_2: intl.formatMessage({ defaultMessage: "Only the selected Bancolombia customers" }),
    ENTER_NATIONAL_ID_3: intl.formatMessage({
      defaultMessage: "that are invited to participate may register at this time.",
    }),
    ENTER_NAME_OFFICIAL: intl.formatMessage({
      defaultMessage:
        "To get started, please enter your name as it appears on official documents. In order to trade, you'll need a valid identity document such as a passport, national ID, or permanent driver's license.",
    }),
  };
};

export const getMarketingCopy = (intl: IntlShape) => {
  return {
    BANCOLOMBIA_NOW_OFFERS: intl.formatMessage({
      defaultMessage:
        "Bancolombia now offers selected clients* the option to buy crypto on Gemini, the #1 cryptocurrency exchange in the world.",
    }),
    START_BUILDING: intl.formatMessage({
      defaultMessage: "Start building your crypto portfolio",
    }),
    NOW_AVAILABLE_IN_THE: intl.formatMessage({ defaultMessage: "Now available in the " }),
    NOW_AVAILABLE_IN: intl.formatMessage({
      defaultMessage: "Now available in ",
    }),
    CRYPTOS_INCLUDING: intl.formatMessage({
      defaultMessage: "45+ cryptos including bitcoin, ether, and dogecoin",
    }),
    INDUSTRY_LEADING_SECURITY: intl.formatMessage({
      defaultMessage: "Industry-leading security and compliance.",
    }),
    INDUSTRY_LEADING_SECURITY_INSURANCE: intl.formatMessage({
      defaultMessage: "Industry-leading security and insurance",
    }),
    AVAILABLE_IN_COLOMBIA: intl.formatMessage({
      defaultMessage: "Available in Colombia for selected clients",
    }),
    GET_STARTED_WITH_DOLLAR: intl.formatMessage({
      defaultMessage: "Get started with as little as $5",
    }),
    GET_STARTED_WITH_EURO: intl.formatMessage({
      defaultMessage: "Get started with as little as €5",
    }),
    GET_STARTED_WITH_POUND: intl.formatMessage({
      defaultMessage: "Get started with as little as £5",
    }),
    AVAILABLE_ALL_STATES_COUNTRIES: intl.formatMessage({
      defaultMessage: "Available in all 50 states and 50+ countries",
    }),
    GEMINI_SUPPORTS_CRYPTOS: intl.formatMessage({
      defaultMessage: "Gemini supports 50+ cryptos including bitcoin, ether, and dogecoin",
    }),
  };
};

type FinePrintCopy = Record<CountryAbbreviation, string>;
export const getFinePrintCopy = (intl: IntlShape): Partial<FinePrintCopy> => ({
  co: intl.formatMessage({
    defaultMessage:
      "*Bancolombia customers must be invited to the early access program before they can trade crypto on Gemini.",
  }),
});
