import { useCallback, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { AxiosError, ErrorCode } from "@gemini-ui/services/axios";
import {
  DEBOUNCE_INTERVAL_MS,
  fetchSubaccountsWithBalances,
  SubaccountSearchParams,
  SubaccountSearchResponse,
  SubaccountWithBalances,
} from "@gemini-ui/services/subaccounts";
const SUBACCOUNTS_RETRY_COUNT = 3;

const INITIAL_STATE = {
  accountDetails: null,
  data: { currentPage: 0, limit: 10, offset: 0, sortAsc: true, total: 0, totalPages: 0, results: [] },
  error: null,
  loading: false,
  refreshing: false,
};

interface SubaccountWithBalanceState {
  accountDetails: SubaccountWithBalances | null;
  data: SubaccountSearchResponse<SubaccountWithBalances>;
  error: AxiosError;
  loading: boolean;
  refreshing: boolean;
}

export function useSubaccountWithBalance(
  searchParams?: Partial<SubaccountSearchParams>,
  limit?: number,
  hasDedupedGet?: boolean
) {
  const [state, setState] = useState<SubaccountWithBalanceState>(INITIAL_STATE);
  const [params, setParams] = useState({ filter: "", offset: 0 });

  const { filter, offset } = params;
  const { accountDetails, data, loading, refreshing, error } = state;

  const interval = filter ? DEBOUNCE_INTERVAL_MS : 10;

  useDebounce(
    () => {
      let params = { ...searchParams };

      if (filter) params = { name: filter, ...params };
      if (offset) params = { offset, ...params };

      fetchSubaccounts(params);
    },
    interval,
    [filter, offset, params]
  );

  const fetchSubaccounts = useCallback(
    (
      searchParams: Partial<SubaccountSearchParams> = {},
      stateParams: Partial<Pick<SubaccountWithBalanceState, "loading" | "refreshing">> = {}
    ) => {
      setState(prev => ({
        ...prev,
        loading: stateParams.loading === undefined ? true : stateParams.loading,
        refreshing: stateParams.refreshing ?? false,
      }));
      fetchSubaccountsWithBalances(
        searchParams,
        hasDedupedGet ? {} : { retry: SUBACCOUNTS_RETRY_COUNT },
        limit,
        hasDedupedGet
      )
        .then(response => {
          const accounts = response.results.filter(account => Boolean(account.balances));
          const shouldReset = offset === 0 || (filter ? response.currentPage === 1 : false);
          setState(prev => ({
            ...prev,
            accountDetails: prev.accountDetails
              ? accounts.find(({ hashid }) => hashid === prev.accountDetails.hashid)
              : null,
            loading: false,
            refreshing: false,
            error: null,
            data: {
              ...response,
              results: shouldReset ? accounts : [...prev.data.results, ...accounts],
            },
          }));
        })
        .catch(error => {
          if (error?.code !== ErrorCode.CANCEL_ERROR) {
            setState(prev => ({ ...prev, error, loading: false, refreshing: false }));
          }
        });
    },
    [filter, offset, limit, hasDedupedGet]
  );

  const values = useMemo(
    () => ({ accountDetails, data, filter, loading, refreshing, setParams, setState, params, error, fetchSubaccounts }),
    [accountDetails, data, filter, loading, refreshing, setParams, setState, params, error, fetchSubaccounts]
  );

  return values;
}
