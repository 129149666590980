import * as Sentry from "@sentry/browser";
import axios from "@gemini-ui/services/axios/cache";
import { UserPermissions } from "@gemini-ui/services/user/constants";

export const getUserPermissions = async () => {
  try {
    const response = await axios.get<UserPermissions>(
      jsRoutes.com.gemini.web.server.account.controllers.UserController.getPermissions().url,
      {
        cache: {
          ttl: 1000 * 5, // 5 seconds
        },
      }
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
  }
};
