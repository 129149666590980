import BigNumber from "bignumber.js";
import { Order } from "@gemini-ui/constants/orders";
import { getQuantity } from "@gemini-ui/pages/ActiveTrader/Spot/MyOrders/utils";

// custom to fixed method that will not round up, in order to not show 100% when it's >99%
const toFixed = (num: number, fixed: number) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + fixed + "})?");
  return Number(num.toString().match(re)[0]);
};

export const getFilledQuantity = (order: Order) => {
  const quantity = getQuantity(order);
  if (!quantity || !order.remainingQuantity) {
    return 0;
  }
  return Number(new BigNumber(quantity.value).minus(new BigNumber(order.remainingQuantity.value)));
};

export const getFilledPercent = (order: Order) => {
  const quantity = getQuantity(order);
  if (!quantity) {
    return 0;
  }
  const filledPercent = (getFilledQuantity(order) / Number(quantity.value)) * 100;
  return toFixed(filledPercent || 0, 3);
};

export const isPartiallyFilled = (order: Order) => {
  return getFilledQuantity(order) > 0;
};
