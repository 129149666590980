import { Fragment, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { EVENTS, track } from "@gemini-ui/analytics";
import { REGEX } from "@gemini-ui/constants";
import { ACCOUNT_NUMBER, NAME_ON_ACCOUNT } from "@gemini-ui/constants/wireFunding";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Spacer,
  Spacing,
  Text,
  useForm,
  useToaster,
} from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { ConfirmationScreen } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/ConfirmationScreen";
import {
  RtpBankRegistrationForm,
  SupportedBank,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { useGetSupportedBanks } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/hooks/useGetSupportedBanks";
import { ModalTitle } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/ModalTitle";
import { registerBank } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/services";
import {
  createDropDownValue,
  validateAccountNumber,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/utils";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import { getError } from "@gemini-ui/utils/error";
import { getRequiredFieldErrorString } from "@gemini-ui/utils/getRequiredFieldErrorString";
import { useIntl } from "@gemini-ui/utils/intl";
import { getFieldData } from "@gemini-ui/utils/wireFunding";
export interface Props {
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

export const RtpBankRegistration = ({ isOpen, onBack, onSubmit, onClose }: Props) => {
  const { intl } = useIntl();
  const { supportedBanks, isLoading } = useGetSupportedBanks();
  const { showToast } = useToaster();
  const [isConfirmScreen, setIsConfirmScreen] = useState(false);
  const requiredFieldString = getRequiredFieldErrorString(intl);
  const fieldData = getFieldData(intl);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    control,
    watch,
  } = useForm<RtpBankRegistrationForm>({
    defaultValues: {
      [fieldData.nameOnAccount.apiField]: "",
      bankCode: "",
      accountNumber: "",
    },
  });
  const formValues = watch();
  const validateAccountNumberField = () => {
    const { bankCode, accountNumber } = getValues();
    const accountNumberErrorString = validateAccountNumber(accountNumber, bankMap[bankCode], intl);
    return !Boolean(accountNumberErrorString) || accountNumberErrorString;
  };

  const { field } = useController<RtpBankRegistrationForm>({
    name: "bankCode",
    control,
    rules: {
      required: requiredFieldString,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBankRegistrationSubmit = async (values: RtpBankRegistrationForm) => {
    const { name, properties } = EVENTS.SUBMIT_BANK_INFO;
    const sanitizedValues: RtpBankRegistrationForm = {
      ...values,
      accountNumber: values.accountNumber.replace(" ", ""),
      bic: supportedBanks.find(bank => bank.bankName === values.bankCode)?.bic,
    };
    try {
      setIsSubmitting(true);
      await registerBank(sanitizedValues);
      track(name, { [properties.SUBMITTED_SUCCESSFULLY]: true });
      onSubmit();
    } catch (err) {
      const errorMessage = getError(err);
      track(name, { [properties.SUBMITTED_SUCCESSFULLY]: false });
      trackPaymentRegistrationFailure(LinkPaymentType.RTP, errorMessage);
      showToast({
        message: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const { bankItems, bankMap } = supportedBanks.reduce<{
    bankItems: Array<{
      value: string;
      label: string;
    }>;
    bankMap: Record<string, SupportedBank>;
  }>(
    ({ bankItems, bankMap }, bank) => {
      bankItems.push(createDropDownValue(bank));
      bankMap[bank.bankName] = bank;
      return {
        bankItems,
        bankMap,
      };
    },
    {
      bankItems: [],
      bankMap: {},
    }
  );

  const { nameOnAccount } = getFieldData(intl);

  /** Generate the mask based on the rules of the selected bank */
  const accountNumberMask = useMemo(() => {
    const { bankCode } = getValues();
    const bank = bankMap[bankCode];
    const accountNumberMaxLength = bank ? bank.maxLength : 21;

    return Array.from({ length: accountNumberMaxLength }, () => REGEX.NUMERIC);
  }, [bankMap, getValues]);

  const titleText = isConfirmScreen
    ? intl.formatMessage({ defaultMessage: "Confirm bank account details" })
    : intl.formatMessage({ defaultMessage: "Add bank account for FAST transfer" });

  return (
    <Modal isOpen={isOpen} hasDropdown>
      {isLoading ? (
        <SpinnerAnimation data-testid="rtp-bank-registration-loading" center size={Spacing.scale[8]} mt={8} mb={8} />
      ) : (
        <Fragment>
          <ModalTitle
            onBack={isConfirmScreen ? () => setIsConfirmScreen(false) : onBack}
            onClose={onClose}
            title={
              <Text.Heading size="md" mt={3} mb={2}>
                {titleText}
              </Text.Heading>
            }
            isEdit={isConfirmScreen}
          />
          <Spacer mt={4}>
            <Form onSubmit={handleSubmit(() => setIsConfirmScreen(true))} errors={errors}>
              {isConfirmScreen ? (
                <ConfirmationScreen values={formValues} bankList={supportedBanks} />
              ) : (
                <Fragment>
                  <Input
                    {...register(NAME_ON_ACCOUNT, { required: requiredFieldString })}
                    data-testid="name-on-account-rtp-input"
                    label={nameOnAccount.label}
                    placeholder={nameOnAccount.placeholder}
                    error={errors[nameOnAccount.apiField]?.message}
                    id="nameOnAccount"
                  />
                  <Select
                    data-testid="rtp-bank-dropdown"
                    error={errors?.bankCode?.message}
                    {...field}
                    id="bankCode"
                    label={intl.formatMessage({ defaultMessage: "Bank name" })}
                    options={bankItems}
                  />
                  <Input
                    {...register(ACCOUNT_NUMBER, {
                      required: requiredFieldString,
                      validate: validateAccountNumberField,
                    })}
                    defaultValue={undefined}
                    data-testid="rtp-account-input"
                    error={errors.accountNumber?.message}
                    label={intl.formatMessage({
                      defaultMessage: "Bank account number",
                    })}
                    mask={accountNumberMask}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Your bank account number",
                    })}
                    id="accountNumber"
                  />
                </Fragment>
              )}

              <Button.Group>
                {isConfirmScreen ? (
                  <Button.Primary
                    data-testid="submit-rtp-register"
                    onClick={() => handleBankRegistrationSubmit(formValues)}
                    loading={isSubmitting}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Submit",
                    })}
                  </Button.Primary>
                ) : (
                  <Button.Primary data-testid="next-rtp-register" type="submit">
                    {intl.formatMessage({
                      defaultMessage: "Review",
                    })}
                  </Button.Primary>
                )}
              </Button.Group>
            </Form>
          </Spacer>
        </Fragment>
      )}
    </Modal>
  );
};
