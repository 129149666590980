import { useCallback } from "react";
import { IconStaticBrandGemini } from "@hubble/icons";
import { HeaderTitle } from "@gemini-ui/components/Header/navigation/GeminiHome/HeaderTitle";
import { BackIcon, Container, HubbleTitle, Title } from "@gemini-ui/components/Header/navigation/GeminiHome/styles";
import { isSwitcherEligiblePage } from "@gemini-ui/components/Header/utils";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { usePageData } from "@gemini-ui/contexts";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const dashboardUrl = jsRoutes.com.gemini.web.server.trading.controllers.DashboardController.main().url;
/* istanbul ignore next */
const custodyDashboardUrl = jsRoutes.com.gemini.web.server.onchain.controllers.CustodyDashboardController.get().url;

interface Props {
  moreThanOneAccount?: boolean;
  mobileMenuActive?: boolean;
}

const GeminiHome = ({ moreThanOneAccount, mobileMenuActive = false }: Props) => {
  const { intl } = useIntl();
  const {
    pageName,
    templateProps: {
      user: { isCustodyAccount },
    },
  } = usePageData();

  const backToGemini = (
    <Container
      href={isCustodyAccount ? custodyDashboardUrl : dashboardUrl}
      aria-label={intl.formatMessage({ defaultMessage: "Gemini home" })}
    >
      <BackIcon />
      {!mobileMenuActive && (
        <Title data-testid="header-back-title">
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "Back to Gemini {isCustodyAccount, select, true {Custody} other {Exchange}}",
            }),
            { isCustodyAccount }
          )}
        </Title>
      )}
    </Container>
  );

  const accountSwitcherGemini = (
    <Container
      href={isCustodyAccount ? custodyDashboardUrl : dashboardUrl}
      aria-label={intl.formatMessage({ defaultMessage: "Gemini home" })}
    >
      <IconStaticBrandGemini aria-hidden="true" />
      {!mobileMenuActive && <HeaderTitle />}
    </Container>
  );

  const renderPageName = useCallback(
    (pageName: PageName) => {
      switch (pageName) {
        case PageName.Home:
          return intl.formatMessage({ defaultMessage: "Home" });
        case PageName.Market:
          return intl.formatMessage({ defaultMessage: "Market" });
        case PageName.Trade:
          return intl.formatMessage({ defaultMessage: "Trade" });
        case PageName.Earn:
        case PageName.Grow:
        case PageName.Stake:
          intl.formatMessage({ defaultMessage: "Stake" });
        case PageName.AdvancedMarkets:
          return intl.formatMessage({ defaultMessage: "Markets" });
        default:
          return intl.formatMessage({ defaultMessage: "Portfolio" });
      }
    },
    [intl]
  );

  const singleAccountGemini = (
    <Container
      href={isCustodyAccount ? custodyDashboardUrl : dashboardUrl}
      aria-label={intl.formatMessage({ defaultMessage: "Gemini home" })}
      isSingleAccount
    >
      <IconStaticBrandGemini aria-hidden="true" />
      {!mobileMenuActive && (
        <HubbleTitle data-testid="header-title" size="xs" ml={1} bold isSingleAccount>
          {renderPageName(pageName)}
        </HubbleTitle>
      )}
    </Container>
  );

  if (!isSwitcherEligiblePage(pageName)) {
    return backToGemini;
  } else if (moreThanOneAccount) {
    return accountSwitcherGemini;
  } else {
    return singleAccountGemini;
  }
};

export default GeminiHome;
