import { IntlShape } from "react-intl";
import {
  CurrencyDetail,
  CurrencyShortName,
  CurrencyShortNameFiat,
  SupportedCurrencyPairs,
} from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { GainLoss } from "@gemini-ui/constants/balances";
import { GrowProviderType, HighestAssetYieldByProviderType, HighestYieldAsset } from "@gemini-ui/constants/earn";
import { Props as CryptoIconProps } from "@gemini-ui/images/icons/cdn/CryptoIcon";

export const INTERVALS = {
  hour: "1h",
  day: "1d",
  week: "1w",
  oneMonth: "1m",
  oneYear: "1y",
  allTime: "all",
} as const;

// used for display (capitalized) only since other enums are used throughout the app
export const getTranslatedIntervalLabel = (intl: IntlShape): Record<ChartInterval, string> => {
  return {
    "1h": intl.formatMessage({ defaultMessage: "1H", description: "one hour interval" }),
    "1d": intl.formatMessage({ defaultMessage: "1D", description: "one day interval" }),
    "1w": intl.formatMessage({ defaultMessage: "1W", description: "one week interval, should be 1S in es-419" }),
    "1m": intl.formatMessage({ defaultMessage: "1M", description: "one month interval" }),
    "3m": intl.formatMessage({ defaultMessage: "3M", description: "three month interval" }),
    "1y": intl.formatMessage({ defaultMessage: "1Y", description: "one year interval, should be 1A in es-419" }),
    all: intl.formatMessage({ defaultMessage: "All", description: "all time interval" }),
  };
};
export const PORTFOLIO_INTERVALS = {
  day: "1d",
  week: "1w",
  oneMonth: "1m",
  threeMonths: "3m",
  oneYear: "1y",
} as const;

const ALL_INTERVALS = { ...INTERVALS, ...PORTFOLIO_INTERVALS };

export type ChartInterval = (typeof ALL_INTERVALS)[keyof typeof ALL_INTERVALS];

export const DEFAULT_CHART_INTERVAL = INTERVALS.day as ChartInterval;

export type RouteAction = "buy" | "sell" | "convert";

export interface Point {
  x: number;
  y: number;
}

export interface PairDetail {
  "24hrDelta": string;
  "24hrMax": string;
  "24hrMin": string;
  "24hrPercentDelta": string;
  ask: string;
  bid: string;
  isPerpPair: boolean;
  range: Point[];
  rangeDelta: string;
  rangePercentDelta: string;
  symbol: SupportedCurrencyPairs;
  supply?: MoneyProps;
  marketCap?: MoneyProps;
  volume: MoneyProps;
  allTimeHigh: MoneyProps;
  instantMarketOpen: boolean;
  limitOrderOpen: boolean;
  from: CurrencyShortName;
  to: CurrencyShortName;
  toDisplayName: string;
  total?: MoneyProps;
  launchDate?: Date;
  fromDisplayName: string;
  isSyntheticPair: boolean;
  priceInputDecimals: number;
  stopLimitOrderOpen: boolean;
  lastTradePrice: string;
  marketOrderOpen: boolean;
}

export type NotionalChartData = [number, string][];

export interface AccountError {
  error: string;
  buttonText: string;
  link?: string;
  action?: string;
}

export interface CurrencyIconProps {
  name: CurrencyDetail["name"];
  route: string;
  Icon: React.FC<React.PropsWithChildren<Omit<CryptoIconProps, "symbol">>>;
  currency: CurrencyDetail;
}

export interface MarketFilter {
  name: string;
  description: string;
  assets: CurrencyShortName[];
  type?: MarketFilterTypes;
}

export enum MarketFilterTypes {
  AllMarketFilter = "AllMarketFilter",
  WatchlistMarketFilter = "WatchlistMarketFilter",
  TopMoversMarketFilter = "TopMoversMarketFilter",
  TopTradedMarketFilter = "TopTradedMarketFilter",
  MetaverseMarketFilter = "MetaverseMarketFilter",
  DeFiMarketFilter = "DeFiMarketFilter",
  NFTMarketFilter = "NFTMarketFilter",
  StakingMarketFilter = "StakingMarketFilter",
  StablecoinsMarketFilter = "StablecoinsMarketFilter",
  MemeMarketFilter = "MemeMarketFilter",
}

export type MarketCapWeights = [string, number][];

export interface DashboardMarketProps {
  marketFilters: MarketFilter[];
  allPairDetails: PairDetail[];
  marketCapWeights: MarketCapWeights;
}

export type ConvertEligible = {
  [currency in CurrencyShortName]?: Array<Partial<CurrencyShortName>>;
};

interface TotalsBase<T = CurrencyShortName> {
  balance: MoneyProps<T>;
  changeAmount?: MoneyProps<T>;
  changePercent?: string;
  /** i.e. "2022-07-06T14:35:14.910Z" */
  previousAsOf?: string;
  previousBalance?: MoneyProps<T>;
  "24hrDelta"?: MoneyProps<T>;
  "24hrPercentDelta"?: string;
}

export interface UnfulfilledRedeem {
  amountPaidSoFar: MoneyProps;
  amountRemaining: MoneyProps;
  amountRemainingNotional: MoneyProps<CurrencyShortNameFiat>;
  fullPaymentSlaDate: string; // "2022-01-24"
  paidSoFar: MoneyProps;
  paidSoFarNotional: MoneyProps<CurrencyShortNameFiat>;
  redeemAmount: MoneyProps;
  redeemAmountNotional: MoneyProps<CurrencyShortNameFiat>;
  redeemDate: string | number; // "2022-01-21T16:32:57.322Z"
  providerType?: GrowProviderType;
}

export interface UnfulfilledEarnRedeems {
  totalNotional: MoneyProps<CurrencyShortNameFiat>;
  unfulfilledRedeems: UnfulfilledRedeem[];
}

export type BalanceByProviderType = Omit<Record<GrowProviderType, MoneyProps<CurrencyShortNameFiat>>, "Promotional">;

export interface Totals {
  totalNotional: TotalsBase<CurrencyShortNameFiat>;
  totalNotionalTradingBalance: TotalsBase<CurrencyShortNameFiat>;
  totalNotionalInterestBalance: TotalsBase<CurrencyShortNameFiat>;
  totalNotionalInterestBalanceEarnedToDate: MoneyProps<CurrencyShortNameFiat>;
  /** Will be added when this ticket is complete: https://gemini-spaceship.atlassian.net/browse/GEM-49487*/
  totalNotionalInterestBalanceEarnedToDateByProviderType?: BalanceByProviderType;

  totalNotionalInterestBalanceByProvider: Record<string, MoneyProps<CurrencyShortNameFiat>>;
  totalNotionalInterestBalanceByProviderType: BalanceByProviderType;

  projectedOneYearInterestNotional: MoneyProps<CurrencyShortNameFiat>;
  unfulfilledEarnRedeems: UnfulfilledEarnRedeems;
  gainLoss?: GainLoss;
  earnTotals?: EarnTotals;
  highestYieldAsset?: HighestYieldAsset;
  highestYieldAssetByProviderType?: HighestAssetYieldByProviderType;
  highestYieldAssetByProvider?: Partial<Record<string, HighestYieldAsset>>;
}

interface EarnTotals {
  totalNotional: TotalsBase<CurrencyShortNameFiat>;
  totalNotionalTradingBalance: TotalsBase<CurrencyShortNameFiat>;
  totalNotionalInterestBalance: TotalsBase<CurrencyShortNameFiat>;
  unfulfilledEarnRedeems: UnfulfilledEarnRedeems;
}

export interface MarketProps {
  marketFilters: MarketFilter[];
  allPairDetails: PairDetail[];
  marketCapWeights: MarketCapWeights;
}

export enum AccountType {
  DEBIT_CARD = "CardAccountType",
  BANK = "BankAccountType",
  BALANCE = "AccountBalancePaymentType",
  PAYPAL = "PayPalAccountType",
}

export const DashboardRoutes = {
  HOME: "/",
  MARKET: "/market",
  REWARDS: "/rewards",
};
