// Github syntax highlighting horks on the "of" in "indication-of-interest".
// According to https://github.com/github/linguist#using-gitattributes, we should be able to set the language to
// "JSX" via .gitattributes, but for some reason it's not working. Using an emacs modeline does. *shrug* - CH
// -*- mode: JSX;-*-
import {
  CurrencyShortName,
  CurrencyShortNameFiat,
  PerpetualPair,
  SupportedCurrencyPairs,
} from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { NotificationSettingKey } from "@gemini-ui/constants/index";
import { UnfulfilledRedeem } from "@gemini-ui/pages/RetailTrade/constants";
import { IntlShape } from "@gemini-ui/utils/intl";

// Order Types
const LIMIT = "limit";
const STOP_LIMIT = "stop-limit";
const MAKER_OR_CANCEL = "maker-or-cancel";
const IMMEDIATE_OR_CANCEL = "immediate-or-cancel";
const MARKET = "market";
const FILL_OR_KILL = "fill-or-kill";

export const ORDER_TYPES = {
  LIMIT,
  STOP_LIMIT,
  MAKER_OR_CANCEL,
  IMMEDIATE_OR_CANCEL,
  MARKET,
  FILL_OR_KILL,
} as const;

export const LIMIT_ORDER_TYPES = [
  ORDER_TYPES.LIMIT,
  ORDER_TYPES.MAKER_OR_CANCEL,
  ORDER_TYPES.IMMEDIATE_OR_CANCEL,
  ORDER_TYPES.FILL_OR_KILL,
] as const;

export const TAKER_ORDER_TYPES = [MARKET, IMMEDIATE_OR_CANCEL, FILL_OR_KILL, MAKER_OR_CANCEL] as const;

export type OrderType = (typeof ORDER_TYPES)[keyof typeof ORDER_TYPES];

type OrderTypeDetail = {
  [orderType in OrderType]: {
    name: string;
    confirmSetting: NotificationSettingKey | null;
  };
};

export const OrderUtils = {
  isMarketOrder: (orderType: OrderType): orderType is typeof ORDER_TYPES.MARKET => {
    return ORDER_TYPES.MARKET === orderType;
  },
  isStopOrder: (orderType: OrderType): orderType is typeof ORDER_TYPES.STOP_LIMIT => {
    return ORDER_TYPES.STOP_LIMIT === orderType;
  },
  isRecurringOrder: (order: Order) => {
    return Boolean(order.isRecurring);
  },
  isBaseOrderType: (order: Order | OpenPosition): order is Order => {
    return order.hasOwnProperty("hashid");
  },
};

export type ContinuousOrderType = keyof Omit<OrderTypeDetail, "limit-ao">;
export const CONTINUOUS_ORDER_TYPES = (intl: IntlShape): Omit<OrderTypeDetail, "limit-ao"> => {
  return {
    [LIMIT]: {
      name: intl.formatMessage({ defaultMessage: "Limit", description: "at continuous order type" }),
      confirmSetting: "confirmLimitOrders",
    },
    [STOP_LIMIT]: {
      name: intl.formatMessage({ defaultMessage: "Stop-Limit", description: "at continuous order type" }),
      confirmSetting: "confirmLimitOrders",
    },

    [MAKER_OR_CANCEL]: {
      name: intl.formatMessage({ defaultMessage: "Maker-or-Cancel (MOC)", description: "at continuous order type" }),
      confirmSetting: "confirmLimitOrders",
    },
    [IMMEDIATE_OR_CANCEL]: {
      name: intl.formatMessage({
        defaultMessage: "Immediate-or-Cancel (IOC)",
        description: "at continuous order type",
      }),
      confirmSetting: "confirmLimitOrders",
    },
    [MARKET]: {
      name: intl.formatMessage({ defaultMessage: "Market", description: "at continuous order type" }),
      confirmSetting: "confirmMarketOrders",
    },
    [FILL_OR_KILL]: {
      name: intl.formatMessage({ defaultMessage: "Fill-or-Kill (FOK)", description: "at continuous order type" }),
      confirmSetting: "confirmLimitOrders",
    },
  };
};

export const ORDER_TYPE_DETAILS = (intl: IntlShape) => CONTINUOUS_ORDER_TYPES(intl);

const BUY = "buy";
const SELL = "sell";
const CONVERT = "convert";
export const ORDER_SIDES = {
  BUY,
  SELL,
  CONVERT,
} as const;
export const getOrderSideLabel = (intl: IntlShape) => {
  return {
    [ORDER_SIDES.BUY]: intl.formatMessage({ defaultMessage: "buy" }),
    [ORDER_SIDES.SELL]: intl.formatMessage({ defaultMessage: "sell" }),

    [ORDER_SIDES.CONVERT]: intl.formatMessage({ defaultMessage: "convert" }),
  };
};

export const getPerpLabel = (intl: IntlShape) => {
  return {
    [ORDER_SIDES.BUY]: intl.formatMessage({ defaultMessage: "Buy / Long" }),
    [ORDER_SIDES.SELL]: intl.formatMessage({ defaultMessage: "Sell / Short" }),
  };
};

export type Side = (typeof ORDER_SIDES)[keyof typeof ORDER_SIDES];

const PERP = "Perp";
const SPOT = "Spot";

export const INSTRUMENT_TYPES = {
  PERP,
  SPOT,
} as const;

export type InstrumentType = (typeof INSTRUMENT_TYPES)[keyof typeof INSTRUMENT_TYPES];

export enum GrowTransferType {
  EARN_DEPOSIT = "earnDeposit",
  EARN_REDEEM = "earnRedeem",
  EARN_ADMIN_REDEEM = "earnAdminRedeem",
  EARN_INTEREST = "earnInterest",
}

type TransferType = `${GrowTransferType}`;

interface OrderBase {
  hashid: string;
  pair: SupportedCurrencyPairs;
  side: Side;
  created?: number;
  updated: number;
  cancelled: boolean;
  reason: string;
  remainingQuantity: MoneyProps;
  avgPrice: MoneyProps;
  stopPrice?: MoneyProps;
  isAuction: boolean;
  isInstant?: boolean;
  isBasket?: boolean;
  isLiquidation?: boolean;
  isRecurring?: boolean;
  orderSource: OrderSource;
  transferType?: TransferType;
  redeemedInFull?: boolean;
  redeemFulfilledOn?: number;
  price: MoneyProps;
  timestamp?: number;
  minDateAccrualForMonth?: number;
  maxDateAccrualForMonth?: number;
  amount?: MoneyProps;
  earnProviderId?: string;
  providerName?: string;
  providerType?: string;
  instrumentType: InstrumentType;
  fullPaymentSlaDate?: string;
  transferOn?: number;
  accrueOn?: number;
  fees?: MoneyProps;
  clientOrderId: string;
  quantity: MoneyProps;
  filledQuantity?: MoneyProps;
}

type MarketOrderBase<T> = OrderBase & {
  accepted?: number;
  totalSpend?: MoneyProps;
  quantityReceived?: MoneyProps;
  type: T;
  grossFillPrice?: MoneyProps;
  totalPrice?: MoneyProps;
};

// API Order Types
const MARKET_BUY = "marketBuy";
const MARKET_SELL = "marketSell";
const TRANSFER = "transfer";
const ORDER = "order";

export const API_ORDER_TYPES = {
  MARKET_BUY,
  MARKET_SELL,
  TRANSFER,
  ORDER,
} as const;

export type LimitOrder = OrderBase & {
  totalPrice?: MoneyProps;
  parentOrderId?: number;
  originalStopPrice?: string; // this should be in StopLimitOrder but TS can't distinguish because both are type="order"
  type: typeof ORDER;
};

export type StopLimitOrder = LimitOrder & {
  stopPrice?: MoneyProps;
  isStopTriggered?: boolean;
};
export type MarketOrder = MarketOrderBase<typeof MARKET_BUY | typeof MARKET_SELL | typeof TRANSFER>;

export type Order = LimitOrder | MarketOrder | StopLimitOrder;

export type APIOrderTypes = (typeof API_ORDER_TYPES)[keyof typeof API_ORDER_TYPES];

type OrderSource =
  | "Mobile"
  | "Web"
  | "WebRetail"
  | "Recurring"
  | "REST"
  | "FIX"
  | "Seize"
  | "Cryptoback"
  | "LiquidationEngine"
  | "TestTool"
  | "gRPC";

export enum OrderStatus {
  BOOKED = "Booked",
  CANCELLED = "Cancelled",
  FILLED = "Filled",
  PARTIALLY_FILLED = "PartiallyFilled",
  REJECTED = "Rejected",
  UNSPECIFIED = "Unspecified",
}
type OrderStatusType =
  | OrderStatus.BOOKED
  | OrderStatus.CANCELLED
  | OrderStatus.FILLED
  | OrderStatus.PARTIALLY_FILLED
  | OrderStatus.REJECTED
  | OrderStatus.UNSPECIFIED;

export type TradeHistoryOrder = {
  avgPrice: MoneyProps;
  clientOrderId: string;
  cumulativeQuantity: MoneyProps;
  fee: MoneyProps;
  filledQuantity: MoneyProps;
  filledValue: MoneyProps;
  hashid: string;
  instrumentType: string;
  orderSource: OrderSource;
  orderStatus: OrderStatusType;
  pair: SupportedCurrencyPairs;
  price: MoneyProps;
  remainingQuantity: MoneyProps;
  side: Side;
  updated: number;
  totalFee: MoneyProps;
  totalQuantity: MoneyProps;
  originalStopPrice?: string;
  stopPrice?: MoneyProps;
  isStopTriggered?: boolean;
};

/**
 * Grow transfers are either deposits or redeems, with redeems having additional fields of `amountPaidSoFar` and `redeemedInFull` and `UnfulfilledRedeem`
 */
type GrowTransferBase = Partial<MarketOrderBase<"transfer">> &
  Pick<MarketOrderBase<"transfer">, "amount" | "timestamp" | "transferType" | "type"> &
  Partial<
    Pick<
      MarketOrderBase<"transfer">,
      | "providerName"
      | "providerType"
      | "redeemedInFull"
      | "redeemFulfilledOn"
      | "isRecurring"
      | "fullPaymentSlaDate"
      | "transferOn"
      | "accrueOn"
    >
  >;

export type GrowTransfer = GrowTransferBase & Partial<UnfulfilledRedeem>;

export enum RecurringOrderStatus {
  ACTIVE = "Active",
  PAUSED = "Paused",
  DISABLED = "Disabled",
}

export interface RecurringOrder {
  accountId?: number;
  created: number;
  earnProviderId?: string;
  executionType: "InstantOrder" | "InstantBasketOrder";
  growProviderType?: GrowProviderType;
  notional: string;
  orderSource: string;
  paymentDetails?: { paymentMethodType?: string };
  priceCurrency: CurrencyShortNameFiat;
  roid: number;
  schedule: "Daily" | "Weekly" | "BiWeekly" | "Monthly";
  startOn: string; // e.g. "2021-11-15"
  status: RecurringOrderStatus.ACTIVE | RecurringOrderStatus.PAUSED | RecurringOrderStatus.DISABLED;
  statusReason?: string;
  targetExecution?: number;
  tradingPair: SupportedCurrencyPairs;
  triggerHour: string; // e.g. "05:00:00.000"
  updated?: number;
}

export type OrderPermissions = {
  canCancelOrder: boolean;
  canPlaceMarginOrder: boolean;
  canPlaceOrder: boolean;
};

export interface PriceAlert {
  alertId: number;
  created: number;
  isActive: boolean;
  isEmail?: boolean;
  pair: SupportedCurrencyPairs;
  price: string;
  triggerAbove: boolean;
}

export interface NotionalMarginAmount {
  available: MoneyProps;
  total: MoneyProps;
  used: MoneyProps;
}

export interface MarginAmounts {
  total: MoneyProps[];
  used: MoneyProps[];
  available: MoneyProps[];
  notional: NotionalMarginAmount;
}

export type OpenPosition = {
  pair: PerpetualPair | CurrencyShortName;
  instrumentType: InstrumentType;
  side: Side;
  quantity: MoneyProps;
  notionalValue: MoneyProps;
  realizedPl?: MoneyProps;
  unrealizedPl?: MoneyProps;
  averagePrice?: MoneyProps;
  currentPrice: MoneyProps;
};

export type PerpetualAccountRisk = {
  mav: MoneyProps; // Margin assets value
  initialMargin: MoneyProps;
  availableMargin: MoneyProps;
  marginMaintenanceLimit: MoneyProps;
  leverage: number;
  notionalValue: MoneyProps;
  estimatedLiquidationPrice: MoneyProps;
  initialMarginPositions: MoneyProps;
  reservedMargin: MoneyProps;
  reservedMarginBuys: MoneyProps;
  reservedMarginSells: MoneyProps;
  buyingPower: MoneyProps;
  sellingPower: MoneyProps;
};
