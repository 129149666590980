import { PartialSuccess } from "@gemini-ui/pages/Earn/Redeem/Success/PartialSuccess";
import { Success } from "@gemini-ui/pages/Earn/Redeem/Success/Success";
import { PlaceRedeemInfo } from "@gemini-ui/pages/Earn/Redeem/types";

interface Props extends Pick<PlaceRedeemInfo, "amount" | "currency" | "provider" | "redemptionStatus"> {
  lastTradePrice: string;
  onDone?: () => void;
}

const RedeemSuccess = (props: Props) => {
  const { redemptionStatus } = props;

  if (redemptionStatus.redeemedInFull) {
    return <Success {...props} />;
  }

  return <PartialSuccess {...props} />;
};

export default RedeemSuccess;
