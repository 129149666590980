import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { AuthenticateRespone } from "@gemini-ui/pages/settings/BankSettings/debit/types";
import { DebitCardType } from "@gemini-ui/transformers/PaymentMethods";
import { IntlShape } from "@gemini-ui/utils/intl";

export interface AddDebitCardFlowState {
  selectDebitCurrencyVisible?: boolean;
  selectCountryVisible?: boolean;
  addDebitCardVisible?: boolean;
  addBillingAddressVisible?: boolean;
  addBillingAddressManualVisible?: boolean;
  verifyCardStepOneVisible?: boolean;
  transactionsSentVisible?: boolean;
  verifyCardStepTwoVisible?: boolean;
  tooManyAttemptsVisible?: boolean;
  cardDeclinedVisible?: boolean;
  showDebitCardSuccessModal?: boolean;
  showChallengeFormIframe?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  countryFullName?: string;
  cardHolderName?: string;
  sessionToken?: string;
  uuid?: string;
  cardProps?: DebitCardType;
  selectedCurrency?: CurrencyShortNameFiat;
  maxMicroDeposit?: MoneyProps<CurrencyShortNameFiat>;
  minMicroDeposit?: MoneyProps<CurrencyShortNameFiat>;
  isExistingCard?: boolean;
  useDefaultAddress?: boolean;
  isManualAddressMode?: boolean;
  openDebitCardErrorModal?: boolean;
  debitCardErrorHeading?: string;
  debitCardErrorDescription?: string;
  selectExchangeAccountVisible?: boolean;
  deviceDataJWT?: string;
  deviceDataURL?: string;
  bin?: string;
  internalReference?: string;
  showDeviceDataIframe?: boolean;
  unVerifiedTokenID?: string;
  authenticateResponse?: AuthenticateRespone;
  isLoading?: boolean;
}

export type ToggleDebitCardModal = (
  name: keyof AddDebitCardFlowState,
  close?: boolean,
  isRedirectedFromTypeahead?: boolean
) => () => void;
export type BillingAddress = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  countryFullName?: string;
};
export type DebitCardInfo = {
  cardHolderName: string;
  sessionToken: string;
};

export const INITIAL_ADDRESS_FIELDS = {
  street: "street",
  apt: "apt",
  city: "city",
  state: "state",
  zip: "zip",
  country: "country",
};

export const getCopy = (intl: IntlShape) => {
  return {
    selectExchangeAccount: intl.formatMessage({
      defaultMessage: "Select exchange account",
    }),
    addDebitCardToSpotMsg: intl.formatMessage({
      defaultMessage: "Payment method must be linked to spot account",
    }),
    addDebitCardToSpotMsgDescription: intl.formatMessage({
      defaultMessage:
        "Your debit card connects to your spot account, with funds passing through it before settling in derivatives.",
    }),
    searchInputPlaceholder: intl.formatMessage({
      defaultMessage: "Search",
    }),
    exchange: intl.formatMessage({
      defaultMessage: "Exchange accounts",
    }),
  };
};
