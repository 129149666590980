import * as Sentry from "@sentry/browser";
import { assign, setup } from "xstate";
import { trackGTMEvent } from "@gemini-ui/analytics";
import { CreditAnalyticsEventLabels, CreditAnalyticsEvents } from "@gemini-ui/analytics/constants/creditCard";
import { applicationApi } from "@gemini-ui/client/api";
import { ApplicationStatusStateStateEnum } from "@gemini-ui/client/credit";
import { UserData, XStateEvent } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/constants";

// View states driven by logged in/out status
export enum ClientState {
  INITIALIZING = "CLIENT_INITIALIZING",
  NEW_APPLICATION = "CLIENT_NEW_APPLICATION",
  EMAIL_VERIFY = "CLIENT_EMAIL_VERIFY",
  SMS_VERIFY = "CLIENT_SMS_VERIFY",
  PREFILL_SUCCESS = "CLIENT_PREFILL_SUCCESS",
  MANUAL_ENTRY = "CLIENT_MANUAL_ENTRY",
  ENTER_INCOME = "CLIENT_ENTER_INCOME",
  REVIEW_TERMS = "CLIENT_REVIEW_TERMS",
  INCOME_VERIFICATION = "INCOME_VERIFICATION",
  CARD_SELECTION = "CLIENT_CARD_SELECTION",
  REWARD_SELECTION = "CLIENT_REWARD_SELECTION",
  RECENT_DENIED = "CLIENT_RECENT_DENIED",
  AUTH_USER_INVITE = "CLIENT_AUTH_USER_INVITE",
  NOT_IN_WAITLIST_QUEUE = "CLIENT_NOT_IN_WAITLIST_QUEUE",
  NOT_IN_US = "CLIENT_NOT_IN_US",
  APPLICATIONS_CLOSED = "CLIENT_APPLICATIONS_CLOSED",
  FULL_TIER_REQUIRED = "CLIENT_FULL_TIER_REQUIRED",
  MAX_APPLICATION_LIMIT = "CLIENT_MAX_APPLICATION_LIMIT",
  INELIGIBLE_TO_APPLY = "CLIENT_INELIGIBLE_TO_APPLY",
  GENERIC_ERROR = "CLIENT_GENERIC_ERROR",
  UPLOAD_DOCUMENTS = "CLIENT_UPLOAD_DOCUMENTS",
  PREQUAL_DENIED = "CLIENT_PREQUAL_DENIED", // before soft pull users can be denied if they have a lockout on their account
  APP_REDESIGN_PHASE_1 = "CLIENT_APP_REDESIGN_PHASE_1",
}

export type ClientViews = ClientState | ApplicationStatusStateStateEnum;

export const FINAL = {
  type: "final",
} as const;

export type UserEvent =
  | XStateEvent
  | { type: ApplicationStatusStateStateEnum.SoftPullApproved }
  | { type: ApplicationStatusStateStateEnum.HardPullApproved }
  | { type: ApplicationStatusStateStateEnum.OfferExtended }
  | { type: ApplicationStatusStateStateEnum.CardDesignSelected }
  | { type: ApplicationStatusStateStateEnum.SoftPullDenied }
  | { type: ApplicationStatusStateStateEnum.HardPullDenied }
  | { type: ApplicationStatusStateStateEnum.SsnBlocklisted }
  | { type: ApplicationStatusStateStateEnum.SoftPullFrozen }
  | { type: ApplicationStatusStateStateEnum.HardPullFrozen }
  | { type: ApplicationStatusStateStateEnum.SoftPullDeceased }
  | { type: ApplicationStatusStateStateEnum.KycMismatch }
  | { type: ApplicationStatusStateStateEnum.KycFailed }
  | { type: ApplicationStatusStateStateEnum.HardPullDeceased }
  | { type: ApplicationStatusStateStateEnum.MoreInfo }
  | { type: ApplicationStatusStateStateEnum.OfacDenied }
  | { type: ApplicationStatusStateStateEnum.KycManualReview }
  | { type: ApplicationStatusStateStateEnum.SoftPullServiceFailure }
  | { type: ApplicationStatusStateStateEnum.HardPullServiceFailure }
  | { type: ApplicationStatusStateStateEnum.KycServiceFailure }
  | { type: ApplicationStatusStateStateEnum.BiometricDenied }
  | { type: ApplicationStatusStateStateEnum.BiometricManual }
  | { type: ApplicationStatusStateStateEnum.BiometricPassed }
  | { type: ApplicationStatusStateStateEnum.PhoneVerificationFailed }
  | { type: ApplicationStatusStateStateEnum.PhoneVerificationIndeterminate }
  | { type: ApplicationStatusStateStateEnum.PhoneTrustScoreFailed }
  | { type: ApplicationStatusStateStateEnum.PhoneTrustScoreIndeterminate }
  | { type: ApplicationStatusStateStateEnum.PhoneTrustScoreVerified }
  | { type: ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied };

const softPullResult = {
  [ApplicationStatusStateStateEnum.SoftPullApproved]: {
    target: ApplicationStatusStateStateEnum.SoftPullApproved,
    actions: "setUserData",
  },
  [ApplicationStatusStateStateEnum.SoftPullDenied]: ApplicationStatusStateStateEnum.SoftPullDenied,
  [ApplicationStatusStateStateEnum.SoftPullDeceased]: ApplicationStatusStateStateEnum.SoftPullDeceased,
  [ApplicationStatusStateStateEnum.SoftPullFrozen]: ApplicationStatusStateStateEnum.SoftPullFrozen,
  [ApplicationStatusStateStateEnum.MoreInfo]: ApplicationStatusStateStateEnum.MoreInfo,
  [ApplicationStatusStateStateEnum.OfacDenied]: ApplicationStatusStateStateEnum.OfacDenied,
  [ApplicationStatusStateStateEnum.SoftPullServiceFailure]: ApplicationStatusStateStateEnum.SoftPullServiceFailure,
  [ApplicationStatusStateStateEnum.SsnBlocklisted]: ApplicationStatusStateStateEnum.SsnBlocklisted,
  [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]:
    ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert,
  [ApplicationStatusStateStateEnum.KycMismatch]: ApplicationStatusStateStateEnum.KycMismatch,
  [ApplicationStatusStateStateEnum.KycFailed]: ApplicationStatusStateStateEnum.KycFailed,
  [ApplicationStatusStateStateEnum.KycManualReview]: ApplicationStatusStateStateEnum.KycManualReview,
  [ApplicationStatusStateStateEnum.KycServiceFailure]: ApplicationStatusStateStateEnum.KycServiceFailure,
  [ClientState.PREQUAL_DENIED]: {
    target: ClientState.PREQUAL_DENIED,
    actions: "setUserData",
  },
  [ClientState.RECENT_DENIED]: ClientState.RECENT_DENIED,
  [ClientState.GENERIC_ERROR]: ClientState.GENERIC_ERROR,
  [ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied]:
    ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied,
} as const;

const hardPullResult = {
  [ApplicationStatusStateStateEnum.KycMismatch]: ApplicationStatusStateStateEnum.KycMismatch,
  [ApplicationStatusStateStateEnum.KycFailed]: ApplicationStatusStateStateEnum.KycFailed,
  [ApplicationStatusStateStateEnum.HardPullDenied]: ApplicationStatusStateStateEnum.HardPullDenied,
  [ApplicationStatusStateStateEnum.HardPullDeceased]: ApplicationStatusStateStateEnum.HardPullDeceased,
  [ApplicationStatusStateStateEnum.HardPullFrozen]: ApplicationStatusStateStateEnum.HardPullFrozen,
  [ApplicationStatusStateStateEnum.HardPullApproved]: [
    {
      target: ClientState.APP_REDESIGN_PHASE_1,
      actions: "setUserData",
      guard: "isPhase1Enabled",
    },
    {
      target: ApplicationStatusStateStateEnum.HardPullApproved,
      actions: "setUserData",
    },
  ],
  [ApplicationStatusStateStateEnum.OfferExtended]: [
    {
      target: ClientState.APP_REDESIGN_PHASE_1,
      actions: "setUserData",
      guard: "isPhase1Enabled",
    },
    {
      target: ApplicationStatusStateStateEnum.OfferExtended,
      actions: "setUserData",
    },
  ],
  [ApplicationStatusStateStateEnum.SsnBlocklisted]: ApplicationStatusStateStateEnum.SsnBlocklisted,
  [ApplicationStatusStateStateEnum.MoreInfo]: ApplicationStatusStateStateEnum.MoreInfo,
  [ApplicationStatusStateStateEnum.OfacDenied]: ApplicationStatusStateStateEnum.OfacDenied,
  [ApplicationStatusStateStateEnum.KycManualReview]: ApplicationStatusStateStateEnum.KycManualReview,
  [ApplicationStatusStateStateEnum.HardPullServiceFailure]: ApplicationStatusStateStateEnum.HardPullServiceFailure,
  [ApplicationStatusStateStateEnum.KycServiceFailure]: ApplicationStatusStateStateEnum.KycServiceFailure,
  [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]:
    ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert,
  [ClientState.GENERIC_ERROR]: ClientState.GENERIC_ERROR,
  [ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied]:
    ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied,
} as const;

export const createCreditAppMachine = (initialContext: UserData) => {
  return setup({
    types: {
      events: {} as UserEvent,
      context: {} as UserData,
    },
    actions: {
      setUserData: assign(({ context, event }) => {
        const eventObj = event as Partial<UserData>;
        return {
          ...context,
          appData: {
            ...context.appData,
            ...eventObj.appData,
          },
          appId: context.appId || eventObj.appId,
          offerId: context.offerId || eventObj.offerId,
          isNewUser: context.isNewUser || eventObj.isNewUser,
          statusHistory: eventObj.statusHistory || context.statusHistory,
          socureId: eventObj.socureId || context.socureId,
          shouldSkipDocUpload: eventObj.shouldSkipDocUpload || context.shouldSkipDocUpload,
        };
      }),
      getInitialState: undefined, // passed dynamically in component, needed here for TS
      trackCompletePrequalDenied: () => {
        trackGTMEvent(CreditAnalyticsEvents.CompletePrequal, {
          eventLabel: CreditAnalyticsEventLabels.Denied,
        });
      },
    },
    guards: {
      isPhase1Enabled: ({ context }) => context.isPhase1Enabled,
    },
  }).createMachine({
    id: "CryptoBack",
    initial: ClientState.INITIALIZING,
    context: initialContext,
    states: {
      [ClientState.INITIALIZING]: {
        entry: "getInitialState",
        on: {
          [ClientState.NEW_APPLICATION]: {
            target: ClientState.NEW_APPLICATION,
            actions: "setUserData",
          },
          [ClientState.NOT_IN_US]: {
            target: ClientState.NOT_IN_US,
          },
          [ClientState.INELIGIBLE_TO_APPLY]: {
            target: ClientState.INELIGIBLE_TO_APPLY,
          },
          [ClientState.APPLICATIONS_CLOSED]: {
            target: ClientState.APPLICATIONS_CLOSED,
          },
          [ClientState.GENERIC_ERROR]: {
            target: ClientState.GENERIC_ERROR,
          },
          [ClientState.MAX_APPLICATION_LIMIT]: {
            target: ClientState.MAX_APPLICATION_LIMIT,
          },

          // Re-entry flows
          [ClientState.EMAIL_VERIFY]: {
            target: ClientState.EMAIL_VERIFY,
            actions: "setUserData",
          },
          [ClientState.SMS_VERIFY]: {
            target: ClientState.SMS_VERIFY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneVerificationFailed]: {
            target: ApplicationStatusStateStateEnum.PhoneVerificationFailed,
          },
          [ApplicationStatusStateStateEnum.PhoneVerificationIndeterminate]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneTrustScoreFailed]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneTrustScoreIndeterminate]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneTrustScoreVerified]: {
            target: ClientState.PREFILL_SUCCESS,
            actions: "setUserData",
          },
          [ClientState.PREFILL_SUCCESS]: {
            target: ClientState.PREFILL_SUCCESS,
            actions: "setUserData",
          },
          [ClientState.MANUAL_ENTRY]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ClientState.UPLOAD_DOCUMENTS]: {
            target: ClientState.UPLOAD_DOCUMENTS,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.BiometricPassed]: {
            target: ClientState.UPLOAD_DOCUMENTS,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.SoftPullApproved]: {
            target: ApplicationStatusStateStateEnum.SoftPullApproved,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.HardPullApproved]: [
            {
              target: ClientState.APP_REDESIGN_PHASE_1,
              actions: "setUserData",
              guard: "isPhase1Enabled",
            },
            {
              target: ApplicationStatusStateStateEnum.HardPullApproved,
              actions: "setUserData",
            },
          ],
          [ApplicationStatusStateStateEnum.OfferExtended]: [
            {
              target: ClientState.APP_REDESIGN_PHASE_1,
              actions: "setUserData",
              guard: "isPhase1Enabled",
            },
            {
              target: ApplicationStatusStateStateEnum.OfferExtended,
              actions: "setUserData",
            },
          ],
          [ApplicationStatusStateStateEnum.CardDesignSelected]: {
            target: ClientState.REWARD_SELECTION,
            actions: "setUserData",
          },

          // Rejection states
          [ApplicationStatusStateStateEnum.SoftPullDenied]: {
            target: ApplicationStatusStateStateEnum.SoftPullDenied,
          },
          [ApplicationStatusStateStateEnum.HardPullDenied]: {
            target: ApplicationStatusStateStateEnum.HardPullDenied,
          },
          [ApplicationStatusStateStateEnum.SsnBlocklisted]: {
            target: ApplicationStatusStateStateEnum.SsnBlocklisted,
          },
          [ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied]: {
            target: ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied,
          },

          // Frozen, resume previous step immediately
          [ApplicationStatusStateStateEnum.SoftPullFrozen]: {
            target: ApplicationStatusStateStateEnum.SoftPullFrozen,
          },
          [ApplicationStatusStateStateEnum.HardPullFrozen]: {
            target: ApplicationStatusStateStateEnum.HardPullFrozen,
          },

          // KYC fails, currently no way to resolve
          [ApplicationStatusStateStateEnum.SoftPullDeceased]: {
            target: ApplicationStatusStateStateEnum.SoftPullDeceased,
          },
          [ApplicationStatusStateStateEnum.KycMismatch]: {
            target: ApplicationStatusStateStateEnum.KycMismatch,
          },
          [ApplicationStatusStateStateEnum.KycFailed]: {
            target: ApplicationStatusStateStateEnum.KycFailed,
          },
          [ApplicationStatusStateStateEnum.HardPullDeceased]: {
            target: ApplicationStatusStateStateEnum.HardPullDeceased,
          },
          [ApplicationStatusStateStateEnum.MoreInfo]: {
            target: ApplicationStatusStateStateEnum.MoreInfo,
          },
          [ApplicationStatusStateStateEnum.OfacDenied]: {
            target: ApplicationStatusStateStateEnum.OfacDenied,
          },
          [ApplicationStatusStateStateEnum.KycManualReview]: {
            target: ApplicationStatusStateStateEnum.KycManualReview,
          },
          [ApplicationStatusStateStateEnum.SoftPullServiceFailure]: {
            target: ApplicationStatusStateStateEnum.SoftPullServiceFailure,
          },
          [ApplicationStatusStateStateEnum.HardPullServiceFailure]: {
            target: ApplicationStatusStateStateEnum.HardPullServiceFailure,
          },
          [ApplicationStatusStateStateEnum.KycServiceFailure]: {
            target: ApplicationStatusStateStateEnum.KycServiceFailure,
          },
          [ApplicationStatusStateStateEnum.BiometricManual]: {
            target: ApplicationStatusStateStateEnum.BiometricDenied,
          },
          [ApplicationStatusStateStateEnum.BiometricDenied]: {
            target: ApplicationStatusStateStateEnum.BiometricDenied,
          },
          [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]: {
            target: ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert,
          },

          [ClientState.APP_REDESIGN_PHASE_1]: {
            target: ClientState.APP_REDESIGN_PHASE_1,
          },
        },
      },
      [ClientState.NEW_APPLICATION]: {
        on: {
          [ClientState.EMAIL_VERIFY]: {
            target: ClientState.EMAIL_VERIFY,
            actions: "setUserData",
          },
          [ClientState.SMS_VERIFY]: {
            target: ClientState.SMS_VERIFY,
            actions: "setUserData",
          },
        },
      },
      [ClientState.EMAIL_VERIFY]: {
        on: {
          [ClientState.SMS_VERIFY]: {
            target: ClientState.SMS_VERIFY,
            actions: "setUserData",
          },
        },
      },
      [ClientState.SMS_VERIFY]: {
        on: {
          [ClientState.PREFILL_SUCCESS]: {
            target: ClientState.PREFILL_SUCCESS,
            actions: "setUserData",
          },
          [ClientState.MANUAL_ENTRY]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneVerificationFailed]: {
            target: ApplicationStatusStateStateEnum.PhoneVerificationFailed,
          },
          [ApplicationStatusStateStateEnum.PhoneVerificationIndeterminate]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneTrustScoreFailed]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneTrustScoreIndeterminate]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ApplicationStatusStateStateEnum.PhoneTrustScoreVerified]: {
            target: ClientState.PREFILL_SUCCESS,
            actions: "setUserData",
          },
        },
      },
      [ApplicationStatusStateStateEnum.PhoneVerificationFailed]: {
        type: "final",
      },
      [ClientState.PREFILL_SUCCESS]: {
        on: {
          [ClientState.PREFILL_SUCCESS]: {
            target: ClientState.PREFILL_SUCCESS,
            actions: "setUserData",
          },
          [ClientState.MANUAL_ENTRY]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ClientState.UPLOAD_DOCUMENTS]: {
            target: ClientState.UPLOAD_DOCUMENTS,
            actions: "setUserData",
          },
          ...softPullResult,
        },
      },
      [ClientState.MANUAL_ENTRY]: {
        on: {
          [ClientState.MANUAL_ENTRY]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          [ClientState.UPLOAD_DOCUMENTS]: {
            target: ClientState.UPLOAD_DOCUMENTS,
            actions: "setUserData",
          },
          ...softPullResult,
        },
      },
      [ClientState.UPLOAD_DOCUMENTS]: {
        on: {
          [ClientState.MANUAL_ENTRY]: {
            target: ClientState.MANUAL_ENTRY,
            actions: "setUserData",
          },
          ...softPullResult,
        },
      },
      [ApplicationStatusStateStateEnum.SoftPullApproved]: {
        on: {
          [ClientState.ENTER_INCOME]: {
            target: ClientState.ENTER_INCOME,
          },
        },
      },
      [ClientState.ENTER_INCOME]: {
        on: {
          [ClientState.REVIEW_TERMS]: {
            target: ClientState.REVIEW_TERMS,
            actions: "setUserData",
          },
        },
      },
      [ClientState.REVIEW_TERMS]: {
        on: {
          ...hardPullResult,
        },
      },
      [ApplicationStatusStateStateEnum.HardPullApproved]: {
        on: {
          [ClientState.CARD_SELECTION]: {
            target: ClientState.CARD_SELECTION,
            actions: "setUserData",
          },
        },
      },
      [ApplicationStatusStateStateEnum.OfferExtended]: {
        on: {
          [ClientState.CARD_SELECTION]: {
            target: ClientState.CARD_SELECTION,
            actions: "setUserData",
          },
        },
      },
      [ClientState.CARD_SELECTION]: {
        on: {
          [ClientState.REWARD_SELECTION]: {
            target: ClientState.REWARD_SELECTION,
            actions: "setUserData",
          },
        },
      },
      [ClientState.REWARD_SELECTION]: {
        on: {
          [ClientState.AUTH_USER_INVITE]: {
            target: ClientState.AUTH_USER_INVITE,
            actions: "setUserData",
          },
        },
      },
      [ClientState.AUTH_USER_INVITE]: FINAL,
      [ClientState.APP_REDESIGN_PHASE_1]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullDenied]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullDenied]: FINAL,
      [ApplicationStatusStateStateEnum.SsnBlocklisted]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullFrozen]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullDeceased]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullFrozen]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullDeceased]: FINAL,
      [ApplicationStatusStateStateEnum.MoreInfo]: FINAL,
      [ApplicationStatusStateStateEnum.OfacDenied]: FINAL,
      [ApplicationStatusStateStateEnum.KycManualReview]: FINAL,
      [ApplicationStatusStateStateEnum.KycMismatch]: FINAL,
      [ApplicationStatusStateStateEnum.KycFailed]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullServiceFailure]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullServiceFailure]: FINAL,
      [ApplicationStatusStateStateEnum.KycServiceFailure]: FINAL,
      [ApplicationStatusStateStateEnum.BiometricDenied]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]: FINAL,
      [ApplicationStatusStateStateEnum.ApplicationPreviouslyDenied]: FINAL,

      [ClientState.RECENT_DENIED]: FINAL,
      [ClientState.NOT_IN_WAITLIST_QUEUE]: FINAL,
      [ClientState.NOT_IN_US]: FINAL,
      [ClientState.APPLICATIONS_CLOSED]: FINAL,
      [ClientState.FULL_TIER_REQUIRED]: FINAL,
      [ClientState.MAX_APPLICATION_LIMIT]: FINAL,
      [ClientState.INELIGIBLE_TO_APPLY]: FINAL,
      [ClientState.GENERIC_ERROR]: FINAL,
      [ClientState.PREQUAL_DENIED]: {
        entry: async ({ context, event }) => {
          const { appId } = context;
          const e = event as XStateEvent; // narrow type down for shouldTriggerAAN availability, default to false below
          const shouldTriggerAAN = e.shouldTriggerAAN || false;
          trackGTMEvent(CreditAnalyticsEvents.CompletePrequal, {
            eventLabel: CreditAnalyticsEventLabels.Denied,
          });

          if (shouldTriggerAAN) {
            try {
              await applicationApi.markKycDenied({ applicationId: appId });
            } catch (error) {
              Sentry.captureException(error);
            }
          }
        },
        type: "final",
      },
    },
  });
};
