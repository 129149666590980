// eslint-disable-next-line no-restricted-imports
import { AxiosRequestConfig } from "axios";

export const AXIOS_BASE_CONFIG: AxiosRequestConfig = {
  baseURL: "/",
  headers: {
    Accept: "application/json",
    "Csrf-Token": "nocheck",
  },
};
