import { IntlShape } from "react-intl";

export const DEPOSIT = "deposit";
export const WITHDRAW = "withdraw";
export const TRADE = "trade";

export const TRANSFER_LIMIT_AND_TIMINGS_TABS = [DEPOSIT, WITHDRAW, TRADE];

export const getTransferLimitAndTimingModalConstants = (intl: IntlShape) => ({
  xfersLimit: intl.formatMessage({ defaultMessage: "No limit" }),
  noLimit: intl.formatMessage({ defaultMessage: "No limit" }),

  wireTransferTiming: intl.formatMessage({ defaultMessage: "1-3 business days" }),

  xfersTransferTiming: intl.formatMessage({ defaultMessage: "Same day" }),

  debitTransferTiming: intl.formatMessage({ defaultMessage: "15 minutes or less" }),

  achAndPaypalTransferTiming: intl.formatMessage({ defaultMessage: "4-5 business days" }),
});
