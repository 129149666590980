import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { EVENTS, track } from "@gemini-ui/analytics";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { REGEX } from "@gemini-ui/constants";
import { ACCOUNT_NUMBER, NAME_ON_ACCOUNT } from "@gemini-ui/constants/wireFunding";
import { Button, Form, Input, Modal, Select, Spacer, Spacing, Text, useForm } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import {
  RegisteredXfersAccount,
  SupportedBank,
  SupportedBanks,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/constants";
import {
  handleGetSupportedBanks,
  validateAccountNumber,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/utils";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import axios from "@gemini-ui/services/axios";
import { XfersAccountType } from "@gemini-ui/transformers/PaymentMethods";
import { transformXferAccounts } from "@gemini-ui/transformers/PaymentMethods/transformPaymentMethods";
import { getError } from "@gemini-ui/utils/error";
import { getRequiredFieldErrorString } from "@gemini-ui/utils/getRequiredFieldErrorString";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
import { getFieldData } from "@gemini-ui/utils/wireFunding";
/* istanbul ignore next */
const bankRegRoute = jsRoutes.com.gemini.web.server.funding.controllers.XfersSettingsController.post().url;

export interface Props {
  supportedBanks: SupportedBanks;
  setSupportedBanks: (banks: SupportedBanks) => void;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  onSubmit: (registeredXfersAccount: RegisteredXfersAccount, userBankAccounts: XfersAccountType[]) => void;
}

const createDropDownValue = (bank: SupportedBank) => {
  if (!bank) {
    return null;
  }
  const { bankName, bankAbbreviation } = bank;
  return {
    value: bankAbbreviation,
    label: `${bankName} (${bankAbbreviation})`,
  };
};

type XfersBankRegistrationForm = {
  [NAME_ON_ACCOUNT]: string;
  bankCode: string;
  [ACCOUNT_NUMBER]: string;
};

export const XfersBankRegistration = ({
  isOpen,
  onBack,
  onSubmit,
  onClose,
  supportedBanks = [],
  setSupportedBanks,
}: Props) => {
  const { intl } = useIntl();
  const requiredFieldString = getRequiredFieldErrorString(intl);
  const fieldData = getFieldData(intl);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    control,
  } = useForm<XfersBankRegistrationForm>({
    defaultValues: {
      [fieldData.nameOnAccount.apiField]: "",
      bankCode: "",
      accountNumber: "",
    },
  });
  const validateAccountNumberField = () => {
    const { bankCode, accountNumber } = getValues();
    const accountNumberErrorString = validateAccountNumber(accountNumber, bankMap[bankCode], intl);
    return !Boolean(accountNumberErrorString) || accountNumberErrorString;
  };

  const { field } = useController<XfersBankRegistrationForm>({
    name: "bankCode",
    control,
    rules: {
      required: requiredFieldString,
    },
  });

  const { showAlert, hideAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (!supportedBanks.length) {
      setIsLoading(true);
      handleGetSupportedBanks()
        .then(response => {
          setSupportedBanks(response.data.supportedBanks);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          const errorMessage = getError(
            err,
            intl.formatMessage({
              defaultMessage: "Unable to fetch supported banks. Please contact support for assistance.",
            })
          );
          showAlert({
            type: AlertTypes.ERROR,
            message: errorMessage,
            timeout: 8000,
          });
          trackPaymentRegistrationFailure(LinkPaymentType.XFERS, errorMessage);
        });
    }
  }, [setSupportedBanks, showAlert, supportedBanks.length, intl]);

  const handleBankRegistrationSubmit = async (values: XfersBankRegistrationForm) => {
    const { name, properties } = EVENTS.SUBMIT_BANK_INFO;
    hideAlert();
    try {
      setIsSubmitting(true);
      const { data } = await axios.post(bankRegRoute, {
        bankCode: values.bankCode,
        accountNumber: values.accountNumber,
        nameOnAccount: values.nameOnAccount,
      });
      track(name, { [properties.SUBMITTED_SUCCESSFULLY]: true });
      setIsSubmitting(false);
      onSubmit(data.xfersAccount, transformXferAccounts(data.xfersAccounts));
    } catch (err) {
      setIsSubmitting(false);
      const errorMessage = getError(err);
      track(name, { [properties.SUBMITTED_SUCCESSFULLY]: false });
      trackPaymentRegistrationFailure(LinkPaymentType.XFERS, errorMessage);
      showAlert({
        type: AlertTypes.ERROR,
        message: errorMessage,
        timeout: 8000,
      });
    }
  };

  const { bankItems, bankMap } = supportedBanks.reduce<{
    bankItems: Array<{
      value: string;
      label: string;
    }>;
    bankMap: Record<string, SupportedBank>;
  }>(
    ({ bankItems, bankMap }, bank) => {
      bankItems.push(createDropDownValue(bank));
      bankMap[bank.bankAbbreviation] = bank;
      return {
        bankItems,
        bankMap,
      };
    },
    {
      bankItems: [],
      bankMap: {},
    }
  );

  const { nameOnAccount } = getFieldData(intl);

  /** Generate the mask based on the rules of the selected bank */
  const accountNumberMask = useMemo(() => {
    const { bankCode } = getValues();
    const bank = bankMap[bankCode];
    const accountNumberMaxLength = bank ? bank.maxLength : 21;

    return Array.from({ length: accountNumberMaxLength }, () => REGEX.NUMERIC);
  }, [bankMap, getValues]);

  return (
    <Modal.MultiStep
      isOpen={isOpen}
      onBack={onBack}
      onClose={onClose}
      title={intl.formatMessage({
        defaultMessage: "Register bank account",
      })}
      hasDropdown
    >
      {isLoading ? (
        <SpinnerAnimation center size={Spacing.scale[8]} mt={8} mb={8} />
      ) : (
        <Fragment>
          <Text.Body mt={1}>
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "Enter your bank details below. Please note that your bank account must be held in your name. <HelpCenterLinkArticlesAddXfersAccount>Need help? See FAQ.</HelpCenterLinkArticlesAddXfersAccount>",
              }),
              {
                HelpCenterLinkArticlesAddXfersAccount: (v: ReactNode) => (
                  <HelpCenterLink article={Articles.ADD_XFERS_ACCOUNT}>{v}</HelpCenterLink>
                ),
              }
            )}
          </Text.Body>
          <Spacer mt={4}>
            <Form onSubmit={handleSubmit(handleBankRegistrationSubmit)} errors={errors}>
              <Input
                {...register(NAME_ON_ACCOUNT, { required: requiredFieldString })}
                data-testid="name-on-account-xfers-input"
                label={nameOnAccount.label}
                placeholder={nameOnAccount.placeholder}
                error={errors[nameOnAccount.apiField]?.message}
              />
              <Select
                data-testid="xfers-bank-dropdown"
                error={errors?.bankCode?.message}
                {...field}
                id="bankCode"
                label={intl.formatMessage({ defaultMessage: "Bank name" })}
                options={bankItems}
              />
              <Input
                {...register(ACCOUNT_NUMBER, {
                  required: requiredFieldString,
                  validate: validateAccountNumberField,
                })}
                defaultValue={undefined}
                data-testid="xfers-account-input"
                error={errors.accountNumber?.message}
                label={intl.formatMessage({
                  defaultMessage: "Bank account number",
                })}
                mask={accountNumberMask}
                placeholder={intl.formatMessage({
                  defaultMessage: "Your bank account number",
                })}
              />
              <Button.Group>
                <Button.Tertiary data-testid="cancel-xfers-register" onClick={onClose}>
                  {intl.formatMessage({
                    defaultMessage: "Cancel",
                  })}
                </Button.Tertiary>
                <Button.Primary data-testid="next-xfers-register" type="submit" loading={isSubmitting}>
                  {intl.formatMessage({
                    defaultMessage: "Next",
                  })}
                </Button.Primary>
              </Button.Group>
            </Form>
          </Spacer>
        </Fragment>
      )}
    </Modal.MultiStep>
  );
};
