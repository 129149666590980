import { Fragment } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { WireDepositDetails } from "@gemini-ui/components/WireDepositDetails";
import { Button, EmptyState, Flex, Modal, Spacing, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { VirtualAccountStatus } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { useGetRtpInstructions } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/hooks/useGetInstructions";
import { ModalTitle } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/ModalTitle";
import { useIntl } from "@gemini-ui/utils/intl";

type InstructionModalPropsType = {
  onClose: () => void;
  isOpen: boolean;
  onBack?: () => void;
  subaccountHashid: string;
  currency: CurrencyShortNameFiat;
};

export const InstructionModal = ({
  onClose,
  isOpen,
  onBack,
  subaccountHashid,
  currency,
}: InstructionModalPropsType) => {
  const { intl } = useIntl();
  const { instructions, refetch, error, isLoading } = useGetRtpInstructions(subaccountHashid, currency);
  const defaultErrorMessage = intl.formatMessage({
    defaultMessage: "There was an error getting wire instructions. Please try again or contact support for assistance.",
  });

  const renderInstructionStatuses = (status: VirtualAccountStatus) => {
    switch (status) {
      case "Active":
        return (
          <WireDepositDetails
            currency={currency}
            isRtp={true}
            internationalBankInfo={instructions}
            subaccountHashid={subaccountHashid}
          />
        );
      case "Deleted":
      case "Rejected":
        return (
          <EmptyState
            label={intl.formatMessage({
              defaultMessage: "Sorry, we couldn’t get your FAST deposit instructions",
            })}
            description={intl.formatMessage({
              defaultMessage: "Please try again or contact customer support.",
            })}
            status="negative"
          />
        );
      case "Pending":
        return (
          <EmptyState
            label={intl.formatMessage({
              defaultMessage: "Your deposit instructions are being prepared.",
            })}
            description={intl.formatMessage({
              defaultMessage: "It usually takes up to 24 hours. We will notify you as soon as it's ready.",
            })}
            status="informational"
          />
        );
      default:
        return null;
    }
  };

  const renderModalContent = () => {
    if (error) {
      return (
        <Fragment>
          <Text.Body size="md">{defaultErrorMessage}</Text.Body>
          <Button.Group>
            <Button.Primary onClick={() => refetch()} data-testid="retry-get-wire-instructions-btn">
              {intl.formatMessage({ defaultMessage: "Try again" })}
            </Button.Primary>
          </Button.Group>
        </Fragment>
      );
    } else if (isLoading) {
      return (
        <Flex mt={8} mb={8} justifyContent="center" alignItems="center">
          <SpinnerAnimation size={Spacing.scale[4]} />
        </Flex>
      );
    } else {
      return instructions && renderInstructionStatuses(instructions.vaStatus);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle
        title={
          <Text.Heading size="md" mb={3}>
            {intl.formatMessage({ defaultMessage: "FAST Instructions" })}
          </Text.Heading>
        }
        onBack={onBack}
        onClose={onClose}
      />
      {renderModalContent()}
      <Button.Group align="right">
        <Button.Primary
          onClick={onClose}
          data-testid="rtp-instruction-got-it-btn"
          cta={intl.formatMessage({ defaultMessage: "Got it" })}
        />
      </Button.Group>
    </Modal>
  );
};
