import { UnauthorizedDeviceDialogProps } from "@gemini-ui/components/Header/UnauthorizedDeviceDialog/index";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const UnauthorizedDeviceDialog = LazyModalWrapper(() => import("./index"));

function LazyLoadUnauthorizedDeviceDialog(props: UnauthorizedDeviceDialogProps) {
  return <UnauthorizedDeviceDialog {...props} />;
}

export default LazyLoadUnauthorizedDeviceDialog;
