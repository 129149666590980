import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { track } from "@gemini-ui/analytics";
import { usePageData } from "@gemini-ui/contexts";
import axios from "@gemini-ui/services/axios";

const ROUTE_PATH = window.location.pathname;
const ASSET_DETAIL_PATH = "/buy/";
const CREDIT_CARD_PATH = "/credit-card";

export const useTradeUI = () => {
  const {
    templateProps: {
      user: { advancedTradeUIEnabled },
      account: { defaultFiat, supportedPairs },
    },
  } = usePageData();
  const [isActiveTrader, setIsActiveTrader] = useState(advancedTradeUIEnabled);
  const [isUpdatingUI, setIsUpdatingUI] = useState(false);

  // Checks symbol against supportedPairs list, and allPairDetails from AT endpoint
  // Redirects to either supported AT chart page for the symbol or BTCUSD as a fallback

  const switchTradeUI = useCallback(
    async (withRedirect = true) => {
      const getActiveTraderPage = async maybeSupportedSymbol => {
        const isMaybeSupportedPair = supportedPairs.some(
          pair => maybeSupportedSymbol === pair.symbol && pair.activeTrader
        );

        await axios
          .get(jsRoutes.com.gemini.web.server.trading.controllers.OrderControllerV2.get(maybeSupportedSymbol).url)
          .then(result => {
            const allPairDetails = result.data?.pageProps?.allPairDetails;
            const isSupportedPair = allPairDetails.some(pair => maybeSupportedSymbol === pair.symbol);
            const activeTraderUrl =
              isMaybeSupportedPair && isSupportedPair ? `/trade/${maybeSupportedSymbol}` : `/trade/BTCUSD`;
            window.location.assign(activeTraderUrl);
          })
          .catch(error => {
            Sentry.captureException(error);
          });
      };
      // If the interface has been changed, set updating UI state for loading animation
      setIsUpdatingUI(true);

      // Toggle user's trading interface between Gemini Retail <-> ActiveTrader
      setIsActiveTrader(isActiveTrader => !isActiveTrader);

      await axios
        .post(
          jsRoutes.com.gemini.web.server.experience.controllers.UserExchangeSettingsController.post().url,
          {
            advancedTradeUIEnabled: !isActiveTrader,
          },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          // Mixpanel tracking
          track("Trading Interface Selected", {
            Interface: !isActiveTrader ? "Active Trader" : "Gemini",
          });
        })
        .catch(error => {
          Sentry.captureException(error);
        });
      if (withRedirect) {
        if (
          (!isActiveTrader && ROUTE_PATH.startsWith(ASSET_DETAIL_PATH)) ||
          (!isActiveTrader && ROUTE_PATH.startsWith(CREDIT_CARD_PATH))
        ) {
          if (ROUTE_PATH.startsWith(CREDIT_CARD_PATH)) {
            window.location.assign(`/trade`);
            return;
          }
          const currency = ROUTE_PATH.split(ASSET_DETAIL_PATH).pop();
          const maybeSupportedSymbol = currency + defaultFiat;
          getActiveTraderPage(maybeSupportedSymbol);
        } else {
          window.location.assign("/");
        }
      }
    },
    [isActiveTrader, defaultFiat, supportedPairs]
  );

  return { isActiveTrader, isUpdatingUI, switchTradeUI };
};
