import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { optimizelyClient } from "@gemini-ui/analytics";
import { offerApi } from "@gemini-ui/client/api";
import { Offer } from "@gemini-ui/client/credit";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";

export const useGetOfferData = (offerId: string) => {
  const [offer, setOffer] = useState<Offer | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await offerApi.getOffer(offerId);
      setOffer(data);
    } catch (err) {
      setHasError(true);
      Sentry.captureException(err);
    } finally {
      setIsLoading(false);
    }
  }, [offerId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const eligibility = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CREDIT_CARD_CLI)
    ? offer?.plaidIncomeVerificationStatus ?? null
    : null;

  return { offer, eligibility, isLoading, hasError, refetch: fetchData };
};
