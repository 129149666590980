import * as React from "react";
import { useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { ConfirmBankDetailsModal } from "@gemini-ui/components/ConfirmBankDetailsModal";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { BankDetailsFormType } from "@gemini-ui/constants/giact";
import { usePageData } from "@gemini-ui/contexts";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import BankBlockedModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/BankBlockedModal";
import { DuplicateAccountModal } from "@gemini-ui/pages/settings/BankSettings/components/DuplicateAccountModal";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getError, getFormErrors } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";
import { transformAddWireValues } from "@gemini-ui/utils/wireFunding";

interface WireSubmission {
  customerBankName: string;
  wireDepositDetails: string;
}

interface ConfirmWireDetailsModalProps {
  currency: CurrencyShortNameFiat;
  isInstitutional: boolean;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  onSubmit: (data: WireSubmission) => void;
  title?: string;
  wireDetails: Partial<BankDetailsFormType>;
  subaccountHashid: string;
}

const addWireRoute = jsRoutes.com.gemini.web.server.funding.controllers.WireSettingsController.addBankAccount().url;

const ConfirmWireDetailsModal = ({
  currency,
  isInstitutional,
  isOpen,
  onBack,
  onClose,
  onSubmit,
  title = "",
  wireDetails,
  subaccountHashid,
}: ConfirmWireDetailsModalProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { showAlert, hideAlert, showGenericFormErrorAlert } = useAlert();
  const [isDuplicateAccount, setIsDuplicateAccount] = useState(false);
  const [isBankBlocked, setIsBankBlocked] = useState(false);
  const { intl } = useIntl();
  const {
    templateProps: {
      user: { fullName },
    },
  } = usePageData();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    hideAlert();
    try {
      const { data } = await axios.post(
        addWireRoute,
        transformAddWireValues(wireDetails, {
          currency,
          isInstitutional,
          nameOnAccount: fullName,
        }),
        { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } }
      );
      const { custBankName, wireDepositDetails } = data.pageProps;
      track(EVENTS.FINISH_MANUAL_BANK_INFO_ENTRY.name, {}, { braze: true });
      onSubmit({ customerBankName: custBankName, wireDepositDetails });
    } catch (err) {
      setIsSubmitting(false);
      const errorMessage = getError(err);
      if (err?.response?.status === 409) {
        // no failure tracking needed
        setIsDuplicateAccount(true);
      } else if (err?.response?.status === 401) {
        setIsBankBlocked(true);
      } else {
        const formErrors = getFormErrors(err);
        if (formErrors) {
          Object.keys(formErrors).forEach(key => {
            const error = formErrors[key][0];
            trackPaymentRegistrationFailure(LinkPaymentType.MANUAL, error);
            showGenericFormErrorAlert({
              formErrors,
              message: `${error}. Please check your input fields and try again.`,
            });
          });
        } else {
          trackPaymentRegistrationFailure(LinkPaymentType.MANUAL, errorMessage);
          showAlert({
            type: AlertTypes.ERROR,
            message: errorMessage,
            timeout: 8000,
          });
        }
      }
    }
  };

  if (isDuplicateAccount) {
    return (
      <DuplicateAccountModal
        onClose={() => {
          setIsDuplicateAccount(false);
          onClose();
        }}
        subTitleText={intl.formatMessage({
          defaultMessage: "Now it is also eligible for Wire deposits. Start trading now or make a deposit.",
        })}
      />
    );
  } else if (isBankBlocked) {
    return (
      <BankBlockedModal
        isOpen={isOpen}
        onClose={() => {
          setIsBankBlocked(false);
          onClose();
        }}
      />
    );
  }
  return (
    <ConfirmBankDetailsModal
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      values={wireDetails}
      onConfirmationSuccess={handleSubmit}
      onBack={onBack}
      onConfirmationClose={onClose}
      title={title}
      type={LinkPaymentType.WIRE}
      currency={currency}
    />
  );
};

export default ConfirmWireDetailsModal;
