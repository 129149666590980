import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import axios from "@gemini-ui/services/axios";
import { DepositLimits } from "@gemini-ui/services/transfer/types";
import { PaymentMethodDataType } from "@gemini-ui/transformers/PaymentMethods/constants";
import { getError } from "@gemini-ui/utils/error";

export const getPaymentMethods = async (currency?: CurrencyShortNameFiat) => {
  const queryParams = currency ? `?currency=${currency}` : "";
  return await axios
    .get<{ data: PaymentMethodDataType[] }>(`/payments/payment-methods${queryParams}`, {
      retry: 3,
    })
    .then(res => res.data.data)
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const usePaymentMethods = (currency?: CurrencyShortNameFiat, filterPaymentMethodsByCurrency?: boolean) => {
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDataType[]>([]);
  const [paymentMethodsError, setPaymentMethodsError] = useState<string>(null);

  const fetchPaymentMethods = useCallback(
    (setIsLoading: boolean) => {
      if (setIsLoading) setIsLoadingPaymentMethods(true);
      getPaymentMethods(filterPaymentMethodsByCurrency ? currency : undefined)
        .then(data => {
          setPaymentMethods(data);
          setPaymentMethodsError(null);
        })
        .catch(e => setPaymentMethodsError(getError(e)))
        .finally(() => setIsLoadingPaymentMethods(false));
    },
    [currency, filterPaymentMethodsByCurrency]
  );

  useEffect(() => {
    fetchPaymentMethods(false);
  }, [fetchPaymentMethods]);

  return {
    paymentMethods,
    fetchPaymentMethods,
    isLoadingPaymentMethods,
    paymentMethodsError,
  };
};

export const getPaymentLimits = async (currency?: CurrencyShortNameFiat) => {
  const queryParams = currency ? `?currency=${currency}` : "";
  return await axios
    .get<DepositLimits>(`/payments/transfers/limits/deposit${queryParams}`, {
      retry: 3,
    })
    .then(res => res.data)
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const usePaymentLimits = (currency?: CurrencyShortNameFiat) => {
  const [isLoadingLimits, setIsLoadingLimits] = useState(true);
  const [limitsError, setLimitsError] = useState<string>(null);
  const [limits, setLimits] = useState<DepositLimits | null>(null);

  const fetchLimits = useCallback(
    (setIsLoading: boolean) => {
      if (setIsLoading) setIsLoadingLimits(true);
      getPaymentLimits(currency)
        .then(data => {
          setLimits(data);
          setLimitsError(null);
        })
        .catch(e => setLimitsError(getError(e)))
        .finally(() => setIsLoadingLimits(false));
    },
    [currency]
  );

  useEffect(() => {
    fetchLimits(false);
  }, [fetchLimits]);

  return {
    limits,
    fetchLimits,
    isLoadingLimits,
    limitsError,
  };
};

export const enum PaymentMethodEligibility {
  ALL = "all",
  BUY = "buy",
  DEPOSIT = "deposit",
  WITHDRAW = "withdraw",
}

/**
 * @description
 * This hook is a combination of usePaymentMethods and usePaymentLimits.
 *
 * @param currency Usually your defaultFiat so payment methods and limits are filtered by account currency
 * @param eligibility defaults to ALL, but can be set to BUY, DEPOSIT, or WITHDRAW (Retail, Cash Deposit, or Transfers).
 */
export const usePaymentData = (
  currency?: CurrencyShortNameFiat,
  paymentMethodEligibility = PaymentMethodEligibility.ALL,
  filterPaymentMethodsByCurrency = true
) => {
  const { paymentMethods, isLoadingPaymentMethods, fetchPaymentMethods, paymentMethodsError } = usePaymentMethods(
    currency,
    filterPaymentMethodsByCurrency
  );

  const { limits, isLoadingLimits, fetchLimits, limitsError } = usePaymentLimits(currency);

  const refetchPaymentData = useCallback(
    (setIsLoading = false) => {
      fetchPaymentMethods(setIsLoading);
      fetchLimits(setIsLoading);
    },
    [fetchLimits, fetchPaymentMethods]
  );

  return {
    paymentMethods:
      paymentMethodEligibility === PaymentMethodEligibility.ALL
        ? paymentMethods
        : paymentMethods.filter(p => p.eligibility[paymentMethodEligibility]),
    limits,
    isLoading: isLoadingPaymentMethods || isLoadingLimits,
    hasError: Boolean(limitsError) || Boolean(paymentMethodsError),
    refetchPaymentData,
  };
};
