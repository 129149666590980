import React from "react";
import { useTheme } from "@emotion/react";
import { IconProfileMultiple } from "@hubble/icons";
import { optimizelyClient } from "@gemini-ui/analytics";
import { ReferralCodeInfoResponse } from "@gemini-ui/client/credit";
import { Money } from "@gemini-ui/components/Money";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Colors, Flex, HubbleCard, IconBadge, Text } from "@gemini-ui/design-system";
import { useGetReferralAppDetails } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/hooks/useGetReferralAppDetails";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface ValidReferralProps {
  referralInfo: ReferralCodeInfoResponse;
}

export const ValidReferral = ({ referralInfo }: ValidReferralProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();
  const { referralAppDetails, isLoading } = useGetReferralAppDetails();
  const isReferralPhase2Enabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CREDIT_CARD_REFERRAL_PHASE_2
  );

  if (isLoading || !referralAppDetails) return null;

  const spendPromoAmount = <Money {...referralAppDetails.spendPromoAmount} hideTrailingSign decimalsOverride={0} />;
  const totalPromoAmount = <Money {...referralAppDetails.totalPromoAmount} hideTrailingSign decimalsOverride={0} />;
  const referralRewardAmount = (
    <Money {...referralAppDetails.referralRewardAmount} hideTrailingSign decimalsOverride={0} />
  );

  return (
    <React.Fragment>
      <HubbleCard variant="filled" customBgColor={Colors.purple[50]} density="md" pt={2} pb={2}>
        <Flex alignItems="center">
          <IconBadge
            size="lg"
            backgroundColor={colorScheme.status.default.background.reward}
            icon={<IconProfileMultiple color={colorScheme.status.default.content.reward} />}
          />
          <Flex.Column ml={1.5}>
            <Text.Body bold mb={0.5}>
              {isReferralPhase2Enabled
                ? intl.formatMessage(defineMessage({ defaultMessage: "{name} referred you a special offer" }), {
                    name: referralInfo.referrerDetails?.name?.firstName,
                  })
                : intl.formatMessage({ defaultMessage: "You are referred for a special offer" })}
            </Text.Body>
            <Text.Body size="sm">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "Get {totalPromoAmount} in crypto! {referralRewardAmount} when you are approved, {spendPromoAmount} after meeting spend requirements.",
                }),
                {
                  totalPromoAmount: totalPromoAmount,
                  referralRewardAmount: referralRewardAmount,
                  spendPromoAmount: spendPromoAmount,
                }
              )}
            </Text.Body>
          </Flex.Column>
        </Flex>
      </HubbleCard>
      <Text.Body size="xs" color={colorScheme.content.secondary} mt={1.5} mb={2}>
        {intl.formatMessage(defineMessage({ defaultMessage: "{terms}" }), {
          terms: referralAppDetails.applicationTerms,
        })}{" "}
        <Text.Link
          href={referralAppDetails.referralTermsLink}
          target="_blank"
          rel="noopener noreferrer"
          color={colorScheme.content.secondary}
        >
          {intl.formatMessage({ defaultMessage: "Referral Program Terms" })}
        </Text.Link>
        {" & "}
        <Text.Link
          href={referralAppDetails.promoTermsLink}
          target="_blank"
          rel="noopener noreferrer"
          color={colorScheme.content.secondary}
        >
          {intl.formatMessage({ defaultMessage: "Intro Promo Terms Apply." })}
        </Text.Link>
      </Text.Body>
    </React.Fragment>
  );
};
