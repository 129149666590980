import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { getQuoteError } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import { Action, OrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import axios, { AxiosRequestConfig } from "@gemini-ui/services/axios";
import { useIntl } from "@gemini-ui/utils/intl";

export type InstantOrderQuoteRouteQueryParams = {
  to: CurrencyShortName;
  from: CurrencyShortName;
  totalSpend: number | string;
  isDirectlyFundingDerivativeAccount?: boolean;
  totalSpendCurrency?: CurrencyShortName;
  paymentMethodUuid?: string;
  paymentMethodType?: AccountType;
  internalReference?: string;
  isRecurringOrder?: boolean;
  cardAccountType?: string;
  earnProviderId?: string;
  side?: Action;
};

export const createInstantOrderQuoteUrl = (queryParams: InstantOrderQuoteRouteQueryParams) => {
  const commonArgs = [
    queryParams.to,
    queryParams.from,
    queryParams.totalSpend,
    queryParams?.paymentMethodUuid,
    queryParams?.paymentMethodType,
    queryParams?.internalReference,
    queryParams?.isRecurringOrder,
    queryParams?.cardAccountType,
    queryParams?.earnProviderId,
    queryParams?.side,
  ];

  if (queryParams?.side && queryParams?.totalSpendCurrency) {
    return jsRoutes.com.gemini.web.server.trading.controllers.InstantOrderController.getQuoteV2(
      ...commonArgs,
      queryParams?.totalSpendCurrency
    ).url;
  }

  return jsRoutes.com.gemini.web.server.trading.controllers.InstantOrderController.quote(
    ...commonArgs,
    queryParams?.isDirectlyFundingDerivativeAccount || false
  ).url;
};

export const getInstantOrderQuote = async (args: InstantOrderQuoteRouteQueryParams, config?: AxiosRequestConfig) => {
  const url = createInstantOrderQuoteUrl(args);
  return await axios
    .get<OrderQuote>(url, config)
    .then(res => res.data)
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const useInstantOrderQuote = () => {
  const { intl } = useIntl();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState<OrderQuote | null>(null);

  const fetchQuote = useCallback(
    async (args: InstantOrderQuoteRouteQueryParams, config?: AxiosRequestConfig): Promise<OrderQuote> => {
      try {
        setLoading(true);
        const data = await getInstantOrderQuote(args, config);
        setData(data);
        setError(null);

        // Returning the data and setting the data to local state is redundant but may be helpful depending on how this function is called.
        return data;
      } catch (e) {
        setError(getQuoteError(e, intl));
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [intl]
  );

  return {
    data,
    fetchQuote,
    loading,
    error,
  };
};
