import {
  TransfersAccountsInitialData,
  TransfersBankAccountInitialData,
} from "@gemini-ui/constants/initialData/pageProps/transfers";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import {
  BankAccountTransferLevel,
  BankType,
  TransfersFiatDetailsType,
} from "@gemini-ui/transformers/PaymentMethods/constants";
import {
  transformBcoloData,
  transformPayPalData,
  transformXfersData,
} from "@gemini-ui/transformers/PaymentMethods/transformBankSettings";
import {
  transformBcoloAccounts,
  transformDebitCards,
  transformPayPalAccounts,
  transformXferAccounts,
} from "@gemini-ui/transformers/PaymentMethods/transformPaymentMethods";
import { extractPaymentMethodMetaDataFromName } from "@gemini-ui/transformers/PaymentMethods/utils";

const transformBanks = (bankAccounts: TransfersBankAccountInitialData[]): Array<BankType> => {
  return bankAccounts.map(bankAccount => {
    const {
      name,
      uuid,
      currency,
      achApproved,
      wireApproved,
      senApproved,
      blincApproved,
      rtpApproved,
      supportsPlaidPaymentInitiation,
    } = bankAccount;
    const { institutionName, lastFour, rawName, accountType } = extractPaymentMethodMetaDataFromName(name);
    return {
      paymentMethodType: PaymentMethodType.BANK,
      id: uuid,
      displayName: rawName,
      institutionName,
      lastFour,
      currencies: [currency],
      accountType,
      rtpApproved,
      isVerified: achApproved || wireApproved || rtpApproved,
      achLevel: achApproved ? BankAccountTransferLevel.Approved : BankAccountTransferLevel.NoTransfers,
      wireLevel: wireApproved ? BankAccountTransferLevel.Approved : BankAccountTransferLevel.NoTransfers,
      senLevel: senApproved ? BankAccountTransferLevel.Approved : BankAccountTransferLevel.NoTransfers,
      blincLevel: blincApproved ? BankAccountTransferLevel.Approved : BankAccountTransferLevel.NoTransfers,
      rtpLevel: rtpApproved ? BankAccountTransferLevel.Approved : BankAccountTransferLevel.NoTransfers,
      supportsPlaidPaymentInitiation,
    };
  });
};

export const transformTransfersFiatDetails = (data: TransfersAccountsInitialData): TransfersFiatDetailsType => {
  const transformedBanks = transformBanks(data.bankAccounts.accounts);
  const transformedBcolo = transformBcoloAccounts(data.bcoloAccounts.accounts);
  const transformXfers = transformXferAccounts(data.xfersAccounts.accounts);
  const transformedCards = transformDebitCards(data.cardAccounts?.accounts || []);
  const transformedPayPal = transformPayPalAccounts(data.payPalAccounts.accounts);

  const paymentMethods = [
    ...transformedBanks,
    ...transformedBcolo,
    ...transformXfers,
    ...transformedCards,
    ...transformedPayPal,
  ];

  return {
    paymentMethods,
    achData: {
      limits: {
        dailyDeposit: data.dailyDepositLimit,
        thirtyDayDeposit: data.thirtyDayDepositLimit,
        withdrawalDaily: data.dailyWithdrawalLimit,
      },
    },
    ppiData: {
      limits: data.ppiDepositLimit,
    },
    bcoloData: {
      ...transformBcoloData(data.bcoloAccounts),
      limits: {
        thirtyDayDeposit: data.bcoloThirtyDayDepositLimit,
        thirtyDayWithdrawal: data.bcoloThirtyDayWithdrawalLimit,
      },
    },
    xfersData: {
      ...transformXfersData(data.xfersAccounts),
      limits: {
        deposit: data.xfersDepositLimit,
        withdrawal: data.xfersWithdrawalLimit,
      },
    },
    payPalData: {
      ...transformPayPalData(data.payPalAccounts),
      limits: {
        deposit: {
          minimum: { currency: data.payPalDepositLimit?.total.currency, value: "5" },
          ...data.payPalDepositLimit,
        },
      },
    },
    rtpData: {
      limits: data.rtpWithdrawalLimits,
    },
  };
};
