import { Component, Fragment } from "react";

interface ChallengeFormProps {
  handleSubmit: () => void;
  jwt: string;
  md: string;
  url: string;
}

class ChallengeForm extends Component<ChallengeFormProps> {
  componentDidMount() {
    window.addEventListener("message", this.handleMessage, false);
    (document.getElementById("challengeForm") as HTMLFormElement).submit();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage, false);
  }

  handleMessage = (x: MessageEvent) => {
    if (x.origin.endsWith(window.location.origin) && x?.data === "success") {
      this.props.handleSubmit();
    }
  };

  render() {
    const { jwt, md, url } = this.props;
    return (
      <Fragment>
        <iframe id="challenge" name="challenge" title="challenge" height="400" width="390" />
        <form
          id="challengeForm"
          name="challengeForm"
          method="POST"
          action={url}
          target="challenge"
          style={{ display: "none" }}
        >
          <input type="hidden" name="JWT" value={jwt} />
          <input type="hidden" name="MD" value={md} />
        </form>
      </Fragment>
    );
  }
}

export default ChallengeForm;
