import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { Totals } from "@gemini-ui/pages/RetailTrade/constants";
import axios, { CACHE_ID } from "@gemini-ui/services/axios/cache";
import { getError } from "@gemini-ui/utils/error";

export const totalPortfolioUrl =
  jsRoutes.com.gemini.web.server.trading.controllers.AccountController.accountBalancesAllAccounts().url;

export type AccountTotals = Pick<
  Totals,
  "totalNotional" | "totalNotionalTradingBalance" | "totalNotionalInterestBalance"
>;

export interface TotalPortfolioResponse {
  totals: AccountTotals;
}

export const getTotalPortfolio = async () => {
  return await axios
    .get<TotalPortfolioResponse>(totalPortfolioUrl, {
      id: CACHE_ID.totalPortfolio,
      cache: {
        ttl: 1000,
      },
      retry: 3,
    })
    .then(response => {
      if (!response) throw new Error("No response when getting total portfolio value.");
      return response.data;
    })
    .catch(error => {
      Sentry.captureException(error);
      throw error;
    });
};

export const useAccountTotalPortfolio = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<TotalPortfolioResponse | null>(null);

  const fetchTotalPortfolio = useCallback(() => {
    getTotalPortfolio()
      .then(data => {
        if (data) {
          setData(data);
          setError(null);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setError(getError(error));
      });
  }, []);

  useEffect(() => fetchTotalPortfolio(), [fetchTotalPortfolio]);

  return {
    data,
    error,
    loading,
    fetchTotalPortfolio,
  };
};
