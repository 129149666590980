import { defineMessage, IntlShape } from "react-intl";
import { SubaccountWithBalances } from "@gemini-ui/services/subaccounts";

export interface ExtendedSubaccountWithBalances extends SubaccountWithBalances {
  needsActivation?: boolean;
}
export const FAKE_SUBBACCOUNT: ExtendedSubaccountWithBalances = {
  hashid: "activate-account",
  name: "Derivative Account",
  shortName: "Derivative Account",
  derivatives: true,
  needsActivation: true,
  trading: true,
  coldStorage: false,
  type: "derivatives",
};

export const successTypes = {
  DEFAULT: "default",
  MAV: "mav",
  BUTTON: "button",
};

export const promiseTypes = {
  LEVERAGE: "leverage",
  COLLATERAL: "collateral",
  NAME: "name",
};

export const leverage = (intl: IntlShape) => [
  { label: intl.formatMessage({ defaultMessage: "1x" }), value: "1" },
  { label: intl.formatMessage({ defaultMessage: "2x" }), value: "2" },
  { label: intl.formatMessage({ defaultMessage: "5x" }), value: "5" },
  { label: intl.formatMessage({ defaultMessage: "10x" }), value: "10" },
  { label: intl.formatMessage({ defaultMessage: "20x (default)" }), value: "20" },
  { label: intl.formatMessage({ defaultMessage: "50x" }), value: "50" },
  { label: intl.formatMessage({ defaultMessage: "100x" }), value: "100" },
];

export const getAdvancedAccountSelectorCopy = (intl: IntlShape) => ({
  ARIA: {
    EDIT_ACCOUNT_BACK_BUTTON: intl.formatMessage({ defaultMessage: "Nav account selector edit mode back button" }),
  },
  BADGE: {
    LEVERAGE_1X: intl.formatMessage({ defaultMessage: "1x" }),
    LEVERAGE_2X: intl.formatMessage({ defaultMessage: "2x" }),
    LEVERAGE_5X: intl.formatMessage({ defaultMessage: "5x" }),
    LEVERAGE_10X: intl.formatMessage({ defaultMessage: "10x" }),
    LEVERAGE_20X: intl.formatMessage({ defaultMessage: "20x" }),
    LEVERAGE_50X: intl.formatMessage({ defaultMessage: "50x" }),
    LEVERAGE_100X: intl.formatMessage({ defaultMessage: "100x" }),
    CROSS_COLLATERAL: intl.formatMessage({ defaultMessage: "Cross collat" }),
    NEGATIVE_BALANCE: intl.formatMessage({ defaultMessage: "Neg bal" }),
  },
  CTA: {
    DONE: intl.formatMessage({ defaultMessage: "Done" }),
    CANCEL_BUTTON: intl.formatMessage({ defaultMessage: "Cancel" }),
    SAVE_BUTTON: intl.formatMessage({ defaultMessage: "Save" }),
    ADD_NEW_ACCOUNT: intl.formatMessage({ defaultMessage: "Add new account" }),
    EDIT_ACCOUNTS: intl.formatMessage({ defaultMessage: "Edit accounts" }),
    TRANSFER_BUTTON: intl.formatMessage({ defaultMessage: "Transfer funds" }),
    ACTIVATE: intl.formatMessage({ defaultMessage: "Activate now" }),
    CREATE_NOW: intl.formatMessage({ defaultMessage: "Create now" }),
  },
  LABELS: {
    ACCOUNTS: intl.formatMessage({ defaultMessage: "Accounts" }),
    SELECT_ACCOUNT: intl.formatMessage({ defaultMessage: "Select account to edit" }),
    EDIT_ACCOUNT: intl.formatMessage({ defaultMessage: "Edit account" }),
    CUSTODY: intl.formatMessage({ defaultMessage: "Custody" }),
    DERIVATIVES: intl.formatMessage({ defaultMessage: "Derivatives" }),
    EXCHANGE: intl.formatMessage({ defaultMessage: "Exchange" }),
    SEARCH_ACCOUNTS: intl.formatMessage({ defaultMessage: "Search accounts" }),
    NONE_FOUND: intl.formatMessage({ defaultMessage: "None Found" }),
  },
});

export const getAccountEditCopy = (intl: IntlShape, newMav: string) => ({
  ARIA: {
    CROSS_COLLATERAL_TOGGLE: intl.formatMessage({ defaultMessage: "Cross Collateral Toggle" }),
    NEGATIVE_BALANCES_TOGGLE: intl.formatMessage({ defaultMessage: "Negative Balances Toggle" }),
  },
  CTA: {
    CANCEL_BUTTON: intl.formatMessage({ defaultMessage: "Cancel" }),
    SAVE_BUTTON: intl.formatMessage({ defaultMessage: "Save" }),
    TRANSFER_BUTTON: intl.formatMessage({ defaultMessage: "Transfer funds" }),
  },
  LABELS: {
    RENAME_ACCOUNT: intl.formatMessage({ defaultMessage: "Rename account" }),
    LEVERAGE: intl.formatMessage({ defaultMessage: "Leverage" }),
    LEVERAGE_DESCRIPTION: intl.formatMessage({
      defaultMessage: "Set the account's maximum leverage when trading perpetual swaps.",
    }),
    CROSS_COLLATERAL: intl.formatMessage({ defaultMessage: "Cross collateral" }),
    CROSS_COLLATERAL_DESCRIPTION: intl.formatMessage({
      defaultMessage: "Allow eligible assets such as BTC to contribute to your Margin Assets Value.",
    }),
    NEGATIVE_BALANCES: intl.formatMessage({
      defaultMessage: "Negative balances",
    }),
    NEGATIVE_BALANCES_DESCRIPTION: intl.formatMessage({
      defaultMessage: "Allow negative GUSD balance in your account to avoid automatic conversion of cross collateral.",
    }),
  },
  MESSAGES: {
    SUCCESS: intl.formatMessage({
      defaultMessage: "Settings updated.",
    }),
    MAV_SUCCESS: intl.formatMessage(
      defineMessage({
        defaultMessage: "Settings updated. Your new MAV is {newMav} GUSD.",
      }),
      {
        newMav,
      }
    ),
    TRANSFER_SUCCESS: intl.formatMessage({
      defaultMessage: "To benefit from using cross collateral transfer eligible assets such as BTC to your account.",
    }),
    DEFAULT_ERROR: intl.formatMessage({
      defaultMessage: "Something went wrong.  Please try again.",
    }),
    NEGATIVE_BALANCES_STILL_ON: intl.formatMessage({
      defaultMessage: "Unable to turn off cross collateral as negative balances are still enabled.",
    }),
  },
});
