import { EmptyState } from "@gemini-ui/design-system";
import { VirtualAccountStatus } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { useIntl } from "@gemini-ui/utils/intl";

export type StatusGuardProps = {
  vaStatus: VirtualAccountStatus;
  children: JSX.Element;
};

export const StatusGuard = ({ vaStatus, children }: StatusGuardProps) => {
  const { intl } = useIntl();
  if (
    Boolean(vaStatus) &&
    (vaStatus === "Pending" || vaStatus === "Unavailable" || vaStatus === "Deleted" || vaStatus === "Rejected")
  ) {
    const isPending = vaStatus === "Pending";
    const label = isPending
      ? intl.formatMessage({
          defaultMessage: "Your deposit instructions are being prepared.",
        })
      : intl.formatMessage({
          defaultMessage: "Sorry, we couldn’t get your FAST deposit instructions",
        });
    const description = isPending
      ? intl.formatMessage({
          defaultMessage: "It usually takes up to 24 hours. We will notify you as soon as it's ready.",
        })
      : intl.formatMessage({
          defaultMessage: "Please try again or contact customer support.",
        });
    return <EmptyState label={label} description={description} status={isPending ? "informational" : "negative"} />;
  }
  return children;
};
