import { Fragment, useMemo } from "react";
import { useTheme } from "@emotion/react";
import { IconClockOutlined } from "@hubble/icons";
import { addBusinessDays, format } from "date-fns";
import { Money } from "@gemini-ui/components/Money";
import { Button, Card, Flex, IconBadge, Spacing, Text, Timeline } from "@gemini-ui/design-system";
import { PlaceRedeemInfo } from "@gemini-ui/pages/Earn/Redeem/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props extends Pick<PlaceRedeemInfo, "provider" | "redemptionStatus" | "amount" | "currency"> {
  onDone?: () => void;
}

export const PartialSuccess = ({ amount, provider, currency, redemptionStatus, onDone }: Props) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();
  const isPartialRedeemed = !redemptionStatus.redeemedInFull && Number(redemptionStatus.amountRedeemed.value) > 0;
  const remainingAmount = isPartialRedeemed
    ? Number(amount) - Number(redemptionStatus.amountRedeemed.value)
    : undefined;

  const { initiatedDate, availableFundsDate } = useMemo(
    () => ({
      initiatedDate: format(new Date(), DateFormats.MonthAbbrevDayYear),
      // TODO: This won't always be business days, see ticket for BE updates
      // https://gemini-spaceship.atlassian.net/browse/GEM-49072?focusedCommentId=498543
      availableFundsDate: format(
        addBusinessDays(new Date(), provider.maxWithdrawDelay),
        DateFormats.MonthAbbrevDayYear
      ),
    }),
    [provider.maxWithdrawDelay]
  );

  const title = (
    <Fragment>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" mb={2} gap={Spacing.scale[0.5]}>
        <Flex alignItems="center" justifyContent="center" mb={1}>
          <IconBadge
            color={colorScheme.status.default.content.informational}
            backgroundColor={colorScheme.status.default.background.informational}
            size="xl"
            icon={<IconClockOutlined size="xl" />}
          />
        </Flex>
        <Text.Heading size="md" data-testid={testIds.redeem.partialSuccess.title}>
          {intl.formatMessage({ defaultMessage: "Your unstake is pending." })}
        </Text.Heading>
        <Text.Body
          size="sm"
          center
          color={colorScheme.content.secondary}
          data-testid={testIds.redeem.partialSuccess.body}
        >
          <Money currency={currency} value={amount} />
        </Text.Body>
      </Flex>
    </Fragment>
  );

  return (
    <Fragment>
      {title}
      <Card variant="filled" padding="none">
        <Timeline
          data-testid={testIds.redeem.partialSuccess.timeline}
          mt={3}
          mb={3}
          steps={[
            {
              label: intl.formatMessage({ defaultMessage: "Initiated" }),
              sublabel: isPartialRedeemed
                ? intl.formatMessage(defineMessage({ defaultMessage: "<Money></Money> unstaked" }), {
                    Money: () => <Money {...redemptionStatus.amountRedeemed}></Money>,
                  })
                : undefined,
              metadata: initiatedDate,
            },
            {
              label: intl.formatMessage({ defaultMessage: "Pending" }),
              sublabel: intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "{isPartialRedeemed, select, true {<Money></Money> remaining} other {Gemini requested funds from network}}",
                }),
                {
                  isPartialRedeemed,
                  Money: () => <Money value={remainingAmount} currency={redemptionStatus.amountRedeemed.currency} />,
                }
              ),
              metadata: initiatedDate,
              isCurrentStep: true,
            },
            {
              label: intl.formatMessage({ defaultMessage: "Complete" }),
              sublabel: intl.formatMessage({ defaultMessage: "Estimated release date" }),
              metadata: intl.formatMessage(
                defineMessage({
                  defaultMessage: "Estimated {availableFundsDate}",
                }),
                { availableFundsDate }
              ),
            },
          ]}
        />
      </Card>
      <Text.Body
        size="xs"
        center
        color={colorScheme.content.secondary}
        data-testid={testIds.redeem.partialSuccess.message}
      >
        {intl.formatMessage({
          defaultMessage:
            "We will notify you when the transfer is complete. You can view the unstaking status in your Portfolio.",
        })}
      </Text.Body>
      <Button.Group type="stacked" css={{ marginTop: Spacing.scale[1] }}>
        <Button.Primary
          size="lg"
          mb={1}
          onClick={() => {
            onDone?.();
          }}
          cta={intl.formatMessage({ defaultMessage: "Done" })}
        />
        <Button.Secondary
          size="lg"
          mb={1}
          onClick={() =>
            window.location.assign(
              jsRoutes.com.gemini.web.server.trading.controllers.DashboardController.portfolio().url
            )
          }
          cta={intl.formatMessage({ defaultMessage: "Go to Portfolio" })}
        />
      </Button.Group>
    </Fragment>
  );
};
