import { useEffect, useState } from "react";
import { optimizelyClient } from "@gemini-ui/analytics";
import { PaymentMethodName } from "@gemini-ui/components/PaymentMethodName";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageRefresh } from "@gemini-ui/contexts";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import { SuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/SuccessModal";
import { AutoLinkedSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/AutoLinkedSuccessModal";
import {
  RegisteredXfersAccount,
  SupportedBanks,
  XfersBankRegFlowProps,
  XfersStep,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/constants";
import { MaximumAccountsModal } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/MaximumAccountsModal";
import {
  hasMultipleXfersAccounts,
  initStep,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/utils";
import { XfersBankRegistration } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/XfersBankRegistration";
import { WireInstructionsModal } from "@gemini-ui/pages/settings/BankSettings/components/WireInstructionsModal";
import axios from "@gemini-ui/services/axios";
import { extractPaymentMethodMetaDataFromName, XfersAccountType } from "@gemini-ui/transformers/PaymentMethods";

const XfersFlow = ({
  onClose,
  onBack,
  currency,
  isOpen,
  xfers: xfersData,
  isSettingsOrOnboarding,
  scope,
  subaccountHashid,
}: XfersBankRegFlowProps) => {
  const { requestRefresh } = usePageRefresh();
  const [xfersAccount, setXfersAccount] = useState<RegisteredXfersAccount>(null);
  const [userBankAccounts, setUserBankAccounts] = useState<XfersAccountType[]>([]);
  const [supportedBanks, setSupportedBanks] = useState<SupportedBanks>([]);
  const [step, setStep] = useState<XfersStep>(initStep(xfersData?.accounts, xfersData));
  const [customerProfileCreated, setCustomerProfileCreated] = useState(false);
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);

  const handlesAddBankSubmit = (
    registeredXfersAccount: RegisteredXfersAccount,
    userBankAccounts: XfersAccountType[]
  ) => {
    requestRefresh();
    setUserBankAccounts(userBankAccounts);
    setXfersAccount(registeredXfersAccount);
    hasMultipleXfersAccounts(userBankAccounts) ? setStep(XfersStep.TOGGLE_LIST) : setStep(XfersStep.SUCCESS_MODAL);
  };
  /* istanbul ignore next */
  const registerXfersUser = async () => {
    try {
      await axios.post(jsRoutes.com.gemini.web.server.funding.controllers.XfersSettingsController.registerUser().url);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (xfersData && !xfersData.hasCustomerProfile && !customerProfileCreated) {
      registerXfersUser();
      setCustomerProfileCreated(true);
    }
  }, [xfersData, customerProfileCreated]);

  // Short circuit the flow here if previously linked xfers banks come back after OTP
  if (step === XfersStep.TOGGLE_LIST) {
    return (
      <AutoLinkedSuccessModal
        onClose={onClose}
        autoLinkedAccounts={userBankAccounts}
        handleClickContinue={() => setStep(XfersStep.SUCCESS_MODAL)}
      />
    );
  }

  if (step === XfersStep.SUCCESS_MODAL) {
    const paymentMethodMetaData = extractPaymentMethodMetaDataFromName(
      xfersAccount?.name || userBankAccounts[0].displayName
    );
    return isNewAddPaymentsFlowEnabled ? (
      <AddPaymentMethodSuccessModal
        isOpen={isOpen}
        onClose={onClose}
        paymentMethodType={AddPaymentMethodType.XFERS}
        showDepositInstructions={() => setStep(XfersStep.SHOW_DEPOSIT_INSTRUCTIONS)}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
      />
    ) : (
      <SuccessModal
        onClose={onClose}
        currency={xfersAccount?.xfersCurrency || (userBankAccounts && userBankAccounts[0].currencies[0]) || currency}
        name={<PaymentMethodName {...paymentMethodMetaData} />}
        registrationFlow
        hasMultipleAccountsFlow={hasMultipleXfersAccounts(userBankAccounts)}
      />
    );
  }

  if (step === XfersStep.SHOW_DEPOSIT_INSTRUCTIONS) {
    return (
      <WireInstructionsModal
        currency={xfersAccount?.xfersCurrency || (userBankAccounts && userBankAccounts[0].currencies[0]) || currency}
        onClose={onClose}
        isOpen={isOpen}
        subaccountHashid={subaccountHashid}
      />
    );
  }

  // five banks connected via Xfers
  if (step === XfersStep.MAX_ACCOUNTS) return <MaximumAccountsModal onClose={onClose} currency={currency} />;

  return (
    <XfersBankRegistration
      setSupportedBanks={setSupportedBanks}
      supportedBanks={supportedBanks}
      onClose={onClose}
      onSubmit={handlesAddBankSubmit}
      onBack={() => onBack()}
      isOpen={isOpen}
    />
  );
};

export default XfersFlow;
