// eslint-disable-next-line no-restricted-imports
import axios, { AxiosPromise, AxiosRequestConfig, CancelTokenSource } from "axios";
import instance from "@gemini-ui/services/axios/index";
import { HEADERS } from "@gemini-ui/services/constants";

const CANCELLED_REQUEST = "Canceled in-flight request.";

export interface Refresher {
  requestRefresh<T = any>(url: string): AxiosPromise<T>;
}

export const createRefresher = (): Refresher => {
  let cancelToken: CancelTokenSource | null = null;

  return {
    requestRefresh: url => {
      if (cancelToken) cancelToken.cancel(CANCELLED_REQUEST);

      cancelToken = axios.CancelToken.source();

      return instance
        .get(url, {
          cancelToken: cancelToken.token,
          headers: {
            [HEADERS.REFRESH_ONLY]: "refresh",
          },
        })
        .finally(() => {
          if (cancelToken) cancelToken.cancel();
        });
    },
  };
};

export const getDedupedRequest = (method: AxiosRequestConfig["method"]) => {
  let cancelToken: CancelTokenSource | null = null;

  return <T = any>(url: string, config: AxiosRequestConfig): AxiosPromise<T> => {
    if (cancelToken) cancelToken.cancel(CANCELLED_REQUEST);

    cancelToken = axios.CancelToken.source();

    return instance
      .request<T>({
        url,
        method,
        cancelToken: cancelToken.token,
        ...config,
      })
      .finally(() => {
        if (cancelToken) cancelToken.cancel();
      });
  };
};
