import { useRef } from "react";
import { useKeyPressEvent } from "react-use";
import { ButtonProps } from "@gemini-ui/design-system/Button";
import { StyledSubmitButton } from "@gemini-ui/pages/Credit/CreditApplication/AppCurrent/styles";

const ENTER_KEY = "Enter";

export const SubmitButton = (props: ButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useKeyPressEvent(ENTER_KEY, () => {
    if (!props.disabled) {
      buttonRef?.current?.click();
    }
  });

  return (
    <StyledSubmitButton ref={buttonRef} {...props}>
      {props.children && props.children}
    </StyledSubmitButton>
  );
};
