import { SyntheticEvent } from "react";
import { EVENTS, optimizelyClient } from "@gemini-ui/analytics";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";

export const isCashDepositEnabled = () =>
  optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CASH_DEPOSIT_FLOW);

export const handleCashDepositClick = (e: SyntheticEvent, handleOpenModal: () => void, initiatedFrom: string) => {
  e.preventDefault();
  const isEnabled = isCashDepositEnabled();
  const { name, properties } = EVENTS.DEPOSIT_START;
  if (isEnabled) {
    handleOpenModal();
    mixpanelClient.track(name, {
      [properties.INITIATED_FROM]: initiatedFrom,
      [properties.JOURNEY_TYPE]: "NEW",
    });
  } else {
    mixpanelClient.trackLinkClickAndRedirectManually(
      jsRoutes.com.gemini.web.server.funding.controllers.TransferPageController.get().url,
      name,
      {
        [properties.INITIATED_FROM]: initiatedFrom,
      }
    );
  }
};
