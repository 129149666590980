import { Component } from "react";
import * as Sentry from "@sentry/browser";
import {
  addDays,
  addWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  Duration,
  isBefore,
} from "date-fns";
import { now } from "lodash";
import { Tooltip } from "@gemini-ui/design-system";
import { IntlShape, withIntl } from "@gemini-ui/utils/intl";

interface RelativeDateProps {
  style?: "tooltip" | "inline" | "either" | "oneday" | "fullday";
  className?: string;
  date: number | Date;
  tooltipTestId?: string;
  intl: IntlShape;
}

const DATE_FORMAT_CONSTANTS = {
  numeric: "numeric",
  twoDigit: "2-digit",
  short: "short",
} as const;

const DEFAULT_PROPS = {
  style: "tooltip",
  tooltipTestId: "relative-date-tooltip",
} as const;

class RelativeDate extends Component<RelativeDateProps> {
  static defaultProps = { style: DEFAULT_PROPS.style, tooltipTestId: DEFAULT_PROPS.tooltipTestId };
  interval: number;

  componentDidMount() {
    if (this.isLessThanOneWeekAgo()) {
      // Updates every 10 seconds
      this.interval = window.setInterval(() => {
        this.forceUpdate();
      }, 10000);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  isLessThanOneWeekAgo = () => {
    return isBefore(new Date(), addWeeks(this.props.date, 1));
  };

  isLessThanOneDayAgo = () => {
    return isBefore(new Date(), addDays(this.props.date, 1));
  };

  render() {
    const { intl } = this.props;

    const inLastWeek = this.isLessThanOneWeekAgo();
    const inLastDay = this.isLessThanOneDayAgo();

    const { date, style, tooltipTestId, className } = this.props;
    const oneDayFormat = intl.formatDate(date, {
      hour: DATE_FORMAT_CONSTANTS.numeric,
      second: DATE_FORMAT_CONSTANTS.numeric,
    });
    const fullFormat = intl.formatDate(date, {
      day: DATE_FORMAT_CONSTANTS.twoDigit,
      month: DATE_FORMAT_CONSTANTS.short,
      year: DATE_FORMAT_CONSTANTS.numeric,
      hour: DATE_FORMAT_CONSTANTS.numeric,
      minute: DATE_FORMAT_CONSTANTS.numeric,
      second: DATE_FORMAT_CONSTANTS.numeric,
    });

    const DifferenceCalculators: {
      [unit in keyof Duration]: (dateLeft: Date | number, dateRight: Date | number) => number;
    } = {
      years: differenceInYears,
      months: differenceInMonths,
      weeks: differenceInWeeks,
      days: differenceInDays,
      hours: differenceInHours,
      minutes: differenceInMinutes,
      seconds: differenceInSeconds,
    };

    const getRelativeFormatText = () => {
      let relativeFormatTime;

      Object.keys(DifferenceCalculators).every(unit => {
        const diff = DifferenceCalculators[unit](new Date(date), now());
        if (diff < 0) {
          relativeFormatTime = intl.formatRelativeTime(diff, unit as keyof Duration);
          return false;
        }
        return true;
      });

      return relativeFormatTime;
    };

    const relativeFormat = getRelativeFormatText();

    let text = (() => {
      if (!inLastWeek || (style === "oneday" && !inLastDay)) {
        return fullFormat;
      } else if (style === "oneday") {
        return oneDayFormat;
      } else if (style === "either" || style === "tooltip") {
        return relativeFormat;
      } else if (style === "inline") {
        return `${relativeFormat} (${fullFormat})`;
      } else if (style === "fullday") {
        return fullFormat;
      } else {
        Sentry.captureMessage(`Unknown RelativeDate style '${style}'.`, "error");
        return relativeFormat;
      }
    })();

    if (!relativeFormat) {
      text = "-";
    }

    if (inLastWeek && style === "tooltip") {
      return (
        <Tooltip data-testid={tooltipTestId} overlay={fullFormat}>
          <span className={className}>{text}</span>
        </Tooltip>
      );
    } else {
      return <span className={className}>{text}</span>;
    }
  }
}

export default withIntl(RelativeDate);
