import React, { Fragment } from "react";
import { Global } from "@emotion/react";
import { IconArrowDown, IconArrowUp } from "@hubble/icons";
import BigNumber from "bignumber.js";
import cx from "classnames";
import RCTooltip from "rc-tooltip";

import {
  getDecimalsForPrice,
  getMaxDecimalsForCurrency,
  isCurrency,
  isFiatCurrency,
  priceCurrency,
  quantityCurrency,
} from "../constants/currencies";
import Num from "../Num";

import { DEFAULT_PRECISION, MoneyComponentProps, PriceProps } from "./constants";
import { MoneyTail, overlayStyles, StyledMoney } from "./styles";
import { formatNumOptions, getBigNumberFromValue, initMoneyFormat, truncateValue } from "./utils";

const Price = (props: PriceProps) => {
  const { tradingPair, value, average, decimalsOverride, currencyPairDetail, hideCurrency, ...otherProps } = props;
  const currency = hideCurrency
    ? undefined
    : currencyPairDetail
    ? currencyPairDetail.priceCurrency
    : priceCurrency(tradingPair);
  const priceDecimals = average ? getDecimalsForPrice(tradingPair) + 3 : getDecimalsForPrice(tradingPair);

  return (
    <Money
      currency={currency}
      value={value}
      // Respect any manual overrides passed from a parent component
      decimalsOverride={decimalsOverride || priceDecimals}
      {...otherProps}
    />
  );
};

const Quantity = (props: PriceProps) => {
  const { tradingPair, value, ...otherProps } = props;
  const currency = quantityCurrency(tradingPair);
  return <Money currency={currency} value={value} {...otherProps} />;
};

class Money extends React.Component<MoneyComponentProps> {
  static priceCurrency = priceCurrency;
  static fromCurrency = priceCurrency;
  static toCurrency = quantityCurrency;
  static quantityCurrency = quantityCurrency;
  static Price = Price;
  static Quantity = Quantity;

  static defaultProps = Object.freeze({
    round: "none",
    diff: false,
    "data-testid": "Money",
  });

  isFiat() {
    return isFiatCurrency(this.props.currency);
  }

  classNames(num: BigNumber) {
    const { className, diff } = this.props;
    // this is a hack until we want to emotionify this
    return cx(className, {
      "money-value": true,
      "value-change": diff && !num.isZero(),
      positive: diff && num.isGreaterThan(0),
      negative: diff && num.isLessThan(0),
    });
  }

  trailingSign() {
    const { hideTrailingSign, trailingSign, currency } = this.props;

    if (Boolean(hideTrailingSign)) return null;

    return <MoneyTail> {trailingSign != null ? trailingSign : currency}</MoneyTail>;
  }

  formattedNumber(num: BigNumber) {
    let roundedBigNum;
    let { truncate } = this.props;
    const { currency, decimalsOverride, diff, locale, round, scale, removeTrailingZeros } = this.props;

    if (diff) {
      num = num.absoluteValue(); // always display as positive - classNames() will take care of adding a ^ or v arrow
    }

    const maxDecimals = decimalsOverride || getMaxDecimalsForCurrency(currency);
    const maxAllowedPrecision = Math.min(maxDecimals, DEFAULT_PRECISION);

    if (round !== "none") {
      // fuzzy rounding!  even if you pick 'up' or 'down', we don't want to be very strict about it.
      // so $1.231 with round="up" should round to $1.24, but $1.23000000000000003834 is probably
      // JavaScript floating point garbage and should end up saying $1.23 even if round="up".
      const roundPrecision = truncate ? maxAllowedPrecision : maxDecimals;
      const remainder = __mod__(num.times(Math.pow(10, roundPrecision)), 1); // so 0.1 is like 0.1 pennies or 0.1 satoshis
      if (remainder.isGreaterThanOrEqualTo(0.001) && remainder.isLessThanOrEqualTo(0.999)) {
        num = num.decimalPlaces(roundPrecision, round === "down" ? BigNumber.ROUND_DOWN : BigNumber.ROUND_UP);
      }
    } else if (!decimalsOverride && !scale && !truncate && !this.isFiat()) {
      // truncate number to 8 decimal places and round down to prevent Numbro from rounding the last digit up
      roundedBigNum = num.decimalPlaces(maxAllowedPrecision, BigNumber.ROUND_DOWN);
    }

    const numToFormat = roundedBigNum || num;
    const options = formatNumOptions({
      decimals: scale,
      currency,
      value: numToFormat,
      removeTrailingZeros,
      decimalsOverride,
    });
    const safeToLocalize = !isCurrency.COP(currency);

    const formatted =
      locale && safeToLocalize
        ? initMoneyFormat({
            currency,
            locale,
            decimals: decimalsOverride ?? options.decimals,
          }).format(numToFormat.toNumber())
        : Num.format(numToFormat.toString(), options); // Localize if the user's locale is provided

    if (truncate != null && !this.isFiat()) {
      truncate -= 3; // leave room for a small ' BTC' at the end.  for USD, the $ is already included in the length of the formatted string
    }

    if (truncate && formatted.length > truncate) {
      // oh no, it's too long!
      return (
        <Fragment>
          <Global styles={overlayStyles} />
          <RCTooltip
            destroyTooltipOnHide={{ keepParent: false }}
            placement="top"
            showArrow={false}
            overlayClassName="rc-tooltip--money"
            overlay={
              <span data-testid="Money-overlay">
                {formatted}
                {this.trailingSign()}
              </span>
            }
          >
            <span>{truncateValue(formatted, truncate, currency, num, decimalsOverride)}</span>
          </RCTooltip>
        </Fragment>
      );
    } else {
      return formatted;
    }
  }

  render() {
    const { ["data-testid"]: dataTestId, diff, value } = this.props;
    const num = getBigNumberFromValue(value);

    return (
      <StyledMoney
        positive={diff && num.isGreaterThan(0)}
        negative={diff && num.isLessThan(0)}
        data-testid={dataTestId}
        id={this.props.id}
        className={this.classNames(num)}
      >
        {diff &&
          !num.isZero() &&
          (num.isLessThan(0) ? (
            <IconArrowDown
              data-testid="icon-arrow-down"
              size="xs"
              style={{ verticalAlign: "-0.125em", height: "1em" }}
            />
          ) : (
            <IconArrowUp data-testid="icon-arrow-up" size="xs" style={{ verticalAlign: "-0.125em", height: "1em" }} />
          ))}
        {this.formattedNumber(num)}
        {this.trailingSign()}
      </StyledMoney>
    );
  }
}

function __mod__(a: BigNumber, b: number) {
  return a.mod(b).plus(b).mod(b);
}

export default Money;
