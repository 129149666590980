// eslint-disable-next-line no-restricted-imports
import axios, { AxiosInstance, CancelToken as CancelTokenInterface } from "axios";
import { AXIOS_BASE_CONFIG } from "@gemini-ui/services/axios/config";
import { credentialsRequestInterceptor } from "@gemini-ui/services/axios/interceptors/credentialsRequestInterceptor";
import { enhanceErrorMessageInterceptor } from "@gemini-ui/services/axios/interceptors/enhanceErrorMessageInterceptor";
import { loginRedirectInterceptor } from "@gemini-ui/services/axios/interceptors/loginRedirectInterceptor";
import { retryInterceptor } from "@gemini-ui/services/axios/interceptors/retryInterceptor";

export * from "./config";
export * from "./errorCodes";
export * from "./helpers";
// eslint-disable-next-line no-restricted-imports
export type {
  AxiosInstance,
  AxiosProgressEvent,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Cancel,
  CancelTokenSource,
  InternalAxiosRequestConfig,
} from "axios";
// eslint-disable-next-line no-restricted-imports
export { AxiosError, AxiosHeaders, isCancel } from "axios";

export type CancelToken = CancelTokenInterface;
export const CancelToken = axios.CancelToken;

const instance: AxiosInstance = axios.create(AXIOS_BASE_CONFIG);

// Apply request interceptors
instance.interceptors.request.use(credentialsRequestInterceptor);

// Apply response interceptors
instance.interceptors.response.use(undefined, loginRedirectInterceptor.bind(instance));
instance.interceptors.response.use(undefined, retryInterceptor.bind(instance));
instance.interceptors.response.use(undefined, enhanceErrorMessageInterceptor);

export default instance;
