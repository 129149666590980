import { CurrencyShortName, CurrencyShortNameCrypto } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { LockoutTransactionAction, lockoutTransactionAllowed, SiteLockout } from "@gemini-ui/components/Lockout/utils";
import { SELECT_ADDRESS_SCREEN_CONTENT } from "@gemini-ui/components/Transfer/CryptoDepositFlow/constants";
import { FEATURE_FLAG_VARIABLES, OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GeminiEntities, GeminiEntity, Subaccount } from "@gemini-ui/constants/templateProps/account";
import { isDerivativesAccount } from "@gemini-ui/pages/Settlement/TransferModal/Views/utils";
import { CryptoAddress, CurrencyWithBoolean, TransferAccount } from "@gemini-ui/pages/transfers/constants";
import { checkUnsupported } from "@gemini-ui/pages/transfers/Withdraw/WithdrawDestinationSelection/utils";

/**
 * Collection of business methods related to the deposit flow.
 */
export const BUSINESS_METHODS = {
  /* 1. These methods are used to determine if the user should be shown an error message before the currency selector screen.*/

  /**
   * Determines if the user should be shown an error message before the currency selector screen.
   * @param action - The action being performed.
   * @param lockout - The lockout status.
   * @param sandboxWalletEnabled - The sandbox wallet status.
   * @param isEligibleForCryptoDeposit - approved for deposit
   * @returns An object containing the result and individual conditions.
   */

  isErrorBeforeCurrencySelectorScreen: (
    action: LockoutTransactionAction,
    lockout: SiteLockout,
    sandboxWalletEnabled: boolean,
    isEligibleForCryptoDeposit: boolean,
    destination: Subaccount
  ): {
    value: boolean;
    lockoutRestriction: boolean;
    isDerivativesAccount: boolean;
  } => {
    const lockoutRestriction = !BUSINESS_METHODS.isAuthorizedToDepositBasedOnLockoutConditions(
      action,
      lockout,
      sandboxWalletEnabled,
      isEligibleForCryptoDeposit
    );
    const isDerivativesAccount = BUSINESS_METHODS.checkIfDerivativeAccount(destination);
    const value = lockoutRestriction || isDerivativesAccount;
    return {
      value,
      lockoutRestriction,
      isDerivativesAccount,
    };
  },

  /**
   * Determines if the user is authorized to deposit based on lockout conditions.
   * @param action - The action being performed.
   * @param lockout - The lockout status.
   * @param sandboxWalletEnabled - The sandbox wallet status.
   * @param isEligibleForCryptoDeposit - The document approval status.
   * @returns A boolean indicating if the user is authorized to deposit.
   */
  isAuthorizedToDepositBasedOnLockoutConditions: (
    action: LockoutTransactionAction,
    lockout: SiteLockout,
    sandboxWalletEnabled: boolean,
    isEligibleForCryptoDeposit: boolean
  ) => {
    return lockoutTransactionAllowed({
      action: action,
      authorized: isEligibleForCryptoDeposit,
      lockout,
      sandbox: sandboxWalletEnabled,
    });
  },

  /* --------------------------------------------------------------------------------------------------------------------------------- */

  /* 2. These methods are used for business logic related to assets error screen. */

  /**
   * Determines if the Gemini entity is Gemini UK.
   * @param geminiEntity - The Gemini entity.
   * @returns A boolean indicating if the Gemini entity is Gemini UK.
   */
  getIsGeminiUk: (geminiEntity: GeminiEntity): boolean =>
    (
      [
        GeminiEntities.GeminiEurope,
        GeminiEntities.GeminiEuropeEmployees,
        GeminiEntities.GeminiEuropeInstitutional,
      ] as const
    ).includes(geminiEntity),

  /**
   * Gets the unsupported outbound assets for a currency.
   * @param currency - The currency.
   * @returns The unsupported assets for the currency.
   */
  getOutBoundChecks: (currency: CurrencyShortName) => {
    const unsupportedAssets = optimizelyClient.getFeatureVariableJSON(
      OPTIMIZELY_FEATURE_FLAGS.WEB_UK_OUTBOUND_TRAVEL_RULE,
      FEATURE_FLAG_VARIABLES.WEB_UK_OUTBOUND_TRAVEL_RULE.UNSUPPORTED_TOKENS
    );

    return unsupportedAssets?.tokens && checkUnsupported(unsupportedAssets, currency);
  },

  /**
   * Determines if UK inbound travel rule is enabled for Gemini UK.
   * @param geminiEntity - The Gemini entity.
   * @returns A boolean indicating if UK inbound travel rule is enabled for Gemini UK.
   */
  getIsUkInboundEnabled: (geminiEntity: GeminiEntity): boolean => {
    return BUSINESS_METHODS.getIsGeminiUk(geminiEntity);
  },

  /**
   * Determines the UK rule condition for a currency and Gemini entity.
   * @param currency - The currency.
   * @param geminiEntity - The Gemini entity.
   * @returns A boolean indicating the [UK rule] condition.
   */
  getUkRuleCondition: (currency: CurrencyShortName, geminiEntity: GeminiEntity) => {
    const isUkInboundEnabled = BUSINESS_METHODS.getIsUkInboundEnabled(geminiEntity);
    const isOutboundCheck = BUSINESS_METHODS.getOutBoundChecks(currency);
    return isUkInboundEnabled && isOutboundCheck;
  },

  getFilteredAssetsForCustody: (currencyList, exchangeOnlyAssets: CurrencyShortName[]) => {
    return currencyList.filter(currency => !exchangeOnlyAssets.includes(currency?.symbol));
  },

  /**
   * Determines if the transaction is disabled for a currency.
   * @param currency - The currency.
   * @param transactionsEnabled - The enabled transactions.
   * @returns A boolean indicating if the transaction is disabled.
   */
  getTransactionDisabled: (currency: CurrencyShortName, transactionsEnabled: CurrencyWithBoolean) => {
    return !transactionsEnabled?.[currency];
  },

  /**
   * Gets the assets error for a currency.
   * @param currency - The currency.
   * @param transactionsEnabled - The enabled transactions.
   * @param geminiEntity - The Gemini entity.
   * @returns An object containing the result and individual conditions.
   */
  getAssetsError: (currency: CurrencyShortName, transactionsEnabled, geminiEntity: GeminiEntity) => {
    const isUkRuleCondition = BUSINESS_METHODS.getUkRuleCondition(currency, geminiEntity);
    const isTransactionDisabled = BUSINESS_METHODS.getTransactionDisabled(currency, transactionsEnabled);
    const value = isUkRuleCondition || isTransactionDisabled;
    return {
      value,
      isUkRuleCondition,
      isTransactionDisabled,
    };
  },

  /* --------------------------------------------------------------------------------------------------------------------------------- */

  /* 3. These methods are used to determine if the user should be shown an error message before the destination selector screen.*/

  /**
   * Determines if the destination is a custody account.
   * @param destination - The destination.
   * @returns A boolean indicating if the destination is a custody account.
   */
  isCustodyAccount: (destination: TransferAccount): boolean => {
    return Boolean(destination?.coldStorage);
  },

  /**
   * Determines if the destination is a restricted derivative transfer.
   * @param destination - The destination.
   * @returns A boolean indicating if the destination is a restricted derivative transfer.
   */
  checkIfDerivativeAccount: (destination: Subaccount): boolean => {
    return isDerivativesAccount(destination);
  },

  /* --------------------------------------------------------------------------------------------------------------------------------- */

  /*  4. These methods are used  for business logic related to address selector screen . */

  /**
   * Determines if an address can be generated for a destination.
   * @param canAddAddress - The ability to add an address.
   * @param destination - The destination.
   * @returns A boolean indicating if an address can be generated.
   */
  canGenerateAddress: (canAddAddress: CurrencyWithBoolean, destination: TransferAccount): boolean => {
    return canAddAddress && !BUSINESS_METHODS.isCustodyAccount(destination);
  },

  /**
   * Determines if the custody deposit message should be shown for a destination and addresses.
   * @param destination - The destination.
   * @param addresses - The addresses.
   * @returns A boolean indicating if the custody deposit message should be shown.
   */
  shouldShowCustodyDepositMessage: (destination: TransferAccount, addresses: CryptoAddress[]): boolean => {
    return BUSINESS_METHODS.isCustodyAccount(destination) && (!addresses || addresses.length === 0);
  },

  /**
   * Determines if the no address available value should be shown for a canAddAddress and addresses.
   * @param canAddAddress - The ability to add an address.
   * @param addresses - The addresses.
   * @returns A boolean indicating if the no address available value should be shown.
   */
  showNoAddressAvailableValue: (
    currency: CurrencyShortNameCrypto,
    canAddAddress: CurrencyWithBoolean,
    addresses: CryptoAddress[]
  ): boolean => {
    return !canAddAddress && !canAddAddress[currency] && !addresses.length;
  },

  /**
   * Determines the condition for the address selector content.
   * @param currency - The currency.
   * @param canAddAddress - The ability to add an address.
   * @param addresses - The addresses.
   * @param destination - The destination.
   * @returns The condition for the address selector content.
   */
  getAddressSelectorContentCondition: (
    currency: CurrencyShortNameCrypto,
    canAddAddress: CurrencyWithBoolean,
    addresses: CryptoAddress[],
    destination: TransferAccount
  ): (typeof SELECT_ADDRESS_SCREEN_CONTENT)[keyof typeof SELECT_ADDRESS_SCREEN_CONTENT] => {
    let condition;
    if (BUSINESS_METHODS.shouldShowCustodyDepositMessage(destination, addresses)) {
      condition = SELECT_ADDRESS_SCREEN_CONTENT.SHOW_CUSTODY_DEPOSIT_MESSAGE_VALUE;
    } else if (BUSINESS_METHODS.showNoAddressAvailableValue(currency, canAddAddress, addresses)) {
      condition = SELECT_ADDRESS_SCREEN_CONTENT.SHOW_NO_ADDRESS_AVAILABLE_VALUE;
    } else {
      condition = SELECT_ADDRESS_SCREEN_CONTENT.SHOW_LIST;
    }
    return condition;
  },

  /* --------------------------------------------------------------------------------------------------------------------------------- */
};
